import {
  ItemComponent,
  MegaMenuComponent,
  TitleBarComponent
} from "@/components/megaMenu/MegaMenu.component";
import AlertComponent from "@/components/sharedComponents/alert/alert.component";
import NotifyComponent from "@/components/sharedComponents/notify/notify.component";
import OverlayComponent from "@/components/sharedComponents/overlay/overlay.component";
import PrintComponent from "@/components/sharedComponents/print/print.component";
import SessionAboutEndComponent from "@/components/sharedComponents/SessionAboutEnd/SessionAboutEnd.component";
import { Location } from "@/interfaces/location";
import {
  MenuCategory,
  MenuPolicy,
  MenuSection,
  MenuSubSection
} from "@/interfaces/menuSection";
import { Modal } from "@/interfaces/modal";
import { ModalNotification } from "@/interfaces/notification";
import { EventBus } from "@/internal";
import { ChangesNotificationService } from "@/plugins/changes-notifications/changes-notification.service";
import { BooleanCheck, Callback } from "@/types/types";
import { HelixPageNavComponent, PageNav } from "helix-vue-components";
import isArray from "lodash/isArray";
import reduce from "lodash/reduce";
import Fullscreen from "vue-fullscreen/src/component.vue";
import { Component, Provide, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { DtrumApi } from "./@types/dtrum";
import Template from "./App.template.vue";
import { pusherEvents } from "./enums/pusherEvents";
import { User } from "./interfaces/user";
import { MenuSectionMetadata } from "./metadata/menuSection";
import { traceabilityService } from "./services/traceability.service";
import { UserPermissions } from "./vuex/modules/permission/permission.types";
const namespace: string = "MegaMenuModule";
interface FullScreenDocument extends Document {
  fullscreenElement: Element | null;
}

Component.registerHooks(["onIdle", "onActive"]);

@Component({
  mixins: [Template],
  components: {
    Fullscreen,
    MegaMenuComponent,
    TitleBarComponent,
    ItemComponent,
    NotifyComponent,
    OverlayComponent,
    PrintComponent,
    HelixPageNavComponent,
    SessionAboutEndComponent
  }
})
export default class App extends Vue {
  // Getting all sections
  @Getter("menuSections", { namespace })
  public sections!: MenuSection[];
  // Getting all props for the title bar
  @Getter("title", { namespace })
  public title!: string;
  @Getter("subTitle", { namespace })
  public subTitle!: string;
  @Getter("icon", { namespace })
  public icon!: string;
  @Getter("iconAction", { namespace })
  public iconAction!: Callback;
  @Getter("isLogged", { namespace: "AuthModule" })
  public isLogged!: boolean;
  @Getter("user", { namespace: "AuthModule" })
  public user!: User;
  @Getter("currentLocation", { namespace: "AuthModule" })
  public currentLocation!: Location;
  @Getter("pageNav", { namespace: "PageNavModule" })
  public pageNav!: PageNav;
  @Getter("userPermissions", { namespace: "PermissionsModule" })
  public userPerms!: UserPermissions;
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  @Action("setRetailSettings", { namespace: "AuthModule" })
  public setReatilSettings!: Callback;

  public modalData: Modal = { title: null, width: "358px", height: "188px" };
  public msgModal: ModalNotification = {
    title: "tilte",
    body: "body",
    captionButton: "OK"
  };
  public modal: boolean = false;
  public fullscreen: boolean = false;
  public scrollPosition = 0;
  public isScrollingUp = false;
  public isExpandedView = false;
  public pageNavHeight = 128;
  public megaMenuHeight = 0;
  public printing = false;
  public printEventStatus = false;
  public traceData: any;
  @Provide("$changes")
  public changes = new ChangesNotificationService();
  protected tokenInterval!: number;
  @Action("checkToken", { namespace: "AuthModule" })
  protected expAction!: Callback;
  @Action("registerApp", { namespace: "AuthModule" })
  protected registerApp!: Callback;
  @Action("unregisterApp", { namespace: "AuthModule" })
  protected unregisterApp!: Callback;
  protected idleModalOpened: boolean = false;

  public changeFullScreen() {
    // @ts-ignore
    this.$refs.fullscreen.toggle();
  }

  public fullscreenChange(fullscreen: boolean) {
    if (fullscreen) {
      this.getFullScreenElement()!.addEventListener("scroll", () => {
        const scroll = (this.$refs.fullscreen as Vue).$el.scrollTop;
        this.isExpandedView = false;
        this.isScrollingUp = this.scrollPosition > scroll;
        this.scrollPosition = scroll;
      });
    }
  }

  public showModal(params: { msgModal: ModalNotification }): void {
    this.$modals.load(
      AlertComponent,
      {
        size: "fit"
      },
      {
        msgModal: params.msgModal
      }
    );
  }

  public goHome() {
    window.scrollTo(0, 0);
  }

  public onSwipe(event: { direction: number; preventDefault: () => {} }) {
    this.isExpandedView = event.direction === 16;
    event.preventDefault();
  }

  public async onIdle() {
    if (this.idleModalOpened) {
      return;
    }
    this.idleModalOpened = true;
    try {
      await this.$modals
        .load(
          SessionAboutEndComponent,
          {
            size: "normal",
            positionY: "center",
            closable: false
          },
          {
            aboutEndTimeout: process.env.VUE_APP_SESSION_ABOUT_END
          }
        )
        .then(
          () => {
            this.idleModalOpened = false;
          },
          () => {
            /** Nothing to do */
          }
        );
    } catch (e) {
      this.idleModalOpened = false;
    }
  }

  public onPrint(printing: boolean) {
    this.printing = printing;
  }

  public onPrintEvent(printEventStatus: boolean) {
    this.printEventStatus = printEventStatus;
    setTimeout(() => {
      this.printEventStatus = false;
    }, 800);
  }

  get hasAvailableSections() {
    return this.availableSections.length > 0;
  }
  get availableSections() {
    if (this.isLogged && this.userPerms) {
      return reduce(
        this.sections,
        (acc: MenuSection[], section) => {
          if (
            (section.requiredPolicyGroup &&
              this.hasPermission(
                section.requiredPolicyGroup.policy,
                section.requiredPolicyGroup.group || "bdi"
              ) &&
              !section.requiredPolicyGroup) ||
            ((section.visibleCondition && section.visibleCondition()) ||
              !section.visibleCondition)
          ) {
            section.subSections = this.allowedSubSections(section.subSections);
            const hasSubsections = !!section.subSections.length;
            if (section.subSections.length && hasSubsections) {
              acc.push(section);
            }
          }
          return acc;
        },
        []
      );
    }
    return [];
  }

  protected allowedSubSections(
    subSections: MenuSubSection[]
  ): MenuSubSection[] {
    return subSections.reduce((subAcc: MenuSubSection[], subSection) => {
      let isSubsSectionAllowed;
      if (
        subSection.requiredSectionPolicy &&
        isArray(subSection.requiredSectionPolicy)
      ) {
        isSubsSectionAllowed = !!(subSection.requiredSectionPolicy as MenuPolicy[]).find(
          menuPolicy =>
            this.hasPermission(menuPolicy.policy, menuPolicy.group || "bdi")
        );
      } else {
        isSubsSectionAllowed =
          !subSection.requiredSectionPolicy ||
          this.hasPermission(
            (subSection.requiredSectionPolicy as MenuPolicy).policy,
            (subSection.requiredSectionPolicy as MenuPolicy).group || "bdi"
          );
      }
      if (isSubsSectionAllowed && (subSection.route || !subSection.route)) {
        subSection.categories =
          subSection.categories &&
          this.allowedCategories(subSection.categories);
        if (
          !subSection.categories ||
          subSection.categories.length ||
          subSection.showIfChildless
        ) {
          subAcc.push(subSection);
        }
      }
      return subAcc;
    }, []);
  }

  protected allowedCategories(categories: MenuCategory[]): MenuCategory[] {
    return categories.filter(category => {
      let isMenuCategoryAllowed = true;
      if (
        category.requiredCategoryPolicy &&
        isArray(category.requiredCategoryPolicy)
      ) {
        isMenuCategoryAllowed = this.hasPermission(
          category.requiredCategoryPolicy.policy,
          category.requiredCategoryPolicy.group || "bdi"
        );
      } else if (
        (category.requiredCategoryPolicy as MenuPolicy).policy !== ""
      ) {
        isMenuCategoryAllowed = this.hasPermission(
          (category.requiredCategoryPolicy as MenuPolicy).policy,
          (category.requiredCategoryPolicy as MenuPolicy).group || "bdi"
        );
      }
      if (
        (category.visibleCondition && category.visibleCondition()) ||
        !isMenuCategoryAllowed
      ) {
        return false;
      }
      return isMenuCategoryAllowed;
    });
  }
  protected getFullScreenElement(): Element | null {
    return (document as FullScreenDocument).fullscreenElement || null;
  }

  protected mounted() {
    // @ts-ignore
    if (+process.env.VUE_APP_DYNATRACE_EMIT && window.dtrum) {
      // @ts-ignore
      (dtrum as DtrumApi).signalLoadEnd();
    }

    window.addEventListener("scroll", () => {
      this.isScrollingUp = this.scrollPosition > window.scrollY;
      this.scrollPosition = window.scrollY;
      this.isExpandedView = false;
    });

    EventBus.$on("userHasNavigated", () => {
      this.isExpandedView = false;
    });

    this.megaMenuHeight = (this.$refs[
      "mega-menu"
    ] as MegaMenuComponent).$el.clientHeight;

    this.changes.do(pusherEvents.retailSettings, {
      callback: this.setReatilSettings,
      displayForCurrentUser: true
    });

    this.registerApp();
    window.addEventListener("beforeunload", this.unregisterApp);
  }

  protected async created() {
    const locationId = +this.user.settings.current_location_id!;
    const traceData = await traceabilityService.getLocationTraceability(
      locationId
    );
    this.$store.dispatch(
      "MegaMenuModule/setMenuSections",
      MenuSectionMetadata(traceData[0].value)
    );
    this.$i18n.locale =
      (this.user && this.user.settings && this.user.settings.locale) ||
      (this.currentLocation && this.currentLocation.locale) ||
      "en";
    EventBus.$on("modal", this.showModal);
    this.tokenInterval = setInterval(this.expAction, 15000);
  }

  protected onPageNavHeightChange(height: number) {
    this.pageNavHeight = height + 128;
  }
}
