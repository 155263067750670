import { Component } from "vue-property-decorator";
import TemplateEditor from "../../editors.declaration";
import UIBlockDefaultComponent from "../UIBlockDefault.component";
import Template from "./UIBlockImageField.template.vue";

import { ImageFieldDefaultConfig } from "../UIBlock.config";
import { ImageFieldDefaultValues } from "../UIBlock.declarations";

@Component({
  mixins: [Template]
})
export default class UIBlockImageFieldComponent extends UIBlockDefaultComponent {
  protected block: TemplateEditor.UIBlocks.Block = {
    model: {
      type: "line-item",
      model: { ...ImageFieldDefaultValues }
    },
    configuration: ImageFieldDefaultConfig
  };

  protected get containerStyles() {
    return {
      ...this.values,
      height: this.values.height ? this.values.height + "px" : "auto",
      width: this.values.width ? this.values.width + "px" : "auto"
    };
  }

  protected get imageStyles() {
    return {
      height: this.values.height
        ? this.active
          ? this.values.height - 3 + "px"
          : this.values.height + "px"
        : "auto",
      width: this.values.width
        ? this.active
          ? this.values.width - 3 + "px"
          : this.values.width + "px"
        : "auto"
    };
  }
}
