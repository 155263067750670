import { Room } from "@/interfaces/room";
import { RoomState } from "@/vuex/modules/room/room.types";
import { RootState } from "@/vuex/types";
import { TablePagination } from "helix-vue-components";
import { GetterTree } from "vuex";

type RoomGetter = GetterTree<RoomState, RootState>;

export const getters: RoomGetter = {
  rooms(state: RoomState): Room[] {
    return state.rooms;
  },
  loading(state: RoomState): boolean {
    return state.loading;
  },
  vendorToEdit(state: RoomState): Room | null {
    return state.editRoom;
  },
  modal(state: RoomState): boolean {
    return state.modal;
  },
  pagination(state: RoomState): TablePagination | null {
    return state.pagination;
  },
  allRooms(state: RoomState): Room[] {
    return state.allRooms;
  }
};
