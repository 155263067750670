import { Customer } from "@/interfaces/customer";
import { customerService } from "@/services/customer.service";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./NotPatientSelectedModal.template.vue";

@Component({
  mixins: [Template]
})
export default class NotPatientSelectedModalComponent extends Vue {
  @Prop({ required: true })
  public caregiver!: Customer;
  public isValid: boolean = false;
  private customers: Customer[] = [];
  private selectedCustomer: Customer | null = null;

  protected async createOrder() {
    this.isValid = await this.$validator.validate();
    if (this.isValid && this.selectedCustomer) {
      this.$emit("resolve", this.selectedCustomer);
    }
  }

  protected async mounted() {
    this.customers = await customerService.findPatients(
      this.caregiver.customer_id!
    );
  }
}
