import { policyList } from "@/enums/permissions";
import { SearchQuery } from "@/interfaces/httpQuery";
import { Pricebreak, PriceModel, PricingRule } from "@/interfaces/product";
import { TaxBreak } from "@/interfaces/taxCategory";
import { store } from "@/internal";
import { i18n } from "@/plugins/i18n";
import { AxiosResponse } from "axios";
import {
  Callback,
  TableAction,
  TableFieldType,
  TableSuccessModalResponse
} from "helix-vue-components";
import Vue from "vue";
import HttpService from "./http.service";
import { messagesService } from "./messages.service";

export interface GroupedTaxes {
  pre: number;
  post: number;
  normal: number;
}

class PricePointService extends HttpService {
  protected searchableField: SearchQuery[] = [
    {
      field: "name",
      type: "contains"
    }
  ];

  public getRowAction(
    openModal: Callback,
    deleteModal: Callback
  ): TableAction[] {
    return [
      {
        icon: "fal fa-pen",
        action: (...arg: PriceModel[]) => {
          const [pricePoint, index] = arg;
          const data = {
            index,
            pricePoint
          };
          openModal(data);
        },
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.modifyBatchLevelPricing
          )
      },
      {
        icon: "fal fa-trash-alt",
        modalActions: {
          modalNumber: 1,
          modalQuestion: i18n.t("price_point_delete_confirm").toString(),
          modalSuccessText: "yes",
          modalSuccessAction: (arg: TableSuccessModalResponse) => {
            deleteModal({ item: arg.item, index: arg.position });
            arg.unselectModal();
          },
          modalCancelText: "No"
        },
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.modifyBatchLevelPricing
          )
      }
    ];
  }
  public valueTax(price: number, tax: number) {
    return price * (tax / 100);
  }
  public taxDetailsValue(pricePoints: PriceModel[], unit: string) {
    if (
      pricePoints.length &&
      pricePoints[0].tax_category &&
      pricePoints[0].tax_category.taxes &&
      pricePoints[0].tax_category.taxes.length
    ) {
      const groupTax = this.getGroupedTaxes(pricePoints[0].tax_category.taxes);

      return pricePoints.map(item => {
        const pre = {
          value: item.price + this.valueTax(item.price, groupTax.pre),
          tax: this.valueTax(item.price, groupTax.pre)
        };

        const normal = {
          value: pre.value + this.valueTax(pre.value, groupTax.normal),
          tax: this.valueTax(pre.value, groupTax.normal)
        };
        const post = {
          value: normal.value + this.valueTax(normal.value, groupTax.post),
          tax: this.valueTax(normal.value, groupTax.post)
        };
        return {
          quantity: `${item.quantity} ${unit}`,
          price: item.price,
          preTax: pre.tax,
          postPreTax: pre.value,
          normalTax: normal.tax,
          postNormalTax: normal.value,
          excisePostTax: post.tax,
          post_tax: item.post_tax,
          member_level_name: item.member_level_name
        };
      });
    }
    return [];
  }

  public headerTaxDetails(
    pricePoints: PriceModel[],
    loadingTable: boolean = false
  ) {
    const formatTable = {
      fieldComponent: TableFieldType.currency,
      class: "tdt__headers__fieldLong"
    };
    if (
      !loadingTable &&
      pricePoints.length &&
      pricePoints[0].tax_category &&
      pricePoints[0].tax_category.taxes &&
      pricePoints[0].tax_category.taxes.length
    ) {
      const groupTax = pricePointService.getGroupedTaxes(
        pricePoints[0].tax_category.taxes
      );
      return [
        {
          value: "quantity",
          label: i18n.t("quantity").toString(),
          fieldComponent: TableFieldType.string,
          class: "tdt__headers__fieldLong",
          show: true
        },
        {
          value: "price",
          label: i18n.t("price").toString(),
          fieldComponent: TableFieldType.currency,
          class: "tdt__headers__fieldLong",
          show: true
        },
        {
          ...formatTable,
          value: "preTax",
          label: `${i18n.t("excise_pre_tax").toString()} (${groupTax.pre}%)`,
          show: groupTax.pre
        },
        {
          ...formatTable,
          value: "postPreTax",
          label: i18n.t("post_excise_pre_tax").toString(),
          show: groupTax.pre && groupTax.normal
        },
        {
          ...formatTable,
          value: "normalTax",
          label: `${i18n.t("normal_tax").toString()} (${groupTax.normal}%)`,
          show: groupTax.normal
        },
        {
          ...formatTable,
          value: "postNormalTax",
          label: i18n.t("post_normal_tax").toString(),
          show: groupTax.normal && groupTax.post
        },
        {
          ...formatTable,
          value: "excisePostTax",
          label: `${i18n.t("excise_post-Tax").toString()} (${groupTax.post}%)`,
          show: groupTax.post
        },
        {
          ...formatTable,
          value: "post_tax",
          label: i18n.t("post_tax").toString(),
          show: true
        },
        {
          fieldComponent: TableFieldType.string,
          class: "tdt__headers__fieldLong",
          value: "member_level_name",
          label: i18n.t("member_level").toString(),
          show: true
        }
      ];
    }
    return [];
  }

  public getGroupedTaxes(taxes: TaxBreak[]) {
    let groupedTaxes = {
      pre: 0,
      post: 0,
      normal: 0
    };
    if (taxes.length) {
      groupedTaxes = taxes.reduce((acc, tax) => {
        if ("EXCISE_PRE_TAX" === tax.type && tax.rate) {
          acc.pre += +tax.rate;
        } else if ("EXCISE_POST_TAX" === tax.type && tax.rate) {
          acc.post += +tax.rate;
        } else if ("NORMAL" === tax.type && tax.rate) {
          acc.normal += +tax.rate;
        }
        return acc;
      }, groupedTaxes);
    }
    return groupedTaxes;
  }

  public applyTax(price: number, tax: number) {
    return +(price * (1 + tax / 100)).toFixed(2);
  }
  public deduceTax(price: number, tax: number) {
    return +(price / (1 + tax / 100)).toFixed(2);
  }

  public calculateForward(pricebreak: Pricebreak, groupedTaxes: GroupedTaxes) {
    let normalTaxed = 0;
    let basePrice = 0;
    basePrice = pricebreak.price || 0;
    pricebreak.pre_excise = this.applyTax(basePrice, groupedTaxes.pre);
    normalTaxed = this.applyTax(pricebreak.pre_excise, groupedTaxes.normal);
    pricebreak.post_tax = this.applyTax(normalTaxed, groupedTaxes.post);
    return pricebreak;
  }

  public calculateBackwards(
    pricebreak: Pricebreak,
    groupedTaxes: GroupedTaxes,
    priceType?: boolean
  ) {
    let basePrice: number = 0;
    let normalTaxed: number = 0;
    if (!pricebreak.post_tax && pricebreak.price && priceType) {
      pricebreak.post_tax = pricebreak.price;
    }
    basePrice = pricebreak.post_tax || 0;
    normalTaxed = this.deduceTax(basePrice, groupedTaxes.post);
    pricebreak.pre_excise = this.deduceTax(normalTaxed, groupedTaxes.normal);
    pricebreak.price = this.deduceTax(pricebreak.pre_excise, groupedTaxes.pre);
    return pricebreak;
  }

  public async savePrices(
    prices: PricingRule[],
    filters: object,
    priceGroupId?: number
  ) {
    try {
      const newPrices = prices.map((price: PricingRule) => {
        return price;
      });
      const response: AxiosResponse = await Vue.axios({
        method: "POST",
        url: "/price/rule_set/bulk",
        data: {
          rules: pricePointService.formatPayloadRules(newPrices),
          filters,
          pricing_group_id: priceGroupId
        }
      });
      messagesService.renderSuccessMessage("prices_updated");
      return response.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return false;
    }
  }

  public notifyPriceTypesUpdated() {
    messagesService.renderSuccessMessage("price_point_copy_success");
  }

  public formatPayloadRules(rules: PricingRule[], products?: boolean) {
    return rules.reduce((pricing: PricingRule[], rule) => {
      const settingPriceType =
        rule.pricing_type === "RETAIL_MEDICAL"
          ? "retail_pre_tax_pricing"
          : "wholesale_pre_tax_pricing";
      // @ts-ignore
      const goForward = !!store.state.AuthModule.currentRetailSettings[
        settingPriceType
      ];
      let priceBreaks;
      if (products && goForward) {
        priceBreaks = rule.price_breaks!.map(price => ({
          id: price.id || null,
          quantity: price.quantity,
          price: price.post_tax ? price.post_tax : price.price
        })) as Pricebreak[];
      } else if (products) {
        priceBreaks = rule.price_breaks!.map(price => ({
          id: price.id || null,
          quantity: price.quantity,
          price: price.post_tax ? price.post_tax : price.price
        })) as Pricebreak[];
      } else {
        priceBreaks = rule.price_breaks!.map(price => ({
          id: price.id || null,
          quantity: price.quantity,
          price: goForward || !price.post_tax ? price.price : price.post_tax
        })) as Pricebreak[];
      }
      pricing.push({ ...rule, price_breaks: priceBreaks });
      return pricing;
    }, []);
  }
}

export const pricePointService: PricePointService = new PricePointService();
