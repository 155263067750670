import { TemplateConfig } from "@/interfaces/templateEditor";

export const receiptDefault: TemplateConfig = {
  imageLogo: {
    text: "template.receipt.logo",
    visible: true,
    editType: "image",
    fieldDisplay: {
      style: {
        width: "100",
        height: "80"
      }
    }
  },
  locationAddress: {
    text: "template.receipt.location_address",
    visible: true,
    editType: "variable",
    fieldDisplay: {
      style: {
        fontSize: "14",
        lineHeight: "21",
        alignSelf: "center",
        textAlign: "center"
      }
    }
  },
  locationPhone: {
    text: "template.receipt.location_phone",
    visible: true,
    editType: "variable",
    fieldDisplay: {
      style: {
        fontSize: "14",
        lineHeight: "21",
        alignSelf: "center",
        textAlign: "center"
      }
    }
  },
  customerName: {
    text: "template.receipt.customer_name",
    visible: true,
    editType: "variable",
    fieldDisplay: {
      style: {
        fontSize: "14",
        lineHeight: "21",
        alignSelf: "flex-start",
        textAlign: "left"
      }
    }
  },
  customerAddress: {
    text: "template.receipt.customer_address",
    visible: true,
    editType: "variable",
    fieldDisplay: {
      style: {
        fontSize: "14",
        lineHeight: "21",
        alignSelf: "flex-start",
        textAlign: "left"
      }
    }
  },
  customerMedical: {
    text: "template.receipt.customer_medical",
    visible: true,
    editType: "variable",
    fieldDisplay: {
      style: {
        fontSize: "14",
        lineHeight: "21",
        alignSelf: "flex-start",
        textAlign: "left"
      }
    }
  },
  employeeName: {
    text: "template.receipt.employee_name",
    visible: true,
    editType: "variable",
    fieldDisplay: {
      style: {
        fontSize: "14",
        lineHeight: "21",
        alignSelf: "flex-start",
        textAlign: "left"
      }
    }
  },
  employeeId: {
    text: "template.receipt.employee_license_number",
    visible: true,
    editType: "variable",
    fieldDisplay: {
      style: {
        fontSize: "14",
        lineHeight: "21",
        alignSelf: "flex-start",
        textAlign: "left"
      }
    }
  },
  dateTime: {
    text: "template.receipt.date_time",
    visible: true,
    editType: "variable",
    fieldDisplay: {
      style: {
        fontSize: "14",
        lineHeight: "21",
        alignSelf: "flex-start",
        textAlign: "left"
      }
    }
  },
  ticketDiscount: {
    text: "template.receipt.ticket_discount",
    visible: true,
    editType: "variable",
    fieldDisplay: {
      style: {
        fontSize: "14",
        lineHeight: "21",
        alignSelf: "flex-start",
        textAlign: "left"
      }
    }
  },
  taxBreakdown: {
    text: "template.receipt.tax_breakdown",
    visible: true,
    editType: "variable",
    fieldDisplay: {
      style: {
        fontSize: "14",
        lineHeight: "21",
        alignSelf: "flex-end",
        textAlign: "right"
      }
    }
  },
  rate: {
    text: "template.receipt.rate",
    visible: true,
    editType: "variable",
    fieldDisplay: {
      style: {
        fontSize: "14",
        lineHeight: "21",
        alignSelf: "flex-start",
        textAlign: "left"
      }
    }
  },
  customText: {
    text: "template.receipt.custom_text",
    visible: true,
    editType: "custom",
    fieldDisplay: {
      style: {
        fontSize: "14",
        lineHeight: "21",
        alignSelf: "center",
        textAlign: "center"
      }
    }
  },
  remainingLimit: {
    text: "template.receipt.remaining_limit",
    visible: true,
    editType: "variable",
    fieldDisplay: {
      style: {
        fontSize: "14",
        lineHeight: "21",
        alignSelf: "center",
        textAlign: "center"
      }
    }
  },
  customerLoyalty: {
    text: "template.receipt.customer_loyalty",
    visible: true,
    editType: "variable",
    fieldDisplay: {
      style: {
        fontSize: "14",
        lineHeight: "21",
        alignSelf: "center",
        textAlign: "center"
      }
    }
  },
  customerSignature: {
    text: "template.receipt.customer_signature",
    visible: true,
    editType: "variable",
    fieldDisplay: {
      style: {
        fontSize: "14",
        lineHeight: "21",
        alignSelf: "center",
        textAlign: "center"
      }
    }
  },
  barcode: {
    text: "template.receipt.barcode",
    visible: true,
    editType: "variable",
    fieldDisplay: {
      style: {
        fontSize: "32",
        lineHeight: "32",
        alignSelf: "center",
        textAlign: "center"
      }
    }
  },
  totalBreakdown: {
    text: "template.receipt.total_breakdown",
    fieldDisplay: {
      style: {
        fontSize: "14",
        lineHeight: "21",
        alignSelf: "flex-end",
        textAlign: "right"
      }
    },
    editType: "variable"
  },
  lineItemDetails: {
    text: "template.receipt.line_item_details",
    fieldDisplay: {
      style: {
        fontSize: "14",
        lineHeight: "21",
        alignSelf: "flex-start",
        textAlign: "start"
      }
    },
    editType: "variable"
  },
  locationName: {
    text: "template.receipt.location_name",
    fieldDisplay: {
      style: {
        fontSize: "14",
        lineHeight: "21",
        alignSelf: "center",
        textAlign: "center"
      }
    },
    editType: "variable"
  }
};
