import { VendorState } from "@/vuex/modules/vendor/vendor.types";

export const state: VendorState = {
  vendors: [],
  vendorFiles: [],
  loading: false,
  loadingFiles: false,
  defaultVendors: [],
  pagination: null,
  editVendor: null
};
