export interface BasicTextFieldModel {
  height: string;
  width: string;
  fontSize: string;
  lineHeight: string;
  fontFamily: string;
  fontWeight: number;
  fontStyle: string;
  color: string;
  textAlign: string;
  bold: boolean;
  italic: boolean;
}

export interface TextFieldModel extends BasicTextFieldModel {
  content: string;
}

export const TextFieldDefaultValues: TextFieldModel = {
  content: " ",
  fontSize: "14px",
  lineHeight: "1.5",
  height: "auto",
  width: "auto",
  color: "#000000",
  textAlign: "left",
  fontWeight: 400,
  fontStyle: "normal",
  fontFamily: "Open Sans, sans serif",
  bold: false,
  italic: false
};

export interface VarFieldModel extends BasicTextFieldModel {
  variable: string;
  prefix: string;
}

export const VarFieldDefaultValues: VarFieldModel = {
  variable: "",
  prefix: "",
  fontSize: "14px",
  lineHeight: "1.5",
  height: "auto",
  width: "auto",
  color: "#000000",
  textAlign: "left",
  fontWeight: 400,
  fontStyle: "normal",
  fontFamily: "Open Sans, sans serif",
  bold: false,
  italic: false
};

export interface ListFieldModel extends BasicTextFieldModel {
  variable: string;
  prefix: string;
}

export const ListFieldDefaultValues: ListFieldModel = {
  variable: "",
  prefix: "",
  fontSize: "14px",
  lineHeight: "1.5",
  height: "auto",
  width: "auto",
  color: "#000000",
  textAlign: "left",
  fontWeight: 400,
  fontStyle: "normal",
  fontFamily: "Open Sans",
  bold: false,
  italic: false
};

export interface LineFieldModel {
  backgroundColor: string;
  height: string;
  padding: string;
  borderRadius: string;
}

export const LineFieldDefaultValues: LineFieldModel = {
  backgroundColor: "#000",
  height: "5",
  padding: "50",
  borderRadius: "0px"
};

export interface BarcodeFieldModel {
  style: string;
  fontSize: number;
  includeId: boolean;
  code: string;
}

export const BarcodeFieldDefaultValues: BarcodeFieldModel = {
  style: "128",
  fontSize: 50,
  includeId: true,
  code: "123456789"
};

export interface ImageFieldModel {
  image?: WindowBase64;
  height: number;
  width: number;
}

export const ImageFieldDefaultValues: ImageFieldModel = {
  height: 150,
  width: 150
};
export interface ItemFactoryInterface {
  [key: string]: any;
}

export const blockItems: ItemFactoryInterface = {
  "text-item": TextFieldDefaultValues,
  "image-item": ImageFieldDefaultValues,
  "barcode-item": BarcodeFieldDefaultValues,
  "line-item": LineFieldDefaultValues,
  "var-item": VarFieldDefaultValues,
  "list-item": ListFieldDefaultValues
};
