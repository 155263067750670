import { messagesService } from "@/services/messages.service";
import { traceabilityService } from "@/services/traceability.service";
import { RootState } from "@/vuex/types";
import { ActionContext } from "vuex";
import { TraceabilityState } from "./traceability.types";

export const actions: any = {
  async setLocationTraceability(
    context: ActionContext<TraceabilityState, RootState>,
    locationId: number
  ) {
    try {
      const result: any = await traceabilityService.getLocationTraceability(
        locationId
      );
      result[0] && result[0].value === "Metrc"
        ? context.commit("metrcConfig", true)
        : context.commit("metrcConfig", false);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }
};
