import { PriceGroup } from "@/interfaces/product";
import { BatchLevelState } from "@/vuex/modules/inventory/batchLevel/batchLevel.types";
import { TablePagination } from "helix-vue-components";
import { MutationTree } from "vuex";

type TypeMutationTree = MutationTree<BatchLevelState>;

export const mutations: TypeMutationTree = {
  setBatchLevels(state: BatchLevelState, batchLevels: PriceGroup[] | null) {
    state.batchLevels = batchLevels;
  },
  setBatchLevel(state: BatchLevelState, batchLevel: PriceGroup | null) {
    state.batchLevel = batchLevel;
  },
  setLoading(state: BatchLevelState, loading: boolean) {
    state.loading = loading;
  },
  setPagination(state: BatchLevelState, pagination: TablePagination | null) {
    state.pagination = pagination;
  }
};
