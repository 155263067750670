export interface TimeClockIncomingNotification {
  data: {
    break_for: string;
    check_in: string;
    check_out: string;
    created_at?: string;
    id: number;
    registered_date: string;
    status: TimeClockNotificationStatus;
    viewed: boolean;
    timeclock_id: number;
    updated_at: string;
    work_for: string;
  };
  user: {
    email: string;
    id: number;
  };
}

export interface TimeClockNotification {
  id: number;
  timeclock_id: number;
  timeclock: {
    account_id: number;
    break_for: string;
    check_in: string;
    check_out: string;
    created_at: string;
    created_by?: string | null;
    deleted_at?: string | null;
    deleted_by?: string | null;
    id: number;
    location_id: number;
    registered_date: string;
    status: string;
    updated_at?: string | null;
    updated_by?: string | null;
    user_id: number;
    work_for: string;
    user: {
      avatar_medium_url: string;
      avatar_original_url: string;
      avatar_thumb_url: string;
      email: string;
      first_name: string;
      id: number;
      last_name: string;
      middle_name: string;
      nickname: string;
    };
  };
  viewed: boolean;
  status: TimeClockNotificationStatus;
  registered_date: string;
  check_in: string;
  check_out: string;
  work_for: string;
  break_for: string;
  created_at: string;
  updated_at: string;
}

export enum TimeClockNotificationStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  CLOSED = "CLOSED"
}
