import { Product } from "@/interfaces/product";
import { PricePointState } from "@/vuex/modules/inventory/pricePoint/pricePoint.types";
import { MutationTree } from "vuex";

type PricePointMutationTree = MutationTree<PricePointState>;

export const mutations: PricePointMutationTree = {
  setProducts(state: PricePointState, products: Product[]) {
    state.products = products;
  }
};
