import { AuthState } from "./auth.types";

export const state: AuthState = {
  user: null,
  loading: false,
  isLogged: false,
  currentLocation: null,
  accessToken: "",
  redirectTo: "",
  currentRetailSettings: null,
  appId: "",
  currentInventorySettings: null,
  limitConfig: [],
  isSeparatedOptionEnable: false
};
