import { MutationTree } from "vuex";
import { TraceabilityState } from "./traceability.types";

type TraceabilityMutationTree = MutationTree<TraceabilityState>;

export const mutations: TraceabilityMutationTree = {
  metrcConfig(state: TraceabilityState, metrcConfig: boolean) {
    state.metrcConfig = metrcConfig;
  }
};
