import { SearchQuery } from "@/interfaces/httpQuery";
import { BatchLevelAssign, MinPrice, PriceGroup } from "@/interfaces/product";
import HttpService from "./http.service";
import { messagesService } from "./messages.service";

class BatchLevelService extends HttpService {
  protected loadDispatcher: string = "BatchLevelModule/loadBatchLevels";
  protected defaultSort: string = "";
  protected searchableField: SearchQuery[] = [
    {
      field: "name",
      type: "contains"
    }
  ];
  public setQuery(query?: object | null) {
    if (query) {
      delete this.query["q[is_active_is_true]"];
      delete this.query["q[is_active_is_false]"];
    }
    this.query = { ...this.query, ...query, "q[group_type_eq]": "BATCH_LEVEL" };
  }

  public async get(query: object | null = null): Promise<PriceGroup[]> {
    this.setQuery(query);
    this.uri = "/price/pricing_group";
    const response = await super.get(query || null);
    const payload: PriceGroup[] = response.data.data;
    return payload;
  }

  public async findByName(value: string): Promise<PriceGroup[]> {
    this.uri = "/price/pricing_group";
    try {
      const query = {
        "q[name_contains]": value,
        "q[is_active_eq]": 1,
        page: 1,
        per_page: 10
      };
      const response = await super.get(query);
      return response.data.data;
    } catch (e) {
      return [];
    }
  }

  public async getAll(): Promise<PriceGroup[]> {
    this.uri = "/price/pricing_group";
    const locations = await super.get({ no_pagination: true });
    return locations.data;
  }

  public async getAssociatedPriceGroup(
    batchSKU: string
  ): Promise<BatchLevelAssign | null> {
    try {
      this.uri = `/price/pricing_group_items/${batchSKU}`;
      const response = await super.get(null, false);
      return response.data;
    } catch (error) {
      return null;
    }
  }

  public async addPriceGroupToBatch(payload: {
    pricing_group_id: number;
    code: string;
  }) {
    this.uri = "/price/pricing_group_items";
    try {
      const response = await super.post(payload);
      return response.data;
    } catch (error) {
      messagesService.renderErrorMessage(error);
    }
  }

  public async updateBatchPriceGroup(
    assocId: number,
    payload: {
      pricing_group_id: number;
      code: string;
    }
  ) {
    this.uri = "/price/pricing_group_items";
    try {
      const response = await super.put({ id: assocId }, payload);
      return response.data;
    } catch (error) {
      messagesService.renderErrorMessage(error);
    }
  }

  public async deleteBatchPriceGroup(assocId: number) {
    this.uri = "/price/pricing_group_items";
    try {
      const response = await super.delete({ id: assocId });
      return response.data;
    } catch (error) {
      messagesService.renderErrorMessage(error);
    }
  }

  public async searchPriceGroup(query: {
    [key: string]: string | number;
  }): Promise<PriceGroup | null> {
    try {
      this.uri = "/price/pricing_group/search";
      const response = await super.get({
        query
      });
      return response.data[0] || null;
    } catch (error) {
      messagesService.renderErrorMessage(error);
      return null;
    }
  }

  public async getBatchPrices(batchSKU: string) {
    this.uri = `/price/rule_set/compile/${batchSKU}`;
    try {
      const response = await super.get(null, false);
      return response.data;
    } catch (error) {
      messagesService.renderErrorMessage(error);
      return null;
    }
  }

  public async getPricingPreview(batchSKU: string, batchLevelId: number) {
    this.uri = `/price/rule_set/compile/${batchSKU}/preview/${batchLevelId}`;
    try {
      const response = await super.get(null, false);
      return response.data;
    } catch (error) {
      messagesService.renderErrorMessage(error);
      return null;
    }
  }

  public toggleBatchType(model: any, isActive: boolean): Promise<void> {
    if (isActive) {
      return super.enable(model);
    }
    return super.disable(model);
  }

  public async getMinBatchPrices(
    skus: string[]
  ): Promise<{ [key: string]: MinPrice | null }> {
    this.uri = "/price/rule_set/prices_for_labels";
    try {
      const response = await super.get(
        {
          skus
        },
        false
      );
      return response.data;
    } catch (error) {
      return {};
    }
  }
}

export const batchLevelService: BatchLevelService = new BatchLevelService();
