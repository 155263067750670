import { SaleReconciliationState } from "./saleReconciliation.types";

export const state = (): SaleReconciliationState => ({
  license_number: "",
  transaction_date_time: "",
  duplicates: [],
  order: {
    date_time: "",
    customer: "",
    transaction_id: "",
    metrc_receipt_id: "",
    subtotal: "",
    sale_type: "",
    status: "",
    duplicates: [],
    license_number: "",
    transaction_date_time: ""
  }
});
