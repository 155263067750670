import HttpService from "@/services/http.service";

/**
 * class to manage the component with shared locations
 */
export default abstract class LocationShareableService extends HttpService {
  /**
   * field with the name in the relation field in the request, ex: location_product_category
   * @return string
   */
  public abstract getFieldToGet(): string;

  /**
   * field with the name in the form to send to the server, ex: locationProductCategory
   * @return string
   */
  public abstract getFieldForm(): string;

  /**
   * builder data to send the server
   * @param Model model
   */
  public locationBuilder(model: object): Promise<boolean> {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      const toDelete = model[this.getFieldToGet()].map(saved => {
        // @ts-ignore
        const selected = model[this.getFieldForm()].find(location => {
          // @ts-ignore
          return location.location_id === saved.location_id;
        });
        if (!selected) {
          // @ts-ignore
          saved._destroy = true;
        }
        return saved;
      });
      // @ts-ignore
      let toSave = model[this.getFieldForm()].map(location => {
        // @ts-ignore
        const actual = model[this.getFieldToGet()].find(saved => {
          return location.id === saved.location_id;
        });
        return typeof actual === "undefined" ? location : null;
      });
      // @ts-ignore
      toSave = toSave.filter(location => {
        return location !== null;
      });
      // @ts-ignore
      model[this.getFieldForm()] = toSave.map(location => {
        // @ts-ignore
        return {
          location_id: location.id || location.location_id,
          product_category_id: location.product_category_id,
          price: location.price,
          tax_id: location.tax_id,
          id: location.locationProductId
        };
      });
      // @ts-ignore
      toDelete.forEach(location => {
        if (location._destroy) {
          // @ts-ignore
          model[this.getFieldForm()].push({ id: location.id, _destroy: true });
        }
      });
      resolve();
    });
  }
}
