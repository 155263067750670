// @ts-ignore
import { MenuSection } from "@/interfaces/menuSection";
import { Callback } from "@/types/types";
import { MutationTree } from "vuex";
import { MegaMenuState } from "./megaMenu.types";

type MegaMenuMutationTree = MutationTree<MegaMenuState>;

export const mutations: MegaMenuMutationTree = {
  setTitle(state: MegaMenuState, title: string) {
    state.title = title;
  },
  setSubTitle(state: MegaMenuState, subTitle: string) {
    state.subTitle = subTitle;
  },
  setIcon(state: MegaMenuState, icon: string) {
    state.icon = icon;
  },
  setIconAction(state: MegaMenuState, iconAction: Callback) {
    state.iconAction = iconAction;
  },
  setMenuSections(state: MegaMenuState, menuSections: MenuSection[]) {
    state.menuSections = menuSections;
  }
};
