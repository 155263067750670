import { CallbackReturn } from "helix-vue-components";
import get from "lodash/get";
import { all, create } from "mathjs";
export const mathJs = create(all, {});
/**
 * Truncates number to 2 decimals. Expects the number
 * to truncate as first param, and the decimals
 * expected to have.
 * @param decimal: number
 * @param places: number
 */
export function truncate(decimal: number, places: number) {
  const multiplier = Math.pow(10, places);
  // tslint:disable-next-line: no-bitwise
  return ((decimal * multiplier) | 0) / multiplier;
}

export function sumArray(
  source: Array<number | { [key: string]: any }>,
  path: string = ""
): number {
  try {
    return +mathJs.format!(
      source.reduce((acc: number, s) => {
        const value =
          typeof source[0] === "object" && path ? get(s, path, 0) : s;
        return mathJs.add!(
          mathJs.bignumber!(value || 0),
          mathJs.bignumber!(acc)
        ) as number;
      }, 0),
      { precision: 4 }
    );
  } catch (error) {
    // tslint:disable-next-line
    console.warn("Error with sumArray", error);
    return 0;
  }
}

export function sumObjArrayBy(
  source: Array<{ [key: string]: any }>,
  c: CallbackReturn<number>
): number {
  try {
    return +mathJs.format!(
      source.reduce((res, item) => {
        const val = mathJs.bignumber!(c(item) || 0);
        return mathJs.add!(res, val) as number;
      }, 0),
      { precision: 4 }
    );
  } catch (error) {
    // tslint:disable-next-line
    console.warn("Error with sumObjArray", error);
    return 0;
  }
}
