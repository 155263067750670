import { Module } from "vuex";
import { RootState } from "../../types";
import { actions } from "./traceability.actions";
import { getters } from "./traceability.getters";
import { mutations } from "./traceability.mutations";
import { state } from "./traceability.state";
import { TraceabilityState } from "./traceability.types";

const namespaced: boolean = true;
export const TraceabilityModule: Module<TraceabilityState, RootState> = {
  namespaced,
  actions,
  getters,
  mutations,
  state
};
