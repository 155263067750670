import { Brand } from "@/interfaces/brand";
import { Grade } from "@/interfaces/grade";
import { PriceGroup, Product } from "@/interfaces/product";
import { ProductCategory } from "@/interfaces/productCategoy";
import { Room } from "@/interfaces/room";
import { Strain } from "@/interfaces/strain";
import { Vendor } from "@/interfaces/vendor";
import { ProductState } from "@/vuex/modules/inventory/product/product.types";
import { TablePagination } from "helix-vue-components";
import { MutationTree } from "vuex";

type ProductMutationTree = MutationTree<ProductState>;

export const mutations: ProductMutationTree = {
  setProducts(state: ProductState, products: Product[]) {
    state.products = products;
  },
  setHeaders(state: ProductState, products: object[]) {
    state.headers = products;
  },
  setLoading(state: ProductState, loading: boolean) {
    state.loading = loading;
  },
  setBrands(state: ProductState, brands: Brand[]) {
    state.brands = brands;
  },
  setRooms(state: ProductState, rooms: Room[]) {
    state.rooms = rooms;
  },
  setGrades(state: ProductState, grades: Grade[]) {
    state.grades = grades;
  },
  setProductCategories(
    state: ProductState,
    productCategories: ProductCategory[]
  ) {
    state.productCategories = productCategories;
  },
  setPagination(state: ProductState, pagination: TablePagination | null) {
    state.pagination = pagination;
  },
  setStrains(state: ProductState, strains: Strain[]) {
    state.strains = strains;
  },
  setBatchTypes(state: ProductState, batchTypes: Strain[]) {
    state.batchTypes = batchTypes;
  },
  setCategories(state: ProductState, categories: ProductCategory[]) {
    state.categories = categories;
  },
  setVendors(state: ProductState, vendors: Vendor[]) {
    state.vendors = vendors;
  },
  setCultivators(state: ProductState, vendors: Vendor[]) {
    state.cultivators = vendors;
  },
  setManufacturers(state: ProductState, vendors: Vendor[]) {
    state.manufacturers = vendors;
  },
  setBatchLevelPrices(state: ProductState, prices: PriceGroup[]) {
    state.batchLevelPrices = prices;
  }
};
