import { BatchState } from "@/vuex/modules/inventory/batch/batch.types";

export const state: BatchState = {
  productTypes: [],
  productBatches: [],
  loading: false,
  productBatchesPagination: null,
  currentBatch: null,
  sampleResultProfiles: [],
  adjustBatch: []
};
