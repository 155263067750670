import { DirectiveOptions } from "vue";
import { DirectiveBinding } from "vue/types/options";
/**
 * outsideClick Directive allows to take action when user clicks outside of the element having the directive.
 * It supports the following binding structure
 * @namespace outsideClick.binding
 * @function action Action to execute on click outside
 * @function pre A pre-run check before executing the action binding. It has to return a boolean.
 */
const directive = (e: Event, el: HTMLElement, binding: DirectiveBinding) => {
  const target = e.target;
  const canRun = (binding.value.pre && binding.value.pre()) || true;
  if (!canRun) {
    return;
  }
  if (!el.contains(target as Node)) {
    binding.value.action();
  }
};
export const outsideClick: DirectiveOptions = {
  inserted(el, binding) {
    const onClick = (e: Event) => {
      return directive(e, el, binding);
    };
    document.addEventListener("click", onClick, true);
    // @ts-ignore
    el._clickOutside = onClick;
  },
  unbind(el) {
    // @ts-ignore
    if (!el._clickOutside) {
      return;
    }
    // @ts-ignore
    document.removeEventListener("click", el._clickOutside);
  }
};
