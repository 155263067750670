import { SelectImage } from "@/components/sharedComponents/selectImage/SelectImage.enum";
import TraceabilityConfirm from "@/components/sharedComponents/traceabilityConfirm/TraceabilityConfirm.component";
import {
  InventoryBatch,
  LaboratoryResult,
  StockSummary
} from "@/interfaces/batch";
import { HttpQuery, SearchQuery } from "@/interfaces/httpQuery";
import { Location } from "@/interfaces/location";
import { OrderItem } from "@/interfaces/order";
import {
  Pricebreak,
  PricingRule,
  Product,
  ProductCost,
  ProductPricing,
  ScanResult
} from "@/interfaces/product";
import { MetrcItem } from "@/interfaces/traceability";
import { store } from "@/internal";
import { i18n } from "@/plugins/i18n";
import LocationShareableService from "@/services/LocationShareable.service";
import { TableHeader } from "helix-vue-components";
import clone from "lodash/clone";
import maxBy from "lodash/maxBy";
import minBy from "lodash/minBy";
import { messagesService } from "./messages.service";
import { priceGroupService } from "./priceGroup.service";
import { pricePointService } from "./pricePoint.service";
// tslint:disable-next-line
const toFormData = require("object-to-formdata");

class ProductService extends LocationShareableService {
  protected loadDispatcher: string = "";
  protected defaultSort: string = "name";
  protected uri: string = "catalog/products";
  protected searchableField: SearchQuery[] = [
    {
      field: "name",
      type: "contains"
    },
    {
      field: "batches.batch_uid",
      type: "contains"
    },
    {
      field: "batches.tracking_id",
      type: "contains"
    }
  ];

  /**
   * sort action to make a request
   * @param params
   */
  public getSortQuery(params: TableHeader) {
    return `${!params.descending ? "-" : ""}${params.value || ""}`;
  }

  public getLoadDispacher() {
    return this.loadDispatcher;
  }

  public async save(product: Product): Promise<Product> {
    const id = 0;
    let priceId: number | string | null;
    const priceData = [product];
    priceData.map(item => {
      priceId = item.price_settings.pricing_group_id;
    });

    product.price_settings.pricing_group_id =
      product.price_settings.pricing_group_id || null;
    this.uri = "catalog/products";
    let response!: Product;
    let productImage: File | null | string = null;

    if (product.image) {
      productImage = product.image;
      delete product.image;
    }

    delete product.location_products;
    if (product.sku) {
      response = await this.put(product, product);
    } else {
      response = await this.post(product);
    }

    if (productImage) {
      if (
        typeof productImage === "string" &&
        productImage === SelectImage.DELETE_CODE
      ) {
        this.deleteProductImage(product);
      } else {
        this.setProductImage(response, productImage);
      }
    }

    return response;
  }

  public async get(
    query: object | null = null,
    onSale = false
  ): Promise<Product[]> {
    try {
      this.uri = "catalog/products";
      if (onSale) {
        this.uri += "/onsale";
      }
      const response = await super.get(query);
      const payload: Product[] = response.data.data || response.data;
      return payload;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return [];
    }
  }

  public async renderSearchErrorModal(
    statusName: string,
    descriptionName: string,
    loader: string,
    accept: boolean,
    cancel: boolean,
    acceptValue: string,
    title: string,
    icon: string
  ) {
    this.$modals.load(
      TraceabilityConfirm,
      {
        size: "normal",
        positionX: "center",
        positionY: "center",
        // @ts-ignore
        style: "max-height:680px"
      },
      {
        modalData: {
          justifyContent: "justify-center",
          icon: {
            name: "fa fa-exclamation-triangle",
            size: 28,
            color: "warning"
          },
          status: {
            name: i18n.t(statusName),
            style: "fontSize:27px ; fontWeight:600;"
          },

          description: {
            name: i18n.t(descriptionName),
            style: "fontSize:20px ; textAlign:left"
          },

          title: {
            name: i18n.t(title),
            style: "fontSize:30px ; letter-spacing: 0px; margin-left:-10px"
          },
          titleAvatar: {
            name: icon,
            size: 80
          },
          loading: i18n.t(loader),
          acceptButton: accept,
          cancelButton: cancel,
          acceptButtonValue: acceptValue
        }
      }
    );
  }

  public async getStock(query: object | null = null): Promise<Product[]> {
    try {
      this.uri = "catalog/stock";
      const response = await super.get(query);
      const payload: Product[] = response.data.data || response.data;
      return payload;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return [];
    }
  }

  public async getAll(query: object | null = null): Promise<Product[]> {
    try {
      query = { ...query, no_pagination: true };
      this.uri = "catalog/products";
      const response = await super.get(query, false);
      const payload: Product[] = response.data;
      return payload;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return [];
    }
  }

  public toggleActiveProduct(
    product: Product,
    isActive: boolean
  ): Promise<void> {
    if (isActive) {
      return super.enable(product);
    }
    return super.disable(product);
  }

  public async getBackStockProducts(filters: any = {}): Promise<Product[]> {
    try {
      this.loadDispatcher = "ProductModule/loadProductsAvailable";
      this.uri = "catalog/products";
      const query = {
        ...this.query,
        ...{
          scopes: "byRoom",
          room_types: ["BACKSTOCK_ROOM"]
        },
        ...filters
      };
      const response = await super.get(query);
      const payload: Product[] = response.data.data || response.data;
      return payload;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return [];
    }
  }

  public setQuery(query: HttpQuery, loadDispacher?: string) {
    if (loadDispacher) {
      this.loadDispatcher = loadDispacher;
    }
    this.query = query;
    this.query.page = 1;
    this.query.per_page = this.pagination.itemsPerPage;
  }

  public changeQuery(id?: number): void {
    id ? (this.query.id = id) : delete this.query.id;
    delete this.query["q[product_category_id_is_in]"];
    delete this.query["q[name_contains]"];
  }

  public changeCategoryQuery(categories: number[]): void {
    this.query["q[product_category_id_is_in]"] = categories;
    delete this.query.id;
    delete this.query["q[name_contains]"];
  }

  public formatCategories(value?: string) {
    this.query["q[name_contains]"] = value;
    delete this.query.id;
    delete this.query["q[product_category_id_is_in]"];
  }

  public async getGrades() {
    this.uri = "inventory/grades";
    delete this.query.id;
    delete this.query["q[name_contains]"];
    const response = await super.get(null);
    return response.data.data;
  }

  public getFieldForm(): string {
    return "locationProducts";
  }

  public getFieldToGet(): string {
    return "location_products";
  }

  public async setExpandedProductView(
    product: Product,
    orderItem: OrderItem | null = null
  ): Promise<Product> {
    product.batches = (product.batches as InventoryBatch[]).filter(
      b => b.on_sale!.length
    );
    const totalQuantity = (product.batches as InventoryBatch[]).reduce(
      (acc: number, b) => +acc + +b.in_store_quantity_value,
      0
    );
    const pricePointByUsableWeight =
      (product.price_settings &&
        product.price_settings.price_point_by_usable_weight) ||
      null;
    product.orderItem = {
      ...product.orderItem,
      id: orderItem && orderItem.id,
      order_item_uid: (orderItem && orderItem.order_item_uid) || null,
      product_sku: (orderItem && orderItem.product_sku) || product.sku,
      batch_sku: (orderItem && orderItem.batch_sku) || null,
      batch_uid: (orderItem && orderItem.batch_uid) || null,
      quantity: (orderItem && orderItem.quantity) || 1,
      payment: (orderItem && orderItem.price_raw! * orderItem.quantity!) || 0,
      unit: (orderItem && orderItem.product_info!.product_unit) || "--",
      price_point_by_usable_weight:
        (orderItem && orderItem.product_info!.price_point_by_usable_weight) ||
        pricePointByUsableWeight,
      batch_barcode_uid: (orderItem && orderItem.batch_barcode_uid) || null
    };
    return {
      ...product,
      total_quantity: (product.requires_weighing
        ? totalQuantity.toFixed(2)
        : totalQuantity) as string
    };
  }

  public async findById(id: number, query?: HttpQuery): Promise<Product> {
    const queryObject: HttpQuery = query || {};
    return await super.findById(id, queryObject);
  }

  public async findBySkuId(
    sku: string,
    query: HttpQuery = {}
  ): Promise<Product> {
    const aux = this.uri;
    this.uri = "/catalog/products";
    return await super.findById(sku, query).finally(() => (this.uri = aux));
  }

  public async findProductsforSKU(SKUs: string[]) {
    this.uri = "/catalog/products";
    const query = {
      "q[sku_is_in]": SKUs
    };
    try {
      const response = await this.get(query);
      return response;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return [];
    }
  }

  public filterProducts(status: {
    fullyqualified: boolean;
    notfullyqualified: boolean;
  }) {
    if (status.fullyqualified && !status.notfullyqualified) {
      this.query.is_fully_qualified = false;
    }
    if (!status.fullyqualified && status.notfullyqualified) {
      this.query.is_fully_qualified = true;
    }
    if (!status.fullyqualified && !status.notfullyqualified) {
      delete this.query.is_fully_qualified;
    }
    store.dispatch("ProductModule/loadProducts");
  }

  public loadEmptyListOfProducts() {
    store.dispatch("ProductModule/loadEmptyProducts");
  }
  public async findByName(
    name: string,
    query: { [key: string]: string | number } = {}
  ): Promise<Product[]> {
    if (name) {
      query = {
        ...query,
        "q[name_contains]": name
      };
    }
    return await this.get(query);
  }

  public samplesResultFormatted(samplesResult: LaboratoryResult[] | undefined) {
    return (
      (samplesResult &&
        samplesResult.map(sample => {
          return {
            type: sample.profile!.type,
            name: sample.profile!.name,
            unit: sample.unit_representation,
            value: sample.value,
            minValue: 0,
            maxValue: 0,
            unitMax: "",
            unitMin: "",
            breakdown: sample.profile!.breakdown
          };
        })) ||
      []
    );
  }

  public async stockSummary(
    batches: InventoryBatch[]
  ): Promise<StockSummary[]> {
    try {
      return batches.reduce((acc: StockSummary[], batch) => {
        batch.summary.forEach(summaryItem => {
          if (summaryItem.batch_fraction_status_type === "AVAILABLE") {
            const inAcc = acc.findIndex(
              stockItem =>
                stockItem.inventory_location.id ===
                summaryItem.inventory_location.id
            );
            if (inAcc !== -1) {
              acc[inAcc].available_quantity += summaryItem.quantity_value;
              if (!acc[inAcc].batch_uids.includes(batch.batch_uid)) {
                acc[inAcc].batch_uids.push(batch.batch_uid);
                acc[inAcc].batches_quantity++;
              }
            } else {
              acc.push({
                inventory_location: {
                  id: summaryItem.inventory_location.id,
                  name: summaryItem.inventory_location.name
                },
                batches_quantity: 1,
                batch_uids: [batch.batch_uid],
                available_quantity: summaryItem.quantity_value
              });
            }
          }
        });
        return acc;
      }, []);
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return [];
    }
  }

  public setProductImage(product: Product, file: File) {
    const aux = this.uri;
    this.uri = `/catalog/products/${product.sku}/media`;
    try {
      const model = toFormData({ media: [file] });
      this.post(model);
    } catch (error) {
      messagesService.renderErrorMessage(error);
    } finally {
      this.uri = aux;
    }
  }

  public deleteProductImage(product: Product) {
    const aux = this.uri;
    try {
      if (product.media && product.sku) {
        this.uri = `catalog/products/${product.sku}/media`;
        const response = super.delete({ id: product.media[0].id }, false);
      }
    } catch (error) {
      messagesService.renderErrorMessage(error);
    } finally {
      this.uri = aux;
    }
  }

  public getLocationPricingRule(
    product: { pricing: PricingRule[] },
    memberLevelCode: string | null = null
  ): PricingRule {
    // Aplicar esta logica a nivel de tipo de location, member level Normal (null)
    const location: Location = store.getters["AuthModule/currentLocation"];
    // Encontrar el price_rule que se adecue a la locationo devolver el primero (no deberia pasar)
    let locationRule = product.pricing && product.pricing[0];
    if (memberLevelCode) {
      // @ts-ignore
      locationRule =
        product.pricing.find(
          rule =>
            rule.pricing_type === location.location_type &&
            rule.member_level_code === memberLevelCode &&
            !!rule.is_active
        ) || product.pricing[0];
    }
    return locationRule;
  }

  public async getProductBatches(
    sku: string,
    query: object | null = null
  ): Promise<InventoryBatch[]> {
    try {
      this.uri = `/catalog/products/${sku}/batches`;
      const response = await super.get(query, false);
      return response.data.data;
    } catch (error) {
      messagesService.renderErrorMessage(error);
      return [];
    }
  }

  public async getProductMetrc(sku: string): Promise<MetrcItem | null> {
    try {
      this.uri = `/traceability/metrc/mapped_item/${sku}`;
      const response = await super.get({ embed: "item" }, false);
      return response.data.item;
    } catch (error) {
      return null;
    }
  }

  public async getMetrcList(name?: string): Promise<MetrcItem[]> {
    try {
      this.uri = `/traceability/metrc/items`;
      const query = {
        sort: "name",
        per_page: 10
      };
      if (name) {
        Object.assign(query, { name });
      }
      const response = await super.get(query, false);
      return response.data.data;
    } catch (error) {
      messagesService.renderErrorMessage(error);
      return [];
    }
  }

  public async getProductMedia(productSKU: string) {
    this.uri = `/catalog/products/${productSKU}/media`;
    try {
      const resp = await super.get({}, false);
      return resp.data;
    } catch (error) {
      messagesService.renderErrorMessage(error);
    }
  }

  public async getProductPricing(
    productSKU: string
  ): Promise<ProductPricing | null> {
    this.uri = `/price/rule_set/${productSKU}`;
    try {
      const response = await super.get({}, false);
      return response.data || [];
    } catch (error) {
      messagesService.renderErrorMessage(error);
      return null;
    }
  }

  public async getProductTotal(
    productSKU: string
  ): Promise<ProductCost | null> {
    this.uri = `/catalog/products/${productSKU}/costs`;
    try {
      const response = await super.get({}, false);
      return response.data || [];
    } catch (error) {
      messagesService.renderErrorMessage(error);
      return null;
    }
  }

  public async setProductPricing(
    productSKU: string,
    rules: PricingRule[],
    isUpdate: boolean
  ) {
    try {
      const isProduct: boolean = true;
      const payload = {
        code: productSKU,
        is_active: true,
        rules: pricePointService.formatPayloadRules(rules, isProduct)
      };
      let response;
      if (isUpdate) {
        this.uri = `/price/rule_set/${productSKU}`;
        response = await super.put({}, payload);
      } else {
        this.uri = `/price/rule_set`;
        response = await super.post(payload);
      }
      return response.data;
    } catch (error) {
      messagesService.renderErrorMessage(error);
      return null;
    }
  }

  public async getProductTotalQuantity(
    productSKU: string
  ): Promise<{ total_in_store: number } | null> {
    this.uri = `/catalog/products/${productSKU}/total_in_store`;
    try {
      const response = await super.get({}, false);
      return response.data;
    } catch (error) {
      messagesService.renderErrorMessage(error);
      return null;
    }
  }

  public async getReplacementProducts(query: object): Promise<Product[]> {
    this.uri = "/catalog/products";
    try {
      const response = await super.get(query, false);
      return response.data.data || response.data;
    } catch (error) {
      messagesService.renderErrorMessage(error);
      return [];
    }
  }

  public async setReplacementProduct(productSku: string, batchUid: string) {
    this.uri = "/inventory/operations/change-sku";
    try {
      const payload = {
        concrete_sku: productSku,
        batch_uid: batchUid
      };
      const response = await super.post(payload);
      return response;
    } catch (error) {
      messagesService.renderErrorMessage(error);
      return null;
    }
  }

  public getCurrentQuery() {
    return this.query;
  }

  public getPriceBreakForUsableWeight(
    weight: number,
    priceBreaks: Pricebreak[]
  ) {
    let priceBreakSeleted = {} as Pricebreak | undefined;
    const priceBreaksClone = clone(priceBreaks);
    priceBreakSeleted =
      maxBy(
        priceBreaksClone.filter(priceIndex => priceIndex.quantity <= weight),
        "quantity"
      ) || minBy(priceBreaksClone, "quantity");

    const priceBreakPerUnit = priceBreakSeleted
      ? priceBreakSeleted.price! / priceBreakSeleted.quantity
      : 0;
    return priceBreakPerUnit * weight;
  }

  public async getProductScan(scan: string): Promise<ScanResult | null> {
    try {
      this.uri = `/catalog/products/scan/${scan}`;
      const response = await super.get({}, false);
      return response.data;
    } catch (error) {
      messagesService.renderErrorMessage(error);
      return null;
    }
  }
}

export const productService = new ProductService();
