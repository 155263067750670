import { RootState } from "@/vuex/types";
import { Module } from "vuex";
import { actions } from "./user.actions";
import { getters } from "./user.getters";
import { mutations } from "./user.mutations";
import { state } from "./user.state";
import { UserState } from "./user.types";

const namespaced: boolean = true;

export const UserModule: Module<UserState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
