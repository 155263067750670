import { Rule } from "@/interfaces/location";
import { Order } from "@/interfaces/order";
import { LoyaltyConfig, OrderState } from "@/vuex/modules/order/order.types";
import { RootState } from "@/vuex/types";
import { TablePagination } from "helix-vue-components";
import { GetterTree } from "vuex";

type OrderGetter = GetterTree<OrderState, RootState>;

export const getters: OrderGetter = {
  order(state: OrderState): Order {
    return state.order;
  },
  orders(state: OrderState): Order[] {
    return state.orders;
  },
  discounts(state: OrderState): object {
    return state.discounts;
  },
  loyaltyConfig(state: OrderState): LoyaltyConfig | null {
    return state.loyaltyConfig;
  },
  loading(state: OrderState): boolean {
    return state.loading;
  },
  pagination(state: OrderState): TablePagination | null {
    return state.pagination;
  },
  customerLimits(state: OrderState): Rule[] {
    return state.customerLimits;
  },
  pharmacistSelected(state: OrderState): number | null {
    return state.pharmacistSelected;
  }
};
