import { NOTICATION_AUDIT_STATUS } from "@/interfaces/audit";
import { PusherNotification } from "@/interfaces/notification";
import { store } from "@/internal";
import PusherService from "@/services/pusher.service";
import { BooleanCheck, Callback } from "@/types/types";
import { Component } from "vue";
import { Vue } from "vue-property-decorator";
import ChangesNotificationModal from "./ChangesNotificationModal/ChangesNotification.modal";

interface SubscriptionConfig {
  callback: Callback;
  displayForCurrentUser?: boolean;
  isModal?: boolean;
  preRun?: BooleanCheck;
  customModal?: Component;
  showforHimself?: boolean;
}

/**
 * IMPLEMENTATION EXAMPLE
 *
 *  @component({
 * inject: ["$changes"]
 * })
 * ...
 * this.$changes.watch("Inventory\\NotifyBatchAdjustment", () => {
 *    // Do something
 * });
 *
 */

export class ChangesNotificationService extends Vue {
  /**
   * User data. Used to retrieve user ID.
   */
  private userID: number | null = null;
  private openedModal = false;
  private pusher = new PusherService();
  private suscribedChannels: string[] = [];
  private canReopen: boolean = false;
  private customComponentModal: Component | null = null;

  /**
   * Watch notifications in order to take action on it.
   * @param channel: string | string[]
   * @param callback: Callback
   * @param preRun?: BooleanCheck
   */
  public watch(
    channel: string | string[],
    callback: Callback,
    preRun?: BooleanCheck,
    customModal?: Component | null,
    reopenModal?: boolean
  ) {
    this.canReopen = !!reopenModal;
    this.customComponentModal = customModal || null;
    this.flush();
    if (!this.userID) {
      this.userID = store.getters["AuthModule/user"].id;
    }
    this.suscribedChannels = Array.isArray(channel) ? channel : [channel];
    this.suscribedChannels.forEach(c => {
      this.do(c, { preRun, callback, isModal: true });
    });
  }

  /**
   * Takes action on a pusher event.
   * @param channel: string
   * @param callback: Callback
   */
  public do(channel: string, config: SubscriptionConfig) {
    let cbk;
    if (config.displayForCurrentUser) {
      cbk = config.isModal
        ? (res: PusherNotification) =>
            this.callModal(res, config.callback, config.preRun)
        : (res: PusherNotification) => config.callback(res);
    } else {
      cbk = (response: PusherNotification) => {
        if (this.isNotificationFromAnotherUser(response)) {
          return config.isModal
            ? this.callModal(response, config.callback, config.preRun)
            : config.callback(response);
        } else {
          return;
        }
      };
    }

    this.pusher.onEvent({
      event: channel,
      action: cbk
    });
  }

  /**
   * Unbinds channel subscriptions.
   */
  public flush() {
    this.suscribedChannels.forEach(channel =>
      this.pusher.channel.unbind(channel)
    );
  }

  /**
   * Opens notification modal.
   * @param callback: Callback
   * @returns Promise<any>
   */
  private async openModal(
    callback: Callback,
    response: PusherNotification
  ): Promise<any> {
    try {
      await this.$modals.load(
        this.customComponentModal || ChangesNotificationModal,
        {
          size: "fit",
          positionX: "right",
          positionY: "bottom",
          backdrop: false,
          closable: false
        },
        { data: response, actionResolve: callback }
      );
      this.openedModal = false;
    } catch (e) {
      this.openedModal = false;
      return e;
    }
  }

  /**
   * Displays a modal on a notification update.
   * @param response: PusherNotification,
   * @param callback: Callback,
   * @param preRun?: BooleanCheck
   */
  private callModal(
    response: PusherNotification,
    callback: Callback,
    preRun?: BooleanCheck
  ) {
    if (this.notificationMustBeDisplayed(response, preRun)) {
      this.openedModal = true;
      setTimeout(() => this.openModal(callback, response), 500);
    }
  }

  /**
   * Determines if the notification modal should be displayed.
   * @param response: PusherNotification
   * @param preRun: BooleanCheck | undefined
   * @returns boolean
   */
  private notificationMustBeDisplayed(
    response: PusherNotification,
    preRun?: BooleanCheck
  ): boolean {
    if (this.canReopen) {
      this.openedModal = false;
    }
    return !this.openedModal && (!preRun || preRun(response));
  }

  /**
   * Determines if in the array of notifications exists
   * a notification from a different user than the current one.
   * @param response: PusherNotification
   * @returns boolean
   */
  private isNotificationFromAnotherUser(response: PusherNotification): boolean {
    const notifications = response.message.map(n => n.user_id || n.user.id);
    return (
      (!!notifications.filter(id => id === this.userID).length &&
        NOTICATION_AUDIT_STATUS.includes(response.message[0].action)) ||
      !!notifications.filter(id => id !== this.userID).length
    );
  }
}
