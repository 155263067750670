import { DtrumApi } from "@/@types/dtrum";
import { Location } from "@/interfaces/location";
import { RetailSettings } from "@/interfaces/retailSettings";
import { EventBus, store } from "@/internal";
import { authService } from "@/services/auth.service";
import { inventorySettings } from "@/services/inventorySettings.service";
import { limitsService } from "@/services/limits.service";
import { locationService } from "@/services/location.service";
import { messagesService } from "@/services/messages.service";
import { retailSettingsService } from "@/services/retailSettings.service";
import { sharingService } from "@/services/sharing.service";
import { usersService } from "@/services/user.service";
import { RootState } from "@/vuex/types";
import differenceInSeconds from "date-fns/differenceInSeconds";
import fromUnixTime from "date-fns/fromUnixTime";
import Cookie from "js-cookie";
import jwtDecode from "jwt-decode";
import every from "lodash/every";
import omit from "lodash/omit";
import Vue from "vue";
import { ActionContext, ActionTree } from "vuex";
import { AuthState } from "./auth.types";

type AuthActionContext = ActionContext<AuthState, RootState>;
type AuthActionTree = ActionTree<AuthState, RootState>;

const getLocationRetail = async (
  location: Location
): Promise<RetailSettings | null> => {
  try {
    return await retailSettingsService.findByLocationId(location.id!);
  } catch (e) {
    messagesService.renderErrorMessage(e);
    return null;
  }
};

export const actions: AuthActionTree = {
  async logout(context: AuthActionContext, fromIdle = false): Promise<void> {
    if (fromIdle) {
      const appMap = Cookie.get("app_map");
      const map = JSON.parse(appMap!);
      const allIdle = every(map, ["idle", true]);
      if (!allIdle) {
        return;
      }
    }
    const token = Cookie.get("auth_data");
    if (token) {
      await authService.revokeToken();
      context.commit("unsetUser");
      context.commit("setCurrentLocation", null);
      context.commit("setCurrentRetailSettings", null);
      context.commit("setAccessToken", null);
      Cookie.remove("auth_data", {
        domain: `${process.env.VUE_APP_COOKIE_DOMAIN}`
      });
      Cookie.remove("app_map", {
        domain: process.env.VUE_APP_COOKIE_DOMAIN
      });
      Cookie.remove("userInfo");
      Cookie.remove("refresh_token", {
        domain: `${process.env.VUE_APP_COOKIE_DOMAIN}`
      });
      Cookie.remove("referrer_app", {
        domain: `${process.env.VUE_APP_COOKIE_DOMAIN}`
      });
    }
    window.location.href = `${process.env.VUE_APP_OAUTH_URL}/logout?client_id=${
      process.env.VUE_APP_OAUTH_CLIENT_ID
    }&logout_uri=${process.env.VUE_APP_CORE_FRONTEND}`;
  },

  async reloadLocation(context: AuthActionContext): Promise<void> {
    const currentLocation = context.getters.currentLocation;
    const location = await locationService.find(Number(currentLocation.id), {
      embed: "state"
    });
    context.commit("setCurrentLocation", location);
  },

  async getByToken(context: AuthActionContext): Promise<boolean> {
    try {
      const token = Cookie.get("auth_data");
      if (!token) {
        return false;
      }
      // @ts-ignore
      const response: AxiosResponse = await Vue.axios({
        method: "GET",
        url: `${process.env.VUE_APP_CORE_URL}/users/get_by_token`,
        config: {
          headers: { authorization: "Bearer " + token }
        }
      });
      const userData = response.data.data;
      // @ts-ignore
      if (+process.env.VUE_APP_DYNATRACE_EMIT && window.dtrum) {
        // @ts-ignore
        (dtrum as DtrumApi).identifyUser(`User ID: ${userData.id.toString()}`);
      }
      context.commit("setUser", userData);
      const location = await locationService.find(
        parseInt(userData.settings.current_location_id, 10),
        { embed: "state" }
      );
      context.commit("setCurrentLocation", location);

      store.dispatch(
        "TraceabilityModule/setLocationTraceability",
        userData.settings.current_location_id
      );

      await context.dispatch("setRetailSettings");
      await context.dispatch("setInventorySettings");

      context.dispatch("setLimits");
      await context.dispatch("setPermissionsForCTState");
      await context.dispatch("setSeparatedOptionEnabled");
      return true;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      context.dispatch("logout");
      return false;
    }
  },

  async saveCurrentLocationPricing(
    context: AuthActionContext,
    retailSettings: RetailSettings
  ) {
    try {
      delete retailSettings.rx_number;
      const currentLocation = context.getters.currentLocation;
      const updatedSettings = await retailSettingsService.save({
        ...retailSettings,
        location_id: currentLocation.id
      });

      context.commit("setCurrentRetailSettings", updatedSettings);
      messagesService.renderSuccessMessage("pricing_options_saved");
      store.dispatch("RouterModule/go", {
        name: "pricing-tools"
      });
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  },

  async updateCurrentLocation(
    context: AuthActionContext,
    newLocation: Location
  ) {
    try {
      const response = await usersService.changeUserLocation(newLocation.id!);
      if (response) {
        await authService.refreshToken();
        context.dispatch("setAccessToken");
        context.commit("setCurrentLocation", newLocation);
        return true;
      }
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return false;
    }
  },

  async setCurrentLocation(context: AuthActionContext, newLocation: Location) {
    context.commit("setCurrentLocation", newLocation);
    context.dispatch("setRetailSettings");
    EventBus.$emit("locationSetted");
  },

  async updateCurrentUser(context: AuthActionContext, user: Location) {
    context.commit("setUser", user);
    context.commit("setLoading", false);
  },

  setAccessToken(context: AuthActionContext, token: string) {
    if (!token) {
      const authCookie = Cookie.get("auth_data");
      token = authCookie ? authCookie : "";
      // unset user if no token is present
      if (!token) {
        context.commit("unsetUser");
      }
    } else {
      const decoded: { exp: number } = jwtDecode(token);
      Cookie.set("token_exp", String(decoded.exp), {
        domain: process.env.VUE_APP_COOKIE_DOMAIN
      });
    }

    context.commit("setAccessToken", token);
  },

  setRedirect(context: AuthActionContext, url: string) {
    context.commit("setRedirect", url);
  },

  async setLimits(context: AuthActionContext) {
    const limits = await limitsService.getLimitsCurrentLocation();
    context.commit("setLimitConfig", limits);
  },

  async setRetailSettings(context: AuthActionContext) {
    const location = context.getters.currentLocation;
    if (location) {
      context.commit(
        "setCurrentRetailSettings",
        await getLocationRetail(location)
      );
    }
  },
  async setInventorySettings(context: AuthActionContext) {
    const res = await inventorySettings.getSettings();
    if (res) {
      context.commit("setCurrentInventorySettings", res.data);
    }
  },
  async checkToken(context: AuthActionContext): Promise<void> {
    const now = new Date();
    const expiration = Cookie.get("token_exp");
    const diff =
      (expiration && differenceInSeconds(fromUnixTime(+expiration), now)) ||
      200;

    if (diff <= 80) {
      context.dispatch("refreshToken");
    }
  },

  async refreshToken(context: AuthActionContext) {
    const newIdToken = await authService.refreshToken();
    if (newIdToken) {
      context.dispatch("setAccessToken", newIdToken);
    }
  },

  registerApp(context: AuthActionContext) {
    const appMap = Cookie.get("app_map");
    const appId = `bdi_${Date.now()}`;
    context.commit("setAppId", appId);

    const map = appMap ? JSON.parse(appMap) : {};
    map[appId] = {
      idle: false
    };
    Cookie.set("app_map", JSON.stringify(map), {
      domain: `${process.env.VUE_APP_COOKIE_DOMAIN}`
    });
  },
  unregisterApp(context: AuthActionContext) {
    const appMap = Cookie.get("app_map");
    const appId: string = context.getters.appId;
    const map = JSON.parse(appMap!);

    Cookie.set("app_map", JSON.stringify(omit(map, [appId])), {
      domain: `${process.env.VUE_APP_COOKIE_DOMAIN}`
    });
  },
  setAppIdle(context: AuthActionContext, idleValue: boolean) {
    const appMap = Cookie.get("app_map");
    if (!appMap || appMap === "{}") {
      context.dispatch("logout");
      return;
    }
    const appId: string = context.getters.appId;
    const map = JSON.parse(appMap!);
    map[appId].idle = idleValue;
    Cookie.set("app_map", JSON.stringify(map), {
      domain: `${process.env.VUE_APP_COOKIE_DOMAIN}`
    });
  },
  async setPermissionsForCTState(context: AuthActionContext) {
    const location = context.getters.currentLocation;
    const retailSettings = context.getters.currentRetailSettings;
    if (location.state!.code && location.state!.code === "CT") {
      if (
        retailSettings.pharmacy_mode_enabled === 0 &&
        retailSettings.doctor_tracking_enabled === 0 &&
        retailSettings.doctor_pharmacy_status_modified === 0
      ) {
        retailSettings.pharmacy_mode_enabled = 1;
        retailSettings.doctor_tracking_enabled = 1;
        retailSettings.doctor_pharmacy_status_modified = 1;

        if (retailSettings.rx_number !== undefined) {
          delete retailSettings.rx_number;
        }
        await retailSettingsService.save(retailSettings);
      }
    }
  },

  async setSeparatedOptionEnabled(context: AuthActionContext) {
    const response = await sharingService.getSharing();
    const isProductEnable =
      !response!.entities.PRODUCT ||
      !response!.entities.PRODUCT!.separated!.length;
    context.commit("isSeparatedOptionEnabled", isProductEnable);
  }
};
