import { RootState } from "@/vuex/types";
import { Module } from "vuex";
import { actions } from "./notifications.actions";
import { getters } from "./notifications.getters";
import { mutations } from "./notifications.mutations";
import { state } from "./notifications.state";
import { NotificationState } from "./notifications.types";

const namespaced: boolean = true;
export const NotificationModule: Module<NotificationState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
