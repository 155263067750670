import { MemberLevelState } from "@/vuex/modules/inventory/memberLevel/memberLevel.types";
import { RootState } from "@/vuex/types";
import { Module } from "vuex";
import { actions } from "./memberLevel.actions";
import { getters } from "./memberLevel.getters";
import { mutations } from "./memberLevel.mutations";
import { state } from "./memberLevel.state";

const namespaced: boolean = true;

export const MemberLevelModule: Module<MemberLevelState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
