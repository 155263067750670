import { Vendor, VendorDocuments } from "@/interfaces/vendor";
import { TablePagination } from "helix-vue-components";
import { MutationTree } from "vuex";
import { VendorState } from "./vendor.types";

type VendorMutationTree = MutationTree<VendorState>;

export const mutations: VendorMutationTree = {
  setVendors(state: VendorState, vendors: Vendor[]) {
    state.vendors = vendors;
  },
  setDefaultVendors(state: VendorState, vendors: Vendor[]) {
    state.defaultVendors = vendors;
  },
  setVendorFiles(state: VendorState, files: VendorDocuments[]) {
    state.vendorFiles = files;
  },
  setLoading(state: VendorState, loading: boolean) {
    state.loading = loading;
  },
  setLoadingFiles(state: VendorState, loading: boolean) {
    state.loadingFiles = loading;
  },
  setPagination(state: VendorState, pagination: TablePagination | null) {
    state.pagination = pagination;
  },
  setEditVendor(state: VendorState, vendor: Vendor) {
    state.editVendor = vendor;
  }
};
