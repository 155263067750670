import { Terminal } from "@/interfaces/system";
import { Type } from "@/interfaces/type";
import { SystemState } from "@/vuex/modules/system/system.types";
import { RootState } from "@/vuex/types";
import { TablePagination } from "helix-vue-components";
import { GetterTree } from "vuex";

type SystemGetter = GetterTree<SystemState, RootState>;

export const getters: SystemGetter = {
  productTypes(state: SystemState): Type[] {
    return state.productTypes;
  },
  terminals(state: SystemState): Terminal[] {
    return state.terminals;
  },
  pagination(state: SystemState): TablePagination | null {
    return state.pagination;
  },
  loading(state: SystemState): boolean {
    return state.loading;
  },
  currentTerminal(state: SystemState): Terminal | null {
    return state.currentTerminal;
  }
};
