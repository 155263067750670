import { PricePointState } from "@/vuex/modules/inventory/pricePoint/pricePoint.types";
import { RootState } from "@/vuex/types";
import { Module } from "vuex";
import { actions } from "./pricePoint.actions";
import { getters } from "./pricePoint.getters";
import { mutations } from "./pricePoint.mutations";
import { state } from "./pricePoint.state";

const namespaced: boolean = true;
export const PricePointModule: Module<PricePointState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
