import {
  customerVariables,
  inventoryVariables,
  listVariables,
  pharmacistVariables
} from "@/components/templateEditor/editorItems/UIBlock.config";
import TemplateEditor from "@/components/templateEditor/editors.declaration";
import { SearchQuery } from "@/interfaces/httpQuery";
import HttpService from "@/services/http.service";
import { messagesService } from "./messages.service";

class TemplatesService extends HttpService {
  protected loadDispatcher = "TemplateModelModule/loadTemplates";
  protected uri: string = "/templates";
  protected searchableField: SearchQuery[] = [
    {
      field: "name",
      type: "contains"
    }
  ];

  public async getTemplate(
    templateId: string
  ): Promise<TemplateEditor.TemplateModel | null> {
    const response = await super.findById(+templateId, this.query);
    return response;
  }

  public async getTemplateByType(
    type: "CUSTOMER" | "INVENTORY"
  ): Promise<TemplateEditor.TemplateModel[]> {
    try {
      const response = await super.get({
        "q[type_eq]": type,
        no_pagination: true
      });
      return response.data;
    } catch (error) {
      messagesService.renderErrorMessage(error);
      return [];
    }
  }

  public async get(
    query: object | null = null
  ): Promise<TemplateEditor.TemplateModel[]> {
    const response = await super.get({ ...query, ...this.query });
    const payload: TemplateEditor.TemplateModel[] = response.data.data;
    return payload;
  }

  public async configuration(
    type: string
  ): Promise<TemplateEditor.TemplateTypeConfiguration> {
    return new Promise((resolve, reject) => {
      resolve(types[type]);
    });
  }

  public getAvailableTypes() {
    const availableTypes: TemplateEditor.TemplateType[] = [
      { name: "INVENTORY", configuration: {} },
      { name: "CUSTOMER", configuration: {} },
      { name: "RECEIPT", configuration: {} },
      { name: "BARCODE", configuration: {} }
    ];
    return availableTypes;
  }

  public async getDefault(
    templateType: string
  ): Promise<TemplateEditor.TemplateModel | null> {
    try {
      const response = await super.get({
        limit: 1,
        type: templateType,
        default: true
      });
      return response.data[0];
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return null;
    }
  }
}

const types: { [key: string]: TemplateEditor.TemplateTypeConfiguration } = {
  test: {
    aggregableItems: [],
    uiBlocks: {
      "test-item": {
        title: "Test custom configuration",
        fields: {
          content: {
            label: "Some label",
            placeholder: "Type your content here"
          },
          var: {
            items: [
              { label: "Option 1", value: "option-1" },
              { label: "Option 2", value: "option-2" },
              { label: "Option 3", value: "option-3" },
              { label: "Option 4", value: "option-4" }
            ]
          },
          barcode: {
            items: [
              { label: "128", value: "128" },
              { label: "QR", value: "QR" },
              { label: "39", value: "39" }
            ]
          }
        }
      }
    }
  },
  BARCODE: {
    aggregableItems: [],
    uiBlocks: {
      "barcode-item": {
        fields: {
          code: {
            type: "text"
          }
        }
      }
    }
  },
  INVENTORY: {
    aggregableItems: [],
    uiBlocks: {
      "var-item": {
        fields: {
          variable: {
            items: [...inventoryVariables]
          }
        }
      },
      "list-item": {
        fields: {
          variable: {
            items: [...listVariables]
          }
        }
      }
    }
  },
  CUSTOMER: {
    aggregableItems: [],
    uiBlocks: {
      "var-item": {
        fields: {
          variable: {
            items: [
              ...inventoryVariables,
              ...customerVariables,
              ...pharmacistVariables
            ]
          }
        }
      },
      "list-item": {
        fields: {
          variable: {
            items: [...listVariables]
          }
        }
      }
    }
  },
  RECEIPT: {
    aggregableItems: [],
    uiBlocks: {
      "var-item": {
        fields: {
          variable: {
            items: [...inventoryVariables, ...customerVariables]
          }
        }
      },
      "list-item": {
        fields: {
          variable: {
            items: [...listVariables]
          }
        }
      }
    }
  }
};

export const templatesService: TemplatesService = new TemplatesService();
