import { fnsFormatToLocalDatetime } from "@/filters/date-fns.filter";
import { TimeClockNotificationStatus } from "@/interfaces/timeclockNotification";
import { Callback } from "@/types/types";
import { Notifications } from "@/vuex/modules/notifications/notifications.types";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";
import Template from "./TimeclockNotification.template.vue";

@Component({
  mixins: [Template]
})
export class TimeclockNotificationComponent extends Vue {
  @Prop({ required: true })
  public notification!: Notifications;

  @Action("modifyTimeclock", { namespace: "NotificationModule" })
  public modifyTimeclock!: Callback;

  // To Update timeclock widgets
  @Action("loadTimeclocks", { namespace: "TimeclockModule" })
  public loadTimeclocks!: Callback;

  // To reset timeclocks on timeclock back card component
  @Action("resetFilteredTimeclocks", { namespace: "TimeclockModule" })
  public resetFilteredTimeclocks!: Callback;

  public get parseTimeclocks() {
    const {
      timeclock: oldTimeclock,
      check_in,
      check_out,
      break_for
    } = this.notification;

    return {
      old: {
        check_in: fnsFormatToLocalDatetime(oldTimeclock.check_in),
        check_out: fnsFormatToLocalDatetime(oldTimeclock.check_out),
        break: oldTimeclock.break_for && oldTimeclock.break_for.substring(0, 5)
      },
      new: {
        check_in: fnsFormatToLocalDatetime(check_in),
        check_out: fnsFormatToLocalDatetime(check_out),
        break: break_for && break_for.substring(0, 5)
      }
    };
  }

  public reject() {
    this.modifyTimeclock({
      ...this.notification,
      status: TimeClockNotificationStatus.REJECTED
    });
    this.$emit("action");
  }

  public approve() {
    this.modifyTimeclock({
      ...this.notification,
      status: TimeClockNotificationStatus.APPROVED
    });
    this.loadTimeclocks();
    this.resetFilteredTimeclocks();
    this.$emit("action");
  }
}
