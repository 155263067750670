import { TemplateEditor } from "@/components/templateEditor/editors.declaration";
import { Component } from "vue-property-decorator";
import UIBlockDefaultComponent from "../UIBlockDefault.component";
import Template from "./TestItem.template.vue";

@Component({
  mixins: [Template]
})
export default class TestItem extends UIBlockDefaultComponent {
  protected block: TemplateEditor.UIBlocks.Block = {
    configuration: {
      title: "Test UI Block",
      subtitle: "Setup your configuration",
      sections: [
        {
          title: "Logo",
          fields: {
            logo: {
              type: "upload-file",
              configuration: { label: "Upload Image", show_preview: true }
            }
          }
        },
        {
          title: "Colors",
          fields: {
            background: {
              type: "color-picker",
              configuration: {}
            }
          }
        },
        {
          title: "Checkboxes section",
          fields: {
            must_show: {
              type: "checkbox",
              configuration: { label: "Must show" }
            }
          }
        },
        {
          title: "Content & Size",
          fields: {
            content: { type: "text" },
            select: { type: "select" },
            display: {
              type: "switch",
              configuration: { label: "Display element" }
            },
            barcode: {
              type: "radiogroup",
              configuration: { label: "Barcode Type" }
            },
            size: {
              type: "group",
              fields: {
                width: { type: "text", configuration: { type: "number" } },
                height: { type: "text", configuration: { type: "number" } }
              }
            },
            fontStyle: {
              type: "group",
              fields: {
                button_1: { type: "boolean-button" },
                button_2: { type: "boolean-button" }
              }
            }
          }
        },
        {
          title: "Text align",
          fields: {
            textAlign: {
              type: "group",
              fields: {
                alignLeft: {
                  type: "boolean-button",
                  configuration: {
                    icon: "fal fa-align-left",
                    value: "align-left"
                  }
                },
                alignCenter: {
                  type: "boolean-button",
                  configuration: {
                    icon: "fal fa-align-center",
                    value: "align-center"
                  }
                },
                alignRight: {
                  type: "boolean-button",
                  configuration: {
                    icon: "fal fa-align-right",
                    value: "align-right"
                  }
                }
              }
            },
            textAlignGroup: {
              type: "boolean-buttons-group",
              configuration: {
                buttons: [
                  {
                    icon: "fal fa-align-left",
                    value: "align-left"
                  },
                  {
                    icon: "fal fa-align-center",
                    value: "align-center"
                  },
                  {
                    icon: "fal fa-align-right",
                    value: "align-right"
                  }
                ]
              }
            }
          }
        }
      ]
    },
    model: {
      type: "test-item",
      model: {
        content: ""
      }
    }
  };
}
