var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"outsideClick",rawName:"v-outsideClick",value:({
    action: function () { return (_vm.menuExpanded = false); },
    pre: function () { return _vm.menuExpanded; }
  }),expression:"{\n    action: () => (menuExpanded = false),\n    pre: () => menuExpanded\n  }"}],staticClass:"mega-menu",class:{
    expandedView: _vm.isExpandedView && !_vm.menuExpanded
  },style:(_vm.megaMenuPositionStyles)},[_c('v-toolbar',{attrs:{"color":"038edf","dark":"","flat":""}},[(_vm.user)?_c('div',[_c('profile-component',{staticClass:"mr-2"}),_vm._v(" "),_c('apps-component',{attrs:{"items":_vm.items}})],1):_vm._e(),_vm._v(" "),_c('v-btn',{staticClass:"ml-2",attrs:{"icon":""},on:{"click":_vm.changeFullScreen}},[(!_vm.fullScreenAction)?_c('v-icon',[_vm._v("fullscreen")]):_vm._e(),_vm._v(" "),(_vm.fullScreenAction)?_c('v-icon',[_vm._v("fullscreen_exit")]):_vm._e()],1),_vm._v(" "),(_vm.environment)?_c('div',{staticClass:"title-version ml-3 mt-1 "},[_vm._v(_vm._s(_vm.environment))]):_vm._e(),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-toolbar-title',{staticClass:"ml-0 pl-3",staticStyle:{"width":"300px"},attrs:{"scroll-off-screen":""}},[_c('router-link',{staticClass:"hidden-sm-and-down",attrs:{"to":"/dashboard"}},[_c('img',{staticClass:"biotrack_logo",attrs:{"src":require("@/assets/images/BioTrackThc_logo_white.svg"),"alt":"Biotrack","height":"40"}})])],1),_vm._v(" "),_c('v-spacer'),_vm._v(" "),(_vm.user && _vm.showMenu)?_c('div',[(!_vm.menuExpanded)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.menuExpanded = true}}},[_c('v-icon',[_vm._v("menu")])],1):_vm._e(),_vm._v(" "),(_vm.menuExpanded)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.menuExpanded = false}}},[_c('v-icon',[_vm._v("close")])],1):_vm._e()],1):_vm._e()],1),_vm._v(" "),(_vm.user)?_c('div',{staticClass:"menu",class:{
      'menu--opened': _vm.menuExpanded,
      'menu--expanded': _vm.isExpandedView && !_vm.menuExpanded
    }},[_c('v-container',{staticClass:"pt-0",attrs:{"fluid":"","grid-list-md":""}},[_c('v-layout',{staticClass:"menu--opened__wrapper menu--expanded__wrapper",staticStyle:{"margin":"0 0 10px"},attrs:{"v-show":_vm.isExpandedView}},[_vm._t("sections")],2),_vm._v(" "),_c('breadcrumbs-component')],1)],1):_vm._e(),_vm._v(" "),(_vm.user)?_vm._t("titlebar"):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }