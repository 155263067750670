import { Room } from "@/interfaces/room";
import { TablePagination } from "helix-vue-components";
import { MutationTree } from "vuex";
import { RoomState } from "./room.types";

type RoomMutationTree = MutationTree<RoomState>;

export const mutations: RoomMutationTree = {
  setRooms(state: RoomState, rooms: Room[]) {
    state.rooms = rooms;
  },
  setLoading(state: RoomState, loading: boolean) {
    state.loading = loading;
  },
  setEditRoom(state: RoomState, vendor: Room) {
    state.editRoom = vendor;
  },
  setModal(state: RoomState, modal: boolean) {
    state.modal = modal;
  },
  setPagination(state: RoomState, pagination: TablePagination | null) {
    state.pagination = pagination;
  },
  setAllRooms(state: RoomState, rooms: Room[]) {
    state.allRooms = rooms;
  }
};
