import { Customer } from "@/interfaces/customer";
import { CustomerState } from "@/vuex/modules/customer/customer.types";
import { RootState } from "@/vuex/types";
import { FormField, TablePagination } from "helix-vue-components";
import { GetterTree } from "vuex";

type CustomerGetter = GetterTree<CustomerState, RootState>;

export const getters: CustomerGetter = {
  customers(state: CustomerState): Customer[] {
    state.customers.map(items => {
      if (typeof items!.limits![0] === "number") {
        items!.limits!.reverse();
        const temp = items!.limits![0];
        items!.limits![0] = items!.limits![1];
        items.limits![1] = temp;
      }
    });
    return state.customers;
  },
  pagination(state: CustomerState): TablePagination | null {
    return state.pagination;
  },
  scrollPagination(state: CustomerState): TablePagination | null {
    return state.scrollPagination;
  },
  loading(state: CustomerState): boolean {
    return state.loading;
  },
  form(state: CustomerState): FormField[] | null {
    return state.form;
  },
  currentCustomer(state: CustomerState): Customer | null {
    return state.currentCustomer;
  },
  customerRouteforSave(state: CustomerState): string | null {
    return state.customerRouteforSave;
  },
  savedForCheckin(state: CustomerState): boolean {
    return state.savedForCheckin;
  },
  navigateToAddDoctor(state: CustomerState): boolean {
    return state.navigateToAddDoctor;
  },
  navigateToCheckIn(state: CustomerState): boolean {
    return state.navigateToCheckIn;
  }
};
