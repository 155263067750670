import { EventBus } from "@/internal";
import { Component, Prop, Vue } from "vue-property-decorator";
import TemplateEditor from "../editors.declaration";

@Component({})
export default class UIBlockDefaultComponent extends Vue {
  @Prop({}) protected templateModel!: TemplateEditor.UIBlocks.BaseBlockModel;
  @Prop({ default: false }) protected printMode!: boolean;
  @Prop({})
  protected configuration!: TemplateEditor.UIBlocks.CustomConfiguration;
  @Prop({
    default: () => {
      return {};
    }
  })
  protected model!: { [key: string]: any };
  @Prop() protected breadcrumb!: number[];

  protected active: boolean = false;
  protected hover = false;

  protected block!: TemplateEditor.UIBlocks.Block;

  public updateHover() {
    this.hover = !this.hover;
  }

  protected onItemClick() {
    EventBus.$emit("exitEdit");
    this.deliverEvents();
    const block = {
      ...this.block,
      ...{ model: this.templateModel, configuration: this.config }
    };

    this.$emit("itemSelected", block);
  }

  protected deliverEvents() {
    EventBus.$emit("activeBlock", this);
    EventBus.$emit("templateEditorEdit", {
      ...this.block,
      ...{ model: this.templateModel, configuration: this.config }
    });
  }

  protected mounted() {
    if (this.templateModel.newBlock) {
      this.deliverEvents();
      delete this.templateModel.newBlock;
    }
    EventBus.$on("activeBlock", (block: any) => {
      this.active = block === this;
    });
    EventBus.$on("exitEdit", () => (this.active = false));
  }
  protected get config(): { [key: string]: any } {
    return (this.block.configuration = {
      ...this.block.configuration,
      ...this.configuration
    });
  }
  protected get values(): { [key: string]: any } {
    return {
      ...this.block.model.model,
      ...this.templateModel,
      ...{
        border: this.active ? "1px solid #0093ff" : "none",
        padding: this.active ? "1px" : "2px",
        cursor: this.hover ? "pointer" : "default"
      }
    };
  }
}
