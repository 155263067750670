import TemplateEditor from "@/components/templateEditor/editors.declaration";
import { Component } from "vue-property-decorator";
import UIBlockDefaultComponent from "../UIBlockDefault.component";
import Template from "./UIBlockTextField.template.vue";

import { TextFieldDefaultConfig } from "../UIBlock.config";
import { TextFieldDefaultValues } from "../UIBlock.declarations";

@Component({
  mixins: [Template]
})
export default class UIBlockTextFieldComponent extends UIBlockDefaultComponent {
  protected block: TemplateEditor.UIBlocks.Block = {
    model: {
      type: "text-item",
      model: { ...TextFieldDefaultValues }
    },
    configuration: TextFieldDefaultConfig
  };

  protected get textStyles() {
    return {
      ...this.values,
      fontSize: this.values.fontSize + "px",
      fontWeight: this.values.bold ? 600 : 400,
      fontStyle: this.values.italic ? "italic" : "normal"
    };
  }
}
