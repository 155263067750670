import {
  Batch,
  CustomBatch,
  Laboratory,
  ProductBatch,
  SampleResultProfile
} from "@/interfaces/batch";
import { Type } from "@/interfaces/type";
import { BatchState } from "@/vuex/modules/inventory/batch/batch.types";
import { RootState } from "@/vuex/types";
import { TablePagination } from "helix-vue-components";
import { GetterTree } from "vuex";

type BatchGetter = GetterTree<BatchState, RootState>;

export const getters: BatchGetter = {
  productTypes(state: BatchState): Type[] {
    return state.productTypes;
  },
  productBatches(state: BatchState): ProductBatch[] {
    return state.productBatches;
  },
  productBatchesPagination(state: BatchState): TablePagination | null {
    return state.productBatchesPagination;
  },
  loading(state: BatchState): boolean {
    return state.loading;
  },
  currentBatch(state: BatchState): Batch | null {
    return state.currentBatch;
  },
  sampleResultProfiles(state: BatchState): SampleResultProfile[] | null {
    return state.sampleResultProfiles;
  },
  getAdjustBatch(state: BatchState): CustomBatch[] {
    return state.adjustBatch;
  }
};
