import { SearchQuery } from "@/interfaces/httpQuery";
import { ProductCategory } from "@/interfaces/productCategoy";
import HttpService from "@/services/http.service";
import { AxiosResponse } from "axios";
import { TableHeader } from "helix-vue-components";
import Vue from "vue";
import { messagesService } from "./messages.service";

class ProductCategoryService extends HttpService {
  protected loadDispatcher = "ProductCategoryModule/loadProductCategories";

  protected searchableField: SearchQuery[] = [
    { type: "contains", field: "name" }
  ];

  protected uri: string = "catalog/product_categories";

  /**
   * sort action to make a request
   * @param params
   */
  public sortQuery(params: TableHeader) {
    return `${!params.descending ? "-" : ""}${params.value || ""}`;
  }

  public async getAllCategories(
    query: object | null = null
  ): Promise<ProductCategory[]> {
    query = { ...query, no_pagination: true, sort: "name" };
    const response = await super.get(query);
    const payload: ProductCategory[] = response.data;
    return payload;
  }

  public async get(query?: object | null): Promise<ProductCategory[]> {
    const response = await super.get(query || this.query);
    return response.data.data;
  }

  public async save(model: ProductCategory): Promise<void> {
    try {
      model.id ? await this.put(model, model) : await this.post(model);
      messagesService.renderSuccessMessage("product_category_saved");
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }

  public async deleteMultiple(productCategories: ProductCategory[]) {
    try {
      await super.deleteMultiple(productCategories.map(c => c.id));
      messagesService.renderSuccessMessage("deleted_successfully");
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }

  public async toggleProductCategory(
    productCategories: ProductCategory,
    isActive: boolean
  ): Promise<void> {
    if (isActive) {
      return super.enable(productCategories);
    }
    return super.disable(productCategories);
  }

  public async findByLocationAndNameContains(
    locationId: number,
    categoryName: string
  ): Promise<ProductCategory[]> {
    this.query.location_id_in = "*";
    this.query.location_id = locationId;
    this.query.embed = "productCategory";
    this.query.no_pagination = true;
    delete this.query.sort;
    delete this.query.page;
    delete this.query.per_page;
    // @ts-ignore
    const response: AxiosResponse = await Vue.axios({
      method: "GET",
      url: "inventory/location_product_categories",
      params: this.query
    });
    // @ts-ignore
    const productCategories: ProductCategory[] = response.data.data.map(
      // @ts-ignore
      categories => {
        return categories.product_category;
      }
    );
    return productCategories;
  }
}

export const productCategoryService = new ProductCategoryService();
