import { render, staticRenderFns } from "./UIBlockImageField.template.vue?vue&type=template&id=5c3ce055&scoped=true&"
var script = {}
import style0 from "./UIBlockImageField.template.vue?vue&type=style&index=0&id=5c3ce055&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c3ce055",
  null
  
)

export default component.exports