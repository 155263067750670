export const policyList = {
  importExportCustomers: "importExport.importExportCustomers",
  importExportLabResults: "importExport.importExportLabResults",
  importExportVendorsLaboratories:
    "importExport.importExportVendorsLaboratories",
  importExportBrands: "importExport.importExportBrands",
  importExportStrains: "importExport.importExportStrains",
  importExportBatchTypes: "importExport.importExportBatchTypes",
  importExportProductsCategories: "importExport.importExportProductsCategories",
  importExportProducts: "importExport.importExportProducts",
  deleteTemplates: "templateManager.deleteTemplates",
  modifyTemplates: "templateManager.modifyTemplates",
  createTemplates: "templateManager.createTemplates",
  viewTemplates: "templateManager.viewTemplates",
  viewReportsTool: "reports.viewReportsTool",
  viewRetailSettings: "retailSettings.viewRetailSettings",
  deleteLoyaltyPrograms: "loyaltyProgram.deleteLoyaltyPrograms",
  modifyLoyaltyPrograms: "loyaltyProgram.modifyLoyaltyPrograms",
  createLoyaltyPrograms: "loyaltyProgram.createLoyaltyPrograms",
  viewLoyaltyPrograms: "loyaltyProgram.viewLoyaltyPrograms",
  deleteDiscounts: "discountManager.deleteDiscounts",
  modifyDiscounts: "discountManager.modifyDiscounts",
  createDiscounts: "discountManager.createDiscounts",
  viewDiscountPrograms: "discountManager.viewDiscountPrograms",
  refund: "retailSalesHistory.refund",
  viewRetailSalesHistory: "retailSalesHistory.viewRetailSalesHistory",
  viewCurrentInventory: "inventory.viewCurrentInventory",
  viewInventorySettings: "inventory.viewInventorySettings",
  modifyInventorySettings: "inventory.modifyInventorySettings",
  voidSale: "retailSalesHistory.voidSale",
  viewProducts: "inventoryTools.viewProducts",
  modifyPayment: "retailSalesHistory.modifyPayment",
  viewProductsCategories: "inventoryTools.viewProductsCategories",
  createProductsCategories: "inventoryTools.createProductsCategories",
  modifyProductsCategories: "inventoryTools.modifyProductsCategories",
  viewBatchTypes: "inventoryTools.viewBatchTypes",
  createBatchTypes: "inventoryTools.createBatchTypes",
  modifyBatchTypes: "inventoryTools.modifyBatchTypes",
  enableDisableBatchTypes: "inventoryTools.enableDisableBatchTypes",
  accessPointOfSale: "pointOfSale.accessPointOfSale",
  modifyPrescriptionDetailsInPos: "pointOfSale.modifyPrescriptionDetailsInPos",
  viewStrains: "inventoryTools.viewStrains",
  createStrains: "inventoryTools.createStrains",
  modifyStrains: "inventoryTools.modifyStrains",
  enableDisableStrains: "inventoryTools.enableDisableStrains",
  viewBrands: "inventoryTools.viewBrands",
  createBrands: "inventoryTools.createBrands",
  modifyBrands: "inventoryTools.modifyBrands",
  enableDisableBrands: "inventoryTools.enableDisableBrands",
  viewVendorsLabs: "inventoryTools.viewVendorsLabs",
  createVendorsLabs: "inventoryTools.createVendorsLabs",
  modifyVendorsLabs: "inventoryTools.modifyVendorsLabs",
  enableDisableVendorsLabs: "inventoryTools.enableDisableVendorsLabs",
  viewInventoryRooms: "inventoryTools.viewInventoryRooms",
  createInventoryRooms: "inventoryTools.createInventoryRooms",
  modifyInventoryRooms: "inventoryTools.modifyInventoryRooms",
  enableDisableInventoryRooms: "inventoryTools.enableDisableInventoryRooms",
  viewInventoryAudit: "inventoryTools.viewInventoryAudit",
  createInventoryAudit: "inventoryTools.createInventoryAudit",
  modifyInventoryAudit: "inventoryTools.modifyInventoryAudit",
  deleteInventoryAudit: "inventoryTools.deleteInventoryAudit",
  viewBatchLevelPricing: "pricingTools.viewBatchLevelPricing",
  createBatchLevelPricing: "pricingTools.createBatchLevelPricing",
  deleteBatchLevelPricing: "pricingTools.deleteBatchLevelPricing",
  enableDisableBatchLevelPricing: "pricingTools.enableDisableBatchLevelPricing",
  viewMemberLevelPricing: "pricingTools.viewMemberLevelPricing",
  createMemberLevelPricing: "pricingTools.createMemberLevelPricing",
  modifyMemberLevelPricing: "pricingTools.modifyMemberLevelPricing",
  enableDisableMemberLevelPricing:
    "pricingTools.enableDisableMemberLevelPricing",
  viewTaxCategories: "pricingTools.viewTaxCategories",
  createTaxCategories: "pricingTools.createTaxCategories",
  modifyTaxCategories: "pricingTools.modifyTaxCategories",
  deleteTaxCategories: "pricingTools.deleteTaxCategories",
  loyaltyActions: "pointOfSale.loyaltyActions",
  accessCustomersList: "customerProfileManagement.accessCustomersList",
  applyManualDiscounts: "pointOfSale.applyManualDiscounts",
  viewBatchDetails: "batchTools.viewBatchDetails",
  modifyBatchDetails: "batchTools.modifyBatchDetails",
  splitBatch: "batchTools.splitBatch",
  adjustBatch: "batchTools.adjustBatch",
  convertBatch: "batchTools.convertBatch",
  combineBatch: "batchTools.combineBatch",
  moveBatch: "batchTools.moveBatch",
  createCustomers: "customerProfileManagement.createCustomers",
  addMoneyToSafe: "moneyManager.addMoneyToSafe",
  deleteCustomer: "customerProfileManagement.deleteCustomer",
  accessCheckInList: "checkIn.accessCheckInList",
  removeMoneyFromSafe: "moneyManager.removeMoneyFromSafe",
  payoutFromSafe: "moneyManager.payoutFromSafe",
  openATill: "moneyManager.openATill",
  deleteDrivers: "batchTransfers.deleteDrivers",
  viewVehicles: "batchTransfers.viewVehicles",
  createVehicles: "batchTransfers.createVehicles",
  modifyVehicles: "batchTransfers.modifyVehicles",
  deleteVehicles: "batchTransfers.deleteVehicles",
  closeATill: "moneyManager.closeATill",
  modifyDrivers: "batchTransfers.modifyDrivers",
  createDrivers: "batchTransfers.createDrivers",
  viewDrivers: "batchTransfers.viewDrivers",
  payoutFromTill: "moneyManager.payoutFromTill",
  addMoneyToTill: "moneyManager.addMoneyToTill",
  removeMoneyFromTill: "moneyManager.removeMoneyFromTill",
  viewQuotesTab: "batchTransfers.viewQuotesTab",
  voidPayout: "moneyManager.voidPayout",
  reviewAClosedTill: "moneyManager.reviewAClosedTill",
  createQuote: "batchTransfers.createQuote",
  modifyQuote: "batchTransfers.modifyQuote",
  viewSalesLimits: "salesLimits.viewSalesLimits",
  modifySalesLimits: "salesLimits.modifySalesLimits",
  accessToOrderFulfillment: "orderFulfillment.accessToOrderFulfillment",
  fillPendingOrder: "orderFulfillment.fillPendingOrder",
  printAPickList: "orderFulfillment.printAPickList",
  modifyLineItemQuantities: "orderFulfillment.modifyLineItemQuantities",
  cancelLineItem: "orderFulfillment.cancelLineItem",
  cancelPendingOrder: "orderFulfillment.cancelPendingOrder",
  cancelSavedOrder: "orderFulfillment.cancelSavedOrder",
  restockCancelledOrder: "orderFulfillment.restockCancelledOrder",
  printFilledOrderReceipt: "orderFulfillment.printFilledOrderReceipt",
  returnOrderToPendingStatus: "orderFulfillment.returnOrderToPendingStatus",
  modifyPrescriptionDetailsInOOF:
    "orderFulfillment.modifyPrescriptionDetailsInOrderFulfillment",
  deleteQuote: "batchTransfers.deleteQuote",
  createOutboundTransfer: "batchTransfers.createOutboundTransfer",
  discountActions: "pointOfSale.discountActions",
  createPurchaseOrder: "batchTransfers.createPurchaseOrder",
  printPayoutReceipt: "moneyManager.printPayoutReceipt",
  viewPayoutReason: "moneyManager.viewPayoutReason",
  employeePayoutFromTill: "moneyManager.employeePayoutFromTill",
  employeeCloseTill: "moneyManager.employeeCloseTill",
  modifyPurchaseOrder: "batchTransfers.modifyPurchaseOrder",
  deletePurchaseOrder: "batchTransfers.deletePurchaseOrder",
  inboundTransfersTool: "batchTransfers.inboundTransfersTool",
  newBatchesTool: "batchTransfers.newBatchesTool",
  addPayments: "batchTransfers.addPayments",
  completeManifest: "batchTransfers.completeManifest",
  voidManifest: "batchTransfers.voidManifest",
  modifyManifest: "batchTransfers.modifyManifest",
  overrideSaleLimits: "accessPointOfSale.overrideSaleLimits",
  viewPurchaseOrdersTab: "batchTransfers.viewPurchaseOrdersTab",
  viewOutboundTransferTab: "batchTransfers.viewOutboundTransferTab",
  viewInboundTransferTab: "batchTransfers.viewInboundTransferTab",
  dailyFinancialBreakdown: "dashboardTiles.dailyFinancialBreakdown",
  dailyLocationActionTicker: "dashboardTiles.dailyLocationActionTicker",
  dailyRetailSales: "dashboardTiles.dailyRetailSales",
  viewBatchPricingDetails: "batchTools.viewBatchPricingDetails",
  viewCustomerListReport: "reportView.viewCustomerListReport",
  saveShoppingCart: "pointOfSale.saveShoppingCart",
  viewProductsReport: "reports.viewProductsReport",
  viewProductPricesReport: "reports.viewProductPricesReport",
  viewTaxBreakdownReport: "reports.viewTaxBreakdownReport",
  viewSalesReport: "reports.viewSalesReport",
  viewRefundReport: "reports.viewRefundReport",
  viewRetailSalesSummaryReport: "reports.viewRetailSalesSummaryReport",
  viewBatchAdjustmentsReport: "reports.viewBatchAdjustmentsReport",
  viewBatchAuditReport: "reports.viewBatchAuditReport",
  viewForensicsReport: "reports.viewForensicsReport",
  printReports: "reports.printReports",
  exportReports: "reports.exportReports",
  viewConversionsReport: "reports.viewConversionsReport",
  printBatchBarcodes: "batchTools.printBatchBarcodes",
  printBatchLabels: "batchTools.printBatchLabels",
  undoSafeAction: "moneyManager.undoSafeAction",
  undoOpeningATill: "moneyManager.undoOpeningATill",
  deleteShoppingCart: "retailSettings.deleteShoppingCart",
  printLabels: "retailSalesHistory.printLabels",
  printReceipts: "retailSalesHistory.printReceipts",
  uploadSalesToMetrcViewMetrcSalesDetails:
    "retailSalesHistory.uploadSalesToMetrcViewMetrcSalesDetails",
  printQuote: "batchTransfers.printQuote",
  viewInvoicesTab: "batchTransfers.viewInvoicesTab",
  printInvoices: "batchTransfers.printInvoices",
  viewManifestsTab: "batchTransfers.viewManifestsTab",
  modifyRetailSettings: "retailSettings.modifyRetailSettings",
  printPurchaseOrder: "batchTransfers.printPurchaseOrder",
  viewMoneyManager: "moneyManager.viewMoneyManager",
  viewOkMonthlySalesReport: "reports.viewOkMonthlySalesReport",
  printManifest: "batchTransfers.printManifest",
  voidInboundTransfer: "batchTransfers.voidInboundTransfer",
  voidOutboundTransfer: "batchTransfers.voidOutboundTransfer",
  viewTimeClockManager: "timeClockManagement.viewTimeClockManager",
  viewTimeClockAlerts: "timeClockManagement.viewTimeClockAlerts",
  employeeTimeClock: "timeClockManagement.employeeTimeClock",
  employeeRequestTimeClockCorrection:
    "timeClockManagement.employeeRequestTimeClockCorrection",
  createTimeClockEvents: "timeClockManagement.createTimeClockEvents",
  modifyTimeClockEvents: "timeClockManagement.modifyTimeClockEvents",
  deleteTimeClockEvents: "timeClockManagement.deleteTimeClockEvents",
  viewCurrentInventoryReport: "reports.viewCurrentInventoryReport",
  modifyBatchLevelPricing: "reports.modifyBatchLevelPricing",
  enableDisableProductsCategories:
    "inventoryTools.enableDisableProductsCategories",
  createModifyCustomers: "checkIn.createModifyCustomers",
  modifyCustomers: "customerProfileManagement.modifyCustomers",
  modifyCustomerProfileDetails: "modifyCustomers.modifyCustomerProfileDetails",
  modifyCustomerLoyaltyPoints:
    "customerProfileManagement.modifyCustomerLoyaltyPoints",
  createProducts: "inventoryTools.createProducts",
  modifyProducts: "inventoryTools.modifyProducts",
  enableDisableProducts: "inventoryTools.enableDisableProducts",
  pricePointsTool: "inventoryTools.pricePointsTool",
  modifyOutboundTransfer: "inventoryTools.modifyOutboundTransfer",
  modifyInboundTransfer: "inventoryTools.modifyInboundTransfer",
  createATicket: "support.createATicket",
  viewPricingOptions: "pricingTools.viewPricingOptions",
  modifyPricingOptions: "pricingTools.modifyPricingOptions",
  viewZOutReport: "reports.viewZOutReport",
  viewMoneyActionsReport: "reports.viewMoneyActionsReport",
  viewInventoryTransfersReport: "reports.viewInventoryTransfersReport",
  voidedInventoryTransfers: "reports.voidedInventoryTransfers",
  viewCloseTillReport: "reports.viewCloseTillReport",
  viewReservations: "reservations.viewReservations",
  releaseReservations: "reservations.releaseReservations",
  viewDataSharingOptions: "dataSharingOptions.viewDataSharingOptions",
  modifyDataSharingOptions: "dataSharingOptions.modifyDataSharingOptions",
  viewSharedProducts: "dataSharingTools.viewSharedProducts",
  modifySharedProducts: "dataSharingTools.modifySharedProducts",
  createMetrcItems: "metrcItems.createMetrcItems",
  deleteMetrcItems: "metrcItems.deleteMetrcItems",
  modifyMetrcItems: "metrcItems.modifyMetrcItems",
  viewMetrcItems: "metrcItems.viewMetrcItems",
  viewModifyStrainReconciliation:
    "strainReconciliation.viewModifyStrainReconciliation",
  viewModifyInventoryReconciliation:
    "inventoryReconciliation.viewModifyInventoryReconciliation",
  viewModifySalesReconciliation:
    "retailSalesReconciliation.viewModifySalesReconciliation",
  viewMetrcTestConnection: "metrcTestConnection.viewMetrcTestConnection",
  viewModifyTagManager: "tagManager.viewModifyTagManager",
  viewTimeClockEventsReport: "reports.viewTimeClockEventsReport",
  metrcSalesReport: "reports.metrcSalesReport",
  viewAsapReport: "reports.asap42A",
  viewDoctors: "doctors.viewDoctors",
  createDoctors: "doctors.createDoctors",
  enableDisableDoctors: "doctors.enableDisableDoctors",
  modifyDoctors: "doctors.modifyDoctors",
  modifyPrescriptionCustomersList:
    "customerProfileManagement.modifyPrescriptionDetailsInCustomerProfile",
  modifyPrescriptionRetailSalesHistory:
    "retailSalesHistory.modifyPrescriptionDetailsInSalesHistory",
  viewCustomersList: "customerProfileManagement.viewCustomers",
  viewPriceGroup: "priceGroup.viewPriceGroup",
  createPriceGroup: "priceGroup.createPriceGroup",
  modifyPriceGroup: "priceGroup.modifyPriceGroup",
  activateDeactivatePriceGroup: "priceGroup.activateDeactivatePriceGroup",
  viewRetailSalesBreakdownReport: "reports.viewRetailSalesBreakdownReport",
  inventorySyncTool: "traceabiltyTools.inventorySyncTool",
  printNewBatchesLabels: "inventory.printNewBatchesLabels"
};
