import { Batch } from "@/interfaces/batch";

export interface Filter {
  rooms: number[];
  productCategories: number[];
}

export const deafultFilter = {
  rooms: [],
  productCategories: []
};

export interface Audit {
  id: number;
  operation_uid: string;
  reference: string;
  real_time_audit: boolean;
  created_by: number;
  confirmed_at: null;
  created_at: string;
  updated_at: string;
  batch_uid: string;
  has_errors: boolean;
  has_cannabis_items: boolean;
  batch_fraction_uid: string;
  sku: string;
  quantity_unit: string;
  batch_secondary_uid: null;
  product_category_id: string;
  product_categories?: Array<{
    id: number;
    name: string;
  }>;
  rooms_table: string;
  product_categories_table: string;
  errorsMsg: string;
  inventory_location_id: number;
  inventory_locations?: Array<{
    id: number;
    name: string;
  }>;
  old_quantity: number;
  new_quantity: number;
  reason: string;
  status: AuditStatus;
  register_by: number;
  register_at: string;
  summary: {
    status: AuditStatus;
    fractions_counted: number;
    fractions_remaining: number;
    updated_by: number;
    created_at: string;
    updated_at: string;
    errors?: {
      message: string;
      exception_class?: string;
    };
  };
}

export interface AuditDetails {
  id: number;
  batch_audit_id: number;
  batch_id: number;
  location_id: number;
  account_id: number;
  old_value: number;
  new_value: null | number;
  reason_note: string;
  status: string;
  created_at: string;
  updated_at: string;
  deleted_at: null | string;
  batch: Batch;
}

export interface BatchSelectedAudit {
  id: number | null;
  productName: string | null;
  batch_id: number | string | null;
  newValue: string | null;
  reason_note: string;
  position?: number;
  product_id?: number;
  room?: string;
}

export interface ProductInfoAudit {
  sku: string;
  name: string;
  metrc_weighable: boolean;
  category?: {
    id: number;
    name: string;
  };
  strain?: null;
  batch_type?: {
    id: number;
    name: string;
  };
  vendor?: {
    id: number;
    name: string;
  };
  unit: string | number;
}

export interface ItemAudit {
  has_cannabis?: boolean;
  batch_uid?: string;
  biotrack_traceability_id?: string;
  batchUid?: string;
  batch_fraction_uid?: string;
  sku?: string;
  errors?: string[];
  batch_adjustment_type?: number | null;
  options?: {
    metrc_unfinish_tag?: boolean | null;
    tracking_id?: string;
    metrc_weighable?: boolean | null;
  };
  tracking_id?: string;
  status?: AuditStatus;
  new_quantity?: number | string;
  room_name?: string;
  old_quantity?: number | string;
  inventory_location_id?: number;
  reason?: string;
  total_batches: number;
  total_fractions: number;
  total_audited: number;
  total_pending: number;
  total_recount: number;
  total_canceled: number;
  total_new_quantity: number | string;
  total_difference_quantity: number | string;
  total_old_quantity: number | string;
  difference: number | string;
  total_not_available: number | string;
  total_error?: number;
  total_counted?: number;
  product_info?: ProductInfoAudit | null;
  reserved_quantity?: string | number;
  total_reserved_quantity?: string | number;
  quantity_unit?: string;
  product_category?: string;
  product_name?: string;
  batch_adjustment_type_id?: number | null;
}

export interface PayloadAudit {
  batch_fraction_uid: string;
  new_quantity: number | undefined;
  reason: string | undefined;
  batch_adjustment_type_id?: number | null;
  options?: OptionsMetrcAudit;
}

export interface OptionsMetrcAudit {
  metrc_unfinish_tag?: boolean;
  metrc_weighable?: boolean;
  tracking_id?: string;
}

export const selectBatchDefault = {
  id: null,
  productName: null,
  batch_id: 0,
  newValue: "",
  reason_note: "",
  position: 0
};

export enum AuditStatus {
  OPENED = "OPENED",
  AUDITED = "AUDITED",
  CANCELED = "CANCELED",
  EXPIRED = "EXPIRED",
  PENDING = "PENDING",
  CLOSED = "CLOSED",
  BUILDING = "BUILDING",
  RECOUNT = "RECOUNT",
  PAUSED = "PAUSED",
  LOADING = "LOADING",
  COUNTED = "COUNTED",
  NOT_AVAILABLE = "NOT_AVAILABLE",
  ERROR = "ERROR",
  TRC_CONNECTION_ERROR = "TRC_CONNECTION_ERROR"
}
export interface AuditResponse {
  status: string;
  message: string;
}

export const NOTICATION_AUDIT_STATUS = [
  "AuditOpened",
  "AuditClosed",
  "AuditResumed",
  "AuditPaused",
  "AuditCanceled",
  "AuditReportFailedOnClose"
];
export enum NotificationAuditAction {
  AUDIT_OPENED = "AuditOpened",
  AUDIT_CLOSED = "AuditClosed",
  AUDIT_RESUMED = "AuditResumed",
  AUDIT_PAUSED = "AuditPaused",
  AUDIT_CANCELED = "AuditCanceled"
}

export const defaultBatchAudit = {
  batch_uid: "",
  sku: "",
  new_quantity: 0,
  room_name: "",
  reason: "",
  batch_adjustment_type_id: null,
  options: {
    metrc_tag_status: false,
    metrc_weighable: false,
    tracking_id: ""
  },
  total_batches: 0,
  total_fractions: 0,
  total_audited: 0,
  total_pending: 0,
  total_recount: 0,
  total_canceled: 0,
  total_new_quantity: 0,
  total_old_quantity: 0,
  total_not_available: 0,
  product_info: null,
  difference: 0,
  total_difference_quantity: 0
};
