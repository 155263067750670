import { Location } from "@/interfaces/location";
import { RootState } from "@/vuex/types";
import { GetterTree } from "vuex";
import { AuthState } from "./auth.types";

type authGetter = GetterTree<AuthState, RootState>;

export const getters: authGetter = {
  isLogged(state: AuthState): boolean {
    return state.isLogged;
  },
  isSeparatedOptionEnabled(state: AuthState): boolean {
    return state.isSeparatedOptionEnable;
  },
  accessToken(state: AuthState): string | null {
    return state.accessToken;
  },
  user(state: AuthState): object | null | undefined {
    return state.user;
  },
  currentLocation(state: AuthState): Location | null {
    return state.currentLocation;
  },
  loading(state: AuthState): boolean {
    return state.loading;
  },
  redirectTo(state: AuthState): string {
    return state.redirectTo;
  },
  paymentMethods(state: AuthState) {
    return (
      state.currentRetailSettings && state.currentRetailSettings.payment_methods
    );
  },
  currentRetailSettings(state: AuthState) {
    return state.currentRetailSettings;
  },
  currentInventorySettings(state: AuthState) {
    return state.currentInventorySettings;
  },
  limitConfig(state: AuthState) {
    return state.limitConfig;
  },
  hasMetrcIntegrations(state: AuthState) {
    return !!(
      state.currentRetailSettings &&
      state.currentRetailSettings.integrations &&
      state.currentRetailSettings.integrations.metrc
    );
  },
  metrcEnabled(state: AuthState): boolean {
    return !!(
      state.currentRetailSettings &&
      state.currentRetailSettings.integrations &&
      state.currentRetailSettings.integrations.metrc &&
      state.currentRetailSettings.integrations.metrc.enable
    );
  },
  weedMapsEnabled(state: AuthState): boolean {
    return !!(
      state.currentRetailSettings &&
      state.currentRetailSettings.integrations &&
      state.currentRetailSettings.integrations.weed_maps &&
      state.currentRetailSettings.integrations.weed_maps.enable
    );
  },
  appId(state: AuthState) {
    return state.appId;
  },
  hasBioTrackTraceIntegrations(state: AuthState): boolean {
    return !!(
      state.currentRetailSettings &&
      state.currentRetailSettings.integrations &&
      state.currentRetailSettings.integrations.biotrack_traceability
    );
  },
  bioTrackTraceEnabled(state: AuthState): boolean {
    return !!(
      state.currentRetailSettings &&
      state.currentRetailSettings.integrations &&
      state.currentRetailSettings.integrations.biotrack_traceability &&
      state.currentRetailSettings.integrations.biotrack_traceability.enable
    );
  },
  // File upload Limit for the application
  fileUploadLimit(): number {
    return 8;
  },
  // File Document extention upload
  fileDocumentExtention(): string {
    return "doc, docx, dotx, odt, xml, xls, xlsx, pdf, png, jpeg , jpg, csv, txt, tiff";
  },
  // File Image  extention upload
  fileImageExtention(): string {
    return "png, jpeg, jpg";
  }
};
