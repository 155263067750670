import Template from "@/components/megaMenu/Apps/Apps.template.vue";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  mixins: [Template]
})
export default class AppsComponent extends Vue {
  @Prop({ required: true })
  public items!: object[];

  public goTo(route: any) {
    if (route) {
      window.location.href = route;
    }
  }
}
