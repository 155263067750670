import { i18n } from "@/plugins/i18n";
import { FNS_DATE_FORMATS } from "@/utils/date-fns.utils";
import TemplateEditor from "../editors.declaration";

export const TextFieldDefaultConfig: TemplateEditor.UIBlocks.BaseConfigDefinition = {
  title: "Editor Text Field",
  sections: [
    {
      title: "Content",
      fields: {
        content: {
          type: "text",
          configuration: {
            label: i18n.t("display_text"),
            placholder: "insert_text_here"
          }
        }
      }
    },
    {
      title: i18n.t("character_style").toString(),
      fields: {
        fontFamily: {
          type: "text",
          configuration: {
            label: i18n.t("font_family")
          }
        },
        fontSize: {
          type: "text",
          configuration: {
            type: "number",
            label: i18n.t("font_size")
          }
        },
        lineHeight: {
          type: "text",
          configuration: {
            type: "number",
            label: i18n.t("line_space")
          }
        },
        color: {
          type: "color-picker",
          configuration: {
            label: i18n.t("font_color")
          }
        },
        bold: {
          type: "boolean-button",
          configuration: {
            icon: "far fa-bold"
          }
        },
        italic: {
          type: "boolean-button",
          configuration: {
            icon: "far fa-italic"
          }
        }
      }
    },
    {
      title: "Text Align",
      fields: {
        textAlign: {
          type: "boolean-buttons-group",
          configuration: {
            buttons: [
              {
                icon: "fal fa-align-left",
                value: "left"
              },
              {
                icon: "fal fa-align-center",
                value: "center"
              },
              {
                icon: "fal fa-align-right",
                value: "right"
              }
            ]
          }
        }
      }
    }
  ]
};

export const VarFieldDefaultConfig: TemplateEditor.UIBlocks.BaseConfigDefinition = {
  title: "settings",
  subtitle: "variables",
  sections: [
    {
      title: i18n.t("select_a_variable").toString(),
      fields: {
        variable: {
          type: "select"
        }
      }
    },
    {
      title: i18n.t("prefix").toString(),
      fields: {
        prefix: {
          type: "text"
        }
      }
    },
    {
      title: i18n.t("character_style").toString(),
      fields: {
        fontFamily: {
          type: "text",
          configuration: {
            label: i18n.t("font_family")
          }
        },
        fontSize: {
          type: "text",
          configuration: {
            type: "number",
            label: i18n.t("font_size")
          }
        },
        lineHeight: {
          type: "text",
          configuration: {
            type: "number",
            label: i18n.t("line_space")
          }
        },
        color: {
          type: "color-picker",
          configuration: {
            label: i18n.t("font_color")
          }
        },
        bold: {
          type: "boolean-button",
          configuration: {
            icon: "far fa-bold"
          }
        },
        italic: {
          type: "boolean-button",
          configuration: {
            icon: "far fa-italic"
          }
        }
      }
    },
    {
      title: "Text Align",
      fields: {
        textAlign: {
          type: "boolean-buttons-group",
          configuration: {
            buttons: [
              {
                icon: "fal fa-align-left",
                value: "left"
              },
              {
                icon: "fal fa-align-center",
                value: "center"
              },
              {
                icon: "fal fa-align-right",
                value: "right"
              }
            ]
          }
        }
      }
    }
  ]
};

export const ListFieldDefaultConfig: TemplateEditor.UIBlocks.BaseConfigDefinition = {
  title: "settings",
  subtitle: "List",
  sections: [
    {
      title: i18n.t("select_a_variable").toString(),
      fields: {
        variable: {
          type: "select",
          configuration: {
            label: "display_text",
            placholder: "insert_text_here"
          }
        }
      }
    },
    {
      title: i18n.t("prefix").toString(),
      fields: {
        prefix: {
          type: "text"
        }
      }
    },
    {
      title: i18n.t("character_style").toString(),
      fields: {
        fontFamily: {
          type: "text",
          configuration: {
            label: i18n.t("font_family")
          }
        },
        fontSize: {
          type: "text",
          configuration: {
            type: "number",
            label: i18n.t("font_size")
          }
        },
        lineHeight: {
          type: "text",
          configuration: {
            type: "number",
            label: i18n.t("line_space")
          }
        },
        color: {
          type: "color-picker",
          configuration: {
            label: i18n.t("font_color")
          }
        },
        bold: {
          type: "boolean-button",
          configuration: {
            icon: "far fa-bold"
          }
        },
        italic: {
          type: "boolean-button",
          configuration: {
            icon: "far fa-italic"
          }
        }
      }
    },
    {
      title: "Text Align",
      fields: {
        textAlign: {
          type: "boolean-buttons-group",
          configuration: {
            buttons: [
              {
                icon: "fal fa-align-left",
                value: "left"
              },
              {
                icon: "fal fa-align-center",
                value: "center"
              },
              {
                icon: "fal fa-align-right",
                value: "right"
              }
            ]
          }
        }
      }
    }
  ]
};

export const LineFieldDefaultConfig: TemplateEditor.UIBlocks.BaseConfigDefinition = {
  title: "Editor Line Field",
  sections: [
    {
      title: "Content & Size",
      fields: {
        backgroundColor: {
          type: "color-picker",
          configuration: {
            label: i18n.t("line_color")
          }
        },
        height: {
          type: "text",
          configuration: {
            type: "number",
            label: i18n.t("line_height")
          }
        },
        padding: {
          type: "text",
          configuration: {
            type: "number",
            label: i18n.t("line_width")
          }
        },
        borderRadius: {
          type: "text",
          configuration: {
            type: "number",
            label: i18n.t("line_radius")
          }
        }
      }
    }
  ]
};

export const ImageFieldDefaultConfig: TemplateEditor.UIBlocks.BaseConfigDefinition = {
  title: "Editor Image Field",
  sections: [
    {
      title: "Content & Size",
      fields: {
        image: {
          type: "upload-file",
          configuration: { label: "Upload Image", show_preview: true }
        },
        width: {
          type: "text",
          configuration: {
            type: "number",
            label: "width"
          }
        },
        height: {
          type: "text",
          configuration: {
            type: "number",
            label: "height"
          }
        }
      }
    }
  ]
};

export const BarcodeFieldDefaultConfig: TemplateEditor.UIBlocks.BaseConfigDefinition = {
  title: "Editor Barcode Field",
  sections: [
    {
      title: "Barcode Style",
      fields: {
        code: {
          type: "text",
          configuration: {
            type: "text",
            label: i18n.t("content")
          }
        },
        style: {
          type: "radiogroup",
          configuration: {
            label: i18n.t("type"),
            items: [
              { label: "128", value: "128" },
              { label: "QR", value: "QR" },
              { label: "39", value: "39" }
            ]
          }
        },
        fontSize: {
          type: "text",
          configuration: {
            type: "number",
            label: i18n.t("size")
          }
        },
        includeId: {
          type: "checkbox",
          configuration: {
            label: i18n.t("include_id")
          }
        }
      }
    }
  ]
};

export interface VarConfig {
  label: string;
  value: string[];
  unit?: string[];
  dateFormat?: string;
}

export const inventoryVariables: VarConfig[] = [
  {
    label: "label.created_date",
    value: ["created_at", "in_this_location_since", "sold_at"],
    dateFormat: FNS_DATE_FORMATS.DASH_DEFAULT
  },
  {
    label: "label.created_date_time",
    value: ["created_at", "in_this_location_since", "sold_at"],
    dateFormat: FNS_DATE_FORMATS.EN_DASH_WITH_MERIDIEM_TIME
  },
  { label: "label.employee_name", value: ["user.name", "employee.name"] },
  {
    label: "label.employee_license_number",
    value: [
      "employee_license_number",
      "employee.license",
      "user.profile.occupational_license_number"
    ]
  },
  {
    label: "label.product",
    value: [
      "fatherItem.name",
      "product.name",
      "batch.product.name",
      "product_info.name",
      "product_variant.name"
    ]
  },
  {
    label: "label.pre_tax",
    value: ["price_base", "price_pre_tax", "prices.pre_formatted"]
  },
  {
    label: "label.post_tax",
    value: ["price_final", "price_post_tax", "prices.post_formatted"]
  },
  {
    label: "label.brand",
    value: [
      "fatherItem.brand.name",
      "product.brand.name",
      "product_variant.brand.name",
      "brand"
    ]
  },
  {
    label: "label.strain",
    value: [
      "fatherItem.strain.name",
      "strain.name",
      "product.strain.name",
      "product_variant.strain.name",
      "strain"
    ]
  },
  {
    label: "label.prescription_number",
    value: ["prescription_no"]
  },
  {
    label: "label.strain_type",
    value: [
      "fatherItem.strain_type.name",
      "product.strain_type.name",
      "product_variant.strain_type.name",
      "strain_type"
    ]
  },
  {
    label: "label.batch_type",
    value: [
      "fatherItem.batch_type.name",
      "batch_type.name",
      "product.batch_type.name",
      "product_variant.batch_type.name",
      "batch_type"
    ]
  },
  {
    label: "label.product_category",
    value: [
      "fatherItem.category.name",
      "category.name",
      "product.category.name",
      "product_variant.category.name",
      "category"
    ]
  },
  {
    label: "label.batch_id",
    value: [
      "batch_uid",
      "batch.batch_uid",
      "batch.biotrack_traceability_id",
      "batch_barcode_uid"
    ]
  },
  { label: "label.secondary_id", value: ["tracking_id", "batch.tracking_id"] },
  {
    label: "label.package_weight",
    value: [
      "batch.weight_per_unit_value",
      "product_variant.weight_per_unit_value",
      "weight_per_unit_value"
    ],
    unit: [
      "batch.weight_per_unit_unit",
      "product_variant.weight_per_unit_unit",
      "weight_per_unit_unit"
    ]
  },
  {
    label: "label.package_date",
    value: [
      "product_variant.packaging_detail.package_date",
      "product_variant.package_date",
      "package_date"
    ],
    dateFormat: FNS_DATE_FORMATS.DASH_DEFAULT
  },
  {
    label: "label.usable_weight",
    value: [
      "batch.usable_weight_value",
      "product_variant.usable_weight_value",
      "product.usable_weight_value",
      "usable_weight_value"
    ],
    unit: [
      "batch.usable_weight_unit",
      "product_variant.usable_weight_unit",
      "product.usable_weight_unit",
      "usable_weight_unit"
    ]
  },
  {
    label: "label.quantity",
    value: ["quantity", "total_quantity", "total_quantity_value"],
    unit: ["quantity_unit", "unit", "in_store_quantity_unit"]
  },
  {
    label: "label.expiration_date",
    value: [
      "expiration_date",
      "product_variant.packaging_detail.expiration_date",
      "product_variant.expiration_date"
    ],
    dateFormat: FNS_DATE_FORMATS.DASH_DEFAULT
  },
  {
    label: "label.external_batch_number",
    value: [
      "external_batch_number",
      "batch.external_batch_number",
      "product_variant.external_batch_number"
    ]
  },
  {
    label: "label.custom_field_1",
    value: [
      "custom_field_1",
      "batch.custom_field_1",
      "product_variant.custom_field_1"
    ]
  },
  {
    label: "label.custom_field_2",
    value: [
      "custom_field_2",
      "batch.custom_field_2",
      "product_variant.custom_field_2"
    ]
  },
  {
    label: "label.custom_field_3",
    value: [
      "custom_field_3",
      "batch.custom_field_3",
      "product_variant.custom_field_3"
    ]
  },
  {
    label: "label.custom_field_4",
    value: [
      "custom_field_4",
      "batch.custom_field_4",
      "product_variant.custom_field_4"
    ]
  },
  {
    label: "label.batch_level_pricing",
    value: ["prices.batch_level_price", "batch_level_pricing"]
  },
  {
    label: "label.activation_time",
    value: [
      "fatherItem.packaging_detail.activation_time",
      "product.packaging_detail.activation_time",
      "batch.product.packaging_detail.activation_time",
      "product_variant.packaging_detail.activation_time",
      "activation_time"
    ],
    unit: [
      "fatherItem.packaging_detail.activation_time_unit",
      "product.packaging_detail.activation_time_unit",
      "batch.product.packaging_detail.activation_time_unit",
      "product_variant.packaging_detail.activation_time_unit",
      "activation_time_unit"
    ]
  },
  {
    label: "label.serving_size",
    value: [
      "fatherItem.packaging_detail.serving_size",
      "product.packaging_detail.serving_size",
      "batch.product.packaging_detail.serving_size",
      "product_variant.packaging_detail.serving_size",
      "serving_size"
    ],
    unit: [
      "fatherItem.packaging_detail.serving_size_unit",
      "product.packaging_detail.serving_size_unit",
      "batch.product.packaging_detail.serving_size_unit",
      "product_variant.packaging_detail.serving_size_unit",
      "serving_size_unit"
    ]
  },
  {
    label: "label.suggested_use_amount",
    value: [
      "fatherItem.packaging_detail.suggested_amount",
      "product.packaging_detail.suggested_amount",
      "batch.product.packaging_detail.suggested_amount",
      "product_variant.packaging_detail.suggested_amount",
      "suggested_amount"
    ]
  },
  {
    label: "label.servings_per_container",
    value: [
      "fatherItem.packaging_detail.serving_size_of_the_container",
      "product.packaging_detail.serving_size_of_the_container",
      "batch.product.packaging_detail.serving_size_of_the_container",
      "product_variant.packaging_detail.serving_size_of_the_container",
      "serving_size_of_the_container"
    ]
  },
  {
    label: "label.manufacturer",
    value: [
      "fatherItem.packaging_detail.manufacturer.name",
      "product.packaging_detail.manufacturer.name",
      "batch.product.packaging_detail.manufacturer.name",
      "product.manufacturer.name",
      "product_variant.manufacturer.name",
      "manufacturer"
    ]
  },
  {
    label: "label.manufacturer_license_number",
    value: [
      "fatherItem.manufacturer.license",
      "product.manufacturer.license",
      "batch.product.manufacturer.license",
      "manufacturer_license"
    ]
  },
  {
    label: "label.cultivator",
    value: [
      "fatherItem.cultivator.name",
      "batch.product.cultivator.name",
      "product.cultivator.name",
      "product_variant.cultivator.name",
      "cultivator"
    ]
  },
  {
    label: "label.cultivator_license_number",
    value: [
      "fatherItem.cultivator.license",
      "product.cultivator.license",
      "batch.product.cultivator.license",
      "product_variant.cultivator.license",
      "cultivator_license"
    ]
  },
  { label: "label.laboratory_total_thc", value: ["laboratory.sample.thc"] },
  { label: "label.laboratory_total_cbd", value: ["laboratory.sample.cbd"] },
  {
    label: "label.laboratory_ratio_thc_cbd",
    value: ["laboratory.sample.ratio"]
  },
  {
    label: "label.laboratory_total_terpenes",
    value: ["laboratory.sample.terpene"]
  },
  { label: "label.laboratory_thc", value: ["laboratory.THC[0].valueFormated"] },
  {
    label: "label.laboratory_thca",
    value: ["laboratory.THCA[0].valueFormated"]
  },
  { label: "label.laboratory_cbd", value: ["laboratory.CBD[0].valueFormated"] },
  {
    label: "label.laboratory_cbda",
    value: ["laboratory.CBDA[0].valueFormated"]
  },
  { label: "label.laboratory_cbn", value: ["laboratory.CBN[0].valueFormated"] },
  { label: "label.laboratory_cbg", value: ["laboratory.CBG[0].valueFormated"] },
  { label: "label.laboratory_cbc", value: ["laboratory.CBC[0].valueFormated"] },
  { label: "label.laboratory_cbl", value: ["laboratory.CBL[0].valueFormated"] },
  { label: "label.laboratory_cbv", value: ["laboratory.CBV[0].valueFormated"] },
  {
    label: "label.laboratory_thcv",
    value: ["laboratory.THCV[0].valueFormated"]
  },
  {
    label: "label.laboratory_cbdv",
    value: ["laboratory.CBDV[0].valueFormated"]
  },
  {
    label: "label.laboratory_cbcv",
    value: ["laboratory.CBCV[0].valueFormated"]
  },
  {
    label: "label.laboratory_cbgm",
    value: ["laboratory.CBGM[0].valueFormated"]
  },
  { label: "label.laboratory_cbe", value: ["laboratory.CBE[0].valueFormated"] },
  { label: "label.laboratory_cbt", value: ["laboratory.CBT[0].valueFormated"] },

  {
    label: "label.laboratory_myrcene",
    value: ["laboratory.MYRCENE[0].valueFormated"]
  },
  {
    label: "label.laboratory_limonene",
    value: ["laboratory.LIMONENE[0].valueFormated"]
  },
  {
    label: "label.laboratory_linalool",
    value: ["laboratory.LINALOOL[0].valueFormated"]
  },
  {
    label: "label.laboratory_a_caryophyllene",
    value: ["laboratory.A_CARYOPHYLLENE[0].valueFormated"]
  },
  {
    label: "label.laboratory_b_caryophyllene",
    value: ["laboratory.B_CARYOPHYLLENE[0].valueFormated"]
  },
  {
    label: "label.laboratory_a_pinene",
    value: ["laboratory.A_PINENE[0].valueFormated"]
  },
  {
    label: "label.laboratory_b_pinene",
    value: ["laboratory.B_PINENE[0].valueFormated"]
  },
  {
    label: "label.laboratory_a_bisabolol",
    value: ["laboratory.A_BISABOLOL[0].valueFormated"]
  },
  {
    label: "label.laboratory_eucaliytol",
    value: ["laboratory.EUCALIYTOL[0].valueFormated"]
  },
  {
    label: "label.laboratory_trans_nerolidol",
    value: ["laboratory.TRANS_NEROLIDOL[0].valueFormated"]
  },
  {
    label: "label.laboratory_humulene",
    value: ["laboratory.HUMULENE[0].valueFormated"]
  },
  {
    label: "label.laboratory_delta3_carene",
    value: ["laboratory.DELTA3_CARENE[0].valueFormated"]
  },
  {
    label: "label.laboratory_camphene",
    value: ["laboratory.CAMPHENE[0].valueFormated"]
  },
  {
    label: "label.laboratory_geraniol",
    value: ["laboratory.GERANIOL[0].valueFormated"]
  },
  {
    label: "label.laboratory_ocimene",
    value: ["laboratory.OCIMENE[0].valueFormated"]
  },
  {
    label: "label.laboratory_terpineol",
    value: ["laboratory.TERPINEOL[0].valueFormated"]
  },
  {
    label: "label.laboratory_terpinolene",
    value: ["laboratory.TERPINOLENE[0].valueFormated"]
  },
  {
    label: "label.laboratory_borneol",
    value: ["laboratory.BORNEOL[0].valueFormated"]
  },
  {
    label: "label.laboratory_valencene",
    value: ["laboratory.VALENCENE[0].valueFormated"]
  },
  {
    label: "label.laboratory_moisture_content",
    value: ["laboratory.MOISTURE[0].valueFormated"]
  },
  {
    label: "label.laboratory_foreign_matter_inspection_stems",
    value: ["laboratory.STEMS[0].valueFormated"]
  },
  {
    label: "label.laboratory_foreign_matter_inspection_other",
    value: ["laboratory.OTHER[0].valueFormated"]
  },
  {
    label: "label.laboratory_microbilogical_screening_aerobic_bacteria",
    value: ["laboratory.AEROBIC_BACTERIA[0].valueFormated"]
  },
  {
    label: "label.laboratory_microbilogical_screening_yeast_and_mold",
    value: ["laboratory.YEAST_AND_MOLD[0].valueFormated"]
  },
  {
    label: "label.laboratory_microbilogical_screening_coliforms",
    value: ["laboratory.COLIFORMS[0].valueFormated"]
  },
  {
    label: "label.laboratory_microbilogical_screening_bile_tolerant",
    value: ["laboratory.BILE_TOLRENT[0].valueFormated"]
  },
  {
    label: "label.laboratory_microbilogical_screening_e_coli_and_salmonella",
    value: ["laboratory.E_COLI_AND_SALMONELLA[0].valueFormated"]
  },
  {
    label: "label.laboratory_residual_solvent",
    value: ["laboratory.RESIDUAL_SOLVENT[0].valueFormated"]
  },
  {
    label: "label.laboratory_total_mycotoxins",
    value: ["laboratory.TOTAL_MYCOTOXINS[0].valueFormated"]
  },
  {
    label: "label.laboratory_pesticide_residue",
    value: ["laboratory.PESTICIDE_RESIDUE[0].valueFormated"]
  },
  {
    label: "label.laboratory_heavy_metal_heavy_metal",
    value: ["laboratory.HEAVY_METAL[0].valueFormated"]
  },
  {
    label: "label.laboratory_heavy_metal_cadmium",
    value: ["laboratory.CADMIUM[0].valueFormated"]
  },
  {
    label: "label.laboratory_heavy_metal_lead",
    value: ["laboratory.LEAD[0].valueFormated"]
  },
  {
    label: "label.laboratory_heavy_metal_arsenic",
    value: ["laboratory.ARSENIC[0].valueFormated"]
  },
  {
    label: "label.laboratory_heavy_metal_mercury",
    value: ["laboratory.MERCURY[0].valueFormated"]
  },
  { label: "label.laboratory_name", value: ["fatherItem.laboratory_name"] },
  {
    label: "label.laboratory_license_number",
    value: ["fatherItem.laboratory_license"]
  },
  {
    label: "label.laboratory_testing_date",
    value: ["fatherItem.laboratory_testing_date"],
    dateFormat: FNS_DATE_FORMATS.DASH_DEFAULT
  },
  {
    label: "label.laboratory_d9_thc",
    value: ["laboratory.D9_THC[0].valueFormated"]
  },
  {
    label: "label.laboratory_d9_thca",
    value: ["laboratory.D9_THCA[0].valueFormated"]
  },
  {
    label: "label.laboratory_d9_thcb",
    value: ["laboratory.D9_THCB[0].valueFormated"]
  },
  {
    label: "label.laboratory_d8_thc",
    value: ["laboratory.D8_THC[0].valueFormated"]
  },
  {
    label: "label.laboratory_d8_thca",
    value: ["laboratory.D8_THCA[0].valueFormated"]
  },
  {
    label: "label.laboratory_caryophyllene_oxide",
    value: ["laboratory.CARYOPHYLLENE_OXIDE[0].valueFormated"]
  },
  { label: "label.location_name", value: ["location.name"] },
  { label: "label.location_address_1", value: ["location.address1"] },
  { label: "label.location_address_2", value: ["location.address2"] },
  { label: "label.location_city", value: ["location.city"] },
  { label: "label.location_state_provence", value: ["location.state.name"] },
  { label: "label.location_zip", value: ["location.zip"] },
  { label: "label.location_phone_number", value: ["location.phone"] },
  {
    label: "label.location_license_number",
    value: ["location.license_number"]
  },
  { label: "label.city_state_zip", value: ["location.full_address"] },
  {
    label: "label.calories_per_serving",
    value: [
      "fatherItem.packaging_detail.nutrition_calories_per_serving",
      "product.packaging_detail.nutrition_calories_per_serving",
      "batch.product.packaging_detail.nutrition_calories_per_serving",
      "product_variant.packaging_detail.nutrition_calories_per_serving",
      "nutrition_calories_per_serving"
    ]
  },
  {
    label: "label.total_calories",
    value: [
      "fatherItem.packaging_detail.nutrition_total_calories",
      "product.packaging_detail.nutrition_total_calories",
      "batch.product.packaging_detail.nutrition_total_calories",
      "product_variant.packaging_detail.nutrition_total_calories",
      "nutrition_total_calories"
    ]
  },
  {
    label: "label.pharmacist_filledby",
    value: ["pharmacist.full_name"]
  },
  { label: "label.location_dea_number", value: ["location.dea"] },
  {
    label: "label.location_license_number",
    value: ["location.license_number"]
  },
  {
    label: "label.presc_sig_info",
    value: [
      "fatherItem.packaging_detail.sig_information",
      "product.packaging_detail.sig_information",
      "batch.product.packaging_detail.sig_information",
      "product_variant.packaging_detail.sig_information",
      "sig_information"
    ]
  },
  {
    label: "label.presc_description",
    value: [
      "fatherItem.packaging_detail.prescription_description",
      "product.packaging_detail.prescription_description",
      "batch.product.packaging_detail.prescription_description",
      "product_variant.packaging_detail.prescription_description",
      "prescription_description"
    ]
  },
  {
    label: "label.product_ndc",
    value: [
      "fatherItem.ndc_number",
      "product.ndc_number",
      "batch.ndc_number",
      "product_variant.ndc_number",
      "ndc_number"
    ]
  }
];

export const customerVariables = [
  {
    label: "label.date",
    value: ["created_at", "in_this_location_since", "sold_at"],
    dateFormat: FNS_DATE_FORMATS.DASH_DEFAULT
  },
  {
    label: "label.datetime",
    value: ["created_at", "in_this_location_since", "sold_at"],
    dateFormat: FNS_DATE_FORMATS.EN_DASH_WITH_MERIDIEM_TIME
  },
  { label: "label.customer_first_name", value: ["customer.first_name"] },
  { label: "label.customer_middle_name", value: ["customer.middle_name"] },
  { label: "label.customer_last_name", value: ["customer.last_name"] },
  {
    label: "label.customer_date_of_birth",
    value: ["customer.birthday"],
    dateFormat: FNS_DATE_FORMATS.DASH_DEFAULT
  },
  {
    label: "label.customer_medical_card_number",
    value: [
      "customer.profiles[0].medical_card_number",
      "customer.medical_card_number"
    ]
  },
  {
    label: "label.customer_medical_card_expiration",
    value: [
      "customer.profiles[0].medical_card_expiration",
      "customer.medical_card_expiration"
    ]
  },
  { label: "label.customer_id_number", value: ["customer.id_number"] },
  { label: "label.customer_id_type", value: ["customer.id_type"] },
  { label: "label.customer_id_expiration", value: ["customer.id_expiration"] },
  { label: "label.patient_name", value: ["customer.full_name"] },
  { label: "label.doctor_name", value: ["doctor.full_name"] },
  {
    label: "label.presc_qty_filled",
    value: [
      "product.packaging_detail.prescription_quantity",
      "prescription_quantity"
    ],
    unit: [
      "product.packaging_detail.prescription_quantity_unit_measure",
      "prescription_quantity_unit_measure"
    ]
  },
  {
    label: "label.presc_dosage_details",
    value: [
      "product.packaging_detail.prescription_dosage_details",
      "prescription_dosage_details"
    ]
  },
  {
    label: "label.presc_dosage_timeframe",
    value: [
      "product.packaging_detail.prescription_dosage_timeframe_details",
      "prescription_dosage_timeframe_details"
    ]
  },
  {
    label: "label.presc_days_supply",
    value: [
      "product.packaging_detail.prescription_days_supply_details",
      "prescription_days_supply_details"
    ]
  },
  {
    label: "label.customer_caregiver_medical_card_number",
    value: ["customer.caregiver_medical_card_number"]
  },
  {
    label: "label.Customer_caregiver_name",
    value: ["customer.caregiver_name"]
  },
  {
    label: "label.Customer_caregiver_medical_card_expiration",
    value: ["customer.caregiver_medical_card_expiration"]
  },
  {
    label: "label.customer_doctor_first_name",
    value: ["customer.doctor.first_name"]
  },
  {
    label: "label.customer_doctor_last_name",
    value: ["customer.doctor.last_name"]
  },
  { label: "label.customer_doctor_title", value: ["customer.doctor.title"] },
  {
    label: "label.customer_doctor_dea_number",
    value: ["customer.doctor.dea_number"]
  },
  {
    label: "label.customer_doctor_national_provider_identifier",
    value: ["customer.doctor.national_provider_identifier"]
  },
  {
    label: "label.customer_doctor_phone_number",
    value: ["customer.doctor.phone_number"]
  },
  {
    label: "label.customer_doctor_fax_number",
    value: ["customer.doctor.fax_number"]
  },
  {
    label: "label.customer_doctor_website",
    value: ["customer.doctor.website"]
  },
  { label: "label.customer_doctor_email", value: ["customer.doctor.email"] },
  {
    label: "label.customer_doctor_license_number",
    value: ["customer.doctor.license"]
  }
];

export const listVariables = [
  {
    label: "label.ingredients",
    value: [
      "fatherItem.packaging_detail.ingredients",
      "product.packaging_detail.ingredients",
      "batch.product.packaging_detail.ingredients"
    ]
  },
  {
    label: "label.allergens",
    value: [
      "fatherItem.packaging_detail.allergens",
      "product.packaging_detail.allergens",
      "batch.product.packaging_detail.allergens"
    ]
  },
  { label: "label.potency_results", value: ["laboratory.potency"] },
  {
    label: "label.terpene_results",
    value: ["laboratory.terpene"]
  },
  { label: "label.potency_top3", value: ["laboratory.top3Potency"] },
  { label: "label.terpene_top3", value: ["laboratory.top3Terpene"] }
];

export const pharmacistVariables = [
  {
    label: "label.pharmacist_title",
    value: ["customer.pharmacist.title"]
  },
  {
    label: "label.pharmacist_first_name",
    value: ["customer.pharmacist.first_name"]
  },
  {
    label: "label.pharmacist_last_name",
    value: ["customer.pharmacist.last_name"]
  }
];
