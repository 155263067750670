import { Product } from "@/interfaces/product";
import { PricePointState } from "@/vuex/modules/inventory/pricePoint/pricePoint.types";
import { RootState } from "@/vuex/types";
import { GetterTree } from "vuex";

type PricePointGetter = GetterTree<PricePointState, RootState>;

export const getters: PricePointGetter = {
  products(state: PricePointState): Product[] | null {
    return state.products;
  }
};
