import { RootState } from "@/vuex/types";
import { Module } from "vuex";
import { actions } from "./pageNav.actions";
import { getters } from "./pageNav.getters";
import { mutations } from "./pageNav.mutations";
import { state } from "./pageNav.state";
import { PageNavState } from "./pageNav.types";

const namespaced: boolean = true;
export const PageNavModule: Module<PageNavState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
