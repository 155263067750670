import isNumber from "lodash/isNumber";
/**
 * Checks if the string is a number and
 * if the number is int or float and parse it after.
 */

export function formatNumber(n: string) {
  if (isNumber(n)) {
    const sign = Math.sign(n);
    const abs = Math.abs(n);
    if (String(n).match(/^-?\d+$/)) {
      n = `\$${Number.parseFloat(String(abs)).toFixed(2)}`;
    } else if (String(n).match(/\-?\d+\.\d+/)) {
      n = `\$${abs.toFixed(2)}`;
    }
    return sign < 0 ? `-${n}` : n;
  }
  return n;
}

export function currencyFilter(
  value: any,
  decimals: number = 2,
  separator: "," | "." = ",",
  fractionSeparator: "," | "." = "."
) {
  value = String(Math.round(Number(value) * 1e8) / 1e8);

  const symbol = "$";

  let valueLeft: string = value.split(".")[0];
  const valueRight: string = value.split(".")[1];

  const left: string[] = [];
  let sign: "-" | "" = "";
  let right: string = "";

  if (valueLeft[0] === "-") {
    sign = "-";
    valueLeft = valueLeft.slice(1);
  }

  valueLeft
    .split("")
    .reverse()
    .forEach((digit: string, index: number) => {
      left.push(digit);
      if (valueLeft.split("").length !== index + 1 && (index + 1) % 3 === 0) {
        left.push(separator);
      }
    });
  right = valueRight
    ? valueRight.substr(0, decimals) +
      Array(Math.max(0, 2 - valueRight.substr(0, decimals).length))
        .fill(0)
        .join("")
    : Array(Math.max(0, 2))
        .fill(0)
        .join("");
  return `${sign} ${symbol} ${left.reverse().join("")}${
    right !== "" ? `${fractionSeparator}${right}` : ""
  }`;
}
