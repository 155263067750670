import { InventorySettings } from "@/interfaces/inventorySettings";
import { Location, LocationSalesLimits } from "@/interfaces/location";
import { RetailSettings } from "@/interfaces/retailSettings";
import { User } from "@/interfaces/user";
import { MutationTree } from "vuex";
import { AuthState } from "./auth.types";

type AuthMutationTree = MutationTree<AuthState>;

export const mutations: AuthMutationTree = {
  setUser(state: AuthState, user: User) {
    state.user = user;
    state.isLogged = true;
  },
  unsetUser(state: AuthState) {
    state.user = null;
    state.isLogged = false;
  },
  setLoading(state: AuthState, loading: boolean) {
    state.loading = loading;
  },
  setCurrentLocation(state: AuthState, currentLocation: Location | null) {
    state.currentLocation = currentLocation;
  },
  setAccessToken(state: AuthState, token: string) {
    state.accessToken = token;
  },
  setRedirect(state: AuthState, url: string) {
    state.redirectTo = url;
  },
  setCurrentRetailSettings(
    state: AuthState,
    retailSettings: RetailSettings | null
  ) {
    state.currentRetailSettings = retailSettings;
  },
  setCurrentInventorySettings(
    state: AuthState,
    inventorySettings: InventorySettings | null
  ) {
    state.currentInventorySettings = inventorySettings;
  },
  setLimitConfig(state: AuthState, limits: LocationSalesLimits[]) {
    state.limitConfig = limits;
  },
  setAppId(state: AuthState, id: string) {
    state.appId = id;
  },
  isSeparatedOptionEnabled(state: AuthState, isSeparatedOptionEnable: boolean) {
    state.isSeparatedOptionEnable = isSeparatedOptionEnable;
  }
};
