import { Callback } from "helix-vue-components/types";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";
import Template from "./ConfirmCheckin.template.vue";
const namespace: string = "CustomerModule";
/**
 * product basic info form component
 */
@Component({
  mixins: [Template]
})
export default class ConfirmCheckinComponent extends Vue {
  @Action("searchToCustomerList", { namespace: "CustomerModule" })
  public searchCustomerAction!: Callback;
  @Prop({ default: () => "" })
  public msg!: string;
  protected confirm(resp: boolean = true) {
    this.$emit("resolve", resp);
  }
  protected refuse() {
    this.$emit("reject");
    this.searchCustomerAction();
  }
}
