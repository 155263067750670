import { policyList } from "@/enums/permissions";
import { Customer } from "@/interfaces/customer";
import { Doctor } from "@/interfaces/doctor";
import { Order, OrderItem } from "@/interfaces/order";
import { OrderFulfillmentItem } from "@/interfaces/orderFulfillment";
import { PharmacistInterface } from "@/interfaces/pharmacist";
import {
  defaultPrescriptionDetails,
  PrescriptionDetails
} from "@/interfaces/prescriptionDetails";
import { i18n } from "@/plugins/i18n";
import { customerService } from "@/services/customer.service";
import { messagesService } from "@/services/messages.service";
import { orderService } from "@/services/order.service";
import { pharmacistService } from "@/services/pharmacist.service";
import { BooleanCheck, Callback } from "@/types/types";
import cloneDeep from "lodash/cloneDeep";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./PosPrescriptionModal.template.vue";
const namespace: string = "OrderModule";
@Component({
  mixins: [Template]
})
export default class PosPrescriptionModalComponent extends Vue {
  @Prop({ default: () => defaultPrescriptionDetails })
  public model!: PrescriptionDetails;
  @Prop({ default: () => null })
  public orderItem!: OrderItem;
  @Prop({ default: null }) public prescriptionNumber!: number | null;
  @Prop({ required: false })
  public disabled!: boolean;
  @Prop({ default: () => null })
  public onDutyPharmacistSelected!: number | null;
  @Prop({ default: () => "" })
  public pageType!: string;
  @Prop({ default: () => "" })
  public routeName!: string;
  @Prop({ default: () => null })
  public filfillmentOrderDetail!: OrderFulfillmentItem;
  @Prop({ default: () => null })
  public batchUid!: string | null;
  @Prop({ default: () => false })
  public isSameBatchAvailable!: boolean;
  @Prop({ default: () => [] })
  public pharmacistItems!: Array<{ text: string; value: number }>;
  @Prop({ default: () => [] })
  public doctorsItems!: Array<{ text: string; value: string }>;
  @Getter("order", { namespace })
  public order!: Order;
  @Action("updatePrescriptionDetail", { namespace })
  public updatePrescriptionDetail!: Callback;
  @Getter("currentCustomer", { namespace: "CustomerModule" })
  public currentCustomer!: Customer;
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  public disableEditMode = false;
  public viewPermission = false;
  public editPermission = false;
  public pharmacistItemsList: Array<{ text: string; value: number }> = [];
  public doctorsItemsList: Array<{ text: string; value: string }> = [];

  public prescriptionDetails: PrescriptionDetails = defaultPrescriptionDetails;
  public dosageTimeframeUnitItems: Array<{ text: string; value: string }> = [];
  public dosageUnitItems: Array<{ text: string; value: string }> = [];
  public quantityOfMeasureItems: Array<{ text: string; value: string }> = [];
  public pharmacistName: string | null = null;
  public doctorName: string | null = null;
  public isLoading = false;

  public cancel() {
    this.$emit("reject");
  }

  public checkPrescriptionNo() {
    return !this.prescriptionNumber && this.orderItem;
  }
  public mounted() {
    this.pharmacistItemsList = this.pharmacistItems;
    this.doctorsItemsList = this.doctorsItems;
    this.prescriptionDetails = cloneDeep(this.model);

    this.prescriptionDetails.pharmacist_id =
      !this.prescriptionDetails.pharmacist_id ||
      this.prescriptionDetails.pharmacist_id === 0
        ? this.onDutyPharmacistSelected
        : this.prescriptionDetails.pharmacist_id;

    this.prescriptionDetails.doctor_id = this.prescriptionDetails!.doctor_id
      ? String(this.prescriptionDetails.doctor_id)
      : this.currentCustomer &&
        this.currentCustomer!.profiles &&
        this.currentCustomer!.profiles[0]!.doctor_id
      ? String(this.currentCustomer.profiles[0].doctor_id)
      : null;

    this.prescriptionDetails.quantity =
      +this!.prescriptionDetails!.quantity! || null;
    this.prescriptionDetails.days_supply =
      +this!.prescriptionDetails!.days_supply! || null;

    if (
      this.$route.name === "sales" ||
      this.$route.name === "customers-edit" ||
      this.$route.name === "refund-history"
    ) {
      this.setDoctorAndPharmacistName();
    }
    this.dosageTimeframeUnitItems = [
      { value: "Minutes", text: i18n.t("minutes").toString() },
      { value: "Hours", text: i18n.t("hours").toString() },
      { value: "Days", text: i18n.t("days").toString() },
      { value: "Weeks", text: i18n.t("weeks").toString() },
      { value: "Months", text: i18n.t("months").toString() }
    ];
    this.dosageUnitItems = [
      { value: "Grams", text: i18n.t("grams").toString() },
      { value: "Ounces", text: i18n.t("ounces").toString() },
      { value: "Units", text: i18n.t("units").toString() },
      { value: "Count", text: i18n.t("count").toString() },
      { value: "Capsules", text: i18n.t("capsules").toString() },
      { value: "Milliliters", text: i18n.t("milliliters").toString() },
      { value: "Centiliters", text: i18n.t("centiliters").toString() },
      { value: "Fluid Ounce", text: i18n.t("fluid_ounce").toString() },
      { value: "Each", text: i18n.t("each").toString() },
      { value: "Puffs", text: i18n.t("puffs").toString() }
    ];
    this.quantityOfMeasureItems = [
      { value: "Grams", text: i18n.t("grams").toString() },
      { value: "Ounces", text: i18n.t("ounces").toString() },
      { value: "Capsules", text: i18n.t("capsules").toString() },
      { value: "Each", text: i18n.t("each").toString() },
      { value: "Units", text: i18n.t("units").toString() },
      { value: "Count", text: i18n.t("count").toString() },
      { value: "Milliliters", text: i18n.t("milliliters").toString() },
      { value: "Centiliters", text: i18n.t("centiliters").toString() },
      { value: "Fluid Ounce", text: i18n.t("fluid_ounce").toString() }
    ];
    this.isLoading = true;
    if (!this.pharmacistItemsList.length || !this.doctorsItemsList.length) {
      this.getDoctorsAndPharmacist();
    } else {
      this.checkSelectedDoctorAndPharamcist();
    }
    this.viewPermission = this.hasPermission(policyList.viewCustomersList);
    this.editPermission = this.hasPermission(policyList.accessCustomersList);
    if (
      this.$route.name === "customers-view" &&
      this.viewPermission &&
      this.editPermission
    ) {
      // Need to change editPermission cond. once API integrated
      this.disableEditMode = true;
    } else {
      this.disableEditMode = this.disabled;
    }
  }

  public setDoctorAndPharmacistName() {
    if (
      this.prescriptionDetails.pharmacist_id === null &&
      this.prescriptionDetails.pharmacist_details
    ) {
      this.pharmacistName = `${
        this.prescriptionDetails.pharmacist_details.user!.first_name
      }  ${this.prescriptionDetails.pharmacist_details.user!.last_name} ${
        this.prescriptionDetails.pharmacist_details.pharmacist_title
          ? `${", " +
              this.prescriptionDetails.pharmacist_details.pharmacist_title}`
          : ""
      } `;
    }
    if (
      this.prescriptionDetails.doctor_id === null &&
      this.prescriptionDetails.doctor_details
    ) {
      this.pharmacistName = `${
        this.prescriptionDetails.doctor_details!.first_name
      }  ${this.prescriptionDetails.doctor_details!.last_name} , ${this
        .prescriptionDetails.doctor_details.title || ""} `;
    }
  }

  /**
   * Load the Doctor and Pharmacist Data
   */
  public async getDoctorsAndPharmacist() {
    const [doctorsList, pharmacistList] = await Promise.all([
      customerService.getDoctors(),
      pharmacistService.getAll()
    ]);
    this.pharmacistItemsList = pharmacistList.map(
      (item: PharmacistInterface) => ({
        text: `${item.pharmacist_first_name}  ${item.pharmacist_last_name} ${
          item.pharmacist_title ? ", " + item.pharmacist_title : ""
        } `,
        value: Number(item.user_id)
      })
    );
    this.doctorsItemsList = doctorsList.map((item: Doctor) => ({
      text: `${item.first_name} ${item.last_name} ${
        item.title ? ", " + item.title : ""
      }`,
      value: String(item.id)
    }));
    this.checkSelectedDoctorAndPharamcist();
  }
  public checkSelectedDoctorAndPharamcist() {
    const pharmacistIndex = this.pharmacistItemsList.findIndex(
      item =>
        Number(item.value) === Number(this.prescriptionDetails.pharmacist_id)
    );
    if (pharmacistIndex === -1) {
      this.prescriptionDetails.pharmacist_id = null;
    }
    const doctorIndex = this.doctorsItemsList.findIndex(
      item => Number(item.value) === Number(this.prescriptionDetails.doctor_id)
    );
    if (doctorIndex === -1) {
      this.prescriptionDetails.doctor_id = null;
    }
    this.$validator.reset();
    this.isLoading = false;
  }
  public async onSave() {
    const isValid = await this.$validator.validateAll();
    if (isValid) {
      const prescriptionPayload = cloneDeep(
        orderService.parsePrescriptionDetail(this.prescriptionDetails)
      );
      if (prescriptionPayload.order_item_id) {
        delete prescriptionPayload.order_item_id;
      }
      prescriptionPayload.doctor_id = Number(prescriptionPayload.doctor_id);
      if (this.pageType === "oofPage") {
        prescriptionPayload.batch_uid = String(this.batchUid);
        const itemUid = this.filfillmentOrderDetail.item_uid || null;
        await orderService.updatePreOrderPrescriptionDetails(
          prescriptionPayload,
          itemUid
        );
        this.$emit("resolve");
      } else {
        prescriptionPayload.order_item_id = this.orderItem.id || null;
        if (this.pageType === "orderPage") {
          this.cancel();
        }
        await this.updatePrescriptionDetail({
          prescriptionDetails: prescriptionPayload,
          pageType: this.pageType
        });
        if (this.pageType !== "orderPage") {
          this.$emit("resolve");
        }
      }
    } else {
      messagesService.showMessage(
        "fal fa-exclamation-triangle",
        String(this.$t("prescription_detail_validation_error")),
        "error"
      );
    }
  }
}
