import ConfirmCheckinComponent from "@/components/retail/customers/customersForm/confirmCheckin/ConfirmCheckin.component";
import NotPatientSelectedMessageComponent from "@/components/retail/pos/customers/notPatientSelectedMessage/NotPatientSelectedMessage.component";
import NotPatientSelectedModalComponent from "@/components/retail/pos/customers/notPatientSelectedMessage/NotPatientSelectedModal.component";
import AlertComponent from "@/components/sharedComponents/alert/alert.component";
import { Attachment, Customer } from "@/interfaces/customer";
import { Doctor } from "@/interfaces/doctor";
import { SearchQuery } from "@/interfaces/httpQuery";
import { customerLimits, RuleConfig } from "@/interfaces/limits";
import { Location, LocationSalesLimits } from "@/interfaces/location";
import { LoyaltyProgram } from "@/interfaces/loyaltyProgram";
import { ModalNotification } from "@/interfaces/notification";
import { store } from "@/internal";
import { AxiosResponse } from "axios";
import differenceInYears from "date-fns/differenceInYears";
import { TablePagination } from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";
import merge from "lodash/merge";
import pickBy from "lodash/pickBy";
// @ts-ignore
import toFormData from "object-to-formdata";
import Vue from "vue";
import HttpService from "./http.service";
import { messagesService } from "./messages.service";
/**
 * customer service
 * @override
 */
class CustomerService extends HttpService {
  /**
   * @override
   */
  protected loadDispatcher: string = "CustomerModule/loadCustomers";

  /**
   * @override
   */
  protected uri: string = "store/customers";

  /**
   * @override
   */
  protected defaultSort: string = "first_name";

  /**
   * @override
   */

  protected searchableField: SearchQuery[] = [
    {
      field: "first_name",
      type: "contains"
    },
    {
      field: "last_name",
      type: "contains"
    }
  ];

  /**
   * get a collection os customer
   * @return Promise<Customer[]>
   */
  constructor() {
    super();
    this.query.embed = "notes,coupons,profiles,balance,servingNumber";
  }

  public config(route: string) {
    delete this.query.sort;
    delete this.query.per_page;
    delete this.query.status;
    delete this.query["q[status_noteq]"];
    delete this.query["q[type_noteq]"];
    delete this.query["q[first_name_contains]"];
    this.uri = "store/customers";
    if (route === "check-in") {
      delete this.query.embed;
      this.uri = "store/customers/in_store";
      this.query = { ...this.query, per_page: 10 };
    } else if (route === "pos") {
      delete this.query.embed;
      this.uri = "store/customers/in_store";
      this.query = { ...this.query, per_page: 4 };
    } else if (route === "customers") {
      this.query.embed = "notes,coupons,profiles,balance";
      this.query.status = "ACTIVE";
      this.query["q[type_noteq]"] = "ANONYMOUS";
    } else if (route === "customers-management") {
      this.query.embed = "notes,coupons,profiles,balance";
      this.query = { ...this.query, sort: "-updated_at" };
      this.query["q[type_noteq]"] = "ANONYMOUS";
    }
  }

  public infoModalValidate(msg: string) {
    return this.$modals.load<boolean>(
      ConfirmCheckinComponent,
      {
        size: "fit"
      },
      {
        msg
      }
    );
  }

  public blockModalValidate(params: { msgModal: ModalNotification }) {
    return this.$modals.load<boolean>(
      AlertComponent,
      {
        size: "fit"
      },
      {
        msgModal: params.msgModal
      }
    );
  }

  public async notPatientSelectedMessage() {
    try {
      await this.$modals.load(NotPatientSelectedMessageComponent, {
        size: "fit"
      });
    } catch (error) {
      // Nothing to do
    }
  }

  public async notPatientSelectedModal(
    caregiver: Customer
  ): Promise<Customer | null> {
    try {
      return await this.$modals.load(
        NotPatientSelectedModalComponent,
        {
          size: "normal",
          positionX: "center",
          positionY: "center"
        },
        { caregiver }
      );
    } catch (error) {
      return null;
    }
  }

  public customerValidAdult(customer: Customer) {
    const split = customer.birthday!.split("-");
    const birthDay = new Date(+split[0], +split[1] - 1, +split[2]);
    const customerAge: number = differenceInYears(new Date(), birthDay);
    let action: "warning" | "block" = "warning";
    let age: number = 16;
    const limitConfig: LocationSalesLimits[] =
      store.getters["AuthModule/limitConfig"];
    const location: Location = store.getters["AuthModule/currentLocation"];
    if (limitConfig.length) {
      const ageRule: LocationSalesLimits | null =
        location.location_type === "RETAIL_RECREATIONAL"
          ? this.getAgeRule("recreational")
          : this.getAgeRule("medicinal");
      if (ageRule) {
        age = Number(ageRule.configs[0].config.from);
        action = ageRule.action;
      }
    }
    return {
      notAdult: customerAge < age,
      age,
      action
    };
  }

  public async get(query?: object): Promise<Customer[]> {
    try {
      this.query.applicable_limits = true;
      const response = await super.get(query || this.query);
      return response.data.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return [];
    }
  }

  public async getLoyaltyRewards(customerId: string): Promise<LoyaltyProgram> {
    const auxUri = this.uri;
    this.uri = `store/customers/${customerId}/rewards`;
    const response = await super.get({}, false);
    this.uri = auxUri;
    return response.data;
  }

  public async callCSV(service: string, file?: File): Promise<any> {
    const method = service === "csv_import" ? "POST" : "GET";
    const data = file ? toFormData({ csv_file: file }, { indices: true }) : {};
    const response: AxiosResponse = await Vue.axios({
      method,
      url: `${this.uri}/${service}${
        service === "csv_export" ? "?q[type_noteq]=ANONYMOUS" : ""
      }`,
      data
    });
    return ["csv_export", "csv_example"].includes(service)
      ? response.data
      : response.data.data;
  }
  public async getConditions(): Promise<Array<{ id: number; name: string }>> {
    try {
      const response: AxiosResponse = await Vue.axios({
        method: "GET",
        url: "setting/conditions",
        params: { no_pagination: true }
      });

      return response.data.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return [];
    }
  }

  public async getDoctors(): Promise<Doctor[]> {
    try {
      const query = {
        url: "store/doctors",
        params: {
          status: "ACTIVE",
          sort: "first_name",
          no_pagination: true,
          embed: "profiles"
        }
      };
      const response: AxiosResponse = await Vue.axios(query);
      return response.data.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return [];
    }
  }
  public async getJournals(
    id: string,
    pagination: TablePagination,
    sortTableColumn?: string
  ) {
    this.uri = "/customers/journals";
    const query = {
      page: pagination.currentPage,
      per_page: pagination.itemsPerPage || 10,
      customer_id: id,
      sort: sortTableColumn ? `${sortTableColumn}` : "-id"
    };
    const response = await super.get(query);
    this.uri = "store/customers";
    return response;
  }

  public async getJournalsPopUpModel(
    id: string | number,
    pagination: TablePagination,
    priority: string[]
  ) {
    const url = "/customers/journals";
    const query = {
      page: pagination.currentPage,
      per_page: pagination.itemsPerPage || 10,
      customer_id: id,
      priority,
      sort: "-id"
    };
    const response = await super.getScrollApi(query, url);

    await store.dispatch("CustomerModule/setScrollPagination", response.data);
    return response.data;
  }

  public async filterInStore(filters: { [key: string]: boolean } = {}) {
    this.query.page = 1;
    const query = cloneDeep(this.query);
    // Adding filters in the query which will  be used for pagination
    this.query = merge(this.query, filters);
    this.query = pickBy(this.query, item => item);
    await store.dispatch("CustomerModule/loadCustomers");
    this.query = query;
  }

  /**
   * create a new customer
   * @param customer
   * @return Promise<Customer>
   */
  public async post(customer: FormData): Promise<Customer> {
    const auxUri = this.uri;
    this.uri = "store/customers";
    const response = await super.post(customer);
    this.uri = auxUri;
    return response;
  }

  public async validationCheckin(customer: Customer) {
    this.uri = "store/customers";
    const isPatient: boolean = customer.profiles
      ? !!find(customer.profiles, ["profile_type", "PATIENT"])
      : false;

    if (isPatient) {
      return true;
    }

    const withPatient: Customer[] = await this.findPatients(
      customer.customer_id!
    );

    if (!withPatient.length) {
      const responseEdit = await this.infoModalValidate(
        "customer.msg_validation_checkin"
      );
      if (responseEdit) {
        store.dispatch("CustomerModule/savedForCheckin", true);
        store.dispatch("RouterModule/go", {
          name: "customers-edit",
          params: {
            id: customer.customer_id,
            currentModel: customer
          }
        });
      }
      return false;
    }
    return true;
  }

  public async checkInCustomer(customerId: string): Promise<Customer | null> {
    const auxUri = this.uri;
    this.uri = "store/customers";
    try {
      const response: AxiosResponse = await Vue.axios({
        method: "PATCH",
        url: `${this.uri}/${customerId}/check_in`
      });
      return response.data.data || response.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return null;
    } finally {
      this.uri = auxUri;
    }
  }

  public async customerAtended(customerId: string): Promise<Customer | null> {
    this.uri = "store/customers";
    try {
      const response: AxiosResponse = await Vue.axios({
        method: "PATCH",
        url: `${this.uri}/${customerId}/serve`
      });
      return response.data.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return null;
    }
  }

  public async checkOutCustomer(id: number): Promise<Customer> {
    const uri = "store/customers";
    const response: AxiosResponse = await Vue.axios({
      method: "PATCH",
      url: `${uri}/${id}/check_out`
    });
    return response.data;
  }

  public async checkOutFromCheckIn(customerId: string): Promise<Customer> {
    const response: AxiosResponse = await Vue.axios({
      method: "PATCH",
      url: `store/customers/${customerId}/check_out_from_check_in`
    });
    return response.data;
  }

  /**
   * update a customer
   * @param customer
   * @param formData
   * @return Promise<Customer>
   */
  public async put(customer: Customer, formData: FormData): Promise<Customer> {
    const uri = "store/customers";
    const response: AxiosResponse = await Vue.axios({
      method: "POST",
      url: `${uri}/${customer.customer_id}`,
      data: formData
    });
    return response.data.data;
  }

  public async find(id: number): Promise<Customer | null> {
    delete this.query.status;
    delete this.query["q[status_noteq]"];
    this.query.embed = "notes,profiles,coupons,balance";
    return await super.find(id);
  }

  public async findCustomerProfile(id: number): Promise<Customer | null> {
    this.uri = "store/customers";
    return await super.find(id);
  }

  public async findPatients(
    customerId: string,
    pagination?: TablePagination
  ): Promise<Customer[]> {
    try {
      this.uri = `store/customers/${customerId}/patients`;
      const pag = pagination
        ? {
            page: pagination.currentPage,
            per_page: pagination.itemsPerPage
          }
        : {
            page: 1,
            per_page: 10
          };
      const response = await super.get(pag, true);
      return response.data.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return [];
    }
  }

  public async findById(id: string, query: object | undefined = {}) {
    const uri = this.uri;
    this.uri = "store/customers";
    const response = await super.findById(id, query);
    this.uri = uri;
    return response.data || response;
  }
  public setOneDailyMonthlyLimit(data: {
    medicinal: RuleConfig[];
    limit_type: string;
    is_limit_extended: 0 | 1;
  }) {
    if (data.limit_type !== "one_daily_limit_with_monthly_overall") {
      return data;
    }
    const limits = [];
    limits.push(
      {
        name: "Daily Single Overall Limit",
        amount: data.medicinal[0].daily_amount,
        available: data.medicinal[0].daily_available,
        consumed: data.medicinal[0].daily_consumed,
        current: data.medicinal[0].daily_current,
        measure: data.medicinal[0].daily_measure
      },
      {
        name: "Monthly Single Overall Limit",
        amount: data.medicinal[0].amount,
        available: data.medicinal[0].available,
        consumed: data.medicinal[0].consumed,
        current: data.medicinal[0].current,
        measure: data.medicinal[0].measure
      }
    );
    return {
      is_limit_extended: data.is_limit_extended,
      limit_type: data.limit_type,
      medicinal: limits
    };
  }

  public setOneDailyMonthlyLimitPOS(data: RuleConfig) {
    const limits = [];
    limits.push(
      {
        name: "Daily Single Overall Limit",
        amount: data.daily_amount,
        available: data.daily_available,
        consumed: data.daily_consumed,
        current: data.daily_current,
        measure: data.daily_measure
      },
      {
        name: "Monthly Single Overall Limit",
        amount: data.amount,
        available: data.available,
        consumed: data.consumed,
        current: data.current,
        measure: data.measure
      }
    );
    return limits;
  }

  public async getPatients(data: string) {
    this.uri = "store/customers";
    const query = {
      embed: "profiles",
      "q[profiles.profile_type_equals]": "PATIENT",
      "q[first_name_or_last_name_or_id_number_contains]": data
    };

    const response = await super.get(query);
    return response.data.data;
  }

  public async filterCustomers(query: object) {
    try {
      const response = await super.get(
        { ...query, page: this.query.page, per_page: this.query.per_page },
        true
      );
      return response.data.data;
    } catch (e) {
      return [];
    }
  }

  public async getCaregivers(
    customerId: string,
    pagination?: TablePagination
  ): Promise<Customer[]> {
    this.uri = `store/customers/${customerId}/caregivers`;
    const pag = pagination
      ? {
          page: pagination.currentPage,
          per_page: pagination.itemsPerPage
        }
      : {
          page: 1,
          per_page: 10
        };
    const response = await super.get(pag, true);
    return response.data.data;
  }

  public async findByLicense(license: string) {
    const auxUri = this.uri;
    try {
      this.uri = "store/customers";
      if (license) {
        const response = await super.get(
          { "q[id_number_equals]": license },
          false
        );
        return response.data.data[0];
      } else {
        return null;
      }
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return null;
    } finally {
      this.uri = auxUri;
    }
  }

  public getAgeRule(group: "medicinal" | "recreational") {
    const limitConfig: LocationSalesLimits[] =
      store.getters["AuthModule/limitConfig"];
    return (
      limitConfig.find(rule => {
        if (rule.type === "age" && rule.group === group) {
          const ageConf = rule.configs[0];
          if (ageConf) {
            return true;
          }
        }
        return false;
      }) || null
    );
  }

  public toggleCustomer(customer: Customer, isActive: boolean) {
    const model = { id: customer.customer_id };
    this.uri = "store/customers";

    if (isActive) {
      return super.enable(model);
    }
    return super.disable(model);
  }

  public setQuery(query: object | null = {}) {
    this.query.page = 1;
    delete this.query["q[is_active]"];
    delete this.query["q[is_active_is_false]"];
    delete this.query["q[missing_details_is_true]"];
    this.query = { ...this.query, ...query };
  }

  public async getAllAttachments(customerId: string): Promise<Attachment[]> {
    const oldUri = this.uri;
    try {
      this.uri = `store/customers/${customerId}/attachments`;
      const response = await super.get({
        no_pagination: true,
        sort: "-media_updated_at"
      });
      this.uri = oldUri;
      return response.data as Attachment[];
    } catch (e) {
      messagesService.renderErrorMessage(e);
      this.uri = oldUri;
      return [];
    }
  }

  public async resetCreatedByApiMark(customerId: string): Promise<Customer> {
    const response: AxiosResponse = await Vue.axios({
      method: "PATCH",
      url: `store/customers/${customerId}/reset_api_status`
    });
    return response.data;
  }
}

export const customerService: CustomerService = new CustomerService();
