import { Discount } from "@/interfaces/discount";
import { RootState } from "@/vuex/types";
import { TablePagination } from "helix-vue-components";
import { GetterTree } from "vuex";
import { DiscountManagerState } from "./discountManager.types";

type EmployeeGetter = GetterTree<DiscountManagerState, RootState>;

export const getters: EmployeeGetter = {
  discounts(state: DiscountManagerState): Discount[] {
    return state.discounts;
  },
  pagination(state: DiscountManagerState): TablePagination | null {
    return state.pagination;
  },
  loading(state: DiscountManagerState): boolean {
    return state.loading;
  }
};
