import { PriceGroup } from "@/interfaces/product";
import { BatchLevelState } from "@/vuex/modules/inventory/batchLevel/batchLevel.types";
import { RootState } from "@/vuex/types";
import { TablePagination } from "helix-vue-components";
import { GetterTree } from "vuex";

type BatchTypeGetter = GetterTree<BatchLevelState, RootState>;

export const getters: BatchTypeGetter = {
  batchLevels(state: BatchLevelState): PriceGroup[] | null {
    return state.batchLevels;
  },
  batchLevel(state: BatchLevelState): PriceGroup | null {
    return state.batchLevel;
  },
  pagination(state: BatchLevelState): TablePagination | null {
    return state.pagination;
  },
  loading(state: BatchLevelState): boolean {
    return state.loading;
  }
};
