import { RootState } from "@/vuex/types";
import { Module } from "vuex";
import { actions } from "./timeclock.actions";
import { getters } from "./timeclock.getters";
import { mutations } from "./timeclock.mutations";
import { state } from "./timeclock.state";
import { TimeclockState } from "./timeclock.types";

const namespaced: boolean = true;
export const TimeclockModule: Module<TimeclockState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
