import { ProductState } from "@/vuex/modules/inventory/product/product.types";

export const state: ProductState = {
  products: [],
  productCategories: [],
  headers: [],
  rooms: [],
  grades: [],
  loading: false,
  error: false,
  pagination: null,
  strains: [],
  batchTypes: [],
  categories: [],
  brands: [],
  vendors: [],
  manufacturers: [],
  cultivators: [],
  batchLevelPrices: []
};
