class LocalStorageService {
  public set(name: string, data: any) {
    localStorage.setItem(name, JSON.stringify(data));
  }
  public get(name: string) {
    return JSON.parse(localStorage.getItem(name)!);
  }
  public delete(name: string) {
    localStorage.removeItem(name);
  }
}

export const localStorageService: LocalStorageService = new LocalStorageService();
