import { BatchLevelState } from "@/vuex/modules/inventory/batchLevel/batchLevel.types";
import { RootState } from "@/vuex/types";
import { Module } from "vuex";
import { actions } from "./batchLevel.actions";
import { getters } from "./batchLevel.getters";
import { mutations } from "./batchLevel.mutations";
import { state } from "./batchLevel.state";

const namespaced: boolean = true;

export const BatchLevelModule: Module<BatchLevelState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
