import { InventoryBatch } from "@/interfaces/batch";
import { BatchType } from "@/interfaces/batchType";
import { Brand } from "@/interfaces/brand";
import { HttpQuery } from "@/interfaces/httpQuery";
import { Product } from "@/interfaces/product";
import { ProductCategory } from "@/interfaces/productCategoy";
import { RetailSettings } from "@/interfaces/retailSettings";
import { Strain } from "@/interfaces/strain";
import { Vendor } from "@/interfaces/vendor";
import { EventBus, store } from "@/internal";
import {
  InventoryViewTableMetadata,
  ProductTableMetadata
} from "@/metadata/product";
import { i18n } from "@/plugins/i18n";
import { batchLevelService } from "@/services/batchLevel.service";
import { batchTypeService } from "@/services/batchType.service";
import { brandService } from "@/services/brand.service";
import { messagesService } from "@/services/messages.service";
import { productService } from "@/services/product.service";
import { productCategoryService } from "@/services/productCategory.service";
import { roomService } from "@/services/room.service";
import { strainService } from "@/services/strain.service";
import { vendorService } from "@/services/vendor.service";
import { mathJs } from "@/utils/math.utils";
import { ProductState } from "@/vuex/modules/inventory/product/product.types";
import { RootState } from "@/vuex/types";
import differenceInDays from "date-fns/differenceInDays";
import { TablePagination } from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import omit from "lodash/omit";
import { ActionContext, ActionTree } from "vuex";

type ProductActionContext = ActionContext<ProductState, RootState>;
type ProductActionTree = ActionTree<ProductState, RootState>;

const searchQueryKey =
  "q[name_or_batches.batch_uid_or_batches.biotrack_traceability_id_or_batches.tracking_id_or_batches.product_variant.external_batch_number_or_barcode_contains]";
const stringResults = (gramVal: number, perVal: number) => {
  const results = [];
  if (gramVal) {
    results.push(`${gramVal} mg/g`);
  }
  if (perVal) {
    results.push(`${perVal}%`);
  }
  if (results.length) {
    return results.join(" - ");
  }
  return 0;
};

export const actions: ProductActionTree = {
  async setHeaders(context: ProductActionContext, route: string): Promise<any> {
    const query: HttpQuery = {};
    if (route === "inventory_view") {
      context.commit("setHeaders", InventoryViewTableMetadata);
    } else if (route === "on_sale") {
      query.from_pos_page = 1;
      productService.setQuery(query, "ProductModule/loadOnSaleProducts");
      context.commit("setHeaders", []);
    } else {
      query.embed = "batchType,category,batches";
      productService.setQuery(query, "ProductModule/loadProducts");
      context.commit("setHeaders", ProductTableMetadata);
    }
  },

  async loadProducts(context: ProductActionContext, route) {
    const loadDispacher = productService.getLoadDispacher();
    if (route) {
      store.dispatch(loadDispacher);
    } else {
      try {
        context.commit("setProducts", []);
        context.commit("setLoading", true);
        const payload: Product[] = await productService.get();
        const pagination: TablePagination = await productService.getPagination();
        context.commit("setPagination", pagination);
        context.commit("setProducts", payload);
      } catch (e) {
        messagesService.renderErrorMessage(e);
      } finally {
        context.commit("setLoading", false);
      }
    }
  },

  async loadInventoryProducts(context: ProductActionContext): Promise<any> {
    context.commit("setLoading", true);
    let payload: Product[] = await productService.getStock();
    EventBus.$emit("stockInventoryView", payload);

    const pagination: TablePagination = await productService.getPagination(
      true
    );
    context.commit("setPagination", pagination);
    payload = payload.map(product => {
      const productFormated = product;
      productFormated.nameQty = `${productFormated.name} (${
        productFormated.batches.length
      })`;
      const unit = product.unit;

      const roomsQuantity = cloneDeep(
        productFormated.batches as InventoryBatch[]
      )
        .flatMap(x => x.on_rooms! || x.summary)
        .reduce((accum, room) => {
          accum = mathJs.add!(
            mathJs.bignumber!(accum),
            mathJs.bignumber!(room.quantity_value || 0)
          ) as number;
          return accum;
        }, 0);
      const availableQuantity = cloneDeep(
        productFormated.batches as InventoryBatch[]
      )
        .flatMap(
          batchQuantity => batchQuantity.on_rooms! || batchQuantity.summary
        )
        .reduce((accum, room) => {
          if (room.batch_fraction_status_type === "AVAILABLE") {
            accum = mathJs.add!(
              mathJs.bignumber!(accum),
              mathJs.bignumber!(room.quantity_value || 0)
            ) as number;
          }
          return accum;
        }, 0);
      productFormated.room_quantity = `${roomsQuantity} ${unit}`;
      productFormated.available_reserved = `${availableQuantity} ${unit} / ${roomsQuantity -
        availableQuantity} ${unit}`;
      productFormated.batches = (product.batches as InventoryBatch[]).map(
        batch => {
          batch.total_quantity = `${Number(
            batch!.in_store_quantity_value.toFixed(2)
          )} ${unit}`;

          batch.time_in_location = i18n
            .t("inventory.days", {
              days: differenceInDays(
                new Date(),
                new Date(batch.in_this_location_since)
              )
            })
            .toString();
          batch.strain_name = batch.product_variant.strain
            ? batch.product_variant.strain.name
            : "--";
          batch.tracking_id = batch.tracking_id || "--";
          batch.totalThcCbd = batch.sample
            ? `${stringResults(
                batch.sample.thc,
                batch.sample.thc_relative!
              )} / ${stringResults(
                batch.sample.cbd,
                batch.sample.cbd_relative!
              )}`
            : "0 / 0";
          let totalInRoomsAvailable = 0;
          const totalInRooms = (batch.on_rooms || batch.summary).reduce(
            (sum, room) => {
              if (room.batch_fraction_status_type === "AVAILABLE") {
                totalInRoomsAvailable += +room.quantity_value;
              }
              sum += +room.quantity_value;
              return sum;
            },
            0
          );
          batch.room_quantity = totalInRooms;
          batch.room_quantity_available = totalInRoomsAvailable;
          batch.room_quantity_formatted = `${totalInRooms} ${unit}`;
          batch.batch_type_id = productFormated.batch_type_id || null;
          batch.requires_weighing = !!productFormated.requires_weighing;
          batch.product_sku = productFormated.sku;
          // Used in batch actions.
          batch.product = omit(product, ["batches"]);
          return batch;
        }
      );

      productFormated.total_quantity = `${(productFormated.batches as InventoryBatch[]).reduce(
        (acc, item) =>
          mathJs.add!(
            mathJs.bignumber!(acc),
            mathJs.bignumber!(item.in_store_quantity_value)
          ) as number,
        0
      ) || productFormated.quantity} 
      ${unit}`;
      return productFormated;
    });
    context.commit("setProducts", payload);
    context.commit("setLoading", false);
  },

  async loadOnSaleProducts(context: ProductActionContext): Promise<any> {
    try {
      context.dispatch("loadProductCategories");
      context.dispatch("loadBrands");

      context.commit("setLoading", true);
      const currentRetail: RetailSettings =
        store.getters["AuthModule/currentRetailSettings"];

      let payload: Product[] = await productService.get(null, true);
      const currentQuery = productService.getCurrentQuery();
      /* When the user searchs by code (BID, external or tracking id) if the product has more than one batch
       * associated, it should only show the one matching the search cirteria.
       */
      let validBiotrackTraceID: string[] = [];
      if (
        store.getters["AuthModule/hasBioTrackTraceIntegrations"] &&
        store.getters["AuthModule/bioTrackTraceEnabled"] &&
        currentQuery[searchQueryKey]
      ) {
        validBiotrackTraceID = payload.map(
          item =>
            (item.batches[0] && item.batches[0].biotrack_traceability_id!) || ""
        );
      }

      // happens only when searcing batchid when trace on Note commit setProducts moved to else for proper behaviour
      if (
        currentQuery[searchQueryKey] &&
        payload.length &&
        payload[0].marijuana &&
        store.getters["AuthModule/hasBioTrackTraceIntegrations"] &&
        store.getters["AuthModule/bioTrackTraceEnabled"] &&
        (validBiotrackTraceID[0] === "" || validBiotrackTraceID[0] === null)
      ) {
        productService.renderSearchErrorModal(
          "biotrack_traceability.search_error",
          "biotrack_traceability.search_error_message",
          "",
          true,
          false,
          "ok",
          "biotrack_traceability.retail_pointOfSale",
          "/img/icon_primary_menu_retail@2x.009e06e8.png"
        );
        return;
      } else {
        context.commit("setProducts", []);
        if (payload.length === 1 && currentQuery[searchQueryKey]) {
          const product = payload[0];
          const codeSearch = currentQuery[searchQueryKey];
          if (product.batches.length > 1) {
            const uniqueResult = (product.batches as InventoryBatch[]).find(
              (b: InventoryBatch) =>
                b.tracking_id === codeSearch ||
                b.batch_uid === codeSearch ||
                b.product_variant.external_batch_number === codeSearch
            );

            if (uniqueResult) {
              payload[0].batches = [uniqueResult];
            }
          }
        }
        // ----------------------- //
        const pagination: TablePagination = await productService.getPagination();
        context.commit("setPagination", pagination);
        payload = await Promise.all(
          payload.map(
            async product =>
              await productService.setExpandedProductView(product)
          )
        );
        context.commit("setProducts", payload);
      }
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setLoading", false);
    }
  },

  async loadEmptyProducts(context: ProductActionContext) {
    context.commit("setLoading", true);
    context.commit("setProducts", []);
    setTimeout(() => context.commit("setLoading", false), 500);
  },

  async searchProducts(
    context: ProductActionContext,
    data: { [key: string]: any }
  ): Promise<any> {
    try {
      let dispatcher;
      const currentRetail: RetailSettings =
        store.getters["AuthModule/currentRetailSettings"];

      if (data.dispatcher) {
        dispatcher = data.dispatcher;
        delete data.dispatcher;
      }

      let pagination = null;
      if (data.pagination) {
        pagination = { ...data.pagination };
        delete data.pagination;
      }
      const route: string = data.route;
      const query: HttpQuery = {};
      if (
        store.getters["AuthModule/hasBioTrackTraceIntegrations"] &&
        store.getters["AuthModule/bioTrackTraceEnabled"] &&
        data.value &&
        data.is_batch_search &&
        data.value.slice(0, currentRetail.batch_prefix!.length) ===
          currentRetail.batch_prefix
      ) {
        delete data.is_batch_search;
        query[`is_batch_search`] = 1;
      } else {
        delete data.is_batch_search;
      }

      if (data.route === "on_sale") {
        query[`from_pos_page`] = 1;
      }

      if (data.route === "inventory_view") {
        query["q[inventory_location_id_is_in]"] = data.byRoom;
        query["q[batch_status_not_in][]"] = "SOLD";
      }
      delete data.route;
      if (data.byText) {
        delete data.byText;
        delete data.byRoom;
        const fields = Object.keys(data);
        const fieldsToQuery = fields.join("_or_").replace("value", "name");

        query[`q[${fieldsToQuery}_contains]`] = data.value;

        if (data["category.name"]) {
          query[`product_category_id_is_in`] = data["category.name"];
        }

        if (data["brand.name"]) {
          query[`brand_id_is_in`] = data["brand.name"];
        }
      } else {
        for (const param in data) {
          if (data.hasOwnProperty(param)) {
            query[`q[${param}_id_is_in]`] = data[param];
          }
        }
        delete query["q[byRoom_id_is_in]"];
      }

      if (dispatcher) {
        productService.setQuery(query, `ProductModule/${dispatcher}`);

        if (pagination) {
          productService.paginationAction()(pagination, route);
          return;
        }
      } else {
        productService.setQuery(query);
      }

      context.dispatch("loadProducts", route);
      productService.resetPagination();
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  },

  async searchToProductList(
    context: ProductActionContext,
    value: string
  ): Promise<any> {
    try {
      const search = productService.searchEvent();
      search(value);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  },

  async findStrains(
    context: ProductActionContext,
    data: {
      strainName: string | number;
      batchStrain?: Strain;
      avoidRequest?: boolean;
    } = { strainName: "" }
  ): Promise<any> {
    try {
      let strains: Strain[] = [];
      if (!data.avoidRequest) {
        const query =
          typeof data.strainName === "number"
            ? { "q[id_equals]": data.strainName }
            : { "q[name_contains]": data.strainName };
        strains = await strainService.get(query);
      }
      if (data.batchStrain) {
        strains = [...strains, data.batchStrain];
      }
      context.commit("setStrains", strains);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  },

  async findReplacementProducts(
    context: ProductActionContext,
    data: {
      requiresWeighing: number;
      marijuana: number;
      productName?: string | number;
      current?: Product;
    } = { requiresWeighing: 0, marijuana: 0 }
  ): Promise<any> {
    try {
      let products: Product[] = [];
      const proxyQuery = {
        "q[is_active]": 1,
        "q[requires_weighing_eq]": data.requiresWeighing,
        "q[marijuana_eq]": data.marijuana,
        per_page: 100
      };
      let query = {};
      if (data.productName) {
        query =
          typeof data.productName === "number"
            ? { ...proxyQuery, "q[id_equals]": data.productName }
            : { ...proxyQuery, "q[name_contains]": data.productName };
      } else {
        query = proxyQuery;
      }
      products = await productService.get(query);
      if (data.current && !products.find(p => p.sku === data.current!.sku)) {
        products.push(data.current);
      }
      context.commit("setProducts", products);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  },

  async findBatchType(
    context: ProductActionContext,
    data: {
      batchTypeName: string | number;
      itemBatchType?: BatchType;
      avoidRequest?: boolean;
    } = { batchTypeName: "" }
  ): Promise<any> {
    try {
      let batchTypes: BatchType[] = [];
      if (!data.avoidRequest) {
        const query =
          typeof data.batchTypeName === "number"
            ? { "q[id_equals]": data.batchTypeName }
            : { "q[name_contains]": data.batchTypeName };
        batchTypes = await batchTypeService.get(query);
      }
      if (data.itemBatchType) {
        batchTypes.push(data.itemBatchType);
      }
      context.commit("setBatchTypes", batchTypes);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  },

  async findCategories(
    context: ProductActionContext,
    data: {
      categoryName: string | number;
      itemCategory?: ProductCategory;
      avoidRequest?: boolean;
    } = { categoryName: "" }
  ): Promise<any> {
    try {
      let categories: ProductCategory[] = [];
      if (!data.avoidRequest) {
        const query =
          typeof data.categoryName === "number"
            ? { "q[id_equals]": data.categoryName }
            : { "q[name_contains]": data.categoryName };
        categories = await productCategoryService.get(query);
      }
      if (data.itemCategory) {
        categories.push(data.itemCategory);
      }
      context.commit("setCategories", categories);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  },

  async findBrands(
    context: ProductActionContext,
    data: {
      brandName: string | number;
      itemBrand?: Brand;
      avoidRequest?: boolean;
    } = { brandName: "" }
  ): Promise<any> {
    try {
      let brands: Brand[] = [];
      if (!data.avoidRequest) {
        const query =
          typeof data.brandName === "number"
            ? { "q[id_equals]": data.brandName }
            : { "q[name_contains]": data.brandName };
        brands = await brandService.get(query);
      }

      if (data.itemBrand) {
        brands.push(data.itemBrand);
      }
      context.commit("setBrands", brands);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  },

  async findVendors(
    context: ProductActionContext,
    data: {
      mutator: string;
      vendorName: string;
      itemVendor?: Vendor;
      avoidRequest?: boolean;
    } = { mutator: "setVendors", vendorName: "" }
  ): Promise<any> {
    try {
      let vendors: Vendor[] = [];
      if (!data.avoidRequest) {
        vendors = await vendorService.search(data.vendorName);
      }
      if (data.itemVendor) {
        vendors.push(data.itemVendor);
      }
      context.commit(data.mutator, vendors);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  },

  async loadVendors(context: ProductActionContext): Promise<void> {
    try {
      const vendors = await vendorService.get(
        {
          no_pagination: true,
          sort: "name"
        },
        false
      );
      context.commit("setVendors", vendors.data.data);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  },

  async loadProductCategories(context: ProductActionContext): Promise<any> {
    try {
      if (!context.getters.productCategories.length) {
        const productCategories = await productCategoryService.getAllCategories();
        context.commit("setProductCategories", productCategories);
      }
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  },

  async loadBrands(context: ProductActionContext): Promise<any> {
    try {
      const brands = await brandService.get({
        no_pagination: true,
        sort: "name"
      });
      context.commit("setBrands", brands);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  },

  async loadRooms(context: ProductActionContext): Promise<any> {
    try {
      const rooms = await roomService.getAll();
      context.commit("setRooms", rooms);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  },

  async loadGrades(context: ProductActionContext): Promise<any> {
    try {
      if (!context.state.grades.length) {
        const grades = await productService.getGrades();
        context.commit("setGrades", grades);
      }
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  },

  async loadBatchLevelPrices(context: ProductActionContext) {
    try {
      const prices = await batchLevelService.getAll();
      context.commit("setBatchLevelPrices", prices);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }
};
