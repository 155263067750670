import { TimeClockNotification } from "@/interfaces/timeclockNotification";
import { messagesService } from "@/services/messages.service";
import { notificationService } from "@/services/notification.service";
import { RootState } from "@/vuex/types";
import { ActionTree } from "vuex";
import { NotificationState } from "./notifications.types";

type NotificationActionTree = ActionTree<NotificationState, RootState>;

export const actions: NotificationActionTree = {
  async loadNotifications(context) {
    try {
      context.commit("setLoading", true);
      const response = await notificationService.get(
        {
          embed: "user"
        },
        false
      );
      context.commit("setNotifications", response.data);
    } catch (e) {
      messagesService.renderErrorMessage(e);
      context.commit("setNotifications", []);
    } finally {
      context.commit("setLoading", false);
    }
  },

  async modifyTimeclock(context, notification: TimeClockNotification) {
    try {
      context.commit("setLoading", true);
      const { id, timeclock_id, status } = notification;
      await notificationService.post({
        id,
        timeclock_id,
        status
      });
      context.dispatch("loadNotifications");
    } catch (e) {
      messagesService.renderErrorMessage(e);
      context.commit("setLoading", false);
    }
  },

  async dismissed(context, notification: TimeClockNotification) {
    try {
      context.commit("setLoading", true);
      await notificationService.put(notification, {
        dismissed: 1
      });
      context.dispatch("loadNotifications");
    } catch (e) {
      messagesService.renderErrorMessage(e);
      context.commit("setLoading", false);
    }
  }
};
