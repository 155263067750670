import { EventBus } from "@/internal";
import cloneDeep from "lodash/cloneDeep";
import { Drag, Drop } from "vue-drag-drop";
import { Component, Prop, Vue } from "vue-property-decorator";
import TestItem from "../../editorItems/TestItem/TestItem.component";

import UIBlockBarcodeFieldComponent from "../../editorItems/UIBlockBarcodeField/UIBlockBarcodeField.component";
import UIBlockColumnFieldComponent from "../../editorItems/UIBlockColumnField/UIBlockColumnField.component";
import UIBlockImageFieldComponent from "../../editorItems/UIBlockimageField/UIBlockImageField.component";
import UIBlockLineFieldComponent from "../../editorItems/UIBlockLineField/UIBlockLineField.component";
import UIBlockListFieldComponent from "../../editorItems/UIBlockListField/UIBlockListField.component";
import UIBlockRowFieldComponent from "../../editorItems/UIBlockRowField/UIBlockRowField.component";
import UIBlockTextFieldComponent from "../../editorItems/UIBlockTextField/UIBlockTextField.component";
import UIBlockVarFieldComponent from "../../editorItems/UIBlockVarField/UIBlockVarField.component";
import TemplateEditor from "../../editors.declaration";
import Template from "./CanvasTreeNode.template.vue";

@Component({
  name: "CanvasTreeNodeComponent",
  mixins: [Template],
  components: {
    "test-item": TestItem,
    "text-item": UIBlockTextFieldComponent,
    "line-item": UIBlockLineFieldComponent,
    "var-item": UIBlockVarFieldComponent,
    "list-item": UIBlockListFieldComponent,
    "barcode-item": UIBlockBarcodeFieldComponent,
    "image-item": UIBlockImageFieldComponent,
    "row-item": UIBlockRowFieldComponent,
    "column-item": UIBlockColumnFieldComponent,
    draggable: Drag,
    "drop-zone": Drop
  }
})
export default class CanvasTreeNodeComponent extends Vue {
  @Prop() public item!: { [key: string]: any; type: string };
  @Prop() public configuration!: TemplateEditor.TemplateTypeConfiguration;
  @Prop() public breadcrumb!: number[];
  @Prop() public model!: any;
  @Prop({ default: false }) public printMode!: boolean;

  public draggingExternal: boolean = false;
  // DRAG STATES

  public hovered: boolean = false;
  protected dragOver: boolean = false;
  protected dragLeave: boolean = false;

  public mounted() {
    EventBus.$on("TemplateEditor.DraggingFired", () => {
      this.draggingExternal = true;
      this.hovered = false;
    });
    EventBus.$on("TemplateEditor.DraggingEnded", () => {
      this.hovered = false;
      this.dragOver = false;
      this.draggingExternal = false;
    });
  }

  public dragStart(data: any, event: DragEvent) {
    event.stopPropagation();
    EventBus.$emit("TemplateEditor.DraggingFired");
  }

  public draggableOver(data: any, event: DragEvent) {
    event.stopPropagation();
    this.dragOver = true;
  }

  public draggableLeave(data: any, event: DragEvent) {
    event.stopPropagation();
    this.dragOver = false;
  }

  public dragEnd(data: any, event: Event) {
    event.stopPropagation();
  }

  public hover(event: MouseEvent, v: boolean) {
    this.hovered = v;
  }

  public drop(data: any, event: Event) {
    event.stopPropagation();
    EventBus.$emit("TemplateEditor.MoveElement", {
      element: data.breadcrumb,
      to: this.breadcrumb
    });
    EventBus.$emit("TemplateEditor.DraggingEnded");
  }

  public childrenBreadcrumb(childrenIndex: number) {
    const clone = cloneDeep(this.breadcrumb);
    clone.push(childrenIndex);
    return clone;
  }

  public getFieldConfiguration(fieldtype: string) {
    if (this.configuration.uiBlocks && this.configuration.uiBlocks[fieldtype]) {
      return this.configuration.uiBlocks![fieldtype];
    }
  }
  public get draggableClasses() {
    return {
      hovered: this.hovered
    };
  }

  public get dragClasses() {
    return {
      hovered: this.hovered,
      dragging: this.dragOver
    };
  }

  public get dragStyles() {
    return { "z-index": this.draggingExternal ? this.breadcrumb.length : -1 };
  }
}
