import { BatchType, StateReportingBatchType } from "@/interfaces/batchType";
import { SearchQuery } from "@/interfaces/httpQuery";
import { EventBus } from "@/internal";
import { SecurityPinService } from "@/plugins/security-pin/security-pin.service";
import HttpService from "@/services/http.service";
import { messagesService } from "@/services/messages.service";
import { TableSuccessModalResponse } from "helix-vue-components";

class BatchTypeService extends HttpService {
  protected uri: string = "catalog/batch_types";
  protected loadDispatcher: string = "BatchTypeModule/loadBatchTypes";
  protected searchableField: SearchQuery[] = [
    {
      field: "name",
      type: "contains"
    }
  ];

  public setQuery(query?: object | null) {
    if (query) {
      delete this.query["q[is_active]"];
      delete this.query["q[is_active_is_false]"];
      delete this.query["q[missing_batch_type_state_reporting]"];
      delete this.query["q[missing_limits]"];
    }
    this.query = { ...this.query, ...query };
  }

  public async get(
    query?: object | null,
    noData?: boolean
  ): Promise<BatchType[]> {
    this.setQuery(query);
    try {
      delete this.query.no_pagination;
      const response = await super.get(query || this.query);
      if (noData) {
        this.pagination.itemsPerPage = 10;
        this.query.per_page = 10;
      }
      if (response.data.data) {
        this.setPagination(response);
        return response.data.data;
      }
      return response.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return [];
    }
  }

  public async getStateReportingBatchTypes(): Promise<
    StateReportingBatchType[]
  > {
    const query = {
      no_pagination: true,
      scope: "user"
    };
    const originalUri = this.uri;
    this.uri = "/inventory/batch_types_state_reporting";

    const response = await super.get({ ...this.query, ...query }, false);
    this.uri = originalUri;
    if (response.data.data) {
      return response.data.data;
    }
    return response.data;
  }

  public async save(model: BatchType): Promise<BatchType> {
    if (model.id) {
      return await this.put(model, model);
    } else {
      return await this.post(model);
    }
  }

  public async toggleBatchType(
    model: TableSuccessModalResponse
  ): Promise<BatchType> {
    if (model.item.is_active) {
      return await this.disable(model.item);
    }
    return await this.enable(model.item);
  }

  public pinAction(pinData: { title: string; message: string }) {
    const pin$ = new SecurityPinService();
    return pin$
      .ensure(pinData.title, {
        text: pinData.message
      })
      .then(
        pin => pin,
        () => {
          EventBus.$emit("notify", {
            icon: "fas fa-exclamation-circle",
            text: "required_pin",
            color: "error"
          });
          return "";
        }
      );
  }
}

export const batchTypeService: BatchTypeService = new BatchTypeService();
