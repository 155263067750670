import { Brand, BrandModel } from "@/interfaces/brand";
import { SearchQuery } from "@/interfaces/httpQuery";
import HttpService from "@/services/http.service";
import { TableSuccessModalResponse } from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import Vue from "vue";
import { messagesService } from "./messages.service";

// tslint:disable-next-line
const toFormData = require("object-to-formdata");

class BrandService extends HttpService {
  protected uri: string = "catalog/brands";
  protected defaultSort: string = "";
  protected loadDispatcher: string = "BrandModule/loadBrands";
  protected searchableField: SearchQuery[] = [
    {
      field: "name",
      type: "contains"
    }
  ];
  constructor() {
    super();
    this.query.embed = "vendors";
  }

  public setQuery(query?: object | null) {
    if (query) {
      delete this.query["q[is_active]"];
      delete this.query["q[is_active_is_false]"];
    }
    this.query = { ...this.query, ...query };
  }

  public async get(query?: object | null): Promise<Brand[]> {
    this.setQuery(query);
    this.searchableField[0].field = "name";
    this.loadDispatcher = "BrandModule/loadBrands";
    const methodQuery = {
      ...query,
      ...this.query,
      embed: "vendors"
    };
    const response = await super.get(methodQuery);
    if (response.data.data) {
      return response.data.data;
    }
    return response.data;
  }

  public async getBrand(brandId: string) {
    const methodQuery = {
      ...this.query,
      embed: "vendors"
    };
    const response = await super.findById(brandId, methodQuery);
    return response;
  }

  public async createBrand(brand: Brand): Promise<Brand | undefined> {
    try {
      let brandCopy = Object.assign({}, brand);

      if (brand.logo) {
        brandCopy = toFormData(brandCopy, { indices: true });
      }
      const response = await super.post(brandCopy);
      messagesService.renderSuccessMessage("brand.save_success");
      // if (brand.brand_vendors && brand.brand_vendors.length) {
      //   await brandService.updateVendors(brand.brand_vendors, response.id);
      // }
      return response;
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }

  public async updateVendors(
    vendors: Array<{
      vendor_id?: string;
      id?: number;
    }>,
    id: number
  ) {
    try {
      const uri = `catalog/brands/${id}/vendors/sync`;
      await Vue.axios({
        method: "POST",
        url: uri,
        data: vendors
      });
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }
  public async toggleBrand(model: TableSuccessModalResponse): Promise<Brand> {
    if (model.item.is_active) {
      return await this.disable(model.item);
    }
    return await this.enable(model.item);
  }

  public async uptadeBrand(
    brand: BrandModel,
    oldBrand: Brand
  ): Promise<Brand | undefined> {
    const copyUri = this.uri;
    const model = cloneDeep(brand);
    let payload;
    try {
      model._method = "PUT";
      this.uri += `/${oldBrand.id}`;
      payload = { ...model };

      if (model.logo) {
        payload = toFormData(model, { indices: true });
      }
      const response = await this.post(payload);
      messagesService.renderSuccessMessage("brand.save_success");
      // if (
      //   !isEqual(model.brand_vendors, oldBrand.brand_vendors) &&
      //   oldBrand.id
      // ) {
      //   await brandService.updateVendors(model.brand_vendors, oldBrand.id);
      // }
      return response;
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      this.uri = copyUri;
    }
  }
}

export const brandService: BrandService = new BrandService();
