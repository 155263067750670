export enum moneyEventTypes {
  Withdrawal = "WITHDRAWAL_FROM_SAFE",
  CashPickup = "CASH_PICKUP_WITHDRAWAL",
  PayoutSafe = "PAYOUT_FROM_SAFE",
  CashDrop = "CASH_DROP_FROM_TILL",
  PayoutTill = "PAYOUT_FROM_TILL",
  ManagerReview = "MANAGER_REVIEW",
  CashDiscrepancy = "CASH_DISCREPANCY_OVERRIDE",
  DepositSafe = "DEPOSIT_TO_SAFE",
  DepositTill = "DEPOSIT_TO_TILL"
}

export enum Payouts {
  PAYOUT_FROM_SAFE = "money_manager.safe",
  PAYOUT_FROM_TILL = "money_manager.till"
}
