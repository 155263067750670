import { Doctor } from "@/interfaces/doctor";
import { PharmacistInterface } from "@/interfaces/pharmacist";
import { TranslateResult } from "vue-i18n";

export interface PrescriptionDetails {
  id?: number;
  batch_uid?: string | null;
  pre_order_item_id?: number | null;
  pre_order_id?: string | null;
  order_item_id?: number | null;
  product_id?: number;
  quantity: number | null;
  quantity_unit_measure: string;
  timeframe_unit_measure: string;
  dosage_unit_measure: string;
  days_supply: number | null;
  dosage_take: number | null;
  dosage_to: number | null;
  dosage_timeframe_take: number | null;
  dosage_timeframe_to: number | null;
  sig_information: string | null;
  prescription_description: string | null;
  pharmacist_id?: string | number | null;
  doctor_id?: string | number | null;
  pharmacist_details?: PharmacistInterface | null;
  doctor_details?: Doctor | null;
  start_date?: string | null;
  end_date?: string | null;
}

export const defaultPrescriptionDetails: PrescriptionDetails = {
  order_item_id: null,
  quantity: null,
  quantity_unit_measure: "",
  timeframe_unit_measure: "",
  dosage_unit_measure: "",
  days_supply: null,
  dosage_take: null,
  dosage_to: null,
  dosage_timeframe_take: null,
  dosage_timeframe_to: null,
  sig_information: "",
  prescription_description: "",
  pharmacist_id: null,
  doctor_id: null
};
export interface ValuesForDropdown {
  value: string;
  text: TranslateResult;
}
