import { CustomerState } from "@/vuex/modules/customer/customer.types";

export const state: CustomerState = {
  customers: [],
  loading: false,
  pagination: null,
  scrollPagination: null,
  form: [],
  currentCustomer: null,
  customerRouteforSave: null,
  savedForCheckin: false,
  navigateToAddDoctor: false,
  navigateToCheckIn: false
};
