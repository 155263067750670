import { SaleReconciliationState } from "@/vuex/modules/inventory/saleReconciliation/saleReconciliation.types";
import { RootState } from "@/vuex/types";
import { Module } from "vuex";
import { actions } from "./saleReconciliation.actions";
import { getters } from "./saleReconciliation.getters";
import { mutations } from "./saleReconciliation.mutations";
import { state } from "./saleReconciliation.state";

const namespaced: boolean = true;

export const SaleReconciliationModule: Module<
  SaleReconciliationState,
  RootState
> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
