import PosPrescriptionModalComponent from "@/components/retail/pos/pointOfSale/posCart/posPrescriptionModal/PosPrescriptionModal";
import { policyList } from "@/enums/permissions";
import { EventBus } from "@/event-bus";
import { currencyFilter } from "@/filters/currency.filter";
import { Doctor } from "@/interfaces/doctor";
import {
  defaultFilterModel,
  Order,
  OrderItem,
  Refund
} from "@/interfaces/order";
import { PharmacistInterface } from "@/interfaces/pharmacist";
import {
  defaultPrescriptionDetails,
  PrescriptionDetails
} from "@/interfaces/prescriptionDetails";
import { RetailSettings } from "@/interfaces/retailSettings";
import { customerService } from "@/services/customer.service";
import { pharmacistService } from "@/services/pharmacist.service";
import { truncate } from "@/utils/math.utils";
import { BooleanCheck, tableService } from "helix-vue-components";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Template from "./TableProducts.template.vue";

export interface ProductListValues {
  label: string;
  value: string;
  secondaryValue?: string;
}
const QUERY_DEFAULT = {
  filters: { ...defaultFilterModel },
  pagination: { currentPage: 1, itemsPerPage: 10 }
};

@Component({
  mixins: [Template],
  components: {
    PosPrescriptionModalComponent
  }
})
export default class TableProductsComponent extends Vue {
  @Prop({ default: false })
  public loading!: boolean;
  @Prop({ required: false })
  public transactionId!: string;
  @Prop({ default: () => [] })
  public list!: OrderItem[];
  @Prop({ default: () => null })
  public orderData!: Order;
  @Prop({ required: true })
  public fieldsToShow!: ProductListValues[];
  @Prop({ default: false })
  public isRefund!: boolean;
  @Prop() public tab!: string;
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  public currentRetailSettings!: RetailSettings;
  public isIdentification: boolean = false;

  public pharmacistItems: Array<{ text: string; value: number }> = [];
  public doctorsItems: Array<{ text: string; value: string }> = [];
  public orderFormated: OrderItem[] = [];
  public tooltipMsg: string = "";
  public isLoading = false;
  /**
   * Load the Doctor and Pharmacist Data
   */

  public get getTotalDiscount() {
    let sum = 0;
    if (!this.isRefund) {
      this.list.forEach((order: OrderItem) => {
        if (order.line_item_discounts) {
          sum += order.line_item_discount_without_taxes!;
        }
      });
    } else {
      this.list.forEach(refund => {
        if (refund.order_items!.line_item_discounts) {
          sum += refund.discount_total;
        }
      });
      sum = sum > 0 ? -sum : 0;
    }
    return sum;
  }

  public async getDoctorsAndPharmacist() {
    this.isLoading = true;
    const [doctorsList, pharmacistList] = await Promise.all([
      customerService.getDoctors(),
      pharmacistService.getAll()
    ]);
    this.pharmacistItems = pharmacistList.map((item: PharmacistInterface) => ({
      text: `${item.pharmacist_first_name}  ${item.pharmacist_last_name} ${
        item.pharmacist_title ? ", " + item.pharmacist_title : ""
      } `,
      value: Number(item.user_id)
    }));
    this.doctorsItems = doctorsList.map((item: Doctor) => ({
      text: `${item.first_name} ${item.last_name} ${
        item.title ? ", " + item.title : ""
      }`,
      value: String(item.id)
    }));
    this.checkSelectedDoctorAndPharamcist();
  }

  public async checkSelectedDoctorAndPharamcist() {
    this.orderFormated = this.list.map(orderItem => {
      // @ts-ignore
      if (orderItem!.marijuana || orderItem.product_info!.marijuana) {
        const prescriptionDetail: PrescriptionDetails | null | undefined =
          orderItem!.prescription_details || null;

        if (prescriptionDetail) {
          if (prescriptionDetail!.pharmacist_id) {
            const pharmacistIndex = this.pharmacistItems.findIndex(
              pharmacist =>
                Number(pharmacist.value) ===
                Number(prescriptionDetail!.pharmacist_id)
            );
            if (pharmacistIndex === -1) {
              prescriptionDetail!.pharmacist_id = null;
            }
          }

          if (prescriptionDetail!.doctor_id) {
            const doctorIndex = this.doctorsItems.findIndex(
              doctor =>
                Number(doctor.value) === Number(prescriptionDetail!.doctor_id)
            );
            if (doctorIndex === -1) {
              prescriptionDetail!.doctor_id = null;
            }
          }
          orderItem!.prescription_details = prescriptionDetail;
        }
      }
      return {
        ...orderItem,
        price_after_discounts: this.setPrice(
          orderItem.price_raw,
          orderItem.line_item_discount_without_taxes
        )
      };
    });
    this.isLoading = false;
  }

  public onSearchOrder() {
    EventBus.$emit("setOrder", this.transactionId);
  }

  public async prescriptionDetails(list: OrderItem) {
    let disableCondition = false;
    if (this.$route.name === "refund-history") {
      disableCondition = true;
    } else if (this.$route.name === "customers-edit") {
      disableCondition = !this.hasPermission(
        policyList.modifyPrescriptionCustomersList
      );
      if (list.refund) {
        disableCondition = true;
      }
    } else if (this.$route.name === "sales") {
      disableCondition = !this.hasPermission(
        policyList.modifyPrescriptionRetailSalesHistory
      );
    }
    this.$modals
      .load(
        PosPrescriptionModalComponent,
        {
          size: "fit",
          positionY: "top"
        },
        {
          model: list.prescription_details || defaultPrescriptionDetails,
          orderItem: list,
          pharmacistItems: this.pharmacistItems,
          doctorsItems: this.doctorsItems,
          disabled: disableCondition
        }
      )
      .then(
        () => {
          this.$emit("refreshOrder", defaultFilterModel, {
            currentPage: 1,
            itemsPerPage: 10
          });
        },
        () => {
          /** Nothing to do */
        }
      );
  }

  public prescriptionIconColor(item: OrderItem) {
    if (
      this.$route.name === "sales" ||
      this.$route.name === "customers-edit" ||
      this.$route.name === "refund-history"
    ) {
      if (!item.prescription_details || item.prescription_details === null) {
        return "disabled";
      }
    }
    return this.availablePrescriptionDetail(item) ? "primary" : "error";
  }

  public availablePrescriptionDetail(item: OrderItem) {
    const prescriptionData = item.prescription_details;
    if (
      prescriptionData &&
      prescriptionData.quantity &&
      prescriptionData.quantity_unit_measure &&
      prescriptionData.timeframe_unit_measure &&
      prescriptionData.dosage_unit_measure &&
      prescriptionData.days_supply &&
      prescriptionData.dosage_take &&
      prescriptionData.dosage_to &&
      prescriptionData.dosage_timeframe_take &&
      prescriptionData.dosage_timeframe_to &&
      (prescriptionData.pharmacist_id || prescriptionData.pharmacist_details) &&
      (prescriptionData.doctor_id || prescriptionData.doctor_details)
    ) {
      return true;
    } else {
      return false;
    }
  }

  public setPrice(price?: number, discount?: number) {
    const count = (price || 0) - (discount || 0);
    return count > 0 ? truncate(count, 2) : count;
  }

  public formatedText(item: OrderItem, label: string) {
    const format = tableService.formatText(item, label);
    if (
      [
        "price_raw",
        "sub_total",
        "discount_total",
        "total",
        "price_final",
        "price_after_discounts",
        "line_item_discount_without_taxes",
        "tax_amount",
        "subtotal",
        "quantity",
        "order_items.line_item_discount_without_taxes",
        "tax_total"
      ].includes(label)
    ) {
      const signal = this.isRefund ? -1 : 1;
      return currencyFilter(signal * +format);
    }
    return format || "--";
  }

  protected mounted() {
    this.getDoctorsAndPharmacist();
    if (this.$route.name === "sales" || this.$route.name === "customers-edit") {
      this.tooltipMsg = this.$t(
        "prescription_module_tooltip_msg_sales"
      ).toString();
    } else if (this.$route.name === "refund-history") {
      this.tooltipMsg = this.$t(
        "prescription_module_tooltip_msg_refund"
      ).toString();
    }
    if (
      this.currentRetailSettings.stock_identification_type_in_pos !== "NONE"
    ) {
      this.isIdentification = true;
    }
    if (this.isRefund) {
      this.orderData.sub_total! = -this.orderData.sub_total!;
      this.orderData.tax_total! = -this.orderData.tax_total!;
      this.orderData.total! = -this.orderData.total!;
    }
  }
}
