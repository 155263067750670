import { Till } from "@/interfaces/money";
import { User } from "@/interfaces/user";
import { UserState } from "@/vuex/modules/user/user.types";
import { MutationTree } from "vuex";

type UserMutationTree = MutationTree<UserState>;

export const mutations: UserMutationTree = {
  setUsers(state: UserState, users: User[]) {
    state.users = users;
  },
  setLoading(state: UserState, loading: boolean) {
    state.loading = loading;
  },
  setCurrentTill(state: UserState, till: Till | null) {
    state.currentTill = till;
  }
};
