import { Discount } from "@/interfaces/discount";
import { discountManagerService } from "@/services/discountManager.service";
import { messagesService } from "@/services/messages.service";
import { RootState } from "@/vuex/types";
import { TablePagination } from "helix-vue-components";
import { ActionContext, ActionTree } from "vuex";
import { DiscountManagerState } from "./discountManager.types";

type DiscountManagerActionContext = ActionContext<
  DiscountManagerState,
  RootState
>;
type DiscountManagerActionTree = ActionTree<DiscountManagerState, RootState>;

export const actions: DiscountManagerActionTree = {
  async loadDiscounts(
    context: DiscountManagerActionContext,
    getAll: boolean = false
  ): Promise<any> {
    try {
      context.commit("setLoading", true);
      const payload: Discount[] = await discountManagerService.getDiscounts(
        getAll
      );
      const pagination: TablePagination = await discountManagerService.getPagination();
      context.commit("setPagination", pagination);
      context.commit("setDiscounts", payload);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setLoading", false);
    }
  },
  async removeDiscount(
    context: DiscountManagerActionContext,
    discount: Discount
  ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        context.commit("setLoading", true);
        await discountManagerService.delete(discount);
        messagesService.renderSuccessMessage(
          "discount_manager.discount_deleted"
        );
        await context.dispatch("loadDiscounts");
        resolve();
      } catch (e) {
        messagesService.renderErrorMessage(e);
        reject();
      } finally {
        context.commit("setLoading", false);
      }
    });
  },
  async saveDiscount(
    context: DiscountManagerActionContext,
    discount: Discount
  ): Promise<any> {
    try {
      context.commit("setLoading", true);
      if (discount.id) {
        await discountManagerService.put(discount, discount);
      } else {
        await discountManagerService.post(discount);
      }
      const msg = discount.id
        ? "discount_manager.discount_updated"
        : "discount_manager.discount_added";
      messagesService.renderSuccessMessage(msg);
      context.dispatch(
        "RouterModule/go",
        { name: "discount-manager" },
        { root: true }
      );
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setLoading", false);
    }
  }
};
