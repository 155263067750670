import { Brand } from "@/interfaces/brand";
import { Grade } from "@/interfaces/grade";
import { PriceGroup, Product } from "@/interfaces/product";
import { ProductCategory } from "@/interfaces/productCategoy";
import { Room } from "@/interfaces/room";
import { Strain } from "@/interfaces/strain";
import { Vendor } from "@/interfaces/vendor";
import { productService } from "@/services/product.service";
import { ProductState } from "@/vuex/modules/inventory/product/product.types";
import { RootState } from "@/vuex/types";
import { TablePagination } from "helix-vue-components";
import { GetterTree } from "vuex";

type ProductGetter = GetterTree<ProductState, RootState>;

export const getters: ProductGetter = {
  products(state: ProductState): Product[] {
    return state.products;
  },
  headers(state: ProductState): object[] {
    return state.headers;
  },
  productCategories(state: ProductState): ProductCategory[] | null {
    return state.productCategories;
  },
  brands(state: ProductState): Brand[] | null {
    return state.brands;
  },
  rooms(state: ProductState): Room[] | null {
    return state.rooms || [];
  },
  pagination(state: ProductState): TablePagination | null {
    return state.pagination || productService.getResetedPagination();
  },
  loading(state: ProductState): boolean {
    return state.loading;
  },
  strains(state: ProductState): Strain[] {
    return state.strains;
  },
  batchTypes(state: ProductState): Strain[] {
    return state.batchTypes;
  },
  categories(state: ProductState): ProductCategory[] {
    return state.categories;
  },
  vendors(state: ProductState): Vendor[] {
    return state.vendors;
  },
  grades(state: ProductState): Grade[] {
    return state.grades || [];
  },
  cultivators(state: ProductState): Vendor[] {
    return state.cultivators;
  },
  manufacturers(state: ProductState): Vendor[] {
    return state.manufacturers;
  },
  batchLevelPrices(state: ProductState): PriceGroup[] {
    return state.batchLevelPrices;
  }
};
