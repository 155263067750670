import { messagesService } from "@/services/messages.service";
import { permissionService } from "@/services/permission.service";
import { RootState } from "@/vuex/types";
import { ActionContext, ActionTree } from "vuex";
import { PermissionsState } from "./permission.types";

type PermissionsActionContext = ActionContext<PermissionsState, RootState>;
type PermissionssActionTree = ActionTree<PermissionsState, RootState>;

export const actions: PermissionssActionTree = {
  async loadPermissions(context: PermissionsActionContext, userId: number) {
    try {
      const userPermissions = await permissionService.getUserPermissions(
        userId
      );
      context.commit("setUserPermissions", userPermissions);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }
};
