var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"label-container"},_vm._l((_vm.orderForPrintLabel.order_items),function(item,index){return _c('div',{key:("item_" + index)},[(
        (!item.product_info.packaging_detail ||
          !item.product_info.packaging_detail[_vm.labelIdName]) &&
          _vm.defaultTemplate
      )?_c('canvas-tree-component',{attrs:{"treeData":_vm.defaultTemplate.config.tree,"model":Object.assign({}, item, _vm.orderForPrintLabel),"printMode":true,"size":_vm.defaultTemplate.config.size,"configuration":_vm.defaultTemplate.config}}):(
        _vm.templatesObject &&
          item.product_info.packaging_detail &&
          _vm.templatesObject[item.product_info.packaging_detail[_vm.labelIdName]]
      )?_c('canvas-tree-component',{attrs:{"treeData":_vm.templatesObject[item.product_info.packaging_detail[_vm.labelIdName]]
          .config.tree,"model":Object.assign({}, item, _vm.orderForPrintLabel),"printMode":true,"size":_vm.templatesObject[item.product_info.packaging_detail[_vm.labelIdName]]
          .config.size,"configuration":_vm.templatesObject[item.product_info.packaging_detail[_vm.labelIdName]]
          .config}}):_vm._e()],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }