import { ProductCategoryState } from "@/vuex/modules/productCategory/productCategory.types";

export const state: ProductCategoryState = {
  productCategories: [],
  loading: false,
  pagination: null,
  addNew: false,
  locations: [],
  productCategory: null,
  currentLocation: null
};
