import { CustomerLimitConfig } from "@/interfaces/customer";
import { Location, LocationSalesLimits } from "@/interfaces/location";
import { RetailSettings } from "@/interfaces/retailSettings";
import { store } from "@/internal";
import HttpService from "@/services/http.service";
import { messagesService } from "./messages.service";

class LimitsService extends HttpService {
  public currentLocation!: Location;
  public currentRetailSettings!: RetailSettings;

  public async getLimitsCurrentLocation(): Promise<LocationSalesLimits[]> {
    this.currentLocation = store.getters["AuthModule/currentLocation"];
    this.currentRetailSettings =
      store.getters["AuthModule/currentRetailSettings"];
    this.uri = "/limits/rule";
    const response = await this.get({});
    return response.data.data;
  }

  public async saveLimitsByLocation(
    limits: LocationSalesLimits[]
  ): Promise<LocationSalesLimits[] | null> {
    try {
      limits.forEach(element => {
        if (
          element.type === "cannabis_product" ||
          element.type === "non_cannabis_product"
        ) {
          element.configs.forEach(e => {
            if (e.id && e.id.toString().includes("remove")) {
              delete e.id;
            }
          });
        }
      });
      this.uri = "/limits/rule/bulk";
      const response = await super.put(null, { rules: limits });
      messagesService.renderSuccessMessage("limits_saved_success");
      return response;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return null;
    }
  }

  public async saveLimitsByCustomer(
    customerId?: string | null,
    limits?: CustomerLimitConfig | null
  ): Promise<CustomerLimitConfig | null> {
    try {
      this.uri = `/limits/consumptions/customer/${customerId}`;
      limits = Array.isArray(limits) ? limits[0] : limits;
      const response = await super.put({}, limits);
      messagesService.renderSuccessMessage("limits_saved_success");
      return response;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return null;
    }
  }

  public async getCustomerConsumptions(customerId: string) {
    this.uri = `/limits/consumptions/customer/${customerId}`;
    try {
      const response = await super.get({}, false);
      return response.data;
    } catch (error) {
      messagesService.renderErrorMessage(error);
    }
  }

  public async getCreateCustomerConsumptions() {
    this.uri = `limits/rule?&q[type]=cannabis_product&`;
    try {
      const response = await super.get({}, false);
      return response.data;
    } catch (error) {
      messagesService.renderErrorMessage(error);
    }
  }

  public async getOrderConsumptions(orderId: number) {
    this.uri = `/limits/consumptions/order/${orderId}`;
    try {
      const response = await super.get({}, false);
      return response.data;
    } catch (error) {
      messagesService.renderErrorMessage(error);
    }
  }
}

export const limitsService: LimitsService = new LimitsService();
