import {
  BatchSample,
  BatchSamplesModel,
  LaboratoryResult
} from "@/interfaces/batch";
import HttpService from "./http.service";
import { messagesService } from "./messages.service";
// tslint:disable-next-line
const toFormData = require("object-to-formdata");

class LabResultsService extends HttpService {
  protected defaultUri: string = "/laboratory/samples";
  protected uri = this.defaultUri;
  public async deleteLabResult(
    batchUID: string,
    resultId: number
  ): Promise<any> {
    this.uri = `/laboratory/samples/${batchUID}/results`;
    try {
      const response = await super.delete({ id: resultId });
      const payload = response.data;
      return payload;
    } catch (error) {
      messagesService.renderErrorMessage(error);
    } finally {
      this.uri = this.defaultUri;
    }
  }

  public async getBatchSamples(batchUID: string): Promise<BatchSample | null> {
    this.uri = `/laboratory/samples/${batchUID}`;
    try {
      const response = await super.get(null, false);
      return response.data;
    } catch (error) {
      return null;
    } finally {
      this.uri = this.defaultUri;
    }
  }

  public async addLabResult(batchUID: string, samples: BatchSamplesModel) {
    try {
      const response = await super.post({
        batch_uid: batchUID,
        ...samples
      });
      messagesService.renderSuccessMessage("lab_results_saved");
      return response;
    } catch (error) {
      messagesService.renderErrorMessage(error);
      return null;
    }
  }

  public async updateLabResult(batchUID: string, samples: BatchSamplesModel) {
    try {
      const parsed = {
        ...samples,
        results: samples.results.map(r => {
          delete r.id;
          return r;
        })
      };
      const response = await super.put({ id: batchUID }, parsed);
      messagesService.renderSuccessMessage("lab_results_updated");
      return response;
    } catch (error) {
      messagesService.renderErrorMessage(error);
      return null;
    }
  }

  public async addAttachment(batchUID: string, attachment: File) {
    this.uri = `/laboratory/samples/${batchUID}/attachments`;
    try {
      const data = toFormData(attachment, { indices: true });
      const response = await super.post(data);
      return response.data;
    } catch (error) {
      messagesService.renderErrorMessage(error);
    } finally {
      this.uri = this.defaultUri;
    }
  }

  public async deleteAttachment(batchUID: string, fileID: number) {
    this.uri = `/laboratory/samples/${batchUID}/attachments`;
    try {
      const response = await super.delete({ id: fileID });
      return response;
    } catch (error) {
      messagesService.renderErrorMessage(error);
    } finally {
      this.uri = this.defaultUri;
    }
  }

  public async getAttachments(batchUID: string) {
    this.uri = `/laboratory/samples/${batchUID}/attachments`;
    try {
      const response = await super.get(null, false);
      return response.data.data;
    } catch (error) {
      return [];
    } finally {
      this.uri = this.defaultUri;
    }
  }
}

export const labResultsService: LabResultsService = new LabResultsService();
