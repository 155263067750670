import { AdjustmentType } from "@/components/batch/adjust/models/batch-adjust.model";
import {
  Batch,
  OperationDetail,
  ProductVariantModel
} from "@/interfaces/batch";
import { i18n } from "@/plugins/i18n";
import HttpService from "@/services/http.service";
import { messagesService } from "@/services/messages.service";
import { getParentSKU } from "@/utils/batch-actions.utils";
import { AxiosResponse } from "axios";
import { TablePagination } from "helix-vue-components";
import groupBy from "lodash/groupBy";
import Vue from "vue";

class BatchService extends HttpService {
  protected uri: string = "inventory/batches";

  public async get(query?: object | null): Promise<Batch[]> {
    const response = await super.get(query || this.query);
    return response.data.data;
  }

  public async findBatch(batchId?: string): Promise<Batch[]> {
    batchId
      ? (this.query["q[tracking_id_or_batch_id_contains]"] = batchId)
      : delete this.query["q[tracking_id_or_batch_id_contains]"];
    const response = await super.get(this.query, false);
    return response.data.data;
  }

  public async findBatchByName(batchName?: string): Promise<Batch[]> {
    batchName
      ? (this.query["q[name_contains]"] = batchName)
      : delete this.query["name_contains]"];
    const response = await super.get(this.query, false);
    return response.data.data;
  }

  public async getBatchesProduct(
    sku: number,
    filter: boolean,
    pagination: TablePagination | {} = {},
    sort?: string
  ) {
    const params: { [key: string]: string | number } = {};
    params[
      filter ? "q[in_store_quantity_value_eq]" : "q[in_store_quantity_value_gt]"
    ] = 0;
    const response: AxiosResponse = await Vue.axios({
      method: "GET",
      url: `/catalog/products/${sku}/batches`,
      params: {
        ...params,
        per_page: (pagination as TablePagination).itemsPerPage || 10,
        page: (pagination as TablePagination).currentPage,
        sort
      }
    });
    return response.data.data;
  }

  public async getNewTag(requestedTagIds: string[] = []): Promise<any> {
    let excludedIds = "";
    if (requestedTagIds.length) {
      excludedIds = requestedTagIds
        .map(t => `q[id_is_not_in][]=${t}`)
        .join("&");
    }
    const response: AxiosResponse = await Vue.axios({
      method: "GET",
      url: `tags?q[status_eq]=AVAILABLE${
        excludedIds ? `&${excludedIds}` : ""
      }&sort=tag&no_pagination&limit=1`
    });
    return response.data.data[0];
  }

  public async getSampleResultProfiles(query: object | null) {
    this.uri = `/laboratory/profiles`;
    // Default Parameter for q[onwer_type] is SYSTEM , it will get us the profiles which are only in system and excluding the third party APIs profiles
    query = {
      query,
      "q[owner_type]": "SYSTEM"
    };
    const oldQuery = this.query;
    // @ts-ignore
    this.query = query;
    this.query.no_pagination = true;
    const response = await super.get(null, false);
    this.query = oldQuery;
    this.uri = "inventory/batches";
    return response.data;
  }

  public async getLaboratories(query: object | null) {
    this.uri = `catalog/vendors?q[type_eq]=LABORATORY`;
    const response = await super.get(query, false);
    this.uri = "inventory/batches";
    return response.data.data;
  }

  public async newBatches(batches: Batch[]) {
    const auxUri = this.uri;
    try {
      this.uri = `/inventory/batches/create_collection`;
      const response = await this.post({ collection: batches });
      messagesService.renderSuccessMessage("new_batches_saved");
      return response;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return false;
    } finally {
      this.uri = auxUri;
    }
  }

  public async getAdjustmentTypes(): Promise<AdjustmentType[]> {
    try {
      const prevUri = this.uri;
      this.uri = `inventory/batch_adjustment_types`;
      const response = await super.get(
        { no_pagination: true, sort: "name" },
        false
      );
      this.uri = prevUri;
      return response.data;
    } catch (e) {
      messagesService.showMessage(
        "fal fa-exclamation-triangle",
        i18n.t("audit.reasons_not_available").toString(),
        "error"
      );
      return [];
    }
  }

  public async getOperationTypes(): Promise<any> {
    const prevUri = this.uri;
    this.uri = `/inventory/operation_types`;
    const response = await super.get();
    this.uri = prevUri;
    return response.data;
  }

  public async getBatchLogs(
    uid: string,
    pagination: TablePagination,
    params: { [id: string]: string | number | null }
  ): Promise<any> {
    const prevUri = this.uri;
    this.uri = `inventory/batches/${uid}/logs`;
    const response = await super.get({
      page: pagination.currentPage,
      per_page: pagination.itemsPerPage,
      ...params
    });
    this.uri = prevUri;
    return response.data.data;
  }

  public async getLogDetail(uid: string, operationId: string) {
    const prevUri = this.uri;
    this.uri = `inventory/batches/${uid}/logs/${operationId}`;
    const response = await super.get({}, false);
    this.uri = prevUri;
    return this.parseOperationDetail(response.data);
  }

  public async getProductVariant(batchSKU: string) {
    this.uri = `/catalog/products/${getParentSKU(
      batchSKU
    )}/variant/${batchSKU}`;
    try {
      const response = await super.get(null, false);
      return response.data;
    } catch (error) {
      messagesService.renderErrorMessage(error);
      return null;
    }
  }

  public async updateProductVariant(
    batchSKU: string,
    model: ProductVariantModel
  ) {
    try {
      this.uri = `/catalog/products/${getParentSKU(
        batchSKU
      )}/variant/${batchSKU}`;
      const response = await super.put({}, model);
      return response;
    } catch (error) {
      messagesService.renderErrorMessage(error);
      return null;
    }
  }

  public async findBatchByUID(batchUID: string) {
    this.uri = "inventory/batches";

    return await super.findById(batchUID, { is_on_transfer: 1 });
  }

  public async updateBatch(batchUID: string, model: { tracking_id: string }) {
    this.uri = "inventory/batches";
    try {
      const response = await super.put({ id: batchUID }, model);
      return response.data;
    } catch (error) {
      messagesService.renderErrorMessage(error);
    }
  }

  protected parseOperationDetail(detail: OperationDetail[]) {
    return groupBy(detail, "inventory_location");
  }
}

export const batchService: BatchService = new BatchService();
