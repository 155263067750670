import { RootState } from "@/vuex/types";
import { PageNav } from "helix-vue-components";
import { ActionContext, ActionTree } from "vuex";
import { PageNavState } from "./pageNav.types";

type PageNavActionContext = ActionContext<PageNavState, RootState>;
type PageNavActionTree = ActionTree<PageNavState, RootState>;

export const actions: PageNavActionTree = {
  async setPageNav(
    context: PageNavActionContext,
    config: PageNav
  ): Promise<any> {
    context.commit("setPageNavConfig", config);
  }
};
