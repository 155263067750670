import { Component } from "vue-property-decorator";
import TemplateEditor from "../../editors.declaration";
import UIBlockDefaultComponent from "../UIBlockDefault.component";
import Template from "./UIBlockLineField.template.vue";

import { LineFieldDefaultConfig } from "../UIBlock.config";
import { LineFieldDefaultValues } from "../UIBlock.declarations";

@Component({
  mixins: [Template]
})
export default class UIBlockLineFieldComponent extends UIBlockDefaultComponent {
  protected block: TemplateEditor.UIBlocks.Block = {
    model: {
      type: "line-item",
      model: { ...LineFieldDefaultValues }
    },
    configuration: LineFieldDefaultConfig
  };

  protected get values(): { [key: string]: any } {
    return {
      ...this.block.model.model,
      ...this.templateModel
    };
  }

  protected get lineStyles() {
    return {
      ...this.values,
      border: this.active ? "1px solid #0093ff" : "none",
      padding: `1px ${this.values.padding}px`,
      cursor: this.hover ? "pointer" : "default",
      height: this.values.height ? this.values.height + "px" : "auto",
      borderRadius: this.values.borderRadius
        ? this.values.borderRadius + "px"
        : "auto"
    };
  }
}
