import { currencyFilter } from "@/filters/currency.filter";
import { PAYMENT_METHODS } from "@/interfaces/batchTransferManager";
import { ResponseItem } from "@/interfaces/order";
import { User } from "@/interfaces/user";
import { store } from "@/internal";
import { CloseTillFormModel } from "@/metadata/money";
import { i18n } from "@/plugins/i18n";
import { SecurityPinService } from "@/plugins/security-pin/security-pin.service";
import { FNS_DATE_FORMATS, fnsFormatDate } from "@/utils/date-fns.utils";
import reduce from "lodash/reduce";
import Vue from "vue";
import HttpService from "./http.service";
import { messagesService } from "./messages.service";

export interface MethodSales {
  cash?: number;
  debit_card?: number;
  credit_card?: number;
  coupon?: number;
  purchase_points?: number;
  check?: number;
  [key: string]: any;
}

class MoneyService extends HttpService {
  protected uri: string = "";

  public getSalesByMethod(tillData: any) {
    const sales: MethodSales = {};
    const methods =
      store.getters["AuthModule/currentRetailSettings"].payment_methods;
    return reduce(
      methods,
      (acc, method) => {
        if (method.enabled) {
          const type = method.type!.toLocaleLowerCase();
          if (type !== PAYMENT_METHODS.CASH) {
            if (type === "credit_card" || type === "creditcard") {
              acc.credit_card = tillData.creditcard
                ? tillData.creditcard
                : tillData.credit_card
                ? tillData.credit_card
                : 0;
            } else if (type === "debit_card" || type === "debitcard") {
              acc.debit_card = tillData.debitcard
                ? tillData.debitcard
                : tillData.debit_card
                ? tillData.debit_card
                : 0;
            } else {
              acc[type] = tillData[type] || 0;
            }
          }
        }
        return acc;
      },
      sales
    );
  }

  public async getCurrentUserTillReview() {
    // @ts-ignore
    const userId = store.state.AuthModule.user.id;
    const prevUri = this.uri;
    this.uri = "/money/events/boxes/review";
    const query = { assigned_user_id: userId, status: "OPEN" };
    try {
      const response = await this.get(query);
      return response.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return false;
    } finally {
      this.uri = prevUri;
    }
  }

  public async getCurrentUserTill() {
    // @ts-ignore
    const userId = store.state.AuthModule.user.id;
    const locationId =
      // @ts-ignore
      store.state.AuthModule.currentLocation.id;
    this.uri = "/money/boxes";
    const query = {
      "q[assigned_user_id_equals]": userId,
      "q[type_equals]": "TILL",
      "q[location_id_equals]": locationId,
      "q[status_equals]": "OPEN",
      "q[events_exists]": "",
      embed: "events"
    };
    try {
      const response = await this.get(query);
      return response.data.hasOwnProperty("data")
        ? response.data.data[0]
        : response.data[0];
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return false;
    }
  }

  public async getMoneySummary() {
    const formerUri = this.uri;
    this.uri = "/money/events/boxes/summary";
    try {
      const response = await this.get();
      return response.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return null;
    } finally {
      this.uri = formerUri;
    }
  }

  public async getTillById(id: number) {
    const formerUri = this.uri;
    this.uri = "/money/events/boxes/review";
    const query = { id };
    try {
      const response = await this.get(query);
      return response.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return false;
    } finally {
      this.uri = formerUri;
    }
  }

  public async employeeCloseTill(
    tillId: number,
    amount: number,
    detail: CloseTillFormModel
  ) {
    // @ts-ignore
    const userId = store.state.AuthModule.user.id;
    this.uri = "/money/events/boxes";
    const model = { id: `${tillId}/count?embed=user` };
    const data = {
      user_id: userId,
      manager_id: userId,
      money_detail: JSON.stringify(detail),
      from_employee: true,
      amount
    };
    const pinText = {
      title: "money_manager.employee_close_till",
      message: "money_manager.confirm_employee_close_till"
    };
    const pin = await this.pinAction(pinText);
    if (pin) {
      try {
        const response = await this.put(model, data, false, pin);
        messagesService.renderSuccessMessage("money_manager.till_close");
        return response;
      } catch (e) {
        messagesService.renderErrorMessage(e);
        return;
      }
    }
  }

  public async getSafe(): Promise<{
    events: any[];
    cash: number;
    boxId?: number;
    employee?: User;
  }> {
    this.loadDispatcher = "MoneyModule/loadSafe";
    const locationId =
      // @ts-ignore
      store.state.AuthModule.currentLocation.id;
    let query: { [key: string]: any } = {
      "q[type_equals]": "SAFE",
      "q[location_id_equals]": locationId
      // "q[status_equals]": "OPEN"
    };
    this.uri = "/money/boxes";
    const response = await this.get(query);
    let safe: { [key: string]: unknown };
    if (response.data.hasOwnProperty("data")) {
      // check if the response.data object has a property called data
      safe = response.data.data[0];
    } else {
      safe = response.data[0];
    }
    if (safe) {
      this.uri = "/money/events";
      query = {
        ...this.query,
        sort: this.query.sort || "-event_date",
        "q[box_id_equals]": safe.id,
        embed: "user,manager"
      };
      let events = await this.get(query);
      events = events.data.data;
      return {
        events,
        cash: safe.cash as number,
        boxId: safe.id as number,
        employee: safe.assigned_user as User
      };
    }
    return { events: [], cash: 0, boxId: 0 };
  }

  public async getTills(open: boolean = false) {
    const locationId =
      // @ts-ignore
      store.state.AuthModule.currentLocation.id;

    const status = open ? "OPEN" : "PENDING";
    const sort = this.query.sort || (open ? "-created_at" : "updated_at");
    let query: { [key: string]: any } = {
      "q[type_equals]": "TILL",
      "q[status_equals]": status,
      "q[location_id_equals]": locationId,
      "q[events_exists]": "",
      sort
    };
    this.uri = "/money/boxes";
    query = { ...this.query, ...query, embed: "events,assignedUser" };
    const response = await this.get(query);
    // check if the response.data object has a property called data
    return response.data.hasOwnProperty("data")
      ? response.data.data
      : response.data;
  }

  public async addRemoveSafe(
    boxId: number,
    model: {
      action: string;
      amount: string;
      sub_type: string;
      description?: string;
      undo?: string;
    },
    relatedTillData: {
      tillID: number;
      eventID: number;
    } | null = null
  ) {
    this.uri = "/money/events?embed=user,box";
    // @ts-ignore
    const userId = store.state.AuthModule.user.id;
    const pinText =
      model.action === "add"
        ? {
            title: "money_manager.add_money",
            message: "money_manager.add_safe_pin"
          }
        : {
            title: "money_manager.remove_money",
            message: "money_manager.remove_safe_pin"
          };
    const pin = await this.pinAction({
      ...pinText,
      translateParams: {
        amount: currencyFilter(+model.amount)
      }
    });
    if (pin) {
      try {
        const response = await super.post(
          {
            user_id: userId,
            manager_id: userId,
            type: model.action.toUpperCase(),
            amount: Number(model.amount).toFixed(2),
            sub_type: model.sub_type,
            box_id: boxId,
            undo: model.undo,
            undo_reference_id:
              (relatedTillData && relatedTillData.eventID) || null
          },
          false,
          pin
        );

        messagesService.renderSuccessMessage("success_msg");
        if (relatedTillData && relatedTillData.tillID) {
          this.addRemoveTillFromUndoSafeAction(
            relatedTillData,
            Number(model.amount).toFixed(2),
            pin,
            model.action.toUpperCase() as "ADD" | "REMOVE"
          );
        }
        return response;
      } catch (e) {
        messagesService.renderErrorMessage(e);
        return false;
      }
    }
  }

  public async openTill(
    tillId: number,
    payload: { user_id: number; amount: string }
  ) {
    this.uri = `/money/events/boxes`;
    // @ts-ignore
    const userId = store.state.AuthModule.user.id;
    const pinText = {
      title: "money_manager.remove_money",
      message: "money_manager.open_till_pin",
      translateParams: {
        amount: currencyFilter(+payload.amount)
      }
    };
    const pin = await this.pinAction(pinText);
    if (pin) {
      try {
        const response = await this.put(
          { id: `${tillId}/status?embed=user` },
          { ...payload, manager_id: userId },
          false,
          pin
        );
        messagesService.renderSuccessMessage("money_manager.till_open_ok");
        return response;
      } catch (e) {
        messagesService.renderErrorMessage(e);
        return false;
      }
    }
  }

  public async recountTill(tillId: number, userId: number) {
    this.uri = `/money/events/boxes`;
    const pinText = {
      title: "money_manager.recount_till",
      message: "money_manager.recount_till_pin"
    };
    // @ts-ignore
    const managerId = store.state.AuthModule.user.id;
    const pin = await this.pinAction(pinText);
    if (pin) {
      try {
        const response = await this.put(
          { id: `${tillId}/review/decline` },
          { assigned_user_id: userId, manager_id: managerId },
          false,
          pin
        );
        messagesService.renderSuccessMessage(
          "money_manager.recount_till_success"
        );
        return response;
      } catch (e) {
        messagesService.renderErrorMessage(e);
        return false;
      }
    }
  }

  public async createTill(payload: {
    name: string;
    safeId: number;
    assignedId: number;
  }) {
    this.uri = "/money/boxes";
    const locationId =
      // @ts-ignore
      store.state.AuthModule.currentLocation.id;
    try {
      const response = await this.post({
        name: payload.name,
        type: "TILL",
        safe_id: payload.safeId,
        location_id: locationId,
        status: "CLOSE",
        assigned_user_id: payload.assignedId
      });

      return response;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return false;
    }
  }

  public async closeOpenTill(
    tillId: number,
    pinText: {
      title: string;
      message: string;
      translateParams?: object;
    }
  ) {
    // @ts-ignore
    const userId = store.state.AuthModule.user.id;
    this.uri = `/money/events/boxes`;
    const pin = await this.pinAction(pinText);
    if (pin) {
      try {
        const response = await this.delete(
          {
            id: `${tillId}/status?user_id=${userId}&manager_id=${userId}&embed=user`
          },
          false,
          pin
        );
        if (response) {
          messagesService.renderSuccessMessage("money_manager.till_closed_ok");
          return response.data;
        }
      } catch (e) {
        messagesService.renderErrorMessage(e);
        return false;
      }
    }
  }

  public async AddRemoveToOpenTill(
    till: any,
    model: { amount: string; action: string }
  ) {
    let uriAction = model.action === "add" ? "till" : "safe";
    uriAction = `${till.boxId}/deposit_to_${uriAction}?embed=user,manager`;
    this.uri = "/money/events/boxes";
    const pinText =
      model.action === "add"
        ? {
            title: "money_manager.add_money",
            message: "money_manager.add_till_pin"
          }
        : {
            title: "money_manager.remove_money",
            message: "money_manager.remove_till_pin"
          };
    const pin = await this.pinAction({
      ...pinText,
      translateParams: {
        amount: currencyFilter(+model.amount)
      }
    });

    if (pin) {
      try {
        // @ts-ignore
        const userId = store.state.AuthModule.user.id;
        const response = await this.put(
          { id: uriAction },
          {
            amount: model.amount,
            manager_id: userId,
            user_id: till.employee.id
          },
          false,
          pin
        );
        messagesService.renderSuccessMessage("success_msg");
        return response;
      } catch (e) {
        messagesService.renderErrorMessage(e);
        return false;
      }
    }
  }

  public async addRemoveTillFromUndoSafeAction(
    relatedTillData: {
      tillID: number;
      eventID: number;
    },
    amount: string,
    pin: string | number,
    action: "ADD" | "REMOVE"
  ) {
    this.uri = "/money/events/boxes";
    const actionUrl = action === "ADD" ? "safe" : "till";
    const uriAction = `${
      relatedTillData.tillID
    }/deposit_to_${actionUrl}?embed=user,manager`;
    // @ts-ignore
    const userId = store.state.AuthModule.user.id;
    try {
      const response = await this.put(
        { id: uriAction },
        {
          amount,
          manager_id: userId,
          user_id: userId,
          undo_reference_id: relatedTillData.eventID
        },
        false,
        pin
      );
      return response;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return false;
    }
  }

  public acceptReview(boxId: number, payload: any) {
    const pinText = {
      title: "money_manager.till_review",
      message: "money_manager.accept_review_difference",
      translateParams: {
        difference: currencyFilter(payload.review_difference)
      }
    };
    return this.closeTillGeneral(boxId, payload, pinText);
  }

  public managerCloseTill(boxId: number, payload: any) {
    const pinText = {
      title: "money_manager.manager_close_till",
      message: "close_till_action"
    };
    return this.managerCloseTillGeneral(boxId, payload, pinText);
  }

  public async payout(
    assignedUserId: number,
    boxId: number,
    model: {
      amount: string;
      sub_type: string;
      description?: string;
      from_employee?: boolean;
    },
    boxType: string
  ) {
    this.uri = "/money/events?embed=user,box";
    const pinText = {
      title: `money_manager.payout_from_${boxType}`,
      message: `money_manager.payout_pin`,
      translateParams: {
        amount: currencyFilter(+model.amount),
        boxType
      }
    };
    const pin = await this.pinAction(pinText);
    if (pin) {
      try {
        // @ts-ignore
        const userId = store.state.AuthModule.user.id;
        const response = await super.post(
          {
            assigned_user_id: assignedUserId,
            manager_id: userId,
            event_date: fnsFormatDate(
              new Date(),
              FNS_DATE_FORMATS.DEFAULT_WITH_TIME
            ),
            type: "REMOVE",
            amount: Number(model.amount).toFixed(2),
            sub_type: model.sub_type,
            box_id: boxId,
            description: model.description || "",
            from_employee: model.from_employee
          },
          false,
          pin
        );
        messagesService.renderSuccessMessage("success_msg");
        return response;
      } catch (e) {
        messagesService.renderErrorMessage(e);
        return false;
      }
    }
  }

  public async getPayouts() {
    this.uri =
      "/money/events?q[sub_type_is_in][]=PAYOUT_FROM_TILL&q[sub_type_is_in][]=PAYOUT_FROM_SAFE&q[voided_equals]=0";
    const sort = this.query.sort || "-event_date";
    const query = {
      ...this.query,
      embed: "user,manager,box.assignedUser",
      sort
    };
    const response = await this.get(query);
    return response.data.hasOwnProperty("data")
      ? response.data.data
      : response.data;
  }

  public async voidPayout(payoutId: number) {
    this.uri = `/money/events/${payoutId}/void?embed=voidedEvent.box.assignedUser`;
    const pinText = {
      title: "money_manager.void_payout",
      message: "money_manager.void_payout_pin"
    };
    const pin = await this.pinAction(pinText);
    if (pin) {
      try {
        const response = await super.post(null, false, pin);
        return response;
      } catch (e) {
        messagesService.renderErrorMessage(e);
        return false;
      }
    }
  }

  // allows the customer to take money from their store credit account

  public async withdrawStoreCredit(payload: ResponseItem) {
    try {
      const request = await Vue.axios({
        method: "POST",
        url: `/money/events/store_credit/withdraw`,
        data: payload
      });
      messagesService.renderSuccessMessage(
        i18n.t("refund.message_cash").toString()
      );
      return request.data.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }

  public async closeTillGeneral(boxId: number, payload: any, pinText: any) {
    const prevUri = this.uri;
    this.uri = "money/events/boxes";
    // @ts-ignore
    const userId = store.state.AuthModule.user.id;
    const pin = await this.pinAction(pinText);
    if (pin) {
      try {
        const model = { id: `${boxId}/review/approve` };
        const response = await this.put(
          model,
          { ...payload, manager_id: userId },
          false,
          pin
        );
        messagesService.renderSuccessMessage("money_manager.till_closed_ok");
        return response;
      } catch (e) {
        messagesService.renderErrorMessage(e);
        return false;
      }
    }
    this.uri = prevUri;
  }

  public async managerCloseTillGeneral(
    boxId: number,
    payload: any,
    pinText: any
  ) {
    const prevUri = this.uri;
    this.uri = "money/events/boxes";
    // @ts-ignore
    const userId = store.state.AuthModule.user.id;
    const pin = await this.pinAction(pinText);
    if (pin) {
      try {
        const model = { id: `${boxId}/review/manager_close_till` };
        const response = await this.put(
          model,
          { ...payload, manager_id: userId },
          false,
          pin
        );
        messagesService.renderSuccessMessage("money_manager.till_closed_ok");
        return response;
      } catch (e) {
        messagesService.renderErrorMessage(e);
        return false;
      }
    }
    this.uri = prevUri;
  }

  private pinAction(pinData: {
    title: string;
    message: string;
    translateParams?: object;
  }) {
    const pin$ = new SecurityPinService();
    return pin$
      .ensure(pinData.title, {
        text: pinData.message,
        translateParams: pinData.hasOwnProperty("translateParams")
          ? pinData.translateParams
          : {}
      })
      .then(
        pin => pin,
        () => {
          messagesService.showMessage(
            "fas fa-exclamation-circle",
            "required_pin",
            "error"
          );
          return 0;
        }
      );
  }
}

export const moneyService: MoneyService = new MoneyService();
