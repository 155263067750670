import { EventBus } from "@/internal";
import { i18n } from "@/plugins/i18n";
import forOwn from "lodash/forOwn";
import isArray from "lodash/isArray";
import reduce from "lodash/reduce";

const getLastNested = (obj: any, stringsArr: string[] = []) => {
  forOwn(obj, value => {
    if (typeof value === "string") {
      stringsArr.push(value);
    } else {
      getLastNested(value, stringsArr);
    }
  });
  return stringsArr;
};

class MessagesService {
  public flatErrors(errorsObj: any): string[] {
    return reduce(
      errorsObj,
      (acc: string[], field, key) => {
        if (key !== "exception") {
          if (isArray(field)) {
            acc = [...acc, ...field];
          } else if (typeof field === "object") {
            acc = [...acc, ...getLastNested(field)];
          } else {
            acc = [...acc, field];
          }
        }
        return acc;
      },
      []
    );
  }

  public renderErrorMessage(e: any) {
    const errorResp: ErrorApiResponse = (e.response && e.response!.data) || {};
    if (errorResp.error && typeof errorResp.error!.errors === "object") {
      const errors = this.flatErrors(errorResp.error!.errors);
      this.showMessage(
        "fas fa-exclamation-circle",
        errors.length ? errors : errorResp.error.message || "server_error",
        "error"
      );
    } else {
      const message =
        (errorResp.error && errorResp.error!.message) ||
        errorResp.message ||
        e.message ||
        e.toString();

      // Avoid showing error message if the request was cancelled.
      if (message !== "Cancel") {
        this.showMessage("fas fa-exclamation-circle", message, "error");
      }
    }
  }

  public renderSuccessMessage(message: string | string[]) {
    this.showMessage("fas fa-check-circle", message, "success");
  }

  public renderWarningMessage(message: string | string[]) {
    this.showMessage("fas fa-exclamation-circle", message, "warning");
  }

  public showMessage(
    icon: string,
    text: string | string[],
    color: string,
    x: string = "",
    y: string = "top"
  ) {
    EventBus.$emit("notify", {
      icon,
      text,
      color,
      x,
      y
    });
  }

  public parseMetrcError(error: any): Array<{ message: string }> {
    if (error && error.response && error.response.data) {
      const errorResp: ErrorApiResponse =
        (error.response && error.response.data) || {};
      const flatted = this.flatErrors(errorResp.error.errors);
      if (flatted.length) {
        return flatted.map(e => ({ message: e }));
      }
      return [{ message: i18n.t("server_error").toString() }];
    }
    return [{ message: i18n.t("server_error").toString() }];
  }
}

export const messagesService: MessagesService = new MessagesService();

interface ErrorApiResponse {
  status: string;
  message: string;
  error: {
    message: string;
    code: number;
    errors: Array<{ [key: string]: string }>;
  };
}
