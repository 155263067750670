import { MemberLevel } from "@/interfaces/memberLevel";
import { store } from "@/internal";
import { memberLevelService } from "@/services/memberLevel.service";
import { messagesService } from "@/services/messages.service";
import { MemberLevelState } from "@/vuex/modules/inventory/memberLevel/memberLevel.types";
import { RootState } from "@/vuex/types";
import { TablePagination } from "helix-vue-components";
import { ActionContext, ActionTree } from "vuex";

type MemberLevelActionContext = ActionContext<MemberLevelState, RootState>;
type MemberLevelActionTree = ActionTree<MemberLevelState, RootState>;

export const actions: MemberLevelActionTree = {
  async loadMemberLevels(context: MemberLevelActionContext): Promise<any> {
    try {
      context.commit("setLoading", true);
      const memberLevel: MemberLevel[] = await memberLevelService.get();
      const pagination: TablePagination = await memberLevelService.getPagination();
      context.commit("setPagination", pagination);
      context.commit("setMemberLevels", memberLevel);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setLoading", false);
    }
  },
  async findMemberLevel(
    context: MemberLevelActionContext,
    id: number
  ): Promise<any> {
    try {
      context.commit("setLoading", true);
      const memberLevel: MemberLevel | null = await memberLevelService.findById(
        +id
      );
      context.commit("setMemberLevel", memberLevel);
    } catch (error) {
      store.dispatch("RouterModule/errorRedirect", {
        location: { name: "member-level-list" },
        error
      });
    } finally {
      context.commit("setLoading", false);
    }
  },
  async saveMemberLevel(
    context: MemberLevelActionContext,
    memberLevel: MemberLevel
  ): Promise<any> {
    try {
      if (memberLevel.id) {
        await memberLevelService.put(memberLevel, {
          ...memberLevel,
          automatic_percentage_fallback: +memberLevel.automatic_percentage_fallback!
        });
      } else {
        await memberLevelService.post(memberLevel);
      }
      messagesService.renderSuccessMessage("member_level_saved");
      context.dispatch(
        "RouterModule/go",
        { name: "member-level-list" },
        { root: true }
      );
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  },
  unsetMemberLevel(context: MemberLevelActionContext): void {
    context.commit("setMemberLevel", null);
  },
  async enableMultiple(
    context: MemberLevelActionContext,
    memberLevel: MemberLevel[]
  ): Promise<any> {
    const memberLevelIds = memberLevel.map(
      (memberLevels: MemberLevel) => memberLevels.id!
    );
    context.commit("setLoading", true);
    try {
      await memberLevelService.enableMultiple(memberLevelIds);
      messagesService.renderSuccessMessage("updated_successfully");
      context.dispatch("loadMemberLevels");
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setLoading", false);
    }
  },

  async disableMultiple(
    context: MemberLevelActionContext,
    memberLevel: MemberLevel[]
  ): Promise<any> {
    const memberLevelIds = memberLevel.map(
      (memberLevels: MemberLevel) => memberLevels.id!
    );
    context.commit("setLoading", true);
    try {
      await memberLevelService.disableMultiple(memberLevelIds);
      messagesService.renderSuccessMessage("updated_successfully");
      context.dispatch("loadMemberLevels");
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setLoading", false);
    }
  }
};
