import { EventBus, router, store } from "@/internal";
import { Http } from "@/namespaces/Http";
import "@/plugins/axios";
import "@/plugins/vee-validate";
import "@/plugins/vuetify";
// @ts-ignore
import CKEditor from "@ckeditor/ckeditor5-vue";
import {
  DatePicker,
  Loading,
  Pagination,
  Popover,
  Table,
  TableColumn,
  TimePicker,
  TimeSelect
} from "element-ui";
// @ts-ignore
import locale from "element-ui/lib/locale";
// @ts-ignore
import lang from "element-ui/lib/locale/lang/en";
import "element-ui/lib/theme-chalk/index.css";
import "element-ui/lib/theme-chalk/reset.css";
import { HelixModals } from "helix-vue-components";
import "helix-vue-components/lib/helix-vue-components.css";
import IdleVue from "idle-vue";
// @ts-ignore
import currency from "v-currency-field";
import "v-currency-field/dist/index.css";
import Vue from "vue";
// @ts-ignore
import VueBarcodeScanner from "vue-barcode-scanner";
import { VueHammer } from "vue2-hammer";
import App from "./App.component";
import { currencyFilter } from "./filters/currency.filter";
import { fnsCompleteDate } from "./filters/date-fns.filter";
import { i18n } from "./plugins/i18n";
import "./registerServiceWorker";
import DOMPurify from "dompurify"

locale.use(lang);
Vue.use(currency);
Vue.use(DatePicker);
Vue.use(Popover);
// Element table
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Pagination);
Vue.use(Loading);
Vue.use(TimePicker);
Vue.use(TimeSelect);
Vue.use(Popover);
Vue.use(VueBarcodeScanner);

Vue.use(IdleVue, {
  eventEmitter: EventBus,
  store,
  startAtIdle: false,
  idleTime: process.env.VUE_APP_SESSION_TIMEOUT
});
VueHammer.config.swipe = {
  direction: 24
};

Vue.use(VueHammer);

// define filters
Vue.filter("currency", currencyFilter);
Vue.filter("fnsCompleteDate", fnsCompleteDate);

Vue.use(CKEditor);
Vue.config.productionTip = false;

// tslint:disable-next-line
new Http.ApiJwtService();

const setToken = async () => {
  store.dispatch("AuthModule/setAccessToken");
  if (store.getters["AuthModule/accessToken"]) {
    await store.dispatch("AuthModule/getByToken");
    const user = store.getters["AuthModule/user"];
    await store.dispatch("PermissionsModule/loadPermissions", user.id);
  }
};

setToken().finally(() => {
  const loader = document.getElementById("loader");
  // @ts-ignore
  document.body.removeChild(loader);
  const vm = new Vue({
    store,
    router,
    i18n,
    render: h => h(App)
  }).$mount("#app")
  Vue.mixin({
    methods:{
    purify:function(text:string){
      return DOMPurify.sanitize(text)
    }}
  });
  Vue.use(HelixModals, { vueApp: vm, i18n, store, router });
});
