import { EventBus } from "@/event-bus";
import { JournalNotes } from "@/interfaces/journalNotes";
import { BiotrackTraceNotification } from "@/interfaces/notification";
import { customerService } from "@/services/customer.service";
import { TablePagination, TablePaginationDefault } from "helix-vue-components";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Template from "./TraceabilityConfirm.template.vue";
@Component({
  mixins: [Template]
})
export default class TraceabilityConfirm extends Vue {
  @Prop({ required: true })
  public modalData!: BiotrackTraceNotification;
  @Prop() public error!: boolean;
  public isLoading: boolean = false;
  @Getter("scrollPagination", { namespace: "CustomerModule" })
  public scrollPagination!: TablePagination;
  public syncStatus = 0;
  public progressBarValue = 0;
  public created() {
    if (!this.modalData.justifyContent) {
      this.modalData.justifyContent = "justify-center";
    }
    if (this.modalData.acceptButton === undefined) {
      this.modalData.acceptButton = true;
    }
    if (this.modalData.cancelButton === undefined) {
      this.modalData.cancelButton = true;
    }
    EventBus.$on("synchingProgressBar", (value: number) => {
      this.syncStatus = value;
    });

    EventBus.$on("removePopup", () => {
      this.$emit("resolve", true);
    });

    EventBus.$on("increaseProgressBarPercentage", (value: number) => {
      this.progressBarValue = value;
    });
    EventBus.$on("removePopup", () => {
      this.$emit("resolve", true);
    });
    this.progressBarValue = this.randomInteger(1, 2);
  }
  public randomInteger(min: number, max: number) {
    return Math.round((Math.random() * (max - min + 1) + min) * 10);
  }

  public accept() {
    this.$emit("resolve", true);
  }

  public cancel() {
    this.$emit("reject", false);
  }

  public async callingScrollDownApi() {
    const listElm = this.$refs.notesScroll as HTMLElement;
    if (
      listElm &&
      listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight
    ) {
      setTimeout(() => {
        this.isLoading = true;
      }, 5);

      if (
        this.scrollPagination.lastPage !== this.scrollPagination.currentPage
      ) {
        const response = await customerService.getJournalsPopUpModel(
          this.modalData.id,
          {
            currentPage: this.scrollPagination.currentPage + 1,
            itemsPerPage: 10,
            totalItems: 0,
            itemsPerPageOptions: [],
            from: 0,
            to: 0
          },
          ["POPUP_MODAL_NOTE_FLAG"]
        );

        const customerJournalNotes: JournalNotes[] = response.data.filter(
          (singleNote: JournalNotes) =>
            singleNote.priority === "POPUP_MODAL_NOTE_FLAG"
        );

        customerJournalNotes.map(singleNote => {
          (singleNote.status = {
            name: singleNote.title,
            style: "fontSize:22px ; fontWeight:600;"
          }),
            (singleNote.description = {
              name: singleNote.note,
              style: "fontSize:20px ; textAlign:left"
            });
        });
        this.modalData.customerJournalNotes = [
          ...this.modalData.customerJournalNotes,
          ...customerJournalNotes
        ];
      }
      setTimeout(() => {
        this.isLoading = false;
      }, 5);
    }
  }
}
