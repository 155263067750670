import { Order } from "@/interfaces/order";
import {
  OnSaleBatch,
  OrderFulfillmentPending,
  PreOrder,
  PreOrderFilters,
  PreOrderScannResponse
} from "@/interfaces/orderFulfillment";
import { EventBus } from "@/internal";
import { i18n } from "@/plugins/i18n";
import { SecurityPinService } from "@/plugins/security-pin/security-pin.service";
import { AxiosRequestConfig } from "axios";
import { TablePagination } from "helix-vue-components";
import forOwn from "lodash/forOwn";
import { leftShift } from "mathjs";
import Vue from "vue";
import HttpService from "./http.service";
import { messagesService } from "./messages.service";

const CANCEL = "cancel";
const ACCEPT = "fill";
const DISAPPROVE = "unfill";
interface OrderParam {
  status: string;
  pagination: TablePagination;
  sort?: string;
  search: string;
}

class OrderFulfillmentService extends HttpService {
  protected loadDispatcher: string = "OrderModule/loadOrders";
  protected uri: string = "preorders/";
  protected inboxesFilters: { [key: string]: string } = {
    online_pending: "online",
    online_filled: "online",
    call_in_pending: "callIn",
    call_in_filled: "callIn",
    in_store_pending: "inStore",
    in_store_filled: "inStore"
  };
  /** The function 'getOrders'  is responsable by
   * Gets orders
   * @param [status]: string;
   * @param [pagination]: interface;
   * @returns Promise (error, success) ex: {status:"ok", message:"message", data:{
   *    data:
   *    [{
   *    id: number
   *    status: string
   *    order_fulfillment_number: number
   *    account_id: number
   *    current_page: number
   *    first_page_url: string
   *    from: number
   *    last_page: number
   *    last_page_url: string
   *    next_page_url: null
   *    per_page: number
   *    prev_page_url: null
   *    to: number
   *    total: number
   *    ]}
   * }}
   */
  public async getOrders(
    param: OrderParam,
    filters?: Partial<PreOrderFilters>
  ): Promise<OrderFulfillmentPending[]> {
    this.uri = "preorders/";
    try {
      let query = param.pagination
        ? {
            per_page: param.pagination.itemsPerPage,
            page: param.pagination.currentPage,
            sort: param.sort,
            search: param.search,
            scopes: ""
          }
        : {};
      if (param.status) {
        this.uri = `preorders/${param.status}`;
      }

      if (filters) {
        const embeds: string[] = [];
        forOwn(filters, (value, key) => {
          if (true === value) {
            embeds.push(this.inboxesFilters[key]);
          }
        });
        query.scopes = embeds.join(",");
        query = {
          ...query,
          ...filters
        };
      }

      const response = await super.get(query);
      return response.data.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return [];
    }
  }

  public async createCallInPreorder(
    customerId: string
  ): Promise<(PreOrder & { order: Order }) | null> {
    const uri = this.uri;
    try {
      this.uri = "/preorders";
      const response = await this.post({
        customer_id: customerId,
        source: "CALL_IN"
      });
      this.uri = uri;
      return response as PreOrder & { order: Order };
    } catch (e) {
      this.uri = uri;
      messagesService.renderErrorMessage(e);
      return null;
    }
  }

  public async completeCallIn(
    preOrderId: string,
    completeInfo: any
  ): Promise<PreOrder | null> {
    try {
      const response = await Vue.axios({
        method: "PATCH",
        url: `/preorders/${preOrderId}/unload`,
        data: completeInfo
      });
      return response.data.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return null;
    }
  }

  /** The function 'updateOrderPending'  is responsable by
   * Updates order pending
   * @param orderId: number; order id
   * @param orderItems: number; order item id
   * @param ProductId: number ;order product id
   * @returns Promise (error, success) ex: {status:"ok", message:"message", data:[]}
   */
  public async updateOrderPending(
    orderItems: Array<{ sku: string | null; quantity: number }>,
    ProductId: string | null
  ) {
    try {
      const request = await Vue.axios({
        method: "PATCH",
        url: `/preorders/items/${ProductId}`,
        data: { batches: orderItems }
      });
      messagesService.renderSuccessMessage(
        i18n.t("order_fulfillment.PreOrderEdited").toString()
      );
      return request.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }
  /** The function 'pickList'  is responsable by
   * Picks list
   * @param orderId: number; order id
   * @returns Promise (error, success) ex: {status:"ok", message:"message", data:[]}
   */
  public async pickList(orderId: string, status: string) {
    try {
      const extension = status === "PENDING" ? "receipt" : "receipt_filled";
      const response = await Vue.axios({
        method: "GET",
        url: `/preorders/${orderId}/${extension}`
      });
      return response.data.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }
  /** The function 'setStatusOrderFulfillment'  is responsable by
   *  set order fulfillment status
   * @param orderId: number; order id
   * @param status: string; order status
   * @returns Promise (error, success) ex: {status:"ok", message:"message", data:[]}
   */
  public async setStatusOrderFulfillment(
    orderId: string,
    status: string,
    reason?: {} | null
  ) {
    let pin;
    let res;
    if (status === CANCEL) {
      pin = await this.pinAction({
        title: "Cancel order",
        message: "Please verify you would like to cancel this pre-order"
      });
    }
    try {
      const request: AxiosRequestConfig = {
        method: "PATCH",
        url: `/preorders/${orderId}/${status}`,
        data: reason
      };

      if (pin && status === CANCEL) {
        request.headers = { Pincode: pin };
        res = await Vue.axios(request);
        messagesService.renderSuccessMessage(
          i18n.t("order_fulfillment.CancelledOrders").toString()
        );
        return res.data.data;
      }
      if (status !== CANCEL) {
        res = await Vue.axios(request);
        if (res && !res.data.data.statusCancel) {
          messagesService.renderSuccessMessage(
            i18n
              .t(
                status === ACCEPT
                  ? "order_fulfillment.PreOrdersaccept"
                  : "order_fulfillment.PreOrderdisapprove"
              )
              .toString()
          );
        }
        return res.data.data;
      }
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }
  /** The function 'searchOrderFulfillmentItems'  is responsable by
   *  Searh  the items of each order id
   * @param orderId: string; order id
   * @returns Promise (error, success) ex: {
   * status:string ex: "ok",
   * message:"message",
   * data:{
   *  status: number
   *  statusText: string
   *  data: [{
   *    code: number
   *    id: number
   *    quantity: number
   *    order_fulfillment_requested_id: number
   *    batch: {
   *        id: number
   *       quantity: number
   *       order_fulfillment_requested_id: number
   * }
   *  }]
   *  }
   */
  public async searchOrderFulfillmentItems(orderId: string) {
    try {
      const request = await Vue.axios.get(`/preorders/${orderId}/items`);
      return request;
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }
  /** The function 'searchOrderFulfillmentRequested'  is responsable by
   *  Searh  the requested of each order id
   * @param orderId: number; order id
   * @returns Promise (error, success) ex: {
   * status:string ex: "ok",
   * code: number ex: "200"
   * data:[{
   *    id: number
   *    status:  string ex: "UPDATE"
   *    order_fulfillment_requested_id: number
   *    batch: {
   *      id: number
   *       batch_id: string
   *       product_id: number
   *       product_name: string
   *       requires_weighing: number
   *   }]
   * }
   */
  public async searchOrderFulfillmentRequested(orderId: number) {
    try {
      const request = await Vue.axios({
        method: "GET",
        url: `/sale/order_fulfillments/${orderId}/requested`
      });
      return request.data.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }
  /** The function 'getCustomer'  is responsable by
   * Gets customer
   * @param customerId: string; customer id
   * @returns Promise (error, success) ex: {status:"ok", message:"message", data:{ interface: Customer } }
   */
  public async getCustomer(customerId: string) {
    try {
      const request = await Vue.axios({
        method: "GET",
        url: `/store/customers/${customerId}`
      });
      return request.data.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }
  /**
   * The function 'destroyItems'  is responsable by
   * Destroys items
   * @param orderId: number; order id
   * @param orderItems number; order item id
   * @returns Promise (error, success) ex: {status:"ok", message:"message", data:[]}
   */
  public async destroyItems(orderId: number, orderItems: number[]) {
    try {
      const request = await Vue.axios({
        method: "POST",
        url: `/sale/order_fulfillments/${orderId}/items/destroy`,
        data: orderItems
      });
      return request.data.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }
  /**
   * The function 'deleteItemOrder'  is responsable by
   * Deletes item when editing an order
   * @param itemId: string pre-order item id
   * @param action: string; action to take (cancel|uncancel)
   * @param cancelReason: {}; reason for cancellation of the pre-order item
   * @returns Promise (error, success) ex: {status:"ok", message:"message", data:[]}
   */
  public async deleteUndeleteItemOrder(
    itemId: string,
    action: string,
    cancelReason: {}
  ) {
    try {
      const request = await Vue.axios({
        method: "PATCH",
        url: `/preorders/items/${itemId}/${action}`,
        data: cancelReason
      });
      messagesService.renderSuccessMessage(`order_fulfillment.${action}_item`);
      return request;
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }

  public pinAction(pinData: { title: string; message: string }) {
    const pin$ = new SecurityPinService();
    return pin$
      .ensure(pinData.title, {
        text: pinData.message
      })
      .then(
        pin => pin,
        () => {
          EventBus.$emit("notify", {
            icon: "fas fa-exclamation-circle",
            text: "required_pin",
            color: "error"
          });
          return false;
        }
      );
  }

  public tableModal() {
    return [
      {
        icon: "fal fa-times",
        id: "btn_delete_customer",
        modalActions: {
          modalNumber: 1,
          modalQuestion: i18n.t("customer.remove_cutomer").toString(),
          modalSuccessText: "yes",
          modalIdSuccess: "btn_delete_yes",
          modalCancelText: "no",
          modalIdCancel: "btn_delete_no"
        }
      }
    ];
  }

  /** The function is responsable to mark a pre-order as canceled
   * @param itemUid: string; pre-order item ID
   * @param action: string; action to do (check|uncheck)
   * @returns Promise (error, success) ex: {status:"ok", message:"message", data:[]}
   */
  public async checkUncheckOrderFulfillmentItem(
    itemUid: string,
    action: string
  ) {
    try {
      const request = await Vue.axios({
        method: "PATCH",
        url: `/preorders/items/${itemUid}/${action}`
      });
      messagesService.renderSuccessMessage(`order_fulfillment.${action}_item`);
      return request.data.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }

  public async preOrderScan(
    scan: string
  ): Promise<PreOrderScannResponse[] | null> {
    try {
      this.uri = `/preorders/scan/${scan}`;
      const response = await super.get({}, false);
      return response as PreOrderScannResponse[];
    } catch (error) {
      messagesService.renderErrorMessage(error);
      return null;
    }
  }

  public async getProductBatches(
    customerId: string,
    productSku: string
  ): Promise<Array<{ batches: OnSaleBatch[] }> | null> {
    const uri = this.uri;
    try {
      this.uri = `/preorders/batches/${customerId}`;
      const query = {
        no_pagination: true,
        "q[sku_eq]": productSku
      };
      const response = await super.get(query);
      this.uri = uri;
      return response.data ? response.data : response;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      this.uri = uri;
      return null;
    }
  }

  public async deletePreorder(orderUid: string): Promise<PreOrder | null> {
    const uri = this.uri;
    this.uri = "/preorders";
    try {
      const response = await this.delete({ id: orderUid });
      this.uri = uri;
      return response.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      this.uri = uri;
      return null;
    }
  }

  public async assginPharmacistOnDuty(
    orderIds: string[],
    pharmacistId: number | null
  ) {
    try {
      await Vue.axios({
        method: "PATCH",
        url: "/public/v1/preorders/prescription/pharmacist/update",
        data: { order_ids: orderIds, pharmacist_id: pharmacistId }
      });
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }

  public async getListLabels(productUid: string) {
    const uri = this.uri;
    try {
      this.uri = `/preorders/${productUid}/print-label`;
      const query = {
        no_pagination: true
      };
      const response = await super.get(query);
      this.uri = uri;
      return response.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      this.uri = uri;
      return null;
    }
  }

  /**
   * This method is responsible for creating the ISOOF
   * @param customerId the customer identifier
   * @returns data array of data
   */
  public async createInStorePreorder(customerId: string) {
    const uri = this.uri;
    try {
      this.uri = "/preorders";
      const response = await this.post({
        customer_id: customerId,
        source: "IN_STORE"
      });
      return response;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return [];
    } finally {
      this.uri = uri;
    }
  }
}
export const orderFulfillmentService = new OrderFulfillmentService();
