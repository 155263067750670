import { RootState } from "@/vuex/types";
import { Module } from "vuex";
import { actions } from "./auth.actions";
import { getters } from "./auth.getters";
import { mutations } from "./auth.mutations";
import { state } from "./auth.state";
import { AuthState } from "./auth.types";

const namespaced: boolean = true;

export const AuthModule: Module<AuthState, RootState> = {
  namespaced,
  state,
  actions,
  getters,
  mutations
};
