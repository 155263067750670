import { policyList } from "@/enums/permissions";
import { User } from "@/interfaces/user";
import { pusherEvents } from "@/internal";
import { traceabilityService } from "@/services/traceability.service";
import { BooleanCheck, Callback } from "@/types/types";
import { Notifications } from "@/vuex/modules/notifications/notifications.types";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter, State } from "vuex-class";
import Template from "./Profile.template.vue";
import { TimeclockNotificationComponent } from "./TimeclockNotification/TimeclockNotification.component";
const namespace = "AuthModule";
@Component({
  mixins: [Template],
  components: { TimeclockNotificationComponent },
  inject: ["$changes"]
})
export default class ProfileComponent extends Vue {
  public profileMenu: boolean = false;
  public expandedValue = null;
  @State("isLogged", { namespace }) public isLogged!: string;
  @State("appName") public appName!: string;
  @Getter("user", { namespace })
  public user!: User;
  @Getter("notifications", { namespace: "NotificationModule" })
  public notifications!: Notifications[];
  @Getter("newNotifications", { namespace: "NotificationModule" })
  public newNotifications!: Notifications[];
  @Getter("isLoading", { namespace: "NotificationModule" })
  public isLoading!: boolean;
  public validCreds: boolean = false;
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  protected hasPermission!: BooleanCheck;

  @Action("loadNotifications", { namespace: "NotificationModule" })
  private loadNotifications!: Callback;
  @Action("dismissed", { namespace: "NotificationModule" })
  private dismissAction!: Callback;
  @Action("logout", { namespace })
  private logoutAction!: Callback;

  public displayMenu() {
    this.profileMenu = !this.profileMenu;
  }

  get profileUrl() {
    return `${
      process.env.VUE_APP_CORE_FRONTEND
    }/application-management/profile`;
  }

  public logout() {
    this.logoutAction();
  }

  public imgError(user: User) {
    user.avatar_thumb_url = "";
  }

  public get profileName() {
    return (this.user && (this.user.nickname || this.user.first_name)) || "";
  }

  public modifyCreds() {
    window.location.href = `${
      process.env.VUE_APP_CORE_FRONTEND
    }/application-management/profile`;
  }

  protected async mounted() {
    if (this.hasPermission(policyList.viewTimeClockAlerts)) {
      this.$changes.do(pusherEvents.timeclockChangeRequest, {
        callback: this.loadNotifications,
        displayForCurrentUser: false
      });
      this.loadNotifications();
    }
    const locationId = +this.user.settings.current_location_id!;
    const traceData = await traceabilityService.getLocationTraceability(
      locationId
    );
    if (traceData[0].value === "Biotrack Traceability") {
      await traceabilityService.traceabilityCredsValidation().then(reponse => {
        this.validCreds =
          reponse.status === "error" || reponse.code === 200 ? false : true;
      });
    }
  }
}
