import { Discount } from "@/interfaces/discount";
import { TablePagination } from "helix-vue-components";
import { MutationTree } from "vuex";
import { DiscountManagerState } from "./discountManager.types";

type VendorMutationTree = MutationTree<DiscountManagerState>;

export const mutations: VendorMutationTree = {
  setDiscounts(state: DiscountManagerState, discounts: Discount[]) {
    state.discounts = discounts;
  },
  setLoading(state: DiscountManagerState, loading: boolean) {
    state.loading = loading;
  },
  setPagination(
    state: DiscountManagerState,
    pagination: TablePagination | null
  ) {
    state.pagination = pagination;
  }
};
