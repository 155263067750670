import { Timeclock } from "@/interfaces/timeclock";
import { TimeclockState } from "@/vuex/modules/timeclock/timeclock.types";
import { TablePagination } from "helix-vue-components";
import { MutationTree } from "vuex";

type TimeclockMutationTree = MutationTree<TimeclockState>;

export const mutations: TimeclockMutationTree = {
  setTimeclocks(state: TimeclockState, timeclocks: Timeclock[]) {
    state.timeclocks = timeclocks;
  },
  setUserTimeclocks(state: TimeclockState, timeclocks: Timeclock[]) {
    state.userTimeclocks = timeclocks;
  },
  setUserTimeWeek(state: TimeclockState, hours: string | null) {
    state.timeWeek = hours;
  },
  setUserFilteredTimeclocks(state, timeclocks: Timeclock[]) {
    state.userFilteredTimeclocks = timeclocks;
  },
  setLoading(state: TimeclockState, loading: boolean) {
    state.loading = loading;
  },
  setLoadingFiltered(state, loading: boolean) {
    state.loadingFiltered = loading;
  },
  setPagination(state: TimeclockState, pagination: TablePagination | null) {
    state.pagination = pagination;
  },
  setCurrentTimeclock(state: TimeclockState, timeclock: Timeclock) {
    state.currentTimeclock = timeclock;
  }
};
