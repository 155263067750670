import { Room } from "@/interfaces/room";
import { messagesService } from "@/services/messages.service";
import { retailSettingsService } from "@/services/retailSettings.service";
import { roomService } from "@/services/room.service";
import { RootState } from "@/vuex/types";
import { TablePagination } from "helix-vue-components";
import { ActionContext, ActionTree } from "vuex";
import { RoomState } from "./room.types";

type RoomActionContext = ActionContext<RoomState, RootState>;
type RoomActionTree = ActionTree<RoomState, RootState>;

export const actions: RoomActionTree = {
  async loadRooms(context: RoomActionContext): Promise<any> {
    try {
      context.commit("setLoading", true);
      const payload: Room[] = await roomService.get();
      const pagination: TablePagination = await roomService.getPagination();
      context.commit("setPagination", pagination);
      context.commit("setRooms", payload);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setLoading", false);
    }
  },
  async deleteRoom(context: RoomActionContext, room: Room): Promise<any> {
    try {
      await roomService.removeRoom(room);
      context.dispatch("loadRooms");
      messagesService.renderSuccessMessage("rooms.room_deleted");
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  },
  async deleteMultipleRooms(
    context: RoomActionContext,
    rooms: Room[]
  ): Promise<any> {
    try {
      await roomService.deleteMultiple(rooms.map(room => room.id));
      context.dispatch("loadRooms");
      messagesService.renderSuccessMessage("deleted_successfully");
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  },
  async addRoom(
    context: RoomActionContext,
    data: { room: Room; edit: boolean }
  ): Promise<Room> {
    try {
      const response = await roomService.save(data.room);
      const message = data.edit ? "rooms.room_updated" : "rooms.room_added";
      messagesService.renderSuccessMessage(message);
      context.dispatch("loadRooms");
      return response;
    } catch (e) {
      const currentRetailSettings = await retailSettingsService.get();
      let error: Room;
      if (
        currentRetailSettings.integrations! &&
        currentRetailSettings.integrations!.biotrack_traceability &&
        currentRetailSettings.integrations!.biotrack_traceability.enable
      ) {
        error = e.response!.data;
      } else {
        messagesService.renderErrorMessage(e.response!.data.data);
        error = e.response;
      }
      return error;
    }
  },
  async loadAllRooms(context: RoomActionContext): Promise<void> {
    const rooms = await roomService.getAll();
    context.commit("setAllRooms", rooms);
  }
};
