import { Location } from "@/interfaces/location";
import { LocationState } from "@/vuex/modules/location/location.types";
import { MutationTree } from "vuex";

type LocationMutationTree = MutationTree<LocationState>;

export const mutations: LocationMutationTree = {
  setLocations(state: LocationState, locations: Location[]) {
    state.locations = locations;
  },
  setDefaultLocations(state: LocationState, locations: Location[]) {
    state.defaultLocations = locations;
  },
  setLoading(state: LocationState, loading: boolean) {
    state.loading = loading;
  }
};
