import { RootState } from "@/vuex/types";
import { Module } from "vuex";
import { actions } from "./discountManager.actions";
import { getters } from "./discountManager.getters";
import { mutations } from "./discountManager.mutations";
import { state } from "./discountManager.state";
import { DiscountManagerState } from "./discountManager.types";

const namespaced: boolean = true;
export const DiscountManagerModule: Module<DiscountManagerState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
