import { HttpQuery, SearchQuery } from "@/interfaces/httpQuery";
import HttpService from "./http.service";
import { messagesService } from "./messages.service";

class DiscountManagerService extends HttpService {
  protected uri: string = "/sale/discount/discounts";
  protected searchableField: SearchQuery[] = [
    {
      field: "name",
      type: "contains"
    }
  ];

  public async getDiscounts(getAll: boolean, query?: HttpQuery) {
    this.loadDispatcher = "DiscountManagerModule/loadDiscounts";
    if (getAll) {
      delete this.query["q[name_contains]"];
    }
    this.query["q[is_system_equals]"] = 0;
    this.query["q[discount_type_noteq]"] = "MEMBER_LEVEL_DISCOUNT_TYPE";
    const response = await this.get(query);

    return getAll ? response.data : response.data.data;
  }

  public async getDiscount(id: number) {
    this.uri = `/sale/discount/discounts`;
    const response = await this.findById(id);
    return response;
  }

  public async voucherDiscount(ticketAmount: number) {
    this.uri = "/sale/discount/discounts";
    const query = {
      "q[print_discount_if_spent_amount_less_than_or_equal]": ticketAmount
    };
    try {
      const response = await this.get(query);
      return response.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return false;
    }
  }
}

export const discountManagerService = new DiscountManagerService();
