import {
  Batch,
  CustomBatch,
  ProductBatch,
  ProductVariantModel
} from "@/interfaces/batch";
import { store } from "@/internal";
import { batchService } from "@/services/batch.service";
import { messagesService } from "@/services/messages.service";
import { productTypesService } from "@/services/type.service";
import { BatchState } from "@/vuex/modules/inventory/batch/batch.types";
import { RootState } from "@/vuex/types";
import { TablePagination } from "helix-vue-components";
import { ActionContext, ActionTree } from "vuex";

type BatchActionContext = ActionContext<BatchState, RootState>;
type BatchActionTree = ActionTree<BatchState, RootState>;

export const actions: BatchActionTree = {
  async findBatch(
    context: BatchActionContext,
    dataRequest: {
      uid: string;
      route?: string;
    }
  ): Promise<any> {
    try {
      context.commit("setLoading", true);
      const batch: Batch | null = await batchService.findBatchByUID(
        dataRequest.uid
      );
      context.commit("setCurrentBatch", batch);
    } catch (error) {
      store.dispatch("RouterModule/errorRedirect", { error });
    } finally {
      context.commit("setLoading", false);
    }
  },
  async deleteBatch(context: BatchActionContext, batch: Batch): Promise<any> {
    try {
      await batchService.delete(batch);
      messagesService.renderSuccessMessage("batch_deleted");
      store.dispatch("RouterModule/back");
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  },

  async loadProductTypes(context: BatchActionContext): Promise<any> {
    try {
      const payload = await productTypesService.get();
      context.commit("setProductTypes", payload);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  },

  async findSampleResultProfiles(
    context: BatchActionContext,
    profileName: string
  ): Promise<any> {
    try {
      const query =
        typeof profileName === "number"
          ? { "q[id_equals]": profileName }
          : { "q[name_contains]": profileName };
      const resultProfiles = await batchService.getSampleResultProfiles(query);
      context.commit("setSampleResultProfiles", resultProfiles);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  },

  unsetCurrentBatch(context: BatchActionContext) {
    context.commit("setCurrentBatch", null);
  },
  async loadProductBatches(
    context: BatchActionContext,
    data: {
      productSKU: number;
      showZeros: boolean;
      pagination?: TablePagination;
      sort?: string;
    }
  ): Promise<any> {
    try {
      context.commit("setLoading", true);
      const payload = await batchService.getBatchesProduct(
        data.productSKU,
        data.showZeros,
        data.pagination,
        data.sort
      );
      const batches = payload.data.map((batch: ProductBatch) => {
        batch.total_quantity = `${batch.in_store_quantity_value} ${
          batch.in_store_quantity_unit
        }`;
        return batch;
      });
      context.commit(
        "setProductBatchesPagination",
        batchService.getPaginationLite(payload)
      );
      context.commit("setProductBatches", batches);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setLoading", false);
    }
  },
  async setAdjustBatch(context: BatchActionContext, data: CustomBatch) {
    context.commit("setAdjustBatch", data);
  }
};
