import { SearchQuery } from "@/interfaces/httpQuery";
import { Location } from "@/interfaces/location";
import { store } from "@/internal";
import { messagesService } from "@/services/messages.service";
import HttpService from "./http.service";

class LocationService extends HttpService {
  protected loadDispatcher: string = "LocationModule/loadLocations";
  protected defaultSort: string = "";
  protected uri = `${process.env.VUE_APP_CORE_URL}/locations`;
  protected searchableField: SearchQuery[] = [
    {
      field: "name",
      type: "contains"
    }
  ];

  public async get(): Promise<Location[]> {
    this.query.embed = "state";
    const response = await super.get(this.query);
    const payload: Location[] = response.data.data;
    this.updateCurrentLocation(payload);
    return payload;
  }

  public async search(value: string = ""): Promise<Location[]> {
    const query = this.query;
    query["q[name_contains]"] = value;
    const response = await super.get(query);
    const payload: Location[] = response.data.data;
    return payload;
  }

  public async getAll(query: { [key: string]: any } = {}): Promise<Location[]> {
    const locations = await super.get(query || { no_pagination: true });
    return locations.data.data || locations.data;
  }
  public async updateLocation(
    model: Location,
    location: Location
  ): Promise<Location | undefined> {
    try {
      this.uri = `${process.env.VUE_APP_CORE_URL}/locations`;

      const resp = await super.put(model, location);
      this.get();
      return resp.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }

  public async find(
    id: number,
    query: { [key: string]: any } = {}
  ): Promise<Location | null> {
    return await super.findById(id, query);
  }

  public changeQuery(route?: string) {
    route === "sales-limit"
      ? (this.query.embed = "rules")
      : delete this.query.embed;
  }

  private updateCurrentLocation(locations: Location[]) {
    const dispatcher = "AuthModule/setCurrentLocation";
    const current = store.getters["AuthModule/currentLocation"];
    const update = current
      ? locations.find((location: Location) => location.id === current.id)
      : null;
    if (update) {
      store.dispatch(dispatcher, update);
    }
  }
}

export const locationService: LocationService = new LocationService();
