import { Vue } from "vue-property-decorator";
import SecurityPinModalComponent from "./SecurityPinModal/SecurityPin.modal";

export class SecurityPinService extends Vue {
  public ensure(
    title: string,
    description: string | { text: string; translateParams?: object }
  ) {
    description =
      typeof description === "string" ? { text: description } : description;
    return this.openPinModal(title, description);
  }

  private openPinModal(
    title: string = "Security Pin",
    description: { text: string; translateParams?: object }
  ): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const pin = await this.$modals.load<string>(
          SecurityPinModalComponent,
          {
            title,
            size: "fit",
            positionY: "top"
          },
          {
            description
          }
        );
        resolve(pin);
      } catch (e) {
        reject(e);
      }
    });
  }
}
