import { Batch, ProductBatch, SampleResultProfile } from "@/interfaces/batch";
import { Type } from "@/interfaces/type";
import { BatchState } from "@/vuex/modules/inventory/batch/batch.types";
import { TablePagination } from "helix-vue-components";
import { MutationTree } from "vuex";

type BatchMutationTree = MutationTree<BatchState>;

export const mutations: BatchMutationTree = {
  setProductTypes(state: BatchState, productTypes: Type[]) {
    state.productTypes = productTypes;
  },
  setProductBatches(state: BatchState, batches: ProductBatch[]) {
    state.productBatches = batches;
  },
  setLoading(state: BatchState, loading: boolean) {
    state.loading = loading;
  },
  setProductBatchesPagination(
    state: BatchState,
    pagination: TablePagination | null
  ) {
    state.productBatchesPagination = pagination;
  },
  setCurrentBatch(state: BatchState, batch: Batch | null) {
    state.currentBatch = batch;
  },
  setSampleResultProfiles(
    state: BatchState,
    sampleResultProfiles: SampleResultProfile[]
  ) {
    state.sampleResultProfiles = sampleResultProfiles;
  },
  setAdjustBatch(state: BatchState, data) {
    state.adjustBatch = data;
  }
};
