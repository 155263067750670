import { Notification, NotificationDefault } from "@/interfaces/notification";
import { EventBus } from "@/internal";
import { Component, Vue, Watch } from "vue-property-decorator";
import Template from "./notify.template.vue";
@Component({
  mixins: [Template]
})
export default class NotifyComponent extends Vue {
  public notify: boolean = false;
  public notifyParams: Notification = NotificationDefault;

  public showNotification(params: Notification): void {
    if (typeof params.text === "string") {
      params.text = [params.text];
    }

    this.notifyParams = Object.assign({ ...NotificationDefault }, params);
    this.notify = true;
  }

  public hideNotify() {
    this.notify = false;
  }

  @Watch("notify")
  protected resetToDefaults(isOpen: boolean) {
    if (!isOpen) {
      this.notifyParams = Object.assign({}, { ...NotificationDefault });
    }
  }

  protected created(): void {
    EventBus.$on("notify", this.showNotification);
  }
}
