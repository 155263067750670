import { policyList } from "@/enums/permissions";
import { store } from "@/internal";
import Vue from "vue";
import Router from "vue-router";
import RouterUtils from "./router.utils";

Vue.use(Router);

const routerUtils = new RouterUtils();

export const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      redirect: "/dashboard"
    },
    {
      path: "/dashboard",
      name: "dashboard",
      component: () =>
        import(/* webpackChunkName: "dashboard" */ "@/components/dashboard/Dashboard.component")
    },
    {
      path: "/profile",
      name: "profile",
      beforeEnter: () => {
        window.location.assign(
          `${process.env.VUE_APP_CORE_FRONTEND}/settings/profile-management/${
            // @ts-ignore
            store.getters["AuthModule/user"].id
          }/edit`
        );
      }
    },
    {
      path: "/time-clock",
      name: "time-clock",
      component: () =>
        import(/* webpackChunkName: "time-clock" */ "@/components/timeclock/Timeclock.component"),
      redirect: "time-clock/",
      children: [
        {
          path: "/",
          name: "time-clock-manager",
          component: () =>
            import(/* webpackChunkName: "time-clock-manager" */ "@/components/timeclock/timeclockManager/TimeclockManager.component"),
          beforeEnter: (to, from, next) => {
            routerUtils.isAllowedGuard(policyList.viewTimeClockManager, next);
          }
        },
        {
          path: "add",
          name: "add-time-clock",
          component: () =>
            import(/* webpackChunkName: "time-clock-form" */ "@/components/timeclock/timeclockForm/TimeclockForm.component"),
          beforeEnter: (to, from, next) => {
            routerUtils.isAllowedGuard(policyList.viewTimeClockManager, next);
          }
        },
        {
          path: `:id(${routerUtils.idRegex})/edit`,
          name: "time-clock-edit",
          component: () =>
            import(/* webpackChunkName: "time-clock-form" */ "@/components/timeclock/timeclockForm/TimeclockForm.component"),
          beforeEnter: (to, from, next) => {
            routerUtils.isAllowedGuard(policyList.viewTimeClockManager, next);
          }
        },
        {
          path: `:id(${routerUtils.idRegex})/change`,
          name: "time-clock-change",
          component: () =>
            import(/* webpackChunkName: "time-clock-change-request" */ "@/components/timeclock/timeclockChangeRequest/TimeclockChangeRequest.component")
        }
      ]
    },
    {
      path: "/inventory",
      component: () =>
        import(/* webpackChunkName: "inventory" */ "@/components/inventory/inventory.component"),
      children: [
        {
          path: "",
          name: "inventory-view",
          component: () =>
            import(/* webpackChunkName: "inventory-view" */ "@/components/inventory/inventoryView/InventoryView.component")
        },
        {
          path: "products",
          name: "products",
          component: () =>
            import(/* webpackChunkName: "products" */ "@/components/inventory/Products/Products.component"),
          beforeEnter: (to, from, next) => {
            routerUtils.isAllowedGuard(policyList.viewProducts, next);
          }
        },
        {
          path: "batch-adjust",
          name: "adjust",
          component: () =>
            import(/* webpackChunkName: "adjust" */ "@/components/batch/adjust/Adjust.component"),
          props: true,
          beforeEnter: (to, from, next) => {
            routerUtils.batchExist(to, next);
          }
        },
        {
          path: "convert",
          name: "convert",
          component: () =>
            import(/* webpackChunkName: "convert" */ "@/components/batch/convert/BatchConvert.component"),
          props: true,
          beforeEnter: (to, from, next) => routerUtils.batchExist(to, next)
        },
        {
          path: "batch-move",
          name: "batch-move",
          component: () =>
            import(/* webpackChunkName: "move" */ "@/components/batch/move/BatchMove.component"),
          props: true,
          beforeEnter: (to, from, next) => routerUtils.batchExist(to, next)
        },
        {
          path: "batch-split",
          name: "split",
          component: () =>
            import(/* webpackChunkName: "split" */ "@/components/batch/split/SplitBatch.component"),
          beforeEnter: (to, from, next) => {
            routerUtils.batchExist(to, next);
          }
        },
        {
          path: "manifest/:id?",
          name: "manifest",
          component: () =>
            import(/* webpackChunkName: "batch-transfer-manifest" */ "@/components/inventory/BatchTransfer/Manifest/Manifest.component"),
          props: true,
          beforeEnter: (to, from, next) => {
            if (to.params.id) {
              routerUtils.isAllowedGuard(policyList.modifyManifest, next);
            } else {
              routerUtils.isAllowedGuard(policyList.viewManifestsTab, next);
            }
          }
        },
        {
          path: "vehicles",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "vehicle-list",
              component: () =>
                import(/* webpackChunkName: "vehicle-list" */ "@/components/inventory/Vehicle/VehicleList/VehicleList.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(policyList.viewVehicles, next);
              }
            },
            {
              path: "add",
              name: "vehicle-add",
              component: () =>
                import(/* webpackChunkName: "vehicle" */ "@/components/inventory/Vehicle/NewVehicle/NewVehicle.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(policyList.createVehicles, next);
              }
            },
            {
              path: `:id(${routerUtils.idRegex})`,
              name: "vehicle-edit",
              component: () =>
                import(/* webpackChunkName: "vehicle" */ "@/components/inventory/Vehicle/NewVehicle/NewVehicle.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(policyList.modifyVehicles, next);
              }
            }
          ]
        },
        {
          path: "products/add",
          name: "products-add",
          component: () =>
            import(/* webpackChunkName: "products-form" */ "@/components/inventory/ProductForm/ProductForm.component"),
          beforeEnter: (to, from, next) => {
            routerUtils.isAllowedGuard(policyList.createProducts, next);
          }
        },
        {
          path: `products/:id`,
          name: "products-edit",
          component: () =>
            import(/* webpackChunkName: "products-form" */ "@/components/inventory/ProductForm/ProductForm.component")
        },
        {
          path: `batch/:id/details`,
          name: "batch-details",
          component: () =>
            import(/* webpackChunkName: "batch-details" */ "@/components/inventory/batchForm/BatchForm.component"),
          beforeEnter: (to, from, next) => {
            routerUtils.isAllowedGuard(policyList.viewBatchDetails, next);
          }
        },
        {
          path: "batch/transfer/:transfer_id/invoice/:invoice_id/add_payment",
          name: "invoice_add_payment",
          component: () =>
            import(/* webpackChunkName: "batch-transfer" */ "@/components/inventory/BatchTransfer/Invoice/AddPayment/AddPayment.component"),
          beforeEnter: (to, from, next) => {
            routerUtils.isAllowedGuard(policyList.addPayments, next);
          }
        },
        {
          path:
            "batch/transfer/:type(purchase-order|quote|inbound-transfer|outbound-transfer|invoice|manifest)?",
          name: "batch-transfer",
          component: () =>
            import(/* webpackChunkName: "batch-transfer-list" */ "@/components/inventory/BatchTransfer/BatchTransferList/BatchTransferList.component"),
          beforeEnter: (to, from, next) => {
            routerUtils.allowedOnceIn(routerUtils.trasferTabsPolicies, next);
          }
        },
        {
          path:
            "batch/transfer/:type(purchase-order|quote|inbound-transfer|outbound-transfer|invoice|manifest|new-batch)/new",
          name: "transfer-manager",
          component: () =>
            import(/* webpackChunkName: "batch-transfer-manager" */ "@/components/inventory/BatchTransfer/BatchTransferManager/BatchTransferManager.component"),
          props: true,
          beforeEnter: (to, from, next) => {
            routerUtils.isAllowedGuard(
              routerUtils.transfersTypePolicies[to.params.type],
              next
            );
          }
        },
        {
          path: `batch/transfer/:type(purchase-order|quote|inbound-transfer)/:id(${
            routerUtils.idRegex
          })`,
          name: "transfer-manager-edit",
          component: () =>
            import(/* webpackChunkName: "batch-transfer-manager" */ "@/components/inventory/BatchTransfer/BatchTransferManager/BatchTransferManager.component"),
          beforeEnter: (to, from, next) => {
            routerUtils.isAllowedGuard(
              routerUtils.transfersTypePoliciesEdit[to.params.type],
              next
            );
          }
        },
        {
          path: "batch-combine",
          name: "batch-combine",
          component: () =>
            import(/* webpackChunkName: "batch-details" */ "@/components/batch/combine/BatchCombine.component"),
          props: true,
          beforeEnter: (to, from, next) => routerUtils.batchExist(to, next)
        },
        {
          path: "pricing",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "pricing-tools",
              component: () =>
                import(/* webpackChunkName: "pricing" */ "@/components/inventory/pricingView/PricingView.component")
            },
            {
              path: "options",
              name: "pricing-options",
              component: () =>
                import(/* webpackChunkName: "pricing-option" */ "@/components/inventory/pricingOptions/PricingOption.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(policyList.viewPricingOptions, next);
              }
            },
            {
              path: "price-groups",
              name: "price-groups",
              component: () =>
                import(/* webpackChunkName: "price-groups" */ "@/components/inventory/priceGroups/PriceGroups.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(policyList.viewPriceGroup, next);
              }
            },
            {
              path: "price-groups/add",
              name: "price-groups-add",
              component: () =>
                import(/* webpackChunkName: "price-group-form" */ "@/components/inventory/priceGroups/priceGroupsForm/PriceGroupsForm.component")
            },
            {
              path: "inbound-transfer/print-label",
              name: "inbound-transfer-print-label",
              component: () =>
                import(/* webpackChunkName: "print-label" */ "@/components/inventory/BatchTransfer/BatchTransferList/InboundTransfersDataTable/printLabel/PrintLabel.component")
            },
            {
              path: `price-groups/:id/edit`,
              name: "price-groups-edit",
              component: () =>
                import(/* webpackChunkName: "price-group-form" */ "@/components/inventory/priceGroups/priceGroupsForm/PriceGroupsForm.component"),
              props: true
            },
            {
              path: "member-level",
              name: "member-level-list",
              component: () =>
                import(/* webpackChunkName: "member-level-pricing-list" */ "@/components/inventory/pricingView/memberLevelPricing/memberLevelPricingList/MemberLevelPricingList.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(
                  policyList.viewMemberLevelPricing,
                  next
                );
              }
            },
            {
              path: "member-level/add",
              name: "member-level-add",
              component: () =>
                import(/* webpackChunkName: "member-level-princing-form" */ "@/components/inventory/pricingView/memberLevelPricing/memberLevelPricingForm/MemberLevelPricingForm.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(
                  policyList.createMemberLevelPricing,
                  next
                );
              }
            },
            {
              path: `member-level/:id(${routerUtils.idRegex})/edit`,
              name: "member-level-edit",
              component: () =>
                import(/* webpackChunkName: "member-level-princing-form" */ "@/components/inventory/pricingView/memberLevelPricing/memberLevelPricingForm/MemberLevelPricingForm.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(
                  policyList.modifyMemberLevelPricing,
                  next
                );
              }
            },
            {
              path: `member-level/:id(${routerUtils.idRegex})/view`,
              name: "member-level-view",
              component: () =>
                import(/* webpackChunkName: "member-level-princing-form" */ "@/components/inventory/pricingView/memberLevelPricing/memberLevelPricingForm/MemberLevelPricingForm.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(
                  policyList.viewMemberLevelPricing,
                  next
                );
              }
            },
            {
              path: "batch-level",
              name: "batch-level-list",
              component: () =>
                import(/* webpackChunkName: "batch-level-pricing-list" */ "@/components/inventory/pricingView/batchLevelPricing/batchLevelPricingList/BatchLevelPricingList.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(
                  policyList.viewBatchLevelPricing,
                  next
                );
              }
            },
            {
              path: "batch-level/add",
              name: "batch-level-add",
              component: () =>
                import(/* webpackChunkName: "batch-level-pricing-form" */ "@/components/inventory/pricingView/batchLevelPricing/batchLevelPricingForm/BatchLevelPricingForm.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(
                  policyList.createBatchLevelPricing,
                  next
                );
              }
            },
            {
              path: `batch-level/:id/edit`,
              name: "batch-level-edit",
              component: () =>
                import(/* webpackChunkName: "batch-level-pricing-form" */ "@/components/inventory/pricingView/batchLevelPricing/batchLevelPricingForm/BatchLevelPricingForm.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(
                  policyList.modifyBatchLevelPricing,
                  next
                );
              }
            },
            {
              path: `batch-level/:id(${routerUtils.idRegex})/view`,
              name: "batch-level-view",
              component: () =>
                import(/* webpackChunkName: "batch-level-pricing-form" */ "@/components/inventory/pricingView/batchLevelPricing/batchLevelPricingForm/BatchLevelPricingForm.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(
                  policyList.viewBatchLevelPricing,
                  next
                );
              }
            },
            {
              path: "points",
              name: "pricing-points",
              component: () =>
                import(/* webpackChunkName: "pricepoint" */ "@/components/inventory/pricePoint/PricePoint.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(policyList.pricePointsTool, next);
              }
            }
          ]
        },
        {
          path: "brands",
          component: () =>
            import(/* webpackChunkName: "brands" */ "@/components/inventory/brands/brands.component"),
          children: [
            {
              path: "",
              name: "brands",
              component: () =>
                import(/* webpackChunkName: "brands-list" */ "@/components/inventory/brands/brandList/BrandList.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(policyList.viewBrands, next);
              }
            },
            {
              path: "add",
              name: "brand-add",
              component: () =>
                import(/* webpackChunkName: "brands-edit" */ "@/components/inventory/brands/brandEdit/BrandEdit.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(policyList.createBrands, next);
              }
            },
            {
              path: `:id/edit`,
              name: "brand-edit",
              component: () =>
                import(/* webpackChunkName: "brands-edit" */ "@/components/inventory/brands/brandEdit/BrandEdit.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(policyList.modifyBrands, next);
              }
            },
            {
              path: `:id(${routerUtils.idRegex})/view`,
              name: "brand-view",
              component: () =>
                import(/* webpackChunkName: "brands-edit" */ "@/components/inventory/brands/brandEdit/BrandEdit.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(policyList.viewBrands, next);
              }
            }
          ]
        },
        {
          path: "tax-categories",
          name: "tax-categories",
          component: () =>
            import(/* webpackChunkName: "tax-categories" */ "@/components/inventory/pricingView/taxCategories/TaxCategories.component"),
          children: [
            {
              path: "/inventory/tax-categories",
              name: "tax-category-list",
              component: () =>
                import(/* webpackChunkName: "tax-categories-list" */ "@/components/inventory/pricingView/taxCategories/taxCategoryList/TaxCategoryList.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(policyList.viewTaxCategories, next);
              }
            },
            {
              path: `:id(${routerUtils.idRegex})/edit`,
              name: "tax-category-edit",
              component: () =>
                import(/* webpackChunkName: "tax-catagory-form" */ "@/components/inventory/pricingView/taxCategories/taxCategoryForm/TaxCategoryForm.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(
                  policyList.modifyTaxCategories,
                  next
                );
              }
            },
            {
              path: `:id(${routerUtils.idRegex})/view`,
              name: "tax-category-view",
              component: () =>
                import(/* webpackChunkName: "tax-catagory-form" */ "@/components/inventory/pricingView/taxCategories/taxCategoryForm/TaxCategoryForm.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(policyList.viewTaxCategories, next);
              }
            },
            {
              path: "add",
              name: "tax-category-add",
              component: () =>
                import(/* webpackChunkName: "tax-category-form" */ "@/components/inventory/pricingView/taxCategories/taxCategoryForm/TaxCategoryForm.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(
                  policyList.createTaxCategories,
                  next
                );
              }
            }
          ]
        },
        {
          path: "products-view/:query?",
          name: "products-view",
          component: () =>
            import(/* webpackChunkName: "products-view" */ "@/components/inventory/ProductView/ProductView.component"),
          beforeEnter: (to, from, next) => {
            routerUtils.isAllowedGuard(policyList.viewCurrentInventory, next);
          }
        },
        {
          path: "biotrack-inventory-reconciliation",
          component: () =>
            import(/*webpackChunkName: "biotrack-inventory-reconciliation"*/ "@/components/inventory/traceability/inventoryReconciliation.component"),
          beforeEnter: (to, from, next) => {
            routerUtils.isAllowedGuard(policyList.viewCurrentInventory, next);
          },
          children: [
            {
              path: "",
              name: "biotrack-inventory-reconciliation",
              component: () =>
                import(/* webpackChunkName: "biotrack-inventory-reconciliation" */ "@/components/inventory/traceability/inventoryReconciliation/BiotrackInventoryReconciliation.component")
            },
            {
              path: "biotrack-add-batch-reconciliation",
              name: "biotrack-add-batch-reconciliation",
              component: () =>
                import(/* webpackChunkName: "biotrack-batch-reconciliation" */ "@/components/inventory/traceability/CreateBatchReconciliation/CreateBatchReconciliation.component")
            },
            {
              path: "biotrack-batch-reconciliation",
              name: "biotrack-batch-reconciliation",
              component: () =>
                import(/* webpackChunkName: "biotrack-batch-reconciliation" */ "@/components/inventory/traceability/batchReconciliation/biotrackBatchReconciliation.component")
            }
          ]
        },
        {
          path: "strains",
          component: () =>
            import(/* webpackChunkName: "strains" */ "@/components/inventory/strain/Strain.component"),
          children: [
            {
              path: "",
              name: "strains",
              component: () =>
                import(/*webpackChunkName: "strains-list" */ "@/components/inventory/strain/strainsList/StrainsList.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(policyList.viewStrains, next);
              }
            },
            {
              path: "add",
              name: "add-strain",
              component: () =>
                import(/* webpackChunkName: "strains-form" */ "@/components/inventory/strain/strainForm/StrainForm.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(policyList.createStrains, next);
              }
            },
            {
              path: `:id/edit`,
              name: "edit-strain",
              component: () =>
                import(/* webpackChunkName: "strains-form" */ "@/components/inventory/strain/strainForm/StrainForm.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(policyList.modifyStrains, next);
              }
            },
            {
              path: `:id(${routerUtils.idRegex})/view`,
              name: "view-strain",
              component: () =>
                import(/* webpackChunkName: "strains-form" */ "@/components/inventory/strain/strainForm/StrainForm.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(policyList.viewStrains, next);
              }
            }
          ]
        },
        {
          path: "audit",
          component: () =>
            import(/* webpackChunkName: "audit" */ "@/components/inventory/Audit/Audit.component"),
          children: [
            {
              path: "",
              name: "audit",
              component: () =>
                import(/*webpackChunkName: "audit-list" */ "@/components/inventory/Audit/auditList/AuditList.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(policyList.viewInventoryAudit, next);
              }
            },
            {
              path: "new",
              name: "audit-add",
              component: () =>
                import(/*webpackChunkName: "audit-form" */ "@/components/inventory/Audit/auditDetails/AuditDetails.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(
                  policyList.createInventoryAudit,
                  next
                );
              }
            },
            {
              path: ":operationId",
              name: "audit-edit",
              props: {
                edit: true
              },
              component: () =>
                import(/*webpackChunkName: "audit-form" */ "@/components/inventory/Audit/auditDetails/AuditDetails.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(
                  policyList.modifyInventoryAudit,
                  next
                );
              }
            },
            {
              path: ":operationId",
              name: "audit-view",
              props: {
                edit: true
              },
              component: () =>
                import(/*webpackChunkName: "audit-form" */ "@/components/inventory/Audit/auditDetails/AuditDetails.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(policyList.viewInventoryAudit, next);
              }
            }
          ]
        },
        {
          path: "inventory-settings",
          name: "inventory-settings",
          component: () =>
            import(/* webpackChunkName: "inventory-settings" */ "@/components/inventory/InventorySettings/InventorySettings.component"),
          beforeEnter: (to, from, next) => {
            routerUtils.allowedOnceIn(
              [
                policyList.viewInventorySettings,
                policyList.modifyInventorySettings
              ],
              next
            );
          }
        },
        {
          path: "rooms/",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "room-list",
              component: () =>
                import(/*webpackChunkName: "room-list" */ "@/components/inventory/room/roomList/RoomList.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(policyList.viewInventoryRooms, next);
              }
            }
          ]
        },
        {
          path: "product-category",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "product-category",
              component: () =>
                import(/* webpackChunkName: "product-category-list" */ "@/components/inventory/productCategory/productCategoryList/ProductCategoryList.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(
                  policyList.viewProductsCategories,
                  next
                );
              }
            },
            {
              path: "add",
              name: "product-category-add",
              component: () =>
                import(/* webpackChunkName: "product-category-form" */ "@/components/inventory/productCategory/productCategoryForm/ProductCategoryForm.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(
                  policyList.createProductsCategories,
                  next
                );
              }
            }
          ]
        },
        {
          path: "batch-types",
          name: "batch-types",
          component: () =>
            import(/* webpackChunkName: "batch-types" */ "@/components/inventory/BatchTypes/BatchTypes.component"),
          beforeEnter: (to, from, next) => {
            routerUtils.isAllowedGuard(policyList.viewBatchTypes, next);
          }
        },
        {
          path: "vendor",
          component: () =>
            import(/* webpackChunkName: "vendor-labs" */ "@/components/vendor/vendor.component"),
          children: [
            {
              path: "",
              name: "vendor-list",
              component: () =>
                import(/*webpackChunkName: "vendor-labs-list" */ "@/components/vendor/vendorList/vendorList.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(policyList.viewVendorsLabs, next);
              }
            },
            {
              path: "add",
              name: "vendor-add",
              component: () =>
                import(/*webpackChunkName: "vendor-labs-form" */ "@/components/vendor/vendorForm/vendorForm.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(policyList.createVendorsLabs, next);
              }
            },
            {
              path: `:id/edit`,
              name: "vendor-edit",
              props: {
                edit: true
              },
              component: () =>
                import(/*webpackChunkName: "vendor-labs-form" */ "@/components/vendor/vendorForm/vendorForm.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(policyList.modifyVendorsLabs, next);
              }
            },
            {
              path: `:id(${routerUtils.idRegex})/view`,
              name: "vendor-view",
              props: {
                edit: true
              },
              component: () =>
                import(/*webpackChunkName: "vendor-labs-form" */ "@/components/vendor/vendorForm/vendorForm.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(policyList.viewVendorsLabs, next);
              }
            }
          ]
        },
        {
          path: "drivers",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "drivers-list",
              component: () =>
                import(/* webpackChunkName: "drivers-list" */ "@/components/inventory/driversList/DriversList.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(policyList.viewDrivers, next);
              }
            },
            {
              path: "add",
              name: "driver-add",
              component: () =>
                import(/* webpackChunkName: "driver" */ "@/components/inventory/driver/Driver.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(policyList.createDrivers, next);
              }
            },
            {
              path: `:id(${routerUtils.idRegex})`,
              name: "driver-edit",
              component: () =>
                import(/* webpackChunkName: "driver" */ "@/components/inventory/driver/Driver.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(policyList.modifyDrivers, next);
              }
            }
          ]
        }
      ]
    },
    {
      path: "/locations",
      component: () =>
        import(/* webpackChunkName: "locations" */ "@/components/locations/Locations.component"),
      children: [
        {
          path: "",
          name: "locations-menu",
          component: () =>
            import(/* webpackChunkName: "location-menu" */ "@/components/locations/locationMenu/LocationMenu.component")
        },
        {
          path: "sales-limit",
          name: "sales-limit",
          component: () =>
            import(/* webpackChunkName: "location-sales-limit" */ "@/components/locations/salesLimit/SalesLimit.component"),
          beforeEnter: (to, from, next) => {
            routerUtils.isAllowedGuard(policyList.viewSalesLimits, next);
          }
        },
        {
          path: "import-export-wizard",
          name: "import-export-wizard",
          component: () =>
            import(/* webpackChunkName: "import-export-wizard" */ "@/components/locations/importExportWizard/ImportExportWizard.component")
        },
        {
          path:
            "import-wizard/:type(batch-types|product-categories|brands|strains|vendors-laboratories|products|customers|lab-results)",
          name: "import-wizard",
          component: () =>
            import(/* webpackChunkName: "import-wizard" */ "@/components/locations/importExportWizard/importWizard/ImportWizard.component"),
          beforeEnter: (to, from, next) => {
            routerUtils.isAllowedGuard(
              routerUtils.importPolicies[to.params.type],
              next
            );
          }
        },
        {
          path: "template-manager",
          component: () =>
            import(/* webpackChunkName: "template-manager" */ "@/components/locations/templateManager/TemplateManagerParent.component"),
          children: [
            {
              path: "",
              name: "template-manager",
              component: () =>
                import(/* webpackChunkName: "template-manager-list" */ "@/components/locations/templateManager/TemplateManager.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(policyList.viewTemplates, next);
              }
            },
            {
              path: `:type(RECEIPT|BARCODE|INVENTORY|CUSTOMER)/:id(${
                routerUtils.idRegex
              })`,
              name: "template-edit",
              component: () =>
                import(/* webpackChunkName: "template-editor" */ "@/components/template/Template.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(policyList.modifyTemplates, next);
              }
            },
            {
              path: `:type(RECEIPT|BARCODE|INVENTORY|CUSTOMER)`,
              name: "template-add",
              component: () =>
                import(/* webpackChunkName: "template-editor" */ "@/components/template/Template.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(policyList.createTemplates, next);
              }
            }
          ]
        },
        {
          path: "reservations",
          name: "reservations",
          component: () =>
            import(/* webpackChunkName: "reservations" */ "@/components/inventory/Reservations/Reservations.component"),
          beforeEnter: (to, from, next) => {
            routerUtils.isAllowedGuard(policyList.viewReservations, next);
          }
        }
      ]
    },
    {
      path: "/retail",
      component: () =>
        import(/* webpackChunkName: "retail" */ "@/components/retail/Retail.component"),
      children: [
        {
          path: "",
          name: "landing",
          component: () =>
            import(/* webpackChunkName: "retail-landing" */ "@/components/retail/retailLanding/RetailLanding.component")
        },
        {
          path: "customers-management",
          name: "customers-management",
          component: () =>
            import(/* webpackChunkName: "customers-management" */ "@/components/retail/customers/customersManagement/listManagement/ListManagement.component"),
          beforeEnter: (to, from, next) => {
            routerUtils.isAllowedGuard(policyList.accessCustomersList, next);
          }
        },
        {
          path: "customers",
          component: () =>
            import(/* webpackChunkName: "customers" */ "@/components/retail/customers/Customer.component"),
          children: [
            {
              path: "",
              name: "customers-list",
              component: () =>
                import(/* webpackChunkName: "customers-list" */ "@/components/retail/customers/customersList/CustomersList.component")
            },
            {
              path: "add",
              name: "customers-add",
              component: () =>
                import(/* webpackChunkName: "customers-form" */ "@/components/retail/customers/customersForm/CustomersForm.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(
                  (from.name === "check-in" &&
                    policyList.createModifyCustomers) ||
                    policyList.createCustomers,
                  next
                );
              }
            }
          ]
        },

        {
          path: `customers/:id(${routerUtils.idRegex})`,
          redirect: `customers/:id(${routerUtils.idRegex})/edit`
        },
        {
          path: `customers/:id/edit`,
          name: "customers-edit",
          component: () =>
            import(/* webpackChunkName: "customers-form" */ "@/components/retail/customers/customersForm/CustomersForm.component"),
          props: true,
          beforeEnter: (to, from, next) => {
            routerUtils.isAllowedGuard(
              (from.name === "customers-management" &&
                policyList.modifyCustomerProfileDetails) ||
                policyList.createModifyCustomers,
              next
            );
          }
        },
        {
          path: `customers/:id/view`,
          name: "customers-view",
          component: () =>
            import(/* webpackChunkName: "customers-form" */ "@/components/retail/customers/customersForm/CustomersForm.component"),
          props: true,
          beforeEnter: (to, from, next) => {
            routerUtils.isAllowedGuard(
              (from.name === "customers-management" &&
                policyList.viewCustomersList) ||
                policyList.accessCheckInList,
              next
            );
          }
        },
        {
          path: "check-in",
          name: "check-in",
          component: () =>
            import(/* webpackChunkName: "check-in" */ "@/components/retail/checkIn/CheckIn.component"),
          beforeEnter: (to, from, next) => {
            routerUtils.isAllowedGuard(policyList.accessCheckInList, next);
          }
        },
        {
          path: "pos",
          component: () =>
            import(/* webpackChunkName: "pos" */ "@/components/retail/pos/Pos.component"),
          beforeEnter: async (to, from, next) => {
            routerUtils.tillGuard(policyList.accessPointOfSale, from, next);
          },
          children: [
            {
              name: "point-of-sale",
              path: "",
              component: () =>
                import(/* webpackChunkName: "point-of-sale" */ "@/components/retail/pos/pointOfSale/PointOfSale.component")
            },
            {
              path: "check-out",
              name: "check-out",
              component: () =>
                import(/* webpackChunkName: "check-out" */ "@/components/retail/pos/checkOut/CheckOut.component")
            },
            {
              path: "customers",
              component: () =>
                import(/* webpackChunkName: "pos-customers" */ "@/components/retail/pos/customers/PosCustomer.component"),
              children: [
                {
                  path: "",
                  props: true,
                  name: "pos-customers-list",
                  component: () =>
                    import(/* webpackChunkName: "customers-list" */ "@/components/retail/customers/customersList/CustomersList.component")
                },
                {
                  path: "add",
                  name: "pos-customers-add",
                  component: () =>
                    import(/* webpackChunkName: "customers-form" */ "@/components/retail/customers/customersForm/CustomersForm.component"),
                  beforeEnter: (to, from, next) => {
                    routerUtils.isAllowedGuard(
                      (from.name === "check-in" &&
                        policyList.createModifyCustomers) ||
                        policyList.createCustomers,
                      next
                    );
                  }
                }
              ]
            }
          ]
        },
        {
          path: "sales-history",
          name: "sales-history",
          component: () =>
            import(/* webpackChunkName: "retail-sales-history" */ "@/components/retail/salesHistory/RetailSalesHistory.component"),
          children: [
            {
              path: "sales", // sales
              name: "sales",
              component: () =>
                import(/* webpackChunkName: "retail-sales-history" */ "@/components/retail/salesHistory/RetailSalesHistory.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(
                  policyList.viewRetailSalesHistory,
                  next
                );
              }
            },
            {
              path: "refund", // refund
              name: "refund-history",
              component: () =>
                import(/* webpackChunkName: "retail-sales-history" */ "@/components/retail/salesHistory/RetailSalesHistory.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(
                  policyList.viewRetailSalesHistory,
                  next
                );
              }
            }
          ]
        },
        {
          path: "discount-manager",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "discount-manager",
              component: () =>
                import(/* webpackChunkName: "discount-manager-list" */ "@/components/retail/discountManager/discountManagerList/DiscountManagerList.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(
                  policyList.viewDiscountPrograms,
                  next
                );
              }
            },
            {
              path: "add",
              name: "discount-manager-add",
              component: () =>
                import(/* webpackChunkName: "discount-manager-add" */ "@/components/retail/discountManager/discountManagerAdd/DiscountManagerAdd.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(policyList.createDiscounts, next);
              }
            },
            {
              path: `:id(${routerUtils.idRegex})/edit`,
              name: "discount-manager-edit",
              component: () =>
                import(/* webpackChunkName: "discount-manager-add" */ "@/components/retail/discountManager/discountManagerAdd/DiscountManagerAdd.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(policyList.modifyDiscounts, next);
              }
            }
          ]
        },
        {
          path: "loyalty-programs",
          component: () =>
            import(/* webpackChunkName: "loyalty-programs" */ "@/components/retail/loyaltyProgram/LoyaltyProgram.component"),
          children: [
            {
              path: "",
              name: "loyalty-programs",
              component: () =>
                import(/*webpackChunkName: "loyalty-programs-form" */ "@/components/retail/loyaltyProgram/loyaltyProgramForm/LoyaltyProgramForm.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(
                  policyList.viewLoyaltyPrograms,
                  next
                );
              }
            }
          ]
        },
        {
          path: "options",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "retail-options",
              component: () =>
                import(/* webpackChunkName: "retail-options" */ "@/components/retail/retailOptions/RetailOptions.component")
            },
            {
              path: `doctors/:id(${routerUtils.idRegex})/edit`,
              name: "doctors-edit",
              component: () =>
                import(/* webpackChunkName: "doctors-form" */ "@/components/retail/retailOptions/Doctors/doctorForm/DoctorsForm.component"),
              props: true
            },
            {
              path: "settings",
              name: "retail-settings",
              component: () =>
                import(/*webpackChunkName: "retail-settings" */ "@/components/retail/retailOptions/retailSettings/RetailSettings.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(policyList.viewRetailSettings, next);
              }
            },
            {
              path: "doctors",
              name: "doctors",
              component: () =>
                import(/* webpackChunkName: "doctors" */ "@/components/retail/retailOptions/Doctors/Doctors.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(policyList.viewDoctors, next);
              }
            },
            {
              path: `doctors/add`,
              name: "doctors-add",
              component: () =>
                import(/* webpackChunkName: "doctors-form" */ "@/components/retail/retailOptions/Doctors/doctorForm/DoctorsForm.component"),
              props: true
            }
          ]
        },
        {
          path: "order-fulfillment",
          component: () =>
            import(/* webpackChunkName: "order-fulfillment" */ "@/components/retail/orderFulfillment/OrderFulfillment.component"),
          children: [
            {
              path: "",
              name: "order-fulfillment",
              component: () =>
                import(/* webpackChunkName: "order-fulfillment-list" */ "@/components/retail/orderFulfillment/orderFulfillmentList/OrderFulfillmentList.component"),
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(
                  policyList.accessToOrderFulfillment,
                  next
                );
              }
            }
          ]
        }
      ]
    },
    {
      path: "/system-settings",
      component: () =>
        import(/* webpackChunkName: "system-settings" */ "@/components/systemSettings/SystemSettings.component"),
      children: [
        // {
        //   path: "",
        //   name: "system-settings",
        //   component: () =>
        //     import(/* webpackChunkName: "system-menu" */ "@/components/systemSettings/systemMenuMenu/SystemMenu.component")
        // },
        // {
        //   path: "terminal",
        //   name: "terminal",
        //   component: () =>
        //     import(/* webpackChunkName: "terminal" */ "@/components/systemSettings/terminalSettings/TerminalSettings.component"),
        //   beforeEnter: (to, from, next) => {
        //     routerUtils.isAllowedGuard(policyList.manageTerminals, next);
        //   }
        // },
        // {
        //   path: "add",
        //   name: "add-terminal",
        //   component: () =>
        //     import(/* webpackChunkName: "terminal-details" */ "@/components/systemSettings/terminalDetails/TerminalDetails.component"),
        //   beforeEnter: (to, from, next) => {
        //     routerUtils.isAllowedGuard(policyList.manageTerminals, next);
        //   }
        // },
        // {
        //   path: `:id(${routerUtils.idRegex})/edit`,
        //   name: "edit-terminal",
        //   component: () =>
        //     import(/* webpackChunkName: "terminal-details" */ "@/components/systemSettings/terminalDetails/TerminalDetails.component"),
        //   beforeEnter: (to, from, next) => {
        //     routerUtils.isAllowedGuard(policyList.manageTerminals, next);
        //   }
        // },
        {
          path: "data-sharing-config",
          name: "sharing-config",
          component: () =>
            import(/* webpackChunkName: "data-sharing-config" */ "@/components/systemSettings/sharingConfig/sharingConfig.component"),
          beforeEnter: (to, from, next) => {
            routerUtils.isAllowedGuard(policyList.viewDataSharingOptions, next);
          }
        },
        {
          path: "shared-products",
          name: "shared-products",
          component: () =>
            import(/* webpackChunkName: "shared-products" */ "@/components/systemSettings/sharedProducts/SharedProducts.component"),
          beforeEnter: (to, from, next) => {
            routerUtils.isAllowedGuard(policyList.viewSharedProducts, next);
          }
        },
        {
          path: `products/:id`,
          name: "shared-products-edit",
          component: () =>
            import(/* webpackChunkName: "products-form" */ "@/components/systemSettings/sharedProducts/sharedProductForm/SharedProductForm.component")
        }
      ]
    },
    {
      path: "/reports",
      name: "reports",
      component: () =>
        import(/* webpackChunkName: "reports-list" */ "@/components/reports/ReportsList/ReportList.component"),
      beforeEnter: (to, from, next) => {
        routerUtils.isAllowedGuard(policyList.viewReportsTool, next);
      }
    },
    {
      path: "/money",
      component: () =>
        import(/* webpackChunkName: "money" */ "@/components/money/Money.component"),
      children: [
        {
          path: "",
          name: "money",
          component: () =>
            import(/* webpackChunkName: "money-menu" */ "@/components/money/moneyMenu/MoneyMenu.component")
        },
        {
          path: "manager",
          name: "money-manager",
          component: () =>
            import(/* webpackChunkName: "money-manager" */ "@/components/money/manager/MoneyManager.component")
        },
        {
          path: "close-till",
          name: "close-till",
          component: () =>
            import(/* webpackChunkName: "close-till" */ "@/components/money/closeTill/EmployeeCloseTill.component"),
          beforeEnter: (to, from, next) => {
            routerUtils.tillGuard(policyList.employeeCloseTill, from, next);
          }
        },
        {
          path: "payout-till",
          name: "payout-till",
          component: () =>
            import(/* webapackChunkName: "payout-till" */ "@/components/money/payoutFromTill/payoutFromTill.component"),
          beforeEnter: (to, from, next) => {
            routerUtils.tillGuard(
              policyList.employeePayoutFromTill,
              from,
              next
            );
          }
        }
      ]
    },
    {
      path: "/metrc-tools",
      component: () => import("@/components/metrc/Metrc.component"),
      beforeEnter: (to, from, next) => {
        routerUtils.metrcGuard(to, from, next);
      },
      children: [
        {
          path: "",
          name: "metrc-landing",
          component: () =>
            import(/* webpackChunkName: "metrc-landing" */ "@/components/metrc/landing/MetrcLanding.component"),
          beforeEnter: (to, from, next) => {
            routerUtils.allowedOnceIn(
              [
                policyList.viewModifyStrainReconciliation,
                policyList.viewModifyInventoryReconciliation,
                policyList.viewModifySalesReconciliation,
                policyList.viewMetrcItems,
                policyList.viewModifyTagManager
              ],
              next
            );
          }
        },
        {
          path: "strains-reconciliation",
          name: "strains-reco",
          beforeEnter: (to, from, next) => {
            routerUtils.isAllowedGuard(
              policyList.viewModifyStrainReconciliation,
              next
            );
          },
          component: () =>
            import(/* webpackChunkName: "strains-reco" */ "@/components/metrc/strainsReconciliation/StrainsReconciliation.component")
        },
        {
          path: "inventory-reconciliation",
          beforeEnter: (to, from, next) => {
            routerUtils.isAllowedGuard(
              policyList.viewModifyInventoryReconciliation,
              next
            );
          },
          component: () =>
            import("@/components/metrc/inventoryReconciliation/InventoryReconciliation.component"),
          children: [
            {
              path: "/",
              name: "inv-reco-list",
              component: () =>
                import(/* webpackChunkName: "inventory-reco" */ "@/components/metrc/inventoryReconciliation/reconciliationList/reconciliationList.component")
            },
            {
              path: "create-batch/:packageTag",
              name: "create-batch",
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(policyList.modifyBatchDetails, next);
              },
              component: () =>
                import(/* webpackChunkName: "inventory-reco" */ "@/components/metrc/inventoryReconciliation/CreateBatch/createBatch.component")
            },
            {
              path: "reconcile/:batchuid",
              name: "reco-batch",
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(policyList.modifyBatchDetails, next);
              },
              component: () =>
                import(/* webpackChunkName: "inventory-reco" */ "@/components/metrc/inventoryReconciliation/BatchReconciliation/batchReconciliation.component")
            }
          ]
        },
        {
          path: "item-manager",
          name: "item-manager",
          beforeEnter: (to, from, next) => {
            routerUtils.isAllowedGuard(policyList.viewMetrcItems, next);
          },
          component: () =>
            import(/* webpackChunkName: "item-mgr" */ "@/components/metrc/itemManager/itemManager.component")
        },
        {
          path: "item-manager/add",
          name: "item-manager-add",
          beforeEnter: (to, from, next) => {
            routerUtils.isAllowedGuard(policyList.createMetrcItems, next);
          },
          component: () =>
            import(/* webpackChunkName: "item-mgr-form" */ "@/components/metrc/itemManager/form/itemManagerForm.component")
        },
        {
          path: `item-manager/edit/:id(${routerUtils.idRegex})`,
          name: "item-manager-edit",
          beforeEnter: (to, from, next) => {
            routerUtils.isAllowedGuard(policyList.modifyMetrcItems, next);
          },
          component: () =>
            import(/* webpackChunkName: "item-mgr-form" */ "@/components/metrc/itemManager/form/itemManagerForm.component")
        },
        {
          path: "retail-sales-reconciliation",
          beforeEnter: (to, from, next) => {
            routerUtils.isAllowedGuard(
              policyList.viewModifySalesReconciliation,
              next
            );
          },
          component: () =>
            import("@/components/metrc/retailSalesReconciliation/RetailSalesReconciliation.component"),
          children: [
            {
              path: "/",
              name: "retail-sales-reco",
              component: () =>
                import("@/components/metrc/retailSalesReconciliation/stepper/Stepper.component")
            },
            {
              path: "reconcile/:batchuid",
              name: "retail-sales-reco-batch",
              beforeEnter: (to, from, next) => {
                routerUtils.isAllowedGuard(policyList.modifyBatchDetails, next);
              },
              component: () =>
                import(/* webpackChunkName: "inventory-reco" */ "@/components/metrc/inventoryReconciliation/BatchReconciliation/batchReconciliation.component")
            },
            {
              path: "possible-duplicates",
              name: "possible-duplicates",
              component: () =>
                import(/* webpackChunkName: "order-fulfillment" */ "@/components/metrc/retailSalesReconciliation/stepper/salesReconciliation/possibleDuplicates/PossibleDuplicates.component")
            }
          ]
        },
        {
          path: "tag-manager",
          name: "tag-manager",
          beforeEnter: (to, from, next) => {
            routerUtils.isAllowedGuard(policyList.viewModifyTagManager, next);
          },
          component: () =>
            import(/* webpackChunkName: "location-tag-manager" */ "@/components/metrc/metrcTagManager/MetrcTagManager.component")
        }
      ]
    },
    {
      name: "create_a_ticket",
      path: "/create_a_ticket",
      beforeEnter: () => {
        window.open("https://www.biotrack.com/support/cannasuite");
      }
    },
    {
      path: "/unauthorized",
      name: "unauthorized",
      component: () =>
        import(/* webpackChunkName: "unauthorized" */ "@/components/unauthorized/unauthorized.component"),
      beforeEnter: routerUtils.noLoginGuard
    },
    {
      path: "/forbidden",
      name: "forbidden",
      component: () =>
        import(/* webpackChunkName: "forbidden" */ "@/components/forbidden/forbidden.component")
    },
    {
      path: "*",
      name: "not-found",
      component: () =>
        import(/*webpackChunkName: "not-found" */ "@/components/notFound/NotFound.component")
    }
  ]
});
router.beforeEach(routerUtils.loginGuard);
