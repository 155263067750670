import { RootState } from "@/vuex/types";
import { Module } from "vuex";
import { actions } from "./batch.actions";
import { getters } from "./batch.getters";
import { mutations } from "./batch.mutations";
import { state } from "./batch.state";
import { BatchState } from "./batch.types";

const namespaced: boolean = true;
export const BatchModule: Module<BatchState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
