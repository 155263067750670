import { Component } from "vue-property-decorator";
import Template from "./UIBlockRowField.template.vue";

import { EventBus } from "@/internal";
import TemplateEditor from "../../editors.declaration";
import UIBlockDefaultComponent from "../UIBlockDefault.component";

@Component({
  mixins: [Template]
})
export default class UIBlockRowFieldComponent extends UIBlockDefaultComponent {
  public hovered: boolean = false;
  protected block: TemplateEditor.UIBlocks.Block = {
    model: {
      type: "row-item",
      model: {
        children: [],
        movable: true
      }
    },
    configuration: {
      title: "Row Editor",
      sections: [
        {
          title: "Layout",
          fields: {
            splitColumn: {
              type: "action-button",
              configuration: {
                label: "Add Column",
                icon: "fal fa-plus-circle",
                action: this.addColumn
              }
            }
          }
        },
        {
          title: "Alignment",
          fields: {
            justifyContent: {
              type: "boolean-buttons-group",
              configuration: {
                title: "Horizontal Alignment",
                buttons: [
                  {
                    icon: "fal fa-align-left",
                    value: "flex-start"
                  },
                  {
                    icon: "fal fa-align-center",
                    value: "center"
                  },
                  {
                    icon: "fal fa-align-right",
                    value: "flex-end"
                  }
                ]
              }
            },
            alignItems: {
              type: "boolean-buttons-group",
              configuration: {
                title: "Vertical Alignment",
                buttons: [
                  {
                    icon: "fal fa-arrow-to-top",
                    value: "left"
                  },
                  {
                    icon: "fal fa-align-center",
                    value: "center"
                  },
                  {
                    icon: "fal fa-arrow-to-bottom",
                    value: "right"
                  }
                ]
              }
            }
          }
        }
      ]
    }
  };
  protected droppable: string[] = ["column"];
  public get styles() {
    return {
      justifyContent: this.values.justifyContent,
      alignItems: this.values.alignItems
    };
  }
  protected addColumn() {
    EventBus.$emit("TemplateEditor.AddColumn", {
      breadcrumb: this.breadcrumb
    });
    EventBus.$emit("TemplateEditor.ExitEditBar");
  }
}
