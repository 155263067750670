// import { ProductCategory } from "@/interfaces/productCategoy";
import { TraceabilityState } from "@/vuex/modules/traceability/traceability.types";
import { RootState } from "@/vuex/types";
import { GetterTree } from "vuex";

type ProductCategoryGetters = GetterTree<TraceabilityState, RootState>;

export const getters: ProductCategoryGetters = {
  metrcConfig(state: TraceabilityState): boolean {
    return state.metrcConfig;
  }
};
