import { ReconciliationDuplicateOrder } from "@/interfaces/retailSalesReconciliation";
import { RootState } from "@/vuex/types";
import { ActionContext, ActionTree } from "vuex";
import { SaleReconciliationState } from "./saleReconciliation.types";

type SaleReconciliationActionContext = ActionContext<
  SaleReconciliationState,
  RootState
>;

type SaleReconciliationActionTree = ActionTree<
  SaleReconciliationState,
  RootState
>;

export const actions: SaleReconciliationActionTree = {
  async storeSaleDuplicates(
    context: SaleReconciliationActionContext,
    data: ReconciliationDuplicateOrder
  ): Promise<any> {
    context.commit("setOrder", data);
    context.commit("setDuplicates", data);
    context.commit("setLicenseNumber", data);
    context.commit("setTransactionDateTime", data);
  }
};
