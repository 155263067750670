import { receiptDefault } from "@/components/template/receipt/default";
import { Customer, Profile } from "@/interfaces/customer";
import { Discount, DiscountResponse } from "@/interfaces/discount";
import { Rule } from "@/interfaces/location";
import {
  LoyalityItem,
  Order,
  OrderInvoice,
  OrderItem
} from "@/interfaces/order";
import { TemplateConfig } from "@/interfaces/templateEditor";
import { User } from "@/interfaces/user";
import { i18n } from "@/plugins/i18n";
import { customerService } from "@/services/customer.service";
import { discountManagerService } from "@/services/discountManager.service";
import { orderService } from "@/services/order.service";
import { templatesService } from "@/services/templates.service";
import { usersService } from "@/services/user.service";
import find from "lodash/find";
import forIn from "lodash/forIn";
import kebabCase from "lodash/kebabCase";
import round from "lodash/round";
import VueBarcode from "vue-barcode";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Template from "./PrintReceipt.template.vue";

@Component({
  mixins: [Template],
  filters: {
    round: (value: number) => round(value, 2)
  },
  components: {
    VueBarcode
  }
})
export default class PrintReceiptComponent extends Vue {
  @Prop({ required: true })
  public item!: Order;
  public licenseNumber: number = 0;
  @Getter("currentLocation", { namespace: "AuthModule" })
  public location!: Location;
  @Getter("user", { namespace: "AuthModule" })
  public storeUser!: User;

  public employee!: User;
  public customer: Customer | null = null;

  public templateConfig: TemplateConfig = receiptDefault;

  public voucherDiscountResult!: DiscountResponse;
  public maxApplicableDiscount: Discount | null = null;

  get discountTotal() {
    let totalDiscounts: number = 0;
    if (this.item.discounts!.length) {
      this.item.discounts!.forEach(
        discount => (totalDiscounts += discount.amount!)
      );
    }
    return totalDiscounts;
  }

  get customText() {
    return (
      (this.templateConfig.customText.fieldDisplay &&
        this.templateConfig.customText.fieldDisplay.value) ||
      ""
    );
  }

  get barcodeConfig() {
    return {
      textAlign:
        this.templateConfig.barcode.fieldDisplay!.style.textAlign || "center",
      fontSize: this.templateConfig.barcode.fieldDisplay!.style.fontSize || 16,
      height: this.templateConfig.barcode.fieldDisplay!.style.lineHeight || 32,
      width: 1,
      marginTop: this.templateConfig.barcode.fieldDisplay!.style.marginTop || 0,
      marginBottom:
        this.templateConfig.barcode.fieldDisplay!.style.marginBottom || 0,
      marginLeft: 0,
      marginRight: 0,
      format: "CODE128",
      tag: "svg",
      font: "Arial"
    };
  }

  public getFieldStyle(fieldDisplay: any) {
    if (fieldDisplay && fieldDisplay.style) {
      const styles: string[] = [];
      forIn(fieldDisplay.style, (val, k) => {
        if (!!+val) {
          val = val + "px";
        }
        styles.push(`${kebabCase(k)}: ${val}`);
      });
      return styles.join("; ");
    }
  }

  public get getMedicalCard() {
    const patientProfile: Profile | null =
      (this.customer &&
        find(this.customer!.profiles, (profile: Profile) => {
          return profile.profile_type === "PATIENT";
        })) ||
      null;
    return patientProfile ? patientProfile.medical_card_number : "--";
  }

  public getUsableWeight(item: OrderItem) {
    return orderService.getUsableWeight(item);
  }

  public getDiscountLabel(discount: Discount, addCountable: number) {
    if (discount) {
      let description = null;
      if (discount.loyalty_points && discount.loyalty_points !== null) {
        description = i18n
          .t("cart_discounts.receipt_description_loyalty", {
            points: +discount.loyalty_points!
          })
          .toString();
      } else if (!discount.loyalty_points) {
        if (discount.apply_type === "PERCENTAGE") {
          discount.whole_order_equivalent_discount_amount = Number(
            discount.original_discount_amount
          );
        }
        discount.whole_order_discount_applied = Number(
          discount.original_discount_amount
        );
        description = orderService.getDiscountLabel(discount, addCountable);
      }
      return description;
    }
  }
  public getLoyaltyDiscountLabel(loyalityItem: LoyalityItem) {
    return orderService.getLoyaltyDiscountLabel(loyalityItem);
  }

  public getAvailableLimits() {
    const limits: Rule[] = this.item.orderInvoice!.remaining_limit;
    const availableLimits: Array<{
      limitName: string;
      availableAmount: string;
    }> = [];
    // Update con nuevos limites
    // limits.forEach(limit => {
    //   availableLimits.push({
    //     limitName: limit.rule_config.rule_name,
    //     availableAmount: `${limit.rule_config.amount - limit.used!} ${
    //       limit.rule_config.unit
    //     }`
    //   });
    // });
    return availableLimits;
  }

  public get changeDue() {
    return + Number(this.item.change_due! + 0.004).toFixed(2);
  }

  public getTaxBreakdown() {
    const taxes: Array<{ taxId: number; name: string; amount: number }> = [];
    this.item.order_items!.forEach(orderItem => {
      orderItem.taxes!.forEach(tax => {
        const existTax:
          | { taxId: number; name: string; amount: number }
          | undefined = taxes.find(taxItem => taxItem.taxId === tax.tax_id);
        if (existTax) {
          existTax!.amount += tax.amount;
        } else {
          taxes.push({
            taxId: tax.tax_id,
            name: tax.name,
            amount: tax.amount
          });
        }
      });
    });
    return taxes;
  }
  public limitConsumed(limit: OrderInvoice) {
    return +(limit.amount - limit.consumed).toFixed(3);
  }
  public async retrieveTotalDiscount() {
    this.voucherDiscountResult = await discountManagerService.voucherDiscount(
      this.item.total!
    );
    let maxApplicableDiscountValue = 0;
    const maxDiscount = Number(this.item.total);
    const discountName = this.item.order_items![0].discounts!.length
      ? this.item.order_items![0].discounts![0].name
      : null;

    if (this.voucherDiscountResult.data.length !== 0) {
      this.voucherDiscountResult.data.map(element => {
        if (
          element.active &&
          ((element.print_discount_on_receipt &&
            maxDiscount >= element.print_discount_if_spent_amount) ||
            (maxDiscount > element.print_discount_if_spent_amount &&
              element.name === discountName))
        ) {
          if (
            maxApplicableDiscountValue < element.print_discount_if_spent_amount
          ) {
            maxApplicableDiscountValue = element.print_discount_if_spent_amount;
            this.maxApplicableDiscount = element;
          }
        }
      });
      if (this.maxApplicableDiscount) {
        this.maxApplicableDiscount.external_barcode =
          "DISC" + this.maxApplicableDiscount.external_barcode;
      }
    }
  }

  protected setLicenseNumber() {
    // For licence number we’re direclty pulling from orders api ,instead of calling a different api for profile.
    this.employee = this.item.user!;
    return this.item.user!.occupational_license_number || null;
  }
  protected async created() {
    const [template, customer] = await Promise.all([
      templatesService.getDefault("RECEIPT"),
      customerService.findById(this.item.customer_id!, { embed: "profiles" })
    ]);

    await this.retrieveTotalDiscount();
    this.licenseNumber = this.setLicenseNumber()!;
    this.customer = customer;
    if (template) {
      this.templateConfig = template.config as TemplateConfig;
    }
    this.$nextTick(() => this.$emit("readyToPrint"));
  }
}
