import { Timeclock } from "@/interfaces/timeclock";
import { TimeclockState } from "@/vuex/modules/timeclock/timeclock.types";
import { RootState } from "@/vuex/types";
import { TablePagination } from "helix-vue-components";
import { GetterTree } from "vuex";

type TimeclockGetter = GetterTree<TimeclockState, RootState>;

export const getters: TimeclockGetter = {
  timeclocks(state: TimeclockState): Timeclock[] {
    return state.timeclocks;
  },
  userTimeclocks(state: TimeclockState): Timeclock[] {
    return state.userTimeclocks;
  },
  userTimeWeek(state: TimeclockState, hours: string | null) {
    return state.timeWeek;
  },
  userFilteredTimeclocks(state): Timeclock[] {
    return state.userFilteredTimeclocks;
  },
  currentTimeclock(state: TimeclockState): Timeclock | null {
    return state.currentTimeclock;
  },
  pagination(state: TimeclockState): TablePagination | null {
    return state.pagination;
  },
  loading(state: TimeclockState): boolean {
    return state.loading;
  },
  loadingFiltered(state: TimeclockState): boolean {
    return state.loadingFiltered;
  }
};
