import { Component, Vue } from "vue-property-decorator";
import { State } from "vuex-class";
import Template from "./Breadcrumbs.template.vue";

@Component({
  mixins: [Template]
})
export default class BreadcrumbsComponent extends Vue {
  @State("appVersion") public appVersion!: string;

  get formatedPath() {
    const list: object[] = [];
    this.$route.matched.forEach(crumb => {
      if (crumb.name) {
        list.push({
          text: crumb.name.replace(/-/g, " "),
          disabled: true,
          href: crumb.name
        });
      }
    });
    return list;
  }
}
