import { Discount } from "@/interfaces/discount";
import { Rule } from "@/interfaces/location";
import { Order } from "@/interfaces/order";
import { LoyaltyConfig, OrderState } from "@/vuex/modules/order/order.types";
import { TablePagination } from "helix-vue-components";
import { MutationTree } from "vuex";

type OrderMutationTree = MutationTree<OrderState>;

export const mutations: OrderMutationTree = {
  setOrder(state: OrderState, order: Order) {
    state.order = order;
  },
  setOrders(state: OrderState, orders: Order[]) {
    state.orders = orders;
  },
  setDiscounts(state: OrderState, discounts: Discount[]) {
    state.discounts = discounts;
  },
  setLoyaltyConfig(state: OrderState, loyaltyConfig: LoyaltyConfig | null) {
    state.loyaltyConfig = loyaltyConfig;
  },
  setLoading(state: OrderState, loading: boolean) {
    state.loading = loading;
  },
  setPagination(state: OrderState, pagination: TablePagination | null) {
    state.pagination = pagination;
  },
  setCustomerLimits(state: OrderState, rules: Rule[]) {
    state.customerLimits = rules;
  },
  setPharmacistOnDuty(state: OrderState, selectedPharmacist: number | null) {
    state.pharmacistSelected = selectedPharmacist;
  }
};
