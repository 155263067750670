import { Location } from "@/interfaces/location";
import { PusherOnEvent } from "@/interfaces/pusher";
import { store } from "@/internal";
import Vue from "vue";
// tslint:disable-next-line
const Pusher = require("pusher-js");

export default class PusherService extends Vue {
  public pusherChannel: any;
  public location!: Location;
  public channel: any;

  constructor() {
    super();
    // Pusher.logToConsole = true;
    this.connect();
    this.setChannel();
  }

  public onEvent(pusherData: PusherOnEvent) {
    if (!this.channel) {
      this.setChannel();
    }

    this.channel.bind(pusherData.event, (data: any) => {
      pusherData.action(data, pusherData.event);
    });
  }

  private connect() {
    this.pusherChannel = new Pusher(process.env.VUE_APP_NOTIFIER_ACCOUNT, {
      cluster: process.env.VUE_APP_NOTIFIER_CLUSTER,
      forceTLS: true
    });
  }

  private setChannel() {
    this.location = store.getters["AuthModule/currentLocation"];
    this.channel =
      this.location &&
      this.pusherChannel.subscribe(`location-${this.location.id}`);
  }
}
