import { Type } from "@/interfaces/type";
import HttpService from "@/services/http.service";

class ProductTypesService extends HttpService {
  protected uri: string = "inventory/product_types";

  public async get(): Promise<Type[]> {
    const response = await super.get(this.query);
    return response.data.data;
  }
}

export const productTypesService: ProductTypesService = new ProductTypesService();
