import { policyList } from "@/enums/permissions";
import { EventBus, store } from "@/internal";
import { Route } from "vue-router";
import { Callback } from "./types/types";

export default class RouterUtils {
  public trasferTabsPolicies = [
    policyList.viewPurchaseOrdersTab,
    policyList.viewQuotesTab,
    policyList.viewInboundTransferTab,
    policyList.viewOutboundTransferTab,
    policyList.viewInvoicesTab,
    policyList.viewManifestsTab
  ];
  public transfersTypePolicies: { [key: string]: string } = {
    "purchase-order": policyList.viewPurchaseOrdersTab,
    quote: policyList.viewQuotesTab,
    "inbound-transfer": policyList.viewInboundTransferTab,
    "outbound-transfer": policyList.viewOutboundTransferTab,
    invoice: policyList.viewInvoicesTab,
    manifest: policyList.viewManifestsTab,
    "new-batch": policyList.newBatchesTool
  };
  public transfersTypePoliciesEdit: { [key: string]: string } = {
    "purchase-order": policyList.modifyPurchaseOrder,
    "inbound-transfer": policyList.modifyInboundTransfer,
    "outbound-transfer": policyList.modifyOutboundTransfer,
    quote: policyList.modifyQuote
  };

  public importPolicies: { [key: string]: string } = {
    "batch-types": policyList.importExportBatchTypes,
    "product-categories": policyList.importExportProductsCategories,
    brands: policyList.importExportBrands,
    strains: policyList.importExportStrains,
    "vendors-laboratories": policyList.importExportVendorsLaboratories,
    products: policyList.importExportProducts,
    customers: policyList.importExportCustomers,
    "lab-results": policyList.importExportLabResults
  };

  public idRegex = "[1-9]{1}\\d{0,}";

  public loginGuard(to: Route, from: Route, next: Callback) {
    const isLogged = store.getters["AuthModule/isLogged"];

    if (
      !isLogged &&
      to.name !== "unauthorized" &&
      to.name !== "oauth-callback"
    ) {
      store.dispatch("AuthModule/setRedirect", to.fullPath);
      next({ name: "unauthorized" });
    } else {
      next();
    }
  }

  public noLoginGuard(to: Route, from: Route, next: Callback) {
    const isLogged = store.getters["AuthModule/isLogged"];
    if (isLogged) {
      next("/");
    } else {
      next();
    }
  }

  public isAllowedGuard(policy: string, next: Callback, group: string = "bdi") {
    if (store.getters["PermissionsModule/hasPermission"](policy, group)) {
      next();
    } else {
      next({ name: "forbidden" });
    }
  }

  /**
   * Verifys if there are batches to move on, else
   * navigates user back to ProductView screen.
   * @param to: Route
   * @param next: Callback
   */
  public batchExist(to: Route, next: Callback) {
    if (to.params.batches && to.params.batches.length) {
      next();
    } else {
      next({ name: "products-view" });
    }
  }

  public async tillGuard(policy: string, from: Route, next: Callback) {
    if (store.getters["PermissionsModule/hasPermission"](policy)) {
      const tillData = await store.dispatch("UserModule/setCurrentTill");
      if (tillData) {
        next();
      } else {
        EventBus.$emit("modal", {
          msgModal: {
            title: "no_till_assigned",
            body: "msg_no_till",
            captionButton: "OK"
          }
        });
        next(from || { name: "dashboard" });
      }
    } else {
      next({ name: "forbidden" });
    }
  }

  public allowedOnceIn(policies: string[], next: Callback) {
    const first = policies.find(p =>
      store.getters["PermissionsModule/hasPermission"](p)
    );

    if (first) {
      next();
    } else {
      next({ name: "forbidden" });
    }
  }

  public metrcGuard(to: Route, from: Route, next: Callback) {
    if (
      metrcEnabled() ||
      (hasMetrcIntegrations() &&
        (to.name === "metrc-landing" || to.name === "tag-manager") &&
        store.getters["PermissionsModule/hasPermission"](
          policyList.viewModifyTagManager
        ))
    ) {
      next();
    } else {
      next(from || { name: "dashboard" });
    }
  }
}
export const doctorsTrackingEnabled = () => {
  const retail = store.getters["AuthModule/currentRetailSettings"];
  return true;
  if (retail.doctor_tracking_enabled === 1) {
    return false;
  } else {
    return true;
  }
};

export const integrationsEnabled = () => {
  const retailSettings = store.getters["AuthModule/currentRetailSettings"];
  return !!(retailSettings && retailSettings.integrations !== null);
};

export const hasMetrcIntegrations = () => {
  return store.getters["AuthModule/hasMetrcIntegrations"];
};

export const metrcEnabled = () => {
  return store.getters["AuthModule/metrcEnabled"];
};

export const weedMapsEnabled = () => {
  return store.getters["AuthModule/weedMapsEnabled"];
};
