import { MemberLevel } from "@/interfaces/memberLevel";
import { MemberLevelState } from "@/vuex/modules/inventory/memberLevel/memberLevel.types";
import { RootState } from "@/vuex/types";
import { TablePagination } from "helix-vue-components";
import { GetterTree } from "vuex";

type BatchTypeGetter = GetterTree<MemberLevelState, RootState>;

export const getters: BatchTypeGetter = {
  memberLevels(state: MemberLevelState): MemberLevel[] {
    return state.memberLevels;
  },
  memberLevel(state: MemberLevelState): MemberLevel | null {
    return state.memberLevel;
  },
  loading(state: MemberLevelState): boolean {
    return state.loading;
  },
  pagination(state: MemberLevelState): TablePagination | null {
    return state.pagination;
  }
};
