import { Drop } from "vue-drag-drop";
import { Component, Prop, Vue } from "vue-property-decorator";
import TemplateEditor from "../../editors.declaration";
import CanvasTreeNodeComponent from "../canvas-tree-node/CanvasTreeNode.component";
import Template from "./CanvasTree.template.vue";

@Component({
  mixins: [Template],
  components: {
    "canvas-tree-node": CanvasTreeNodeComponent,
    "drop-zone": Drop
  }
})
export default class CanvasTreeComponent extends Vue {
  @Prop() public treeData!: TemplateEditor.UIBlocks.LayoutItem[];
  @Prop() public configuration!: TemplateEditor.TemplateTypeConfiguration;
  @Prop({ default: false }) public printMode!: boolean;
  @Prop() public model!: any;
  @Prop() public size!: { height: string; unit: string; width: string };

  public sizeStyle: { height: string; width: string } | null = null;

  protected mounted() {
    if (this.size) {
      this.sizeStyle = {
        height: this.size.height + this.size.unit,
        width: this.size.width + this.size.unit
      };
    }
    this.$nextTick(() => this.$emit("readyToPrint"));
  }
}
