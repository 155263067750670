import { Location } from "@/interfaces/location";
import { locationService } from "@/services/location.service";
import { messagesService } from "@/services/messages.service";
import { LocationState } from "@/vuex/modules/location/location.types";
import { RootState } from "@/vuex/types";
import { ActionContext, ActionTree } from "vuex";

type LocationActionContext = ActionContext<LocationState, RootState>;
type LocationActionTree = ActionTree<LocationState, RootState>;

export const actions: LocationActionTree = {
  async loadLocations(context: LocationActionContext): Promise<any> {
    try {
      context.commit("setLoading", true);
      const payload: Location[] = await locationService.get();
      context.commit("setLocations", payload);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setLoading", false);
    }
  },
  async searchLocation(context: LocationActionContext, value: string) {
    if (value || !context.state.defaultLocations.length) {
      try {
        const payload = await locationService.search(value);
        context.commit("setLocations", payload);
      } catch (e) {
        messagesService.renderErrorMessage(e);
      }
    } else {
      context.commit("setLocations", context.state.defaultLocations);
    }
  },

  async findLocation(
    context: LocationActionContext,
    dataRequest: {
      id: number;
      route?: string;
    }
  ): Promise<any> {
    try {
      context.commit("setLoading", true);
      locationService.changeQuery(dataRequest.route);
      const location: Location | null = await locationService.find(
        Number(dataRequest.id)
      );
      context.commit("setCurrentLocation", location);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setLoading", false);
    }
  }
};
