import { SearchQuery } from "@/interfaces/httpQuery";
import {
  Timeclock,
  TimeclockChangeRequst,
  TimeclockEvent
} from "@/interfaces/timeclock";
import { AxiosResponse } from "axios";
import { TableHeader } from "helix-vue-components";
import Vue from "vue";
import HttpService from "./http.service";
class TimeclockService extends HttpService {
  protected loadDispatcher: string = "TimeclockModule/loadTimeclocks";
  protected defaultSort: string = "";
  protected uri: string = "/timeclocks";
  protected searchableField: SearchQuery[] = [
    {
      field: "user_id",
      type: "is_in"
    }
  ];

  /**
   * sort action to make a request
   * @param params
   */
  public sortQuery(params: TableHeader) {
    return `${!params.descending ? "-" : ""}${params.value || ""}`;
  }

  public async get(query?: { [id: string]: string }): Promise<Timeclock[]> {
    this.uri = "/timeclocks";
    this.query.sort = "-check_in";
    this.query.embed = "user,timeclockEvents";
    const response = await super.get({ ...this.query, ...query });
    return response.data.data;
  }

  public async deleteEvent(
    id: number,
    eventId: number
  ): Promise<TimeclockEvent> {
    const uri = `/timeclocks/${id}/timeclock_events/${eventId}`;
    const response: AxiosResponse = await Vue.axios({
      method: "DELETE",
      url: uri
    });
    const payload: TimeclockEvent = response.data.data;
    return payload;
  }

  public async applyTransition(id: number, verb: string): Promise<Timeclock[]> {
    this.query.embed = "timeclockEvents";
    this.uri = `/timeclocks/${id}/apply_transition/${verb}`;
    const response: AxiosResponse = await Vue.axios({
      method: "PUT",
      url: this.uri,
      params: this.query
    });
    const payload: Timeclock[] = response.data.data;
    return payload;
  }

  public async updateTimeclock(
    id: number,
    timeclock: Timeclock
  ): Promise<Timeclock[]> {
    this.query.embed = "timeclockEvents";
    this.uri = `/timeclocks/${id}?embed=timeclockEvents`;
    const response: AxiosResponse = await Vue.axios({
      method: "PUT",
      url: this.uri,
      data: { ...timeclock, user: timeclock.user.id }
    });
    const payload: Timeclock[] = response.data.data;
    return payload;
  }

  public async findUserTimeclocks(userId: number): Promise<Timeclock[]> {
    this.uri = "/timeclocks";
    delete this.query.id;
    this.query.sort = "-check_in";
    this.query.user_id = userId;
    this.query.embed = "timeclockEvents";
    const response = await super.get(this.query);
    const payload: Timeclock[] = response.data.data;
    delete this.query.user_id;
    return payload;
  }

  public async findUserTimeWeek(userId: number): Promise<Timeclock[]> {
    const query = {
      sort: "-check_in",
      user_id: userId,
      embed: this.query.embed,
      page: this.query.page,
      per_page: this.query.per_page
    };
    const prevUrl = this.uri;
    this.uri = `/users/${userId}/timeclocks?thisWeek=1`;
    const response = await super.get(query);
    const payload: Timeclock[] = response.data.hours_worked;
    delete this.query.user_id;
    this.uri = prevUrl;
    return payload;
  }

  public async changeRequest(timeclockId: number, data: TimeclockChangeRequst) {
    const response = await Vue.axios({
      method: "POST",
      url: `timeclocks/${timeclockId}/changes_request`,
      data
    });
    return response;
  }
}

export const timeclockService = new TimeclockService();
