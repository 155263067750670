import {
  BatchAction,
  BatchSample,
  CustomBatch,
  InventoryBatch
} from "@/interfaces/batch";
import { PRODUCT_UNIT } from "@/interfaces/batchTransferManager";
import { OrderItem } from "@/interfaces/order";
import { PricingRule } from "@/interfaces/product";
import { router } from "@/internal";
import { i18n } from "@/plugins/i18n";
import { labResultsService } from "@/services/labResults.service";
import chunk from "lodash/chunk";
import every from "lodash/every";
import groupBy from "lodash/groupBy";
import orderBy from "lodash/orderBy";
import { mathJs } from "./math.utils";

interface InventoryBatchItem {
  details: string | null;
  id: number;
  profile_id: number;
  sample_id: number;
  unit_representation: string;
  value: number;
  profile: {
    breakdown: string;
    id: number;
    name: string;
    type: string;
  };
}

export const timeUntFormat: {
  [key: string]: string;
} = {
  H: i18n.t("hours").toString(),
  M: i18n.t("minutes").toString(),
  S: i18n.t("seconds").toString()
};
/**After analyzing the floating point limitations for mass conversions,
 * together with Manuel and Mariano, it was determined that the conversion
 * criteria to be used would be the following expressed in 6
 * significant digits
 * https://tm01.biotrackthc.net:8443/browse/HLXT-5812?focusedCommentId=41910&page=com.atlassian.jira.plugin.system.issuetabpanels%3Acomment-tabpanel#comment-41910
 *
 */
export const UNIT_CONVERSION_CRITERIA: {
  [key: string]: { [key: string]: string };
} = {
  mg: {
    oz: "3.52739e-5",
    g: "1.0e-3"
  },
  g: {
    oz: "3.52739e-2",
    mg: "1.0e3"
  },
  oz: {
    mg: "2.83495e4",
    g: "2.83495e1"
  }
};
/**
 * Routes the user to a batch action section.
 * @param batchList: Batch[]
 */
export function goToBatchAction(batchList: InventoryBatch[], name: string) {
  router.push({
    name,
    params: {
      batches: JSON.stringify(batchList)
    }
  });
}

/**
 * Maps batches to be used in Batches combine/move section.
 * @param batchList: Batch[]
 * @returns BatchAction<CustomBatch>
 */
export function mapBatchesToAction(
  batchList: InventoryBatch[],
  hasSingleUnit?: boolean | null,
  batchName?: string
): BatchAction<CustomBatch> {
  let total = 0;
  let totalWeighable = 0;
  let totalCountable = 0;
  let unit = "";
  let isMarijuana = 0;
  let batchTotal = 0;
  const batches = batchList.flatMap((batch, i) => {
    const dataSource = batch.product_variant || batch.product;
    if (!i) {
      isMarijuana = (batch.product || batch.product_variant).marijuana!;
    }
    unit = (
      (batch.product || batch.product_variant).unit! ||
      batch.in_store_quantity_unit
    ).toString();
    return (batch.on_rooms || batch.summary)
      .filter(
        roomIndex =>
          roomIndex.batch_fraction_status_type === "AVAILABLE" ||
          (roomIndex.batch_fraction_status_type === "RESERVED" &&
            batchName === "ADJUST")
      )
      .map(summItem => {
        const batchUnit =
          batch.in_store_quantity_unit || batch.product_variant.unit;
        total = mathJs.add!(
          mathJs.bignumber!(total),
          mathJs.bignumber!(summItem.quantity_value || 0)
        ) as number;
        if (batchUnit === PRODUCT_UNIT.UNIT_READABLE) {
          totalCountable = mathJs.add!(
            mathJs.bignumber!(totalCountable),
            mathJs.bignumber!(summItem.quantity_value || 0)
          ) as number;
        } else {
          totalWeighable = mathJs.add!(
            mathJs.bignumber!(totalWeighable),
            mathJs.bignumber!(summItem.quantity_value || 0)
          ) as number;
        }

        i && batch.batch_uid !== batchList[i - 1].batch_uid
          ? (batchTotal = summItem.quantity_value || 0)
          : (batchTotal += summItem.quantity_value || 0);

        return {
          unit: batchUnit as PRODUCT_UNIT,
          tracking_id: batch.tracking_id,
          quantity: batch.in_store_quantity_value || batch.total_quantity_value,
          product_name: dataSource.name!,
          product_sku: dataSource.concrete_sku,
          isMarijuana: dataSource.marijuana,
          batch_uid: batch.batch_uid,
          biotrack_traceability_id: batch.biotrack_traceability_id,
          room_quantity:
            summItem.batch_fraction_status_type !== "RESERVED"
              ? summItem.quantity_value!
              : 0,
          room_quantity_editable:
            summItem.batch_fraction_status_type !== "RESERVED"
              ? summItem.quantity_value!
              : 0,
          room_name: summItem.inventory_location.name,
          room_id: summItem.inventory_location.id!,
          batch_totals: batchTotal,
          batch_totals_editable: batchTotal,
          strain:
            batch.strain_name ||
            (dataSource.strain && dataSource.strain.name) ||
            "",
          selected: true,
          usable_weight_value: batch.product_variant.usable_weight_value,
          batch_type: dataSource.batch_type!.name || "",
          usable_weight_unit: batch.product_variant.usable_weight_unit,
          normal_price: !!(
            dataSource.pricing ||
            ([] as PricingRule[]).filter(b => !b.member_level_code).length
          ),
          requires_weighing: batch.product_variant.requires_weighing,
          reserved_quantity:
            summItem.batch_fraction_status_type === "RESERVED"
              ? summItem.quantity_value
              : 0
        };
      });
  });
  return {
    total_quantity: hasSingleUnit ? total : 0,
    total_weighable: hasSingleUnit ? 0 : totalWeighable,
    total_weighable_unit: PRODUCT_UNIT.GRAMS,
    total_countable: hasSingleUnit ? 0 : totalCountable,
    unit: unit === PRODUCT_UNIT.UNIT_READABLE ? unit : PRODUCT_UNIT.GRAMS,
    isMarijuana,
    batches: groupBy(batches, "batch_uid")
  };
}

/**
 * Checks if combine should be available based on next conditions:
 * A user can only combine a weighable cannabis group of products
 * to generate only a weighable cannabis product.
 * A user can only combine a countable cannabis group of products
 * to generate only a countable cannabis product.
 * A user can only combine a non-cannabis group of products
 * to generate a non-cannabis product.
 * @param batches: Batch[]
 * @returns boolean
 */
export function batchesAreOfSameType(batches: InventoryBatch[]): boolean {
  const areWeighableCannabis = every(
    batches,
    batch =>
      batch.product_variant!.requires_weighing &&
      batch.product_variant!.marijuana
  );
  const areWeighableNonCannabis = every(
    batches,
    batch =>
      batch.product_variant!.requires_weighing &&
      !batch.product_variant!.marijuana
  );
  const areCountableCannabis = every(
    batches,
    batch =>
      !batch.product_variant!.requires_weighing &&
      batch.product_variant!.marijuana
  );
  const areCountableNonCannabis = every(
    batches,
    batch =>
      !batch.product_variant!.requires_weighing &&
      !batch.product_variant!.marijuana
  );

  const firstBatchType = batches[0].batch_type_id;
  const areSameBatchType = every(
    batches,
    batch => batch.batch_type_id === firstBatchType
  );

  return (
    areSameBatchType &&
    (areWeighableCannabis ||
      areWeighableNonCannabis ||
      areCountableCannabis ||
      areCountableNonCannabis)
  );
}

/**
 *
 * @param sku {string} Ej: 1232112123:123123213:123213123
 * the first two blocks are the parent sku
 */
export function getParentSKU(sku: string): string {
  return sku
    .split(":")
    .splice(0, 2)
    .join(":");
}

/**
 *
 * @param unitIn {'g'| 'mg'| 'oz'} Ej: 'g'
 * @param unitOut {'g'| 'mg'| 'oz'} Ej: 'g'
 * @param value {number} Ej: 2.564
 * According to the conversion criteria used in the backend,
 * UNIT_CONVERSION_CRITERIA is used to make unit conversions
 * so that we can handle the same number of significant decimals
 */

export function convertionUnit(
  unitIn: PRODUCT_UNIT,
  unitOut: PRODUCT_UNIT,
  value: number
): math.BigNumber {
  return (unitIn === unitOut
    ? value
    : mathJs.multiply!(
        mathJs.bignumber!(
          UNIT_CONVERSION_CRITERIA[unitIn][unitOut]
        ) as math.BigNumber,
        value
      )) as math.BigNumber;
}

export const batchFormatedforLabResults = (labs: BatchSample) => {
  const stringResults = (gramVal: number, perVal: number) => {
    const results = [];
    if (gramVal) {
      results.push(`${gramVal} mg/g`);
    }
    if (perVal) {
      results.push(`${perVal}%`);
    }
    if (results.length) {
      return results.join(" - ");
    }
    return 0;
  };
  const mappBatch =
    labs.results.map((item: any) => {
      /**
       * Conversion of mg / g to concentration percentage is performed,
       * when trying value / 1000 to obtain the grams and then multiplying
       * it by 100%, we get the zeros, and we get value * 10
       */
      const breakdownConvertion =
        item.unit_representation === "mg/g" ? item.value! / 10 : item.value;
      return {
        ...item,
        valueFormated: `${item.value}${item.unit_representation}`,
        label: item.profile!.name,
        percentage: breakdownConvertion
      };
    }) || [];
  const breakdown = groupBy(mappBatch, "profile.breakdown");
  const top3Terpene = chunk(
    orderBy(breakdown.TERPENE, "percentage", ["desc"]),
    3
  )[0];
  const top3Potency = chunk(
    orderBy(breakdown.POTENCY, "percentage", ["desc"]),
    3
  )[0];
  let ratio: string;
  try {
    const sumThc = labs.thc + labs.thc_relative!;
    const sumCbd = labs.cbd + labs.cbd_relative!;
    const fraction = mathJs.fraction!(sumThc * 1000, sumCbd * 1000);
    ratio = mathJs.format!(fraction, { fraction: "ratio" }).replace("/", ":");
  } catch (e) {
    ratio = "--";
  }
  const laboratory = {
    ...groupBy(mappBatch, "profile.type"),
    terpene: breakdown.TERPENE,
    potency: breakdown.POTENCY,
    top3Potency,
    top3Terpene,
    sample: labs && {
      terpene: stringResults(labs.terpene, labs.terpene_relative!),
      thc: stringResults(labs.thc, labs.thc_relative!),
      cbd: stringResults(labs.cbd, labs.cbd_relative!),
      ratio
    }
  };
  return laboratory;
};

export const getLabsDataForPrintLabels = async (batches: InventoryBatch[]) => {
  const stringResults = (gramVal: number, perVal: number) => {
    const results = [];
    if (gramVal) {
      results.push(`${gramVal} mg/g`);
    }
    if (perVal) {
      results.push(`${perVal}%`);
    }
    if (results.length) {
      return results.join(" - ");
    }
    return 0;
  };

  const batchFormated = (labs: InventoryBatch | any) => {
    const mappBatch =
      labs.results.map((item: InventoryBatchItem) => {
        /**
         * Conversion of mg / g to concentration percentage is performed,
         * when trying value / 1000 to obtain the grams and then multiplying
         * it by 100%, we get the zeros, and we get value * 10
         */
        const breakdownConvertion =
          item.unit_representation === "mg/g" ? item.value! / 10 : item.value;
        return {
          ...item,
          valueFormated: `${item.value}${item.unit_representation}`,
          label: item.profile!.name,
          percentage: breakdownConvertion
        };
      }) || [];
    const breakdown = groupBy(mappBatch, "profile.breakdown");
    const top3Terpene = chunk(
      orderBy(breakdown.TERPENE, "percentage", ["desc"]),
      3
    )[0];
    const top3Potency = chunk(
      orderBy(breakdown.POTENCY, "percentage", ["desc"]),
      3
    )[0];
    let ratio: string;
    try {
      const sumThc = labs.thc + labs.thc_relative!;
      const sumCbd = labs.cbd + labs.cbd_relative!;
      const fraction = mathJs.fraction!(sumThc * 1000, sumCbd * 1000);
      ratio = mathJs.format!(fraction, { fraction: "ratio" }).replace("/", ":");
    } catch (e) {
      ratio = "--";
    }
    const laboratory = {
      ...groupBy(mappBatch, "profile.type"),
      terpene: breakdown.TERPENE,
      potency: breakdown.POTENCY,
      top3Potency,
      top3Terpene,
      sample: labs && {
        terpene: stringResults(labs.terpene, labs.terpene_relative!),
        thc: stringResults(labs.thc, labs.thc_relative!),
        cbd: stringResults(labs.cbd, labs.cbd_relative!),
        ratio
      }
    };
    return laboratory;
  };
  return await Promise.all(
    batches.map(async item => {
      const labs = await labResultsService.getBatchSamples(
        (item as OrderItem).batch_barcode_uid || item.batch_uid
      );
      const hasLab = !!labs!.laboratory;
      if (!item.activation_time && !item.product) {
        item.activation_time =
          item.activation_time_unit === "I"
            ? i18n.t("immediate").toString()
            : item.activation_time;
        item.activation_time_unit =
          item.activation_time_unit === "I"
            ? " "
            : item.activation_time_unit === "M" ||
              item.activation_time_unit === "S" ||
              item.activation_time_unit === "H"
            ? timeUntFormat[item.activation_time_unit]
            : item.activation_time_unit;
      } else if (item.product !== undefined && item.product.packaging_detail) {
        item.product!.packaging_detail.activation_time =
          item.product!.packaging_detail.activation_time_unit === "I"
            ? i18n.t("immediate").toString()
            : item.product!.packaging_detail.activation_time;

        item.product!.packaging_detail.activation_time_unit =
          item.product!.packaging_detail.activation_time_unit === "I"
            ? " "
            : item.product!.packaging_detail.activation_time_unit === "M" ||
              item.product!.packaging_detail.activation_time_unit === "S" ||
              item.product!.packaging_detail.activation_time_unit === "H"
            ? timeUntFormat[item.product!.packaging_detail.activation_time_unit]
            : item.product!.packaging_detail.activation_time_unit;
      }
      return {
        ...item,
        laboratory: hasLab ? batchFormatedforLabResults(labs!) : null,
        fatherItem: {
          ...item.fatherItem,
          laboratory_name: hasLab ? labs!.laboratory!.name : "N/A",
          laboratory_license: hasLab ? labs!.laboratory!.license : "N/A",
          laboratory_testing_date: hasLab ? labs!.testing_date : "N/A"
        }
      };
    })
  );
};
export function batchActionFilters() {
  return [
    {
      value:
        "inventory.batch_Log.operation.transfer.inbound.created.from_purchase",
      text: String(i18n.t("filter_reports.from_purchase"))
    },
    {
      value:
        "inventory.batch_log.operation.transfer.inbound.created.from_new_batches",
      text: i18n.t("filter_reports.from_new_batches")
    },
    {
      value:
        "inventory.batch_log.operation.transfer.inbound.created.from_location_transfer",
      text: i18n.t("filter_reports.from_location_transfer")
    },
    {
      value:
        "inventory.batch_log.operation.transfer.inbound.void.location.inventory",
      text: i18n.t("filter_reports.inbound_void_location_inventory")
    },
    {
      value:
        "inventory.batch_log.operation.transfer.inbound.void.location.new_batch",
      text: i18n.t("filter_reports.inbound_void_location_new_batch")
    },
    {
      value:
        "inventory.batch_log.operation.transfer.inbound.void.vendor.inventory",
      text: i18n.t("filter_reports.inbound_void_vendor_inventory")
    },
    {
      value:
        "inventory.batch_log.operation.transfer.inbound.void.vendor.new_batch",
      text: i18n.t("filter_reports.inbound_void_vendor_new_batch")
    },
    {
      value:
        "inventory.batch_log.operation.transfer.inbound.modified.from_purchase",
      text: i18n.t("filter_reports.modified_from_purchase")
    },
    {
      value:
        "inventory.batch_log.operation.transfer.inbound.modified.from_new_batches",
      text: i18n.t("filter_reports.modified_from_new_batches")
    },
    {
      value:
        "inventory.batch_log.operation.transfer.inbound.modified.from_location_transfer",
      text: i18n.t("filter_reports.modified_from_location_transfer")
    },
    {
      value:
        "inventory.batch_log.operation.transfer.outbound.sent.from_inventory",
      text: i18n.t("filter_reports.sent_from_inventory")
    },
    {
      value: "inventory.batch_log.operation.transfer.outbound.sent.from_quote",
      text: i18n.t("filter_reports.sent_from_quote")
    },
    {
      value:
        "inventory.batch_log.operation.transfer.outbound.void.location.inventory",
      text: i18n.t("filter_reports.outbound_void_location_inventory")
    },
    {
      value:
        "inventory.batch_log.operation.transfer.outbound.void.location.quote",
      text: i18n.t("filter_reports.outbound_void_location_quote")
    },
    {
      value:
        "inventory.batch_log.operation.transfer.outbound.void.vendor.inventory",
      text: i18n.t("filter_reports.outbound_void_vendor_inventory")
    },
    {
      value:
        "inventory.batch_log.operation.transfer.outbound.void.vendor.quote",
      text: i18n.t("filter_reports.outbound_void_vendor_quote")
    },
    {
      value:
        "inventory.batch_log.operation.transfer.outbound.modified.from_inventory",
      text: i18n.t("filter_reports.modified_from_inventory")
    },
    {
      value:
        "inventory.batch_log.operation.transfer.outbound.modified.from_quote",
      text: i18n.t("filter_reports.modified_from_quote")
    },
    {
      value: "inventory.batch_log.details_updated",
      text: i18n.t("filter_reports.details_updated")
    },
    {
      value: "inventory.batch_log.operation.combine.created",
      text: i18n.t("filter_reports.combine_created")
    },
    {
      value: "inventory.batch_log.operation.combine.used",
      text: i18n.t("filter_reports.combine_used")
    },
    {
      value: "inventory.batch_log.operation.adjust.adjusted",
      text: i18n.t("filter_reports.adjust_adjusted")
    },
    {
      value: "inventory.batch_log.operation.convert.created",
      text: i18n.t("filter_reports.convert_created")
    },
    {
      value: "inventory.batch_log.operation.convert.used",
      text: i18n.t("filter_reports.convert_used")
    },
    {
      value: "inventory.batch_log.operation.move.moved",
      text: i18n.t("filter_reports.move_moved")
    },
    {
      value: "inventory.batch_log.operation.split.created",
      text: i18n.t("filter_reports.split_created")
    },
    {
      value: "inventory.batch_log.operation.split.used",
      text: i18n.t("filter_reports.split_used")
    },
    {
      value: "inventory.batch_log.operation.audit.audited",
      text: i18n.t("filter_reports.audit_created")
    },
    {
      value: "inventory.batch_log.retail.sold",
      text: i18n.t("filter_reports.retail_sold")
    },
    {
      value: "inventory.batch_log.retail.refund",
      text: i18n.t("filter_reports.retail_refund")
    },
    {
      value: "inventory.batch_log.retail.voided",
      text: i18n.t("filter_reports.retail_voided")
    },
    {
      value: "inventory.batch_log.operation.change_sku",
      text: i18n.t("filter_reports.product_name_change")
    },
    {
      value: "inventory.batch_log.operation.reconciliation.adjust_biotrack",
      text: i18n.t("filter_reports.adjustment_biotrack")
    },
    {
      value: "metrc.batch_log.operation.adjust",
      text: i18n.t("filter_reports.adjustment_biotrack_metrc")
    },
    {
      value: "inventory.batch_log.operation.split.refund_created",
      text: i18n.t("filter_reports.split_refund_created")
    },
    {
      value: "inventory.batch_log.operation.split.refund_used",
      text: i18n.t("filter_reports.split_refund_used")
    }
  ];
}
