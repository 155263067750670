import { InventoryBatch, LaboratoryResult } from "@/interfaces/batch";
import { Location } from "@/interfaces/location";
import { Product } from "@/interfaces/product";
import { User } from "@/interfaces/user";
import { Vendor } from "@/interfaces/vendor";
import { TableHeader, TablePagination } from "helix-vue-components";
import { Driver } from "./driver";
import { Strain } from "./strain";
import { TaxCategory } from "./taxCategory";
import { Vehicle } from "./vehicle";

export enum DISCOUNT_FEE_TYPES {
  PERCENTAGE = "PERCENTAGE",
  FIXED = "FIXED"
}
export enum MANIFEST_STATUS {
  COMPLETE = "COMPLETED",
  PENDING = "PENDING"
}
export enum TRANSFER_STATUS {
  COMPLETE = "COMPLETED",
  PENDING = "PENDING",
  DRAFT = "DRAFT",
  CANCELED = "CANCELED",
  CLOSED = "CLOSED",
  ACCEPTED = "ACCEPTED",
  NOT_ACCEPTED = "NOT_ACCEPTED",
  VOID = "VOID"
}
export enum TRANSFER_SOURCE {
  LOCATION_TRANSFER = "LOCATION_TRANSFER",
  VENDOR_TRANSFER = "VENDOR_TRANSFER",
  EXISTING_INVENTORY = "EXISTING_INVENTORY",
  CANNABIS_VENDOR_TRANSFER = "CANNABIS_VENDOR_TRANSFER",
  NON_CANNABIS_VENDOR_TRANSFER = "NON_CANNABIS_VENDOR_TRANSFER"
}

export enum TRANSFERABLE_TYPES {
  PURCHASE_ORDER = "PURCHASE_TRANSFER",
  OUTBOUND_TRANSFER = "OUTBOUND_TRANSFER",
  QUOTE = "QUOTE_TRANSFER"
}

export enum PRICE_POINTS_TYPE {
  MEDICAL = "RETAIL_MEDICAL",
  RECREATIONAL = "RETAIL_RECREATIONAL",
  WHOLESALE_INBOUND = "WHOLESALE_INBOUND",
  WHOLESALE_OUTBOUND = "WHOLESALE_OUTBOUND"
}

export enum PRODUCT_UNIT {
  GRAMS = "g",
  MG = "mg",
  OZ = "oz",
  UNIT = "unit",
  UNIT_READABLE = "u"
}

export enum LAB_RESULT_UNIT {
  PERCENTAGE = "%",
  MG_G = "mg/g"
}

export enum TRANSFER_TYPES {
  TRANSFER = "TRANSFER",
  INBOUND = "INBOUND",
  OUTBOUND = "OUTBOUND",
  OUTGOING = "OUTGOING",
  PURCHASE_ORDER = "PURCHASE_ORDER",
  QUOTE = "QUOTE",
  NEW_BATCH = "INBOUND"
}
export enum PAYMENT_METHODS {
  CASH = "cash",
  CREDIT = "credit",
  CHECK = "check",
  DEBIT = "debit"
}

export namespace BatchTransfer {
  export interface PaginatedResponse<T> {
    pagination: TablePagination;
    data: T;
  }

  export interface Actions {
    [key: string]: Action;
  }

  export interface Action {
    title: string;
    steps: Step[];
  }

  export interface Step {
    label: string;
    component: string;
  }

  export interface PaymentMethodsBreakdown {
    initial: number;
    payment_and_methods: PaymentMethod[];
    outstanding: number;
  }

  export interface PaymentMethod {
    type: number;
    applied: number;
    remaining: number;
    name?: string;
  }

  export interface TaxCount {
    pre_excise: number;
    excise: number;
    post_excise: number;
    per_transaction: number;
  }

  export interface Result {
    profile_id: number;
    value: number;
    unit_representation: string;
  }
  export interface LabResults {
    laboratory_id: number | null;
    testing_date: string;
    results: Result[];
  }

  export interface TransferNote {
    id?: number;
    name: string;
    title: string;
    note: string;
  }

  export interface TransferPrices {
    subTotal: number;
    total: number;
    discount: TransferFlyingDiscount;
    fee: TransferFlyingFee;
    taxCollected: number;
  }

  export interface BatchPrices extends TransferPrices {
    price_per_unit: number;
    cost_per_unit: number;
  }

  export interface TransferFlyingDiscount {
    description: string;
    amount: number;
    type: DISCOUNT_FEE_TYPES;
    postTaxDiscount: boolean;
    value: number;
  }

  export interface TransferFlyingFee {
    description: string;
    amount: number;
    type: DISCOUNT_FEE_TYPES;
    postTaxFee: boolean;
    value: number;
  }

  export interface List {
    [key: string]: any;
    id: number;
    name: string;
    value?: string;
  }

  export interface FilterScopeTransfer {
    locations: BatchTransfer.List[];
    vendors: BatchTransfer.List[];
  }

  export const defaultFilterScope = {
    locations: [],
    vendors: []
  };

  export interface SelectOption {
    text: string;
    value: string;
  }

  export interface SelectPID {
    text: string;
    value: object;
  }

  export interface Transfer {
    initialLength?: number;
    currentProduct?: string | null;
    name?: string;
    barcode_id?: string;
    data?: {
      data: InboundTransferItem[];
    };
    id?: number;
    uid?: string;
    barcode?: string;
    transfer_type: TRANSFER_TYPES;
    notes: TransferNote[];
    items: Batch[];
    pricing_enabled: boolean;
    is_payable?: boolean;
    prices: TransferPrices;
    destination?: Vendor | Location;
    source_id?: number | string;
    source?: Vendor | Location;
    source_type?: TRANSFER_SOURCE;
    payment_methods_breakdown?: PaymentMethodsBreakdown;
    updated_at: string;
    created_at?: string;
    status: TRANSFER_STATUS;
    transferable?: Transfer;
    transferable_type?: TRANSFERABLE_TYPES;
    transferable_id?: number;
    sourceable_transfer_type?: string;
    product?: string;
  }

  export interface CurrentVendor {
    barcode?: string;
    id?: number;
    items?: Batch[];
    notes?: TransferNote[];
    prices?: TransferPrices;
    pricing_enabled?: boolean;
    purchase_order_number?: string;
    request_on_or_before?: string;
    source?: Vendor | Location;
    source_id?: number | string;
    source_type?: TRANSFER_SOURCE;
    license?: string;
    status: TRANSFER_STATUS;
    transfer_type?: string;
    updated_at?: string;
    user?: User;
    user_id?: number;
  }

  export interface LocationTransfer {
    id?: number;
    name?: string;
    license_number?: string;
    location?: {
      name?: string;
      license_number?: string;
    };
    manifest?: {
      barcode?: string;
    };
  }

  export interface ManifestId {
    text?: string;
    value?: { manifest_id?: number; transfer_date?: number };
  }

  export interface Batch {
    notatLocation?: boolean;
    addedNew?: boolean;
    lab_results?: any;
    currentProductName?: string | number;
    currentProduct?: string;
    is_return?: number;
    unit?: PRODUCT_UNIT | null;
    strain?: Strain | null;
    name?: string | null;
    barcode_id?: string;
    inventorytype?: string | number;
    sale_price?: string | number;
    id?: number | null;
    persisted?: boolean;
    batch_level_pricing?: number | null;
    expiration_date?: string;
    product?: Product;
    tax_category_id: number | null;
    tax_category?: TaxCategory;
    usable_weight_value?: number;
    usable_weight_unit?: PRODUCT_UNIT;
    tax_count?: TaxCount;
    quantity: number;
    prices: BatchPrices;
    destroy?: boolean;
    weight_per_unit_value?: number;
    weight_per_unit_unit?: PRODUCT_UNIT;
    rooms?: RoomDef[];
    sku?: string | null;
    // This is only used in forms that have a batch select
    batch?: InventoryBatch;
  }
  export interface PurchaseOrder extends Transfer {
    items: PurchaseOrderItem[];
    user?: any;
    purchase_order_number: string;
    request_on_or_before: string;
    source_type: TRANSFER_SOURCE;
    source_id: number | string;
    source?: Location | Vendor;
    license?: string;
  }
  export interface PurchaseOrderItem extends Batch {
    usable_weight_value?: number;
    usable_weight_unit?: PRODUCT_UNIT;
  }
  export interface InboundTransfer extends Transfer {
    data?: any;
    invoice_id?: number | null;
    transfer_id?: string;
    transferable_type: TRANSFERABLE_TYPES;
    transferable_id: number;
    transferable?: any;
    transfer?: {
      items?: InventoryBatch[];
    };
    source_type: TRANSFER_SOURCE;
    sourceable_transfer_type?: string;
    sourceable_id?: number | string;
    source_id: number | string;
    manifest_id?: string;
    source?: Location | Vendor;
    location_id?: number;
    location?: Location;
    user_id?: number;
    user?: User;
    payments?: ExternalBatchTransfer.Payment[];
    items: InboundTransferItem[];
    payment_methods_breakdown: PaymentMethodsBreakdown;
    is_voidable?: boolean;
    is_payable?: boolean;
  }
  export interface InboundTransferItem extends Batch {
    atLocation?: boolean;
    batch_type_id?: string | null | undefined;
    fromApi?: boolean;
    addedNew?: boolean;
    tax?: number;
    priceUpdated?: boolean;
    batch_uid?: string;
    secondary_id?: string;
    expiration_date?: string;
    lab_results?: LabResults;
  }
  export interface OutboundTransfer extends Transfer {
    transfer_id?: string;
    transferable_type?: TRANSFERABLE_TYPES;
    transferable_id: number;
    transferable?: any;
    destination_type: TRANSFER_SOURCE;
    destination_id: number | string;
    destination?: Location | Vendor;
    location_id?: number;
    location?: Location;
    user_id: number;
    user?: User;
    items: OutboundTransferItem[];
    payment_methods_breakdown: PaymentMethodsBreakdown;
    is_payable: boolean;
    manifest: Manifest;
  }
  export interface OutboundTransferItem extends Batch {
    batch_uid: string;
    secondary_id?: string;
    expiration_date: string;
    lab_results: LabResults;
  }
  export interface Quote extends Transfer {
    items: QuoteItem[];
    user_id: number;
    user?: any;
    quote_number: string;
    request_on_or_before: string;
    destination_type: TRANSFER_SOURCE;
    destination_id: number | string;
    destination?: Location | Vendor;
    location_id?: number;
  }
  export interface QuoteItem extends Batch {
    product_id?: number;
    batch?: InventoryBatch;
    batch_uid: string;
    secondary_id?: string;
  }

  export interface NewBatch extends Transfer {
    transfer_id?: string;
    custom_transfer_id?: string;
    source_type: TRANSFER_SOURCE;
    source_id: number | string;
    source?: Location | Vendor;
    location_id?: number;
    location?: Location;
    user_id: number;
    user?: User;
    items: NewBatchItem[];
    is_payable?: boolean;
    payment_methods_breakdown: PaymentMethodsBreakdown;
    reconciliation?: boolean;
  }
  export interface NewBatchItem extends Batch {
    priceUpdated?: boolean;
    sku?: string | null;
    batch_uid?: string;
    secondary_id?: string;
    batch_level_pricing?: number;
    expiration_date: string;
    lab_results: LabResults;
    biotrack_traceability_id?: string;
    state_reporting_type?: string;
  }
  export interface Manifest {
    id?: number;
    barcode: string;
    location_id: number;
    location?: Location;
    vehicle_id: number;
    vehicle?: Vehicle;
    driver_id: number;
    driver?: Driver;
    start_date: string;
    end_date: string;
    start_time: string;
    end_time: string;
    drop_off_time: string;
    route?: string;
    status?: string;
    destinations?: Destination[];
  }
  export interface Destination {
    id?: number;
    order: number;
    estimated_travel_time: string | null;
    estimated_arrival: string | null;
    transfer_id: number;
    transfer?: OutboundTransfer;
    address: string;
    route?: string;
    _destroy?: boolean;
  }

  export interface Invoice {
    id: number;
    barcode: string;
    transfer_id: number;
    transfer?: Transfer;
    payments: InvoicePayment[];
    total: number;
    outstanding: number;
    updated_at: string;
    created_at: string;
    status: TRANSFER_STATUS;
  }
  export interface Inbound {
    id: number;
    barcode: string;
    transfer_id: number;
    transfer_items: Transfer[];
    transfer?: Transfer;
    payments: ExternalBatchTransfer.Payment[];
    total: number;
    outstanding: number;
    updated_at: string;
    created_at: string;
    status: TRANSFER_STATUS;
    items: InventoryBatch[];
  }
  export interface InvoicePayment extends PaymentMethod {
    id?: number;
    temp_id?: number;
    paymentMethod?: string;
    payment_method_id?: number;
    created_at: string;
    updated_at: string;
  }
  export interface InboundPayment extends PaymentMethod {
    id?: number;
    temp_id?: number;
    paymentMethod: string;
    created_at: string;
    updated_at: string;
  }
  export namespace DataTable {
    export enum TABLEACTIONS {
      EDIT = "edit",
      PRINT = "print",
      VOID = "void",
      PRINT_INVOICE = "print-invoice"
    }
    export interface GenericTableDefs {
      headers: TableHeader[];
      sublistHeaders: TableHeader[];
      rowActions?: RowActions[];
      mapper: (data: any) => { [key: string]: any };
    }
    export interface TableDefs<T extends BatchTransfer.Transfer>
      extends GenericTableDefs {
      mapper: TableDataMapper<T>;
    }

    export interface RowActions {
      icon: string;
      action: TABLEACTIONS;
    }
    export type TableDataMapper<T extends BatchTransfer.Transfer> = (
      transfer: T
    ) => { [key: string]: any; id: string | number };
  }
}
export namespace ExternalBatchTransfer {
  export interface Transfer {
    [key: string]: any;
  }
  export interface TransferFlyingDiscount {
    name: string;
    type: DISCOUNT_FEE_TYPES;
    amount: number;
    post_tax: boolean;
  }
  export interface TransferFlyingFee {
    name: string;
    type: DISCOUNT_FEE_TYPES;
    amount: number;
    post_tax: boolean;
  }
  export interface Payment {
    payment_method_id: number;
    amount: number;
    type?: number;
    applied?: number;
  }
  export interface Source {
    sourceable_type: TRANSFER_SOURCE;
    id: number;
    name: string;
    address: string;
  }
  export namespace Input {
    export namespace Invoices {
      export interface Invoice {
        id: number;
        transfer_id: number;
        transfer: Transfer;
        barcode: string;
        payments: InvoicePayment[];
        total: number;
        outstanding: number;
        updated_at: string;
        created_at: string;
        status: TRANSFER_STATUS;
      }
      export interface InvoicePayment extends Payment {
        id: number;
        transfer_invoice_id: number;
        payment_method: { name: string };
        created_at: string;
        upadted_at: string;
      }
    }

    export interface TransferNote {
      id: number;
      notable_type: string;
      notable_id: number;
      name: string;
      title: string;
      note: string;
      created_by: string;
      account_id: number;
      deleted_at: string | null;
      created_at: string;
      updated_at: string;
    }
    export namespace PurchaseOrderTransfer {
      export interface PurchaseOrder extends Transfer {
        id: number;
        account_id: number;
        location_id: number;
        sourceable_type: string;
        sourceable_id: number | string;
        sourceable?: Location | Vendor;
        order_date: string;
        request_at: string;
        sub_total: number;
        total: number;
        barcode: string;
        tax: number;
        status: TRANSFER_STATUS;
        created_at: string;
        updated_at: string;
        discount: TransferFlyingDiscount;
        fees: TransferFlyingFee;
        notes: TransferNote[];
        purchase_order_items: OrderItem[];
      }
      export interface OrderItem {
        id: number;
        purchase_order_id: number;
        sku: string;
        product?: any;
        quantity_value: number;
        quantity_unit: PRODUCT_UNIT;
        unit: PRODUCT_UNIT;
        usable_weight_unit: PRODUCT_UNIT;
        price_per_unit: number;
        cost_per_unit: number;
        usable_weight_value: number;
        weight_per_unit_value?: number;
        weight_per_unit_unit?: PRODUCT_UNIT;
        tax_category_id: number | null;
        tax_total: number;
        total: number;
        created_at: string;
        updated_at: string;
        deleted_at: string | null;
        discount: TransferFlyingDiscount;
        fees: TransferFlyingFee;
      }
    }
    export namespace QuoteTransfer {
      export interface Quote {
        id: number;
        targetable_type: TRANSFER_SOURCE;
        targetable_id: number;
        barcode: string;
        request_at: string;
        sub_total: number;
        tax: number;
        total: number;
        discount: TransferFlyingDiscount;
        fees: TransferFlyingFee;
        payments: Payment[];
        status: TRANSFER_STATUS;
        user_id: number;
        user: any;
        account_id: number;
        location_id: number;
        created_at: string;
        updated_at: string;
        deleted_at: string | null;
        quote_items: QuoteItem[];
        notes: TransferNote[];
        targetable?: Location | Vendor;
      }
      export interface QuoteItem {
        id: number;
        quote_id: number;
        batch_uid: string;
        batch: InventoryBatch;
        product?: Product;
        cost_per_unit: number;
        price_per_unit: number;
        barcode: string;
        unit: PRODUCT_UNIT;
        quantity_value: number;
        quantity_unit: string;
        price_raw: number;
        tax_category_id: number | null;
        tax_total: number;
        total: number;
        discount: TransferFlyingDiscount;
        fees: TransferFlyingFee;
        created_at: string;
        updated_at: string;
        deleted_at: string | null;
        sku: string | null;
      }
    }

    export namespace InboundTransfer {
      export interface Inbound {
        sourceable_transfer_type: string | undefined;
        id: number;
        transfer_id: string;
        transferable_type: TRANSFERABLE_TYPES;
        transferable_id: number;
        transferable: PurchaseOrderTransfer.PurchaseOrder | QuoteTransfer.Quote;
        sourceable_type: TRANSFER_SOURCE;
        is_payable?: boolean;
        invoice_id?: number;
        sourceable_id: number | string;
        sourceable: Location | Vendor;
        type: TRANSFER_TYPES.INBOUND;
        status: TRANSFER_STATUS;
        tax: number;
        sub_total: number;
        total: number;
        discount: TransferFlyingDiscount;
        fees: TransferFlyingFee;
        notes: TransferNote[];
        payments: Payment[];
        user_id: number;
        user?: any;
        account_id: number;
        location_id: number;
        location?: Location;
        created_at: string;
        updated_at: string;
        deleted_at: string | null;
        transfer_items: InboundItem[];
        is_voidable?: boolean;
      }
      export interface InboundItem {
        id: number;
        transfer_id: number;
        sku?: string;
        product?: Product;
        batch_uid?: string;
        batch?: InventoryBatch;
        quantity_value: number;
        quantity_unit: string;
        expiration_date: string;
        unit: PRODUCT_UNIT;
        price_per_unit: number;
        cost_per_unit: number;
        usable_weight_value: number;
        usable_weight_unit: PRODUCT_UNIT;
        tax_category_id: number | null;
        tax_total: number;
        total: number;
        created_at: string;
        updated_at: string;
        payments: Payment[];
        deleted_at: string | null;
        discount: TransferFlyingDiscount;
        fees: TransferFlyingFee;
        sample: {};
      }
    }

    export namespace OutboundTransfer {
      export interface Outbound {
        id: number;
        transfer_id: string;
        transferable_type: TRANSFERABLE_TYPES;
        transferable_id: number;
        transferable: PurchaseOrderTransfer.PurchaseOrder | QuoteTransfer.Quote;
        sourceable_type: TRANSFER_SOURCE;
        sourceable_id: number | string;
        sourceable: Location | Vendor;
        is_payable: boolean;
        type: TRANSFER_TYPES.INBOUND;
        status: TRANSFER_STATUS;
        tax: number;
        sub_total: number;
        total: number;
        discount: TransferFlyingDiscount;
        fees: TransferFlyingFee;
        notes: TransferNote[];
        payments: Payment[];
        user_id: number;
        user?: any;
        account_id: number;
        location_id: number;
        location?: Location;
        created_at: string;
        updated_at: string;
        deleted_at: string | null;
        transfer_items: OutboundItem[];
        manifest: BatchTransfer.Manifest;
      }
      export interface OutboundItem {
        id: number;
        transfer_id: number;
        batch_uid: string;
        batch?: InventoryBatch;
        product?: Product;
        quantity_value: number;
        expiration_date: string;
        quantity_unit: PRODUCT_UNIT;
        price_per_unit: number;
        cost_per_unit: number;
        usable_weight_value: number;
        usable_weight_unit: PRODUCT_UNIT;
        weight_per_unit_value: number;
        weight_per_unit_unit: PRODUCT_UNIT;
        tax_category_id: number | null;
        tax_total: number;
        total: number;
        created_at: string;
        updated_at: string;
        deleted_at: string | null;
        discount: TransferFlyingDiscount;
        fees: TransferFlyingFee;
        sample: {};
      }
    }

    export namespace NewBatchTransfer {
      export interface NewBatch {
        id: number;
        transfer_id: string;
        sourceable_type: TRANSFER_SOURCE;
        sourceable_id: number | string;
        sourceable: Location | Vendor;
        type: TRANSFER_TYPES.NEW_BATCH;
        status: TRANSFER_STATUS;
        tax: number;
        sub_total: number;
        is_payable?: boolean;
        total: number;
        discount: TransferFlyingDiscount;
        fees: TransferFlyingFee;
        notes: TransferNote[];
        payments: Payment[];
        user_id: number;
        user?: any;
        account_id: number;
        location_id: number;
        location?: Location;
        created_at: string;
        updated_at: string;
        deleted_at: string | null;
        transfer_items: NewBatchItem[];
      }
      export interface NewBatchItem {
        id: number;
        transfer_id: number;
        product_id?: number;
        product?: Product;
        batch?: InventoryBatch;
        batch_uid?: string;
        quantity: number;
        expiration_date: string;
        unit: PRODUCT_UNIT;
        price_per_unit: number;
        cost_per_unit: number;
        usable_weight_value: number;
        usable_weight_unit: PRODUCT_UNIT;
        weight_per_unit_value: number;
        weight_per_unit_unit: PRODUCT_UNIT;
        tax_category_id: number | null;
        tax_total: number;
        total: number;
        created_at: string;
        updated_at: string;
        deleted_at: string | null;
        discount: TransferFlyingDiscount;
        fees: TransferFlyingFee;
        sample: {};
      }
    }
    export namespace ManifestDeclaration {
      export interface Manifest {
        id: number;
        barcode: string;
        location_id: number;
        location?: Location;
        created_at: string;
        updated_at: string;
        deleted_at: null;
        arrival: string;
        departure: string;
        drop_off_time: string;
        route: string;
        status: TRANSFER_STATUS;
        driver_id: number;
        driver?: Driver;
        vehicle_id: number;
        vehicle?: Vehicle;
        destinations: Destination[];
      }
      export interface Destination {
        id?: number;
        estimated_arrival: string;
        estimated_travel_time: string;
        order: number;
        manifest_id: number;
        transfer_id: number;
        transfer?: OutboundTransfer.Outbound;
        route: string;
      }
    }
  }
  export namespace Output {
    export interface Invoice {
      id: number;
      payments: Payment[];
    }
    export interface TransferNote {
      name: string;
      title: string;
      note: string;
    }
    export namespace PurchaseOrderTransfer {
      export interface PurchaseOrder extends Transfer {
        sourceable_type: TRANSFER_SOURCE;
        sourceable_id: number | string;
        request_at: string;
        sub_total: number;
        tax: number;
        total: number;
        discount: TransferFlyingDiscount;
        fees: TransferFlyingFee;
        notes: TransferNote[];
        purchaseOrderItems: OrderItem[];
      }
      export interface OrderItem {
        sku: string;
        quantity_value: number;
        price_per_unit: number;
        cost_per_unit: number;
        subtotal: number;
        total: number;
        tax_category_id: number | null;
        tax_total: number;
        discount: TransferFlyingDiscount;
        fees: TransferFlyingFee;
        usable_weight_value?: number;
        usable_weight_unit?: PRODUCT_UNIT;
        quantity_unit?: "g" | "mg" | "oz" | "u" | null;
      }
    }
    export namespace QuoteTransfer {
      export interface Quote {
        targetable_type: TRANSFER_SOURCE;
        targetable_id: number | string;
        request_at: string;
        sub_total: number;
        tax: number;
        total: number;
        payments: Payment[];
        discount: TransferFlyingDiscount;
        fees: TransferFlyingFee;
        notes: TransferNote[];
        quoteItems: QuoteItem[];
      }
      export interface QuoteItem {
        sku: string;
        batch_uid: string;
        quantity_value: number;
        cost_per_unit: number;
        tax_category_id: number | null;
        price_per_unit: number;
        tax_total: number;
        total: number;
        discount: TransferFlyingDiscount;
        fees: TransferFlyingFee;
      }
    }

    export namespace InboundTransfer {
      export interface Inbound {
        id?: number;
        transferable_type?: TRANSFERABLE_TYPES | string;
        transferable_id?: number | string;
        sourceable_type: TRANSFER_SOURCE;
        sourceable_transfer_type?: string;
        sourceable_id?: number | string;
        manifest_id?: string;
        sub_total: number;
        total: number;
        tax: number;
        type: TRANSFER_TYPES.INBOUND;
        status: TRANSFER_STATUS;
        payments: Payment[];
        is_payable?: boolean;
        discount: TransferFlyingDiscount;
        fees: TransferFlyingFee;
        notes: TransferNote[];
        transfer_items: InboundItem[];
      }
      export interface InboundItem {
        product_id?: number;
        batch_uid?: string;
        expiration_date?: string;
        batch_level_pricing?: number;
        quantity_value: number;
        price_per_unit: number;
        cost_per_unit: number;
        subtotal: number;
        total: number;
        tax_category_id: number | null;
        tax_total: number;
        discount: TransferFlyingDiscount;
        fees: TransferFlyingFee;
        usable_weight_value?: number;
        usable_weight_unit?: PRODUCT_UNIT | null;
        quantity_unit?: "g" | "mg" | "oz" | "u" | null;
      }
    }
    export namespace OutboundTransfer {
      export interface Outbound {
        id?: number;
        transferable_type?: TRANSFERABLE_TYPES;
        transferable_id?: number;
        sourceable_type: TRANSFER_SOURCE;
        sourceable_transfer_type: TRANSFER_SOURCE;
        sourceable_id: number | string;
        is_payable: boolean;
        sub_total: number;
        total: number;
        tax: number;
        type: TRANSFER_TYPES;
        status: TRANSFER_STATUS;
        payments: Payment[];
        discount: TransferFlyingDiscount;
        fees: TransferFlyingFee;
        notes: TransferNote[];
        transfer_items: OutboundItem[];
      }
      export interface OutboundItem {
        batch_uid: string;
        expiration_date?: string;
        batch_level_pricing?: number;
        quantity_value: number;
        price_per_unit: number;
        cost_per_unit: number;
        subtotal: number;
        total: number;
        tax_category_id: number | null;
        tax_total: number;
        discount: TransferFlyingDiscount;
        fees: TransferFlyingFee;
        usable_weight_value?: number | null;
        usable_weight_unit?: PRODUCT_UNIT | null;
        quantity_unit?: "g" | "mg" | "oz" | "u" | null;
      }
    }

    export namespace NewBatchTransfer {
      export interface NewBatch {
        id?: number;
        add_batch_reconciliation?: boolean;
        sourceable_type: TRANSFER_SOURCE;
        sourceable_id: number | string;
        sub_total: number;
        total: number;
        tax: number;
        type: TRANSFER_TYPES.INBOUND;
        status: TRANSFER_STATUS;
        payments: Payment[];
        discount: TransferFlyingDiscount;
        fees: TransferFlyingFee;
        notes: TransferNote[];
        transfer_items: NewBatchItem[];
        is_payable?: boolean;
        sourceable_transfer_type?: string;
      }
      export interface NewBatchItem {
        product_id?: number;
        batch_uid?: string;
        expiration_date?: string;
        batch_level_pricing?: number;
        quantity_value: number;
        price_per_unit: number;
        cost_per_unit: number;
        subtotal: number;
        total: number;
        tax_category_id: number | null;
        tax_total: number;
        discount: TransferFlyingDiscount;
        fees: TransferFlyingFee;
        // sample: { results: LabResult[] };
        state_reporting_type?: string;
        usable_weight_value?: number;
        usable_weight_unit?: PRODUCT_UNIT | null;
        quantity_unit?: "g" | "mg" | "oz" | "u" | null;
      }
    }
    export namespace ManifestDeclaration {
      export interface Manifest {
        drop_off_time: string;
        route: string;
        driver_id: number;
        vehicle_id: number;
        location_id: number;
        departure?: string;
        arrival?: string;
        destinations: Destination[];
      }
      export interface Destination {
        id?: number;
        estimated_arrival?: string;
        estimated_travel_time?: string;
        order: number;
        transfer_id: number;
        route?: string;
        _destroy?: boolean;
      }
    }
  }
}

export const defaultLaboratoryResult: LaboratoryResult = {
  laboratory_id: undefined,
  profile_id: undefined,
  value: null,
  unit_representation: LAB_RESULT_UNIT.PERCENTAGE,
  testing_date: ""
};

interface RoomDef {
  room_id: number;
  quantity: number;
}

export interface SourceType {
  label?: string;
  value: TRANSFER_SOURCE | string;
  text?: string;
}
