import { Vendor, VendorDocuments } from "@/interfaces/vendor";
import { VendorState } from "@/vuex/modules/vendor/vendor.types";
import { RootState } from "@/vuex/types";
import { TablePagination } from "helix-vue-components";
import { GetterTree } from "vuex";

type EmployeeGetter = GetterTree<VendorState, RootState>;

export const getters: EmployeeGetter = {
  vendors(state: VendorState): Vendor[] {
    return state.vendors;
  },
  defaultVendors(state: VendorState): Vendor[] {
    return state.defaultVendors;
  },
  vendorFiles(state: VendorState): VendorDocuments[] | null {
    return state.vendorFiles;
  },
  pagination(state: VendorState): TablePagination | null {
    return state.pagination;
  },
  loading(state: VendorState): boolean {
    return state.loading;
  },
  loadingFiles(state: VendorState): boolean {
    return state.loadingFiles;
  },
  vendorToEdit(state: VendorState): Vendor | null {
    return state.editVendor;
  }
};
