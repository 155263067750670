import { RoomState } from "@/vuex/modules/room/room.types";

export const state: RoomState = {
  rooms: [],
  loading: false,
  editRoom: null,
  modal: false,
  pagination: null,
  allRooms: []
};
