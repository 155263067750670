import {
  convertUTCDateStringToLocalDate,
  FNS_DATE_FORMATS,
  fnsFormatDate,
  fnsParse
} from "../utils/date-fns.utils";

export function fnsFormatToLocalDatetime(date: string) {
  return date
    ? fnsFormatDate(new Date(date), FNS_DATE_FORMATS.EN_BARS_WITH_MERIDIEM_TIME)
    : "";
}

/**
 * @param date: string
 * @returns string
 * Use this filter to convert api dates to local in cases
 * where you only need the date field and dismiss the time.
 * Expects this format: 2020-02-20T15:00:00+00:00
 */
export function fnsSimpleDate(date: string): string {
  return date
    ? fnsFormatDate(date.split("T")[0], FNS_DATE_FORMATS.BARS_DEFAULT)
    : "";
}

/**
 * @param date: string
 * @returns string
 * Use this filter to convert api dates to local.
 * Expects this format: 2020-02-20T15:11:01+00:00
 */
export function fnsCompleteDate(date: string): string {
  return date
    ? fnsFormatDate(
        convertUTCDateStringToLocalDate(date),
        FNS_DATE_FORMATS.BARS_FULL_DATE
      )
    : "";
}

export function fnsTime(time: string) {
  if (time) {
    const split = time.split("+")[0].split(":");
    const today = new Date();
    return fnsFormatDate(
      new Date(
        Date.UTC(
          +today.getFullYear(),
          +today.getMonth(),
          +today.getDate(),
          +split[0],
          +split[1],
          +split[2]
        )
      ),
      FNS_DATE_FORMATS.LT
    );
  }

  return "";
}

/**
 * Turns a basic API date to user readable date.
 * @param date: string
 * @returns string
 */
export function fnsBasicDate(date: string) {
  if (date) {
    const d = date.split("-");
    return `${d[1]}/${d[2]}/${d[0]}`;
  }

  return "";
}
