import { InventoryBatch, ProductVariant } from "@/interfaces/batch";
import { Customer } from "@/interfaces/customer";
import { Discount } from "@/interfaces/discount";
import { Location, Rule } from "@/interfaces/location";
import { PrescriptionDetails } from "@/interfaces/prescriptionDetails";
import { PackageDetail, Pricebreak, Product } from "@/interfaces/product";
import { OrderPayment } from "@/services/paymentMethods.service";
import { PreOrder } from "./orderFulfillment";
import { User } from "./user";

export interface Order {
  online_filled?: boolean;
  call_in_filled?: boolean;
  in_store_filled?: boolean;
  pre_order_mismatch?: boolean;
  account_id?: number | null;
  applied_discounts?: AppliedDiscount[];
  available_discounts?: AvailableDiscount[];
  caregiver?: Customer | null;
  caregiver_id?: string | null;
  created_at?: string;
  credit?: CreditOrder[];
  customer?: Customer | null;
  customer_id?: string | null;
  discounts?: Discount[];
  change_due?: number;
  id?: number | null;
  location?: Location | null;
  location_id?: number | null;
  online_pending?: boolean;
  in_store_pending?: boolean;
  call_in_pending?: boolean;
  orderInvoice?: OrderInvoice;
  orderItemid?: PerRefundItem | null;
  pre_order?: PreOrder;
  order_items?: OrderItem[];
  order_payments?: OrderPayment[];
  pmp_enabled?: 1 | 0 | null;
  pmp_status?: string | null;
  order_id?: number | null;
  operation_uid?: string;
  parent_id?: number;
  paymentDetail?: object | null;
  program_tier_discounts?: AvailableProgramDiscount[];
  sold_at?: string;
  status?: string;
  sub_total?: number | null;
  tax?: number | null;
  taxes?: number | null;
  tax_total?: number | null;
  terminal_id?: number | null;
  total?: number | null;
  trc_receipt_id?: string;
  trc_status?: string;
  user?: User;
  user_id?: number | null;
  warnings?: any;
  line_order_discounts?: number | null;
  order_number?: string;
  marijuana?: 0 | 1;
  address?: string;
  city?: string;
  state?: string;
  order_payment_refunds?: OrderPayment[];
  refunds?: Refund[];
}

export interface OrderPayments {
  payment_method_id: number;
  amount: number;
}

export interface CreditOrder {
  amount: number;
  parent_id: number;
}

export interface PerRefundItem {
  order_id: number;
  order_item_id: number;
  per_unit_refund_value: number;
}

export interface Refund {
  payment_method: {
    id: string | number;
  };
  payment_method_id: string | number;
  order_payments?: OrderPayment[];
  payment_methods: [];
  order_id: number;
  order: Order;
  operation_uid: string;
  products?: ProductVariant[];
  items: Refunditem[];
  pmp_enabled?: 1 | 0 | null;
  sub_total: number;
  tax_total: number;
  total: number;
  discount_total?: number;
  trc_status?: string;
  trc_receipt_id?: string;
  marijuana?: 0 | 1;
  pre_order?: PreOrder;
  order_items?: OrderItem[];
  line_order_discounts?: number | null;
  status?: string;
  source?: string;
  order_item_id?: string | number;
  id: number;
}

export interface Refunditem {
  pmp_upload_status?: string | null;
  sku: string;
  order_item_id: number;
  quantity: number;
  price_raw: number;
  marijuana: 0 | 1;
  order_items?: OrderItem;
  product_info?: ProductInfo;
  batch_uid?: string;
}

export interface AppliedDiscount {
  post_tax: boolean;
  discount_id: number;
  points: number;
  item_id: number;
  items_ids?: number[];
  scope: "LINE_ITEM";
  item_quantity: number;
  auto_apply: boolean;
  auto_apply_quantity: number;
  effective_quantity: number;
  amount: number;
  description: string;
  applicable_amount: number;
  rejected?: boolean;
  codename: string;
  apply_type?: string;
  original_discount_amount?: number | string;
  applied_scope?: "WHOLE_ORDER" | null;
  applied_points_to_whole?: number | null;
  whole_order_unique_id: string | null;
  whole_order_discount_applied?: number;
}
export interface AvailableDiscount {
  discount_id: number;
  quantity: number;
  auto_apply: boolean;
  discount_program: string;
  scope: "LINE_ITEM";
  codename: string;
  items_ids?: number[];
  points?: number;
}

export interface OverrideDiscount {
  description: string | null;
  amount: number | null;
  item_id?: number;
  discount_id: number;
  post_tax: boolean;
}
export interface AvailableProgramDiscount {
  sku?: string;
  loyalty_program_tier_id?: number;
  quantity?: number;
  batch_uid?: string;
  batch_barcode_uid?: string;
}

export interface AvailableDiscountGroup {
  items: number[];
  discount: Discount;
}

export const orderDefault: Order = {
  user_id: null,
  customer_id: null,
  sub_total: 0,
  trc_status: "NOT_UPLOADED",
  tax_total: 0,
  total: 0,
  account_id: null,
  location_id: null,
  id: null,
  order_items: [],
  discounts: [],
  status: "",
  terminal_id: null,
  paymentDetail: null,
  pre_order_mismatch: false,
  operation_uid: ""
};

export interface LoyalityProgram {
  account_id: number;
  auto_apply: number;
  created_at: string;
  id: number;
  location_id: number;
  loyalty_point_id: number;
  name: string;
  program_based_on: number;
  updated_at: string;
}

export interface ProgramTier {
  based_on_value: number;
  created_at: string;
  cumulative_eligible: boolean;
  id: number;
  loyalty_program: LoyalityProgram;
  loyalty_program_id: number;
  max_redeemable: number;
  price: number;
  product_id: number;
  sku: string;
  updated_at: string;
}

export interface LoyalityItem {
  created_at: string;
  id: number;
  loyalty_program_tier_id: number;
  order_item_id: number;
  points_type: string;
  points_used: number;
  program_tier: ProgramTier;
  updated_at: string;
}

export interface SimpleDiscount {
  name: string;
  amount: number;
  discount_id: number;
}

export interface OrderItemST extends OrderItem {
  structure: {
    discounts_breakdown: SimpleDiscount[];
  };
}

export interface OrderItem {
  refund_subtotal?: number;
  subtotal?: number;
  discount_total?: any;
  tracking_id?: string;
  _destroy?: boolean;
  action?: string;
  available?: number | null;
  base_price?: number | null;
  batch_barcode_uid?: string | null;
  currentPrice?: number;
  discounts?: Discount[];
  id?: number | null;
  line_item_discount_without_taxes?: number;
  line_item_discounts?: number;
  loyalty_item?: LoyalityItem | null;
  loyalty_amount?: string | number | null;
  loyalty_points?: string | number | null;
  order_item_id?: number | null;
  order_item_uid?: string | null;
  pmp_upload_status?: string | null;
  payment?: number | null;
  price_final?: number | null;
  price_raw?: number;
  priceFinalRound?: number | null;
  prices?: Pricebreak[];
  product_info?: ProductInfo;
  product_unit?: string | null;
  quantity?: number | null;
  quantityToRefund?: number | null;
  quantity_unit?: string | null;
  refund?: number;
  refunded?: boolean;
  sku?: string;
  tax_amount?: number;
  taxes?: Tax[];
  totalPricesRefundItems?: number | null;
  unitPriceForRefund?: number;
  // UI generated fields
  batch_sku?: string | null;
  batch_uid?: string | null;
  biotrack_traceability_id?: string;
  batch?: InventoryBatch;
  price_point_by_usable_weight?: number | null;
  product_sku?: string | null;
  product?: Product;
  unit?: string;
  usable_weight_unit?: string;
  usable_weight_value?: number | null;
  new_tacking_id?: string;
  has_preorder_item?: boolean;
  prescription_details?: PrescriptionDetails | null;
  pharmacist_id?: number | null;
  orders_items?: OrderItem | null;
  unit_base_price?: number | null;
  order_items?: OrderItem | null;
  order?: {
    status?: string;
    pre_order?: {
      source?: string;
    };
  };
}
export interface ApplyLoyaltyFields {
  batch?: string | null;
  product?: string;
  loyalty_amount: any;
  loyalty_points: number;
  quantity?: number | null;
  discount?: number | string | null;
  product_unit?: string;
  discounts?: Discount[];
  product_info?: object;
  id?: number | null;
  is_selected: boolean;
  points_limit?: boolean;
  price_final?: number | null | undefined;
}
export interface BalanceItem {
  amount: number;
  created_at: string;
  customer_id: number | null;
  id?: number | null;
  updated_at: string;
}

export interface ResponseItem {
  customer_id: string | null;
  manager_id: number | null;
  type: string;
  amount: number;
  sub_type: string;
  description: string;
}

export interface ProductInfo {
  block_item_from_being_sold_at_zero_cost: boolean;
  block_item_from_being_sold_below_cost_per_unit: boolean;
  cost_per_unit: number | null;
  default_cost_per_unit: number | null;
  marijuana: 0 | 1;
  member_discount_eligible: 0 | 1;
  name: string;
  packaging_detail: PackageDetail;
  price_point_by_usable_weight: 0 | 1;
  product_unit: string;
  requires_weighing: 0 | 1;
  usable_weight_unit: string;
  usable_weight_value: number;
  ndc_number?: string;
}

export interface Tax {
  tax_id: number;
  name: string;
  amount: number;
  tax_type?: string;
  rate?: number;
}

export const overrideDiscount: OverrideDiscount = {
  description: null,
  amount: null,
  discount_id: 0,
  post_tax: false
};

export interface DiscountToApply {
  discount_id: number;
  amount?: number;
  rate?: number;
  points?: number;
  item_id: number;
  description?: string;
  auto_apply?: boolean;
}

export interface DataToAddDiscount {
  discount: AvailableDiscount[] | AvailableDiscount;
  pin?: number;
}
export interface DataToAddProgramDiscount {
  program: AvailableProgramDiscount[] | AvailableProgramDiscount;
  pin?: number;
}

export interface OrderInvoice {
  id: number;
  order_id: number;
  total_usable_weight: TotalUsableWeight;
  total_loyalty_points: TotalLoyaltyPoints;
  remaining_limit: Rule[];
  tax_breakdown: Taxbreakdown;
  created_at: string;
  updated_at: string;
  amount: number;
  consumed: number;
}

export interface Taxbreakdown {
  total_tax: number;
  excise_tax: number;
  normal_tax: number;
  transaction_tax: number;
}

export interface FilterModel {
  startDate: string;
  endDate: string;
  customer: number | null;
  users: number[];
  transactionId: string;
  trcStatus: string;
  sort?: string;
  source: string;
  vendor: string[];
  pmpStatus: string;
}

export interface PrescriptionDetailsWithOrderItems {
  order_item_uid?: string | null;
  sku?: string;
  batch_barcode_uid?: string | null;
  quantity?: number | null;
  prescription_details?: PrescriptionDetails;
  pharmacist_id?: number | null;
}

export const defaultFilterModel = {
  startDate: "",
  endDate: "",
  customer: null,
  users: [],
  transactionId: "",
  trcStatus: "",
  pmpStatus: "",
  source: "",
  vendor: []
};

export interface TotalLoyaltyPoints {
  AMOUNT_SPENT: number;
  LOYALTY_POINTS: number;
  NUMBER_OF_VISITS: number;
}

export interface TotalUsableWeight {
  unit: string;
  quantity: number;
}

export interface AddToCartPayload {
  orderItem: OrderItem[];
  loyalty_program_tier_id: number | null;
}

export interface EditItemInCartPayload {
  item: AddToCartPayload;
  replace: OrderItem;
}

export interface ManualDiscounts {
  item: {
    fixed: number;
    percentage: number;
  };
  order: {
    fixed: number;
    percentage: number;
  };
}

export const DEFAULT_TOTALS = {
  sub_total: 0,
  tax_total: 0,
  total: 0,
  per_transaction_taxes: []
};
export interface RefundInformation {
  refund_sub_total?: number;
  items?: RefundInformationItem[];
  sub_total: number;
  tax_total: number;
  total: number;
  refund_total?: number;
  per_transaction_taxes: PerTransactionInfo[];
}

export interface RefundInformationItem {
  refund_total: number;
  refund_sub_total?: number;
  order_item_id: number;
  total: number;
  price_per_item: number;
}

export interface PerTransactionInfo {
  id: number;
  name: string;
  tax_type: string;
  amount: number;
  tax_id: number;
}

export enum statusOrder {
  /**
   * PARTIALLY_REFUND When an order is made a refound and the total amount of items in the order is not selected
   * PAID_MODIFIED Order where the payment method was modified after having been paid in normal check-out process
   * PAID Order normally paid after a check-out process
   * PENDING current order being processed for a customer served
   * SAVED order saved to be recovered in future purchase processes. examples generated from order fulfillment or saved from POS
   * REFUND Orders that were already returned in full and cannot continue processing for return
   * REFUNDING Status in which order A remains when B is pending in a refund process.
   * CANCEL When an order is void, it should actually be a Cancel.
   * FULFILLED Not implemented
   * ABANDONED Order B that was generated for the return of an order A and that was not confirmed after 10 minutes Of operated.
   */

  partiallyRefound = "PARTIALLY_REFUND",
  paidModified = "PAID_MODIFIED",
  paid = "PAID",
  pending = "PENDING",
  saved = "SAVED",
  refund = "REFUND",
  refounding = "REFUNDING",
  cancel = "CANCEL",
  fullfilled = "FULFILLED",
  abandoned = "ABANDONED"
}

export interface OrderActionCallback {
  hasMetrc: () => boolean;
  refund: (data: Order) => void;
  payment: (data: Order) => void;
  metrcDetail: (data: Order) => void;
  syncMetrcStatus: (data: Order) => void;
  voidSale: (data: Order) => void;
  uploadPmp: (data: Order) => void;
}

export interface RefundHistory {
  operation_uid: string;
  order_id: number;
  order: Order;
  items: RefundHistoryItem[];
  sub_total: number;
  tax_total: number;
  total: number;
  products?: ProductVariant[];
  metadata?: RefundHistoryMetadata;
  order_item_id?: string | number;
  user?: User;
}

export interface RefundHistoryMetadata {
  refund_information: RefundMetadataInformation[];
  integration_exception?: string;
}

export interface RefundMetadataInformation {
  refund_transaction: RefundMetadataTransaction;
  destination: RefundMetadataDestination;
}

export interface RefundMetadataTransaction {
  validate_quantities: boolean;
  order_item_id: number;
  tracking_id: string;
  orderItem: OrderItem;
  quantity: number;
  reason: string;
  previous_refunds: object[];
  quantity_unit: string;
}

export interface RefundMetadataDestination {
  origin: string;
  destination: string;
  to_inventory_location_id: number;
  tracking_id: string;
  restock_to_new_batch: boolean;
  batch_uid: string;
}

export interface RefundHistoryItem {
  sku: string;
  order_item_id: number;
  quantity: number;
  taxes_breakdown?: Tax[];
  discounts_breakdown?: object[];
  batch_uid?: string;
  order_items?: OrderItem;
}

export interface RefundActionCallback {
  hasMetrc: () => boolean;
  metrcDetail: (data: Order) => void;
  syncMetrcStatus: (data: Order) => void;
  uploadPmp: (data: Order) => void;
  voidRefund: (data: Refund) => void;
  reprintReceipts(refund: RefundHistory): void;
  printBarcodes(refund: RefundHistory): void;
  payment(refund: Refund): void;
}

export interface OrderItemsTotal {
  refund_sub_total?: number;
  refund_subtotal?: number;
  available: { [key: string]: number };
  price_final: number;
  price_raw: number;
  quantity: { [key: string]: number };
  quantityToRefund: { [key: string]: number };
  refund: { [key: string]: number };
  tax_amount: number;
  totalPricesRefundItems: number;
  unitPriceForRefund: number;
  wholeOrderTaxes: number;
  subtotal: number;
}

export interface VoidOrderResponse {
  status: string;
  order?: Order;
  errors?: Array<{ message: string }>;
}

export interface ManualDiscountFields {
  batch?: string | null;
  product: string;
  quantity?: number | null;
  discount: number | string | null;
  total_discount: number | string;
  product_unit?: string;
  discounts?: Discount[];
  product_info?: object;
  id?: number | null;
  item_price?: number | null;
  is_selected?: boolean;
}
