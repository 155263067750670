import { Timeclock } from "@/interfaces/timeclock";
import { messagesService } from "@/services/messages.service";
import { timeclockService } from "@/services/timeclock.service";
import { TimeclockState } from "@/vuex/modules/timeclock/timeclock.types";
import { RootState } from "@/vuex/types";
import { TablePagination } from "helix-vue-components";
import { ActionContext, ActionTree } from "vuex";

type TimeclockActionContext = ActionContext<TimeclockState, RootState>;
type TimeclockActionTree = ActionTree<TimeclockState, RootState>;

export const actions: TimeclockActionTree = {
  async loadTimeclocks(context: TimeclockActionContext): Promise<any> {
    try {
      context.commit("setLoading", true);
      const payload: Timeclock[] = await timeclockService.get();
      const pagination: TablePagination = await timeclockService.getPagination();
      context.commit("setPagination", pagination);
      context.commit("setTimeclocks", payload);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setLoading", false);
    }
  },
  async findTimeclock(
    context: TimeclockActionContext,
    id: number
  ): Promise<any> {
    try {
      context.commit("setLoading", true);
      const timeclock: Timeclock | null = await timeclockService.findById(
        Number(id),
        {
          embed: "user,timeclockEvents"
        }
      );
      context.commit("setCurrentTimeclock", timeclock);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setLoading", false);
    }
  },
  async findUserTimeclocks(
    context: TimeclockActionContext,
    employeeId: number
  ): Promise<any> {
    try {
      context.commit("setLoading", true);
      const timeclocks:
        | Timeclock[]
        | null = await timeclockService.findUserTimeclocks(Number(employeeId));
      context.commit("setUserTimeclocks", timeclocks);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setLoading", false);
    }
  },
  async findUserTimeWeek(
    context: TimeclockActionContext,
    employeeId: number
  ): Promise<any> {
    try {
      context.commit("setLoading", true);
      const timeWeek = await timeclockService.findUserTimeWeek(
        Number(employeeId)
      );
      context.commit("setUserTimeWeek", timeWeek);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setLoading", false);
    }
  },
  async filterUserTimeclocks(context, options) {
    try {
      context.commit("setLoadingFiltered", true);
      const timeclocks = await timeclockService.get(options);
      context.commit("setUserFilteredTimeclocks", timeclocks);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setLoadingFiltered", false);
    }
  },
  async checkInTimeclock(
    context: TimeclockActionContext,
    data: object
  ): Promise<any> {
    try {
      context.commit("setLoading", true);
      await timeclockService.post(data);
      // @ts-ignore
      context.dispatch("findUserTimeclocks", data.user_id);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setLoading", false);
    }
  },
  async checkOutTimeclock(
    context: TimeclockActionContext,
    data: { user_id: number; timeclock_id: number }
  ): Promise<any> {
    try {
      context.commit("setLoading", true);
      await timeclockService.applyTransition(data.timeclock_id, "check_out");
      context.dispatch("findUserTimeclocks", data.user_id);
      await context.dispatch("findUserTimeWeek", data.user_id);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setLoading", false);
    }
  },
  async breakInTimeclock(
    context: TimeclockActionContext,
    data: object
  ): Promise<any> {
    try {
      context.commit("setLoading", true);
      await timeclockService.applyTransition(
        // @ts-ignore
        data.timeclock_id,
        "break_time_in"
      );
      // @ts-ignore
      context.dispatch("findUserTimeclocks", data.user_id);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setLoading", false);
    }
  },
  async breakOutTimeclock(
    context: TimeclockActionContext,
    data: object
  ): Promise<any> {
    try {
      context.commit("setLoading", true);
      await timeclockService.applyTransition(
        // @ts-ignore
        data.timeclock_id,
        "break_time_out"
      );
      // @ts-ignore
      context.dispatch("findUserTimeclocks", data.user_id);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setLoading", false);
    }
  },
  async saveTimeclock(
    context: TimeclockActionContext,
    timeclock: Timeclock
  ): Promise<any> {
    context.commit("setLoading", true);
    if (timeclock.id) {
      // @ts-ignore
      timeclock._method = "PUT";
      await timeclockService.put(timeclock, timeclock);
    } else {
      await timeclockService.post(timeclock);
    }
    context.commit("setLoading", false);
    messagesService.renderSuccessMessage("timeclock_saved");
    context.dispatch(
      "RouterModule/go",
      { name: "time-clock-manager" },
      { root: true }
    );
  },
  async deleteTimeclock(
    context: TimeclockActionContext,
    timeclock: Timeclock
  ): Promise<any> {
    try {
      context.commit("setLoading", true);
      await timeclockService.deleteForce(timeclock);
      messagesService.renderSuccessMessage("timeclock_deleted");
      context.dispatch(
        "RouterModule/go",
        { name: "time-clock-manager" },
        { root: true }
      );
      context.commit("setLoading", false);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  },
  async deleteEvent(
    context: TimeclockActionContext,
    data: { timeclockId: number; eventId: number }
  ): Promise<any> {
    try {
      context.commit("setLoading", true);
      await timeclockService.deleteEvent(data.timeclockId, data.eventId);
      messagesService.renderSuccessMessage("timeclock_event_deleted");
      context.commit("setLoading", false);
    } catch (e) {
      messagesService.renderWarningMessage("timeclock_event_no_delete");
    }
  },
  // Is used only if a change request is approved and timeclocks have changed
  async resetFilteredTimeclocks(context) {
    context.commit("setUserFilteredTimeclocks", []);
  }
};
