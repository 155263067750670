import { Customer } from "@/interfaces/customer";
import { Order } from "@/interfaces/order";
import { EventBus } from "@/internal";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./TablePatientSelect.template.vue";
@Component({
  mixins: [Template],
  filters: {
    fixName(name: string) {
      return name.length > 15 ? name.substr(0, 15) + "..." : name;
    }
  }
})
export default class TablePatientSelectComponent extends Vue {
  @Prop({ required: true }) public caregiver!: Customer;

  @Prop({ required: true, default: null }) public patient!: Customer;

  public async selectPatient() {
    EventBus.$emit("open-modal-patient");
  }

  public get inCheckout() {
    return this.$route.name === "check-out";
  }

  public get label(): string {
    const data = this.caregiver;
    const label = `${data.first_name} ${data.last_name}`;
    return label ? label : "no data";
  }

  public get selectedPatient(): string {
    return this.patient &&
      this.patient.customer_id !== this.caregiver.customer_id
      ? this.patient.first_name!.substr(0, 1) + " " + this.patient.last_name
      : "";
  }
}
