import { Till } from "@/interfaces/money";
import { User } from "@/interfaces/user";
import { UserState } from "@/vuex/modules/user/user.types";
import { RootState } from "@/vuex/types";
import { GetterTree } from "vuex";

type UserGetter = GetterTree<UserState, RootState>;

export const getters: UserGetter = {
  users(state: UserState): User[] {
    return state.users.map(user => {
      return {
        ...user,
        name: `${user.first_name ? user.first_name : ""} ${
          user.middle_name ? user.middle_name : ""
        } ${user.last_name ? user.last_name : ""}`
      };
    });
  },
  loading(state: UserState): boolean {
    return state.loading;
  },
  currentTill(state: UserState): Till | null {
    return state.currentTill;
  }
};
