import { Location } from "@/interfaces/location";
import { store } from "@/internal";
import { RootState } from "@/vuex/types";
import find from "lodash/find";
import get from "lodash/get";
import { GetterTree } from "vuex";
import { PermissionsState, UserPermissions } from "./permission.types";

type PermissionsGetter = GetterTree<PermissionsState, RootState>;

export const getters: PermissionsGetter = {
  userPermissions(state: PermissionsState): UserPermissions | null {
    return state.userPermissions;
  },
  hasPermission(state: PermissionsState) {
    const location: Location = store.getters["AuthModule/currentLocation"];
    return (policyPath: string, tag: string = "bdi") => {
      const policy = get(state.userPermissions, `${tag}.${policyPath}`);
      if (policy && policy.locations_count) {
        return !!find(policy.options, ["location_id", location.id]);
      }
      return !!policy;
    };
  }
};
