import { ReconciliationDuplicateOrder } from "@/interfaces/retailSalesReconciliation";
import { i18n } from "@/plugins/i18n";
import { MutationTree } from "vuex";
import { SaleReconciliationState } from "./saleReconciliation.types";

type TypeMutationTree = MutationTree<SaleReconciliationState>;

export const mutations: TypeMutationTree = {
  setOrder(state: SaleReconciliationState, data: ReconciliationDuplicateOrder) {
    state.order = {
      date_time: data.transaction_date_time,
      customer: `${data.order.customer.first_name} ${
        data.order.customer.last_name
      }`,
      transaction_id: data.order.transaction_id,
      metrc_receipt_id: data.order.trc_receipt_id,
      subtotal: data.order.sub_total!.toFixed(2),
      sale_type: i18n
        .t(
          `metrc.retail_sales_reconciliation.sales_type.${
            data.order.transaction_type
          }`
        )
        .toString(),
      status: data.order.trc_status,
      order: data.order,
      duplicates: data.duplicates,
      license_number: data.license_number,
      transaction_date_time: data.transaction_date_time
    };
  },
  setDuplicates(
    state: SaleReconciliationState,
    data: ReconciliationDuplicateOrder
  ) {
    state.duplicates = data.duplicates;
  },
  setLicenseNumber(
    state: SaleReconciliationState,
    data: ReconciliationDuplicateOrder
  ) {
    state.license_number = data.license_number;
  },
  setTransactionDateTime(
    state: SaleReconciliationState,
    data: ReconciliationDuplicateOrder
  ) {
    state.transaction_date_time = data.transaction_date_time;
  }
};
