import { JournalNotes } from "./journalNotes";

export interface Notification {
  text: string | string[];
  timeout?: number;
  color?: string;
  icon?: string;
  y?: "top" | "bottom" | undefined;
  x?: "left" | "right" | undefined;
}

export const NotificationDefault: Notification = {
  text: "",
  timeout: 5000,
  color: "primary",
  y: "top"
};

export interface ModalNotification {
  title: string;
  body: string;
  captionButton: string;
}
export interface ModalConfirm {
  title: string;
  icon?: string;
  body: { [key: string]: string };
  bodyParams?: string;
}
export interface ReportModal {
  title: string;
  icon?: string;
  body: { [key: string]: string };
  bodyParams?: string;
  rejectBtnText?: string;
  resolveBtnText?: string;
  showRejectBtn?: boolean;
  showResolveBtn?: boolean;
}

export interface PusherNotification {
  message: PusherNotificationData[];
}

export interface PusherNotificationData {
  created_at: string;
  event_type: string;
  object_type: string;
  model_name: string;
  meta: any;
  payload: { [key: string]: any } | Array<{ [key: string]: any }>;
  action: string;
  user_id: number;
  item_id: number | string;
  user: {
    middle_name: string;
    last_name: string;
    id: number;
    first_name: string;
    email: string;
  };
}

export interface BiotrackTraceNotification {
  title?: {
    name: string;
    style: string;
  };
  status?: {
    name: string;
    style: string;
  };
  message?: {
    name: string;
    style: string;
  };
  description?: {
    name: string;
    style: string;
  };
  justifyContent?: string;
  icon?: {
    name?: string;
    size?: string;
    color?: string;
  };
  titleIcon?: {
    name?: string;
    size?: string;
    color?: string;
  };
  titleAvatar?: {
    name?: string;
    size?: string;
    color?: string;
  };
  cancelButton?: boolean;
  acceptButton?: boolean;
  acceptButtonValue?: string;
  loading?: boolean;
  customerJournalNotes: JournalNotes[];
  id: string;
}
