export const pusherEvents = {
  /// catalog module
  batchTypeTouched: "Catalog\\BatchType",
  brandTouched: "Catalog\\Brand",
  productCategoryTouched: "Catalog\\ProductCategory",
  productTouched: "Catalog\\Product",
  productVariantTouched: "Catalog\\ProductVariant",
  strainTouched: "Catalog\\Strain",
  vendorTouched: "Catalog\\Vendor",

  /// inventory module
  batchTouched: "Inventory\\Batch",
  roomTouched: "Inventory\\Location",

  /// dispensary
  driver: "Dispensary\\Driver",
  enforcementLimits: "Dispensary\\EnforcementLimit",
  fixedDiscountTouched: "Dispensary\\FixedDiscountProgram",
  invoiceTouched: "Dispensary\\Invoice",
  manifestTouched: "Dispensary\\Manifest",
  memberLevelTouched: "Dispensary\\MemberLevel",
  percentageDiscountTouched: "Dispensary\\PercentageDiscountProgram",
  purchaseOrderTouched: "Dispensary\\PurchaseOrder",
  retailSettings: "Dispensary\\RetailSetting",
  tagTouched: "Dispensary\\Tag",
  taxCategoryTouched: "Dispensary\\TaxCategory",
  templateTouched: "Dispensary\\Template",
  timeclockTouched: "Dispensary\\Timeclock",
  transferTouched: "Dispensary\\Transfer",
  orderFulfillment: "Dispensary\\OrderFulfillment",
  quoteTouched: "Dispensary\\Quote",
  vehicle: "Dispensary\\Vehicle",

  /// money manager
  addDepositToTill: "Money\\AddDepositToTill",
  addFromSafeToTill: "Money\\AddFromSafeToTill",
  addFromTillToSafe: "Money\\AddFromTillToSafe",
  addManagerReview: "Money\\AddManagerReview",
  cashPickupWithdrawal: "Money\\RemoveCashPickupWithdrawal",
  closeManagerReview: "Money\\CloseManagerReview",
  closeTill: "Money\\Employee_count",
  depositToSafe: "Money\\AddDepositToSafe",
  openTill: "Money\\Open",
  removeDepositToSafe: "Money\\RemoveDepositToSafe",
  removeFromSafeToTill: "Money\\RemoveFromSafeToTill",
  removeFromTillToSafe: "Money\\RemoveFromTillToSafe",
  removeManagerReview: "Money\\RemoveManagerReview",
  removePayoutFromTill: "Money\\RemovePayoutFromTill",
  removePayoutFromSafe: "Money\\RemovePayoutFromSafe",
  removeWithdrawalFromSafe: "Money\\RemoveWithdrawalFromSafe",

  /// pricing module
  batchPriceLevelTouched: "Price\\PricingGroup",

  //////////// FROM CORE
  locationTouched: "Core\\Location",

  //////////// CUSTOM
  batchAudit: "InventoryManager\\Audit",
  batchAuditReservation: "InventoryManager\\Reservation",
  batchAction: "Inventory\\BatchOperation",
  customer: "Dispensary\\Customer",
  customerProfile: "Dispensary\\CustomerProfile",
  orderPayment: "Dispensary\\Order",
  orderVoid: "Dispensary\\OrderVoid",
  timeclockChangeRequest: "Dispensary\\Timeclock"
};
