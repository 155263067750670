import { SearchQuery } from "@/interfaces/httpQuery";
import { Room } from "@/interfaces/room";
import { store } from "@/internal";
import HttpService from "./http.service";

class RoomService extends HttpService {
  protected uri: string = "/rooms";
  protected loadDispatcher: string = "RoomModule/loadRooms";
  protected searchableField: SearchQuery[] = [
    {
      field: "name",
      type: "contains"
    }
  ];

  public setQuery(query: object | null = {}) {
    super.resetQuery();
    this.query = { ...this.query, ...query };
  }

  public async get(): Promise<Room[]> {
    this.query = { ...this.query };
    const response = await super.get(this.query);
    return response.data.data;
  }

  public async getAll(): Promise<Room[]> {
    this.query = { ...this.query, ...{ no_pagination: true, sort: "name" } };
    const response = await super.get(this.query);
    return response.data;
  }

  public async removeRoom(room: Room) {
    const response = await super.delete(room);
    return response.data;
  }

  public async save(model: Room): Promise<Room> {
    if (model.id) {
      return this.put(model, model);
    } else {
      return await this.post(model);
    }
  }

  public async getRoom(roomId: string) {
    this.uri += `/${roomId}`;
    const response = await super.get({ ...this.query, id: roomId });
    this.uri = this.uri.replace(/\/\d/, "");
    return response.data;
  }

  public async updateRoom(room: Room) {
    store.dispatch("RouterModule/go", {
      name: "room-list"
    });
    return super.put(room, room);
  }

  public toggleRoom(room: Room, isActive: boolean): Promise<void> {
    if (isActive) {
      return super.enable(room);
    }
    return super.disable(room);
  }
}

export const roomService: RoomService = new RoomService();
