import { render, staticRenderFns } from "./ChangesNotification.template.vue?vue&type=template&id=7c30208c&scoped=true&"
var script = {}
import style0 from "./ChangesNotification.template.vue?vue&type=style&index=0&id=7c30208c&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c30208c",
  null
  
)

export default component.exports