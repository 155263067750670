import PurchasingDetailsComponent from "@/components/retail/customers/customersForm/purchasingDetails/PurchasingDetails.component";
import { Customer } from "@/interfaces/customer";
import { Discount } from "@/interfaces/discount";
import { RuleConfig } from "@/interfaces/limits";
import {
  LocationSalesConfig,
  LocationSalesLimits
} from "@/interfaces/location";
import {
  AppliedDiscount,
  AvailableProgramDiscount,
  DataToAddProgramDiscount,
  Order,
  orderDefault,
  OrderItem,
  statusOrder
} from "@/interfaces/order";
import { Ordered } from "@/interfaces/orderFulfillment";
import {
  defaultPrescriptionDetails,
  PrescriptionDetails
} from "@/interfaces/prescriptionDetails";
import { Product } from "@/interfaces/product";
import { RetailSettings } from "@/interfaces/retailSettings";
import { store } from "@/internal";
import { CustomerPosTableMetadata } from "@/metadata/customer";
import { customerService } from "@/services/customer.service";
import { discountManagerService } from "@/services/discountManager.service";
import { limitsService } from "@/services/limits.service";
import { localStorageService } from "@/services/localStorage.service";
import { messagesService } from "@/services/messages.service";
import { orderActionService } from "@/services/order.action.service";
import { orderService } from "@/services/order.service";
import { orderFulfillmentService } from "@/services/orderFulfillment.service";
import { pharmacistService } from "@/services/pharmacist.service";
import { productService } from "@/services/product.service";
import { getParentSKU } from "@/utils/batch-actions.utils";
import {
  FNS_DATE_FORMATS,
  fnsFormatDate,
  UTCTimeToLocalDate
} from "@/utils/date-fns.utils";
import { OrderState } from "@/vuex/modules/order/order.types";
import { RootState } from "@/vuex/types";
import { TablePagination } from "helix-vue-components";
import Cookie from "js-cookie";
import chunk from "lodash/chunk";
import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";
import flatMap from "lodash/flatMap";
import orderBy from "lodash/orderBy";
import { ActionContext, ActionTree } from "vuex";

type OrderActionContext = ActionContext<OrderState, RootState>;
type OrderActionTree = ActionTree<OrderState, RootState>;

const checkLimits = (
  warnings: { require_security: boolean; limits?: string[] },
  context: OrderActionContext
) => {
  if (warnings.limits && warnings.limits.length) {
    const message = messagesService.flatErrors(warnings.limits);

    const saleHourMessage = salesHourMessage(message, context);
    if (saleHourMessage.isSaleHourMessage) {
      messagesService.renderWarningMessage(saleHourMessage.warningMessage);
      return;
    }
    if (warnings.require_security) {
      messagesService.renderWarningMessage(saleHourMessage.warningMessage);
    }
  }
};

const salesHourMessage = (
  message: string | string[],
  context: OrderActionContext
) => {
  const result = {
    warningMessage: message,
    isSaleHourMessage: false
  };
  if (typeof message === "string") {
    if (message.includes("sales_hour_limit")) {
      const warningMessage = warningsMessage(message, context);
      message = warningMessage;
      result.isSaleHourMessage = true;
    }
    result.warningMessage = [message];
  } else {
    const index = message.findIndex(msg => msg.includes("sales_hour_limit"));
    const warningMessage = warningsMessage(message[index], context);
    message[index] = warningMessage;
    result.warningMessage = message;
    result.isSaleHourMessage = true;
  }
  return result;
};

const warningsMessage = (message: string, context: OrderActionContext) => {
  const limitConfig = context.rootGetters["AuthModule/limitConfig"];
  const saleHour = limitConfig.find(
    (item: LocationSalesLimits) => item.type === "sale_hour"
  );
  const config = saleHour!.configs.find(
    (configItem: LocationSalesConfig) => configItem.config.type === "hours"
  );
  const fromTime = fnsFormatDate(
    UTCTimeToLocalDate(config!.config.from),
    FNS_DATE_FORMATS.LT
  );
  const toTime = fnsFormatDate(
    UTCTimeToLocalDate(config!.config.to),
    FNS_DATE_FORMATS.LT
  );
  const salesHourLimitMessage = `${fromTime} - ${toTime}`;

  const warningMessage =
    message && message.replace("sales_hour_limit", salesHourLimitMessage);
  return warningMessage;
};

export const actions: OrderActionTree = {
  async loadOrder(context: OrderActionContext): Promise<any> {
    if (!context.state.order.id) {
      const location = context.rootGetters["AuthModule/currentLocation"];
      const order: Order = localStorageService.get("order_saved");
      order &&
      order.id &&
      Number(order.account_id!) === location.account_id &&
      order.location_id === location.id
        ? context.commit("setOrder", order)
        : context.dispatch("newOrder");
    }
  },

  async findOrder(
    context: OrderActionContext,
    currentOrder: Order
  ): Promise<any> {
    try {
      context.commit("setLoading", true);
      let order = currentOrder;
      if (!!currentOrder.id) {
        order = (await orderService.find(currentOrder.id, "preOrder")) as Order;
        /**To check order Item have prescription detail or not
         * if not to check and get Prescription detail for that item from Products List
         */
        const selectedPharmacist = context.getters.pharmacistSelected;
        const orderItems: OrderItem[] = await orderItemPrescriptionDetail({
          orderItems: order.order_items || [],
          pharmacistId: selectedPharmacist,
          doctorId:
            currentOrder!.customer &&
            currentOrder!.customer!.profiles &&
            currentOrder!.customer!.profiles[0]!.doctor_id
              ? String(currentOrder!.customer!.profiles[0]!.doctor_id)
              : null
        });
        order.order_items = orderItems;
        order.customer = currentOrder.customer;
        order.pre_order = order.pre_order;
      }
      context.dispatch("setOrder", order);
      if (order.customer) {
        context.dispatch("CustomerModule/setCustomer", order.customer, {
          root: true
        });
      }
      context.dispatch("customerLimits");
    } catch (e) {
      messagesService.renderErrorMessage(e);
      context.dispatch("newOrder");
    } finally {
      context.commit("setLoading", false);
    }
  },

  async orderRefund(context: OrderActionContext, order: Order): Promise<any> {
    context.dispatch("setOrder", order);
    store.dispatch("RouterModule/go", {
      name: "pos"
    });
  },

  async newOrder(context: OrderActionContext): Promise<any> {
    const order = {
      ...orderDefault,
      user_id: context.rootGetters["AuthModule/user"].id,
      customer_id:
        (context.rootGetters["CustomerModule/currentCustomer"] &&
          context.rootGetters["CustomerModule/currentCustomer"].customer_id) ||
        null
    };
    context.dispatch("setOrder", order);
  },

  async saveNewOrder(
    context: OrderActionContext,
    orderItem?: OrderItem
  ): Promise<any> {
    try {
      const orderStore: Order = cloneDeep(context.state.order);
      const currentCustomer =
        context.rootGetters["CustomerModule/currentCustomer"];
      const purchasingBy =
        (currentCustomer && currentCustomer.purchasingBy) || null;
      orderStore.order_items = orderItem ? [orderItem] : [];
      const profiles = (currentCustomer && currentCustomer.profiles) || null;
      context.commit("setLoading", true);
      const response = await orderService.createOrder(
        orderService.parseOrder(orderStore)
      );
      const orderSaved: Order = response;
      orderSaved.customer = { ...currentCustomer, purchasingBy, profiles };
      if (orderSaved.warnings) {
        checkLimits(orderSaved.warnings, context);
      } else {
        messagesService.renderSuccessMessage("item_added_to_cart");
      }
      const orderItemIndex = orderSaved.order_items!.findIndex(
        item => item.sku === orderItem!.batch_sku
      );
      if (orderItemIndex !== -1) {
        orderSaved.order_items![
          orderItemIndex
        ].prescription_details = orderItem!.prescription_details;
      }
      context.dispatch("setOrder", orderSaved);
      context.dispatch("customerLimits");
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  },

  async setOrder(context: OrderActionContext, order: Order) {
    context.commit("setOrder", order);
    const customer = context.rootGetters["CustomerModule/currentCustomer"];
  },

  async addCustomer(
    context: OrderActionContext,
    customer: Customer
  ): Promise<any> {
    try {
      context.commit("setLoading", true);
      const order: Order = cloneDeep(context.state.order);
      order.customer_id = customer.customer_id;
      order.customer = customer;
      context.dispatch("setOrder", order);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setLoading", false);
    }
  },

  async updateCustomer(
    context: OrderActionContext,
    customer: Customer
  ): Promise<any> {
    try {
      context.commit("setLoading", true);
      context.dispatch("newOrder");
      const customerOrders = await orderService.getCustomerOrders(
        customer.customer_id!
      );
      const customerOrdersSize = customerOrders.length;
      if (customerOrdersSize) {
        const orders = customerOrders.map(order => {
          if (!order.customer) {
            order.customer = customer;
          }
          order.order_items = order.order_items!.map(item => {
            return {
              ...item,
              quantityUnit: `${item.quantity}${
                item.product_info!.requires_weighing
                  ? item.product_info!.product_unit
                  : "u"
              }`
            };
          });
          // For TableCheckinComponent
          if (order.pre_order) {
            const pending = order.pre_order.status === statusOrder.pending;
            const callIn = order.pre_order.source === "CALL_IN";
            const isoof = order.pre_order.source === "IN_STORE";
            return {
              ...order,
              online_filled: !pending && !callIn && !isoof,
              online_pending: pending && !callIn && !isoof,
              call_in_pending: pending && callIn,
              call_in_filled: !pending && callIn,
              in_store_pending: pending && isoof,
              in_store_filled: !pending && isoof
            };
          }
          const status = order.status === statusOrder.saved;
          return { ...order, order_saved: status, pending: !status };
        });
        let orderToSet;
        const modalCaregiver = customer.profiles!.find(
          (obj: any) => obj.profile_type === "CAREGIVER"
        );

        if (modalCaregiver) {
          const patients = await customerService.findPatients(
            customer.customer_id!
          );
          orderToSet =
            customerOrdersSize > 1
              ? await orderActionService.modalGetOrdersCaregiver(
                  customer,
                  patients
                )
              : customerOrders[0];
        } else {
          orderToSet =
            customerOrdersSize > 1
              ? await orderActionService.modalGetOrders(
                  orderBy(orders, "status"),
                  customer
                )
              : customerOrders[0];
        }
        if (orderToSet) {
          // To save Prescription detail for Pre Order either it's Pending or Marked as Filled
          if (
            orderToSet &&
            orderToSet.order_items &&
            orderToSet.pre_order &&
            orderToSet.pre_order.status !== "LOADED" &&
            orderToSet.pre_order.status !== "CANCELED"
          ) {
            for (const preOrderItem of orderToSet.pre_order.items) {
              if (preOrderItem.filled && preOrderItem.filled.length) {
                orderToSet!.order_items = await preOrderItemPrescriptionDetail({
                  orderItems: orderToSet!.order_items,
                  preOrders: preOrderItem.filled
                });
              } else {
                orderToSet!.order_items = await preOrderItemPrescriptionDetail({
                  orderItems: orderToSet!.order_items,
                  preOrders: preOrderItem.ordered
                });
              }
            }
          }

          /**In order to use a saved order, it must be restored and
           * so it changes to status pending to be able to display
           */

          if (orderToSet.id && orderToSet.status === statusOrder.saved) {
            await orderService.restoreOrder(orderToSet.id);
          }

          /**
           * Tells the API that the order selected comes from a pre-order
           * so that it can transition the pre-order
           */
          if (
            orderToSet.pre_order &&
            orderToSet.pre_order.status !== "LOADED" &&
            orderToSet.pre_order.status !== "CANCELED"
          ) {
            const preOrderUpdated = await orderService.restorePreOrder(
              orderToSet.pre_order.uid
            );
            if (preOrderUpdated) {
              orderToSet.pre_order.status = preOrderUpdated.status;
            }
          }
          if (!orderToSet.id || !customerOrdersSize) {
            const retailSettings: RetailSettings =
              context.rootGetters["AuthModule/currentRetailSettings"];
            // If the RS for OFF is ON we create the ISOFF for the Customer from the Order Modal selection
            if (retailSettings.enable_in_store_order_fulfillment_flow) {
              orderToSet = await context.dispatch(
                "createInStorePreorder",
                orderToSet.customer
              );
            } else {
              orderToSet = await orderService.createOrder(
                orderService.parseOrder(orderToSet)
              );
            }
          }
          if (
            orderToSet!.customer!.caregiver &&
            !orderToSet!.customer!.purchasingBy
          ) {
            orderToSet!.customer!.purchasingBy = customer;
          }
          orderToSet!.customer!.serving_number = customer.serving_number;
          context.dispatch("findOrder", orderToSet);
        } else {
          localStorageService.set("order_saved", orderDefault);
          context.dispatch("customerLimits");
        }
      } else {
        const retailSettings: RetailSettings =
          context.rootGetters["AuthModule/currentRetailSettings"];
        // If the RS for OFF is ON we create the ISOFF for the Customer without the Order Modal selection
        if (retailSettings.enable_in_store_order_fulfillment_flow) {
          const order = await context.dispatch(
            "createInStorePreorder",
            customer
          );
          context.dispatch("setOrder", order);
        } else {
          context.dispatch("newOrder");
        }
        context.dispatch("customerLimits");
      }
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setLoading", false);
    }
  },

  async customerLimits(context: OrderActionContext) {
    try {
      const order: Order = cloneDeep(context.state.order);
      if (order.customer_id) {
        let limits: RuleConfig[] = flatMap(
          (order.id && (await limitsService.getOrderConsumptions(order.id))) ||
            (await limitsService.getCustomerConsumptions(order.customer_id))
        );
        if (limits[1] === "one_daily_limit_with_monthly_overall") {
          limits = customerService
            .setOneDailyMonthlyLimitPOS(limits[0])
            .reverse();
          context.commit("setCustomerLimits", chunk(limits, 5)[0]);
        }
        context.commit("setCustomerLimits", chunk(limits, 5)[0]);
      }
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  },

  async addOrderItem(
    context: OrderActionContext,
    items: OrderItem[] // Now the flow handles a multiple orderItems in the request
  ): Promise<boolean> {
    try {
      context.commit("setLoading", true);
      let response;
      const orderStore: Order = cloneDeep(context.state.order);
      const retailSettings: RetailSettings =
        context.rootGetters["AuthModule/currentRetailSettings"];
      if (!orderStore.customer_id) {
        messagesService.renderWarningMessage("add_customer_error");
        return false;
      } else {
        if (!orderStore.id) {
          if (retailSettings.enable_in_store_order_fulfillment_flow) {
            const order = await context.dispatch(
              "createInStorePreorder",
              context.rootGetters["CustomerModule/currentCustomer"]
            );
            context.dispatch("setOrder", order);
            context.dispatch("addOrderItem", items);
            return false;
          } else {
            // Adding process always handle one item at time, for that the acces to the 0 position
            await context.dispatch("saveNewOrder", items[0]);
          }
        } else {
          const customer =
            context.rootGetters["CustomerModule/currentCustomer"];
          orderStore.order_items = items;
          if (customer.purchasingBy) {
            orderStore.caregiver_id = customer.purchasingBy.customer_id;
          }
          const parsedOrder = orderService.parseOrder(orderStore);
          response = await orderService.updateOrder(
            parsedOrder,
            parsedOrder,
            true
          );
          const updateOrderResponse = cloneDeep(response);

          await context.dispatch("customerLimits");
          if (response.warnings) {
            checkLimits(response.warnings, context);
          } else {
            messagesService.renderSuccessMessage("item_added_to_cart");
          }
          const orderToSet: Order = updateCaregiverInOrder(
            response,
            orderStore
          );
          if (response.warnings) {
            orderStore.warnings = response.warnings;
          }
          const orderToSetItemIndex = orderToSet.order_items!.findIndex(
            (item: OrderItem) => item.sku === items[0]!.batch_sku
          );
          const updateOrderResponseItemIndex = updateOrderResponse.order_items!.findIndex(
            (item: OrderItem) => item.sku === items[0]!.batch_sku
          );
          if (
            orderToSetItemIndex !== -1 &&
            updateOrderResponseItemIndex !== -1
          ) {
            orderToSet.order_items![orderToSetItemIndex].prescription_details =
              updateOrderResponse.order_items[
                updateOrderResponseItemIndex
              ].prescription_details;
          }
          context.dispatch("setOrder", orderToSet);
        }
        return true;
      }
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return false;
    } finally {
      context.commit("setLoading", false);
    }
  },

  async deleteOrderItem(
    context: OrderActionContext,
    orderItem: OrderItem
  ): Promise<any> {
    try {
      context.commit("setLoading", true);
      const itemToDelete: OrderItem = {
        order_item_uid: orderItem.order_item_uid,
        _destroy: true
      };
      const orderStore: Order = cloneDeep(context.state.order);

      const wholeOrderIndex = orderItem.discounts!.findIndex(
        item => item.is_whole_order_discount === 1
      );
      if (wholeOrderIndex !== 1) {
        orderStore.applied_discounts = [];
      }

      orderStore.order_items = [itemToDelete];
      const parsedOrder = orderService.parseOrder(orderStore);
      const response = await orderService.updateOrder(
        parsedOrder,
        parsedOrder,
        true
      );
      const orderToSet: Order = updateCaregiverInOrder(response, orderStore);
      context.dispatch("setOrder", orderToSet);
      context.dispatch("customerLimits");

      if (orderToSet && wholeOrderIndex !== -1) {
        context.commit("setLoading", true);
        setTimeout(() => {
          context.dispatch("reApplyWholeOrderDiscount", {
            orderItem
          });
        }, 200);
      } else {
        context.commit("setLoading", false);
      }
    } catch (e) {
      messagesService.renderErrorMessage(e);
      context.commit("setLoading", false);
    }
  },

  async reApplyWholeOrderDiscount(
    context: OrderActionContext,
    data: { orderItem: OrderItem }
  ) {
    try {
      context.commit("setLoading", true);
      const orderStore: Order = cloneDeep(context.state.order);
      const appliedDiscounts = orderStore.applied_discounts!.filter(
        discount =>
          (!discount.whole_order_unique_id ||
            discount.whole_order_unique_id === null) &&
          discount.item_id !== data.orderItem.id
      );
      data.orderItem.discounts!.map(discountItem => {
        if (discountItem.is_whole_order_discount === 1) {
          const discountFields = {
            amount: String(discountItem.whole_order_discount_applied),
            description: discountItem.description || null,
            discount_id: Number(discountItem.discount_id),
            items_ids: orderStore.order_items!.map(mdf => mdf.id),
            post_tax: Boolean(discountItem.post_tax)
          };
          // @ts-ignore
          appliedDiscounts.push(discountFields);
        }
      });
      orderStore.applied_discounts = cloneDeep(appliedDiscounts);

      const parsedOrder = orderService.parseOrder(orderStore);
      const response = await orderService.updateOrder(
        parsedOrder,
        parsedOrder,
        true
      );
      const orderToSet: Order = updateCaregiverInOrder(response, orderStore);
      context.dispatch("setOrder", orderToSet);
      context.dispatch("customerLimits");
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setLoading", false);
    }
  },

  /**
   *
   * @param data: discount: Disctount to be applied
   * pin: Pin code
   * isEdit: flag for override discounts (manual)
   */

  async addDiscount(
    context: OrderActionContext,
    data: {
      discount: AppliedDiscount[];
      pin: number;
      isEdit?: boolean;
      orderItems?: OrderItem[];
      clear_other_line_item_discount?: boolean;
    }
  ) {
    try {
      context.commit("setLoading", true);
      const orderStore: Order = cloneDeep(context.state.order);
      orderStore.order_items = data.orderItems || orderStore.order_items;

      if (!orderStore.applied_discounts) {
        orderStore.applied_discounts = [];
      }
      const appliedDiscounts = cloneDeep(orderStore.applied_discounts);
      if (data.isEdit) {
        orderStore.applied_discounts = orderStore.applied_discounts.reduce(
          (acc: AppliedDiscount[], discount) => {
            if (
              discount.discount_id === data.discount[0].discount_id &&
              discount.item_id === data.discount[0].item_id
            ) {
              if (discount.auto_apply && discount.rejected) {
                delete discount.rejected;
                acc.push(discount);
              } else {
                acc.push(data.discount[0]);
              }
            } else {
              acc.push(discount);
            }

            return acc;
          },
          []
        );
      } else {
        const filteredDiscounts = data.discount.reduce(
          (
            acc: { new: AppliedDiscount[]; autoApply: AppliedDiscount[] },
            d: AppliedDiscount
          ) => {
            if (d.auto_apply) {
              acc.autoApply.push(d);
            } else {
              acc.new.push(d);
            }
            return acc;
          },
          {
            new: [],
            autoApply: []
          }
        );

        orderStore.applied_discounts = [
          ...orderStore.applied_discounts.map(d => {
            if (
              d.rejected &&
              filteredDiscounts.autoApply.find(f => f.item_id === d.item_id)
            ) {
              delete d.rejected;
            }
            return d;
          }),
          ...filteredDiscounts.new
        ];
      }

      if (data.clear_other_line_item_discount) {
        orderStore.applied_discounts = appliedDiscounts;
        if (data.discount[0].items_ids) {
          data.discount[0].items_ids.map(discount => {
            const appliedDiscountsIndex = orderStore.applied_discounts!.findIndex(
              disc => disc.item_id === discount
            );
            orderStore.applied_discounts!.splice(appliedDiscountsIndex, 1);
          });
        } else {
          data.discount.map(discount => {
            const appliedDiscountsIndex = orderStore.applied_discounts!.findIndex(
              disc => disc.item_id === discount.item_id
            );
            orderStore.applied_discounts!.splice(appliedDiscountsIndex, 1);
          });
        }
      }

      const parsedOrder = orderService.parseOrder(orderStore);
      const order = await orderService.updateOrder(
        parsedOrder,
        parsedOrder,
        true,
        data.pin
      );
      const orderToSet: Order = updateCaregiverInOrder(order, orderStore);
      context.dispatch("setOrder", orderToSet);

      if (orderToSet && data.clear_other_line_item_discount) {
        context.commit("setLoading", true);
        setTimeout(() => {
          context.dispatch("reApplyExclisiveOrderDiscount", data.discount);
        }, 200);
      } else {
        messagesService.renderSuccessMessage("discounts_added_successfully");
        context.commit("setLoading", false);
      }
    } catch (e) {
      messagesService.renderErrorMessage(e);
      context.commit("setLoading", false);
    }
  },

  async reApplyExclisiveOrderDiscount(
    context: OrderActionContext,
    discount: AppliedDiscount[]
  ) {
    try {
      context.commit("setLoading", true);
      const orderStore: Order = cloneDeep(context.state.order);

      orderStore.applied_discounts!.push(...discount);

      const parsedOrder = orderService.parseOrder(orderStore);
      const response = await orderService.updateOrder(
        parsedOrder,
        parsedOrder,
        true
      );
      const orderToSet: Order = updateCaregiverInOrder(response, orderStore);
      context.dispatch("setOrder", orderToSet);
      messagesService.renderSuccessMessage("discounts_added_successfully");
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setLoading", false);
    }
  },

  async addProgramDiscount(
    context: OrderActionContext,
    data: DataToAddProgramDiscount
  ): Promise<boolean> {
    try {
      context.commit("setLoading", true);
      const currentOrder = context.state.order;
      if (!currentOrder.id) {
        await context.dispatch("saveNewOrder");
      }
      const orderStore: Order = cloneDeep(context.state.order);
      if (!orderStore.program_tier_discounts) {
        orderStore.program_tier_discounts = [];
      }
      orderStore.program_tier_discounts = [
        ...orderStore.program_tier_discounts,
        ...(data.program as AvailableProgramDiscount[])
      ];
      const parsedOrder = orderService.parseOrder(orderStore);
      const order = await orderService.updateOrder(
        parsedOrder,
        parsedOrder,
        true,
        data.pin
      );
      const orderToSet: Order = updateCaregiverInOrder(order, orderStore);
      messagesService.renderSuccessMessage("program_added_successfully");
      context.dispatch("setOrder", orderToSet);
      return true;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return false;
    } finally {
      context.commit("setLoading", false);
    }
  },

  async removeDiscount(
    context: OrderActionContext,
    data: { discount: AppliedDiscount }
  ) {
    try {
      context.commit("setLoading", true);
      const orderStore: Order = cloneDeep(context.state.order);
      const discountToDelete = data.discount;
      orderStore.applied_discounts = orderStore.applied_discounts!.filter(
        discount => {
          if (discountToDelete.item_id) {
            return (
              discount.item_id !== discountToDelete.item_id ||
              (discount.item_id === discountToDelete.item_id &&
                discount.discount_id !== discountToDelete.discount_id) ||
              !(
                discount.discount_id === discountToDelete.discount_id &&
                Number(discount.original_discount_amount).toFixed(2) ===
                  Number(discountToDelete.original_discount_amount).toFixed(
                    2
                  ) &&
                discount.apply_type === discountToDelete.apply_type
              )
            );
          } else {
            return discount.discount_id !== discountToDelete.discount_id;
          }
        }
      );
      const parsedOrder = orderService.parseOrder(orderStore);
      const order = await orderService.updateOrder(
        parsedOrder,
        parsedOrder,
        true
      );
      const orderToSet: Order = updateCaregiverInOrder(order, orderStore);
      context.dispatch("setOrder", orderToSet);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setLoading", false);
    }
  },

  async removeWholeOrderDiscount(
    context: OrderActionContext,
    discountItem: Discount
  ) {
    try {
      context.commit("setLoading", true);
      const orderStore: Order = cloneDeep(context.state.order);
      orderStore.applied_discounts = orderStore.applied_discounts!.filter(
        discount =>
          !discount.whole_order_unique_id ||
          discount.whole_order_unique_id === null ||
          discount.whole_order_unique_id !== discountItem.whole_order_unique_id
      );
      const parsedOrder = orderService.parseOrder(orderStore);
      const order = await orderService.updateOrder(
        parsedOrder,
        parsedOrder,
        true
      );
      const orderToSet: Order = updateCaregiverInOrder(order, orderStore);
      context.dispatch("setOrder", orderToSet);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setLoading", false);
    }
  },

  async removeAutoApplyDiscount(
    context: OrderActionContext,
    data: { discount: AppliedDiscount }
  ) {
    try {
      context.commit("setLoading", true);
      const orderStore: Order = cloneDeep(context.state.order);
      const discountToDelete: AppliedDiscount = data.discount;
      orderStore.applied_discounts! = orderStore.applied_discounts!.map(
        discount => {
          if (discountToDelete.item_id) {
            return discount.discount_id === discountToDelete.discount_id &&
              discount.item_id === discountToDelete.item_id &&
              discount.auto_apply
              ? { ...discount, rejected: true }
              : discount;
          } else {
            return discount.discount_id === discountToDelete.discount_id &&
              discount.auto_apply
              ? { ...discount, rejected: true }
              : discount;
          }
        }
      );
      const parsedOrder = orderService.parseOrder(orderStore);
      const order = await orderService.updateOrder(
        parsedOrder,
        parsedOrder,
        true
      );
      const orderToSet: Order = updateCaregiverInOrder(order, orderStore);
      context.dispatch("setOrder", orderToSet);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setLoading", false);
    }
  },

  async deleteOrder(context: OrderActionContext) {
    try {
      context.commit("setLoading", true);
      const order = context.state.order;
      if (order.id) {
        await orderService.deleteOrder(order);
      }
      messagesService.renderSuccessMessage("order_deleted");
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setLoading", false);
    }
  },

  async getDiscounts(context: OrderActionContext) {
    try {
      const discounts = await discountManagerService.getDiscounts(true, {
        no_pagination: true
      });
      context.commit("setDiscounts", discounts);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  },

  async getLoyaltyPrograms(context: OrderActionContext) {
    if (context.state.order && context.state.order.customer_id) {
      try {
        const loyaltyConfig = await customerService.getLoyaltyRewards(
          context.state.order.customer_id!
        );
        context.commit("setLoyaltyConfig", loyaltyConfig);
      } catch (e) {
        messagesService.renderErrorMessage(e);
      }
    }
  },

  async loadOrders(context: OrderActionContext) {
    try {
      context.commit("setLoading", true);
      const payload: Order[] = await orderService.getOrdersPaid();
      const pagination: TablePagination | null = await orderService.getPagination();
      context.commit("setOrders", payload);
      context.commit("setPagination", pagination);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setLoading", false);
    }
  },

  async loadOrderPaidOfCaregiver(
    context: OrderActionContext,
    idCargiver: string
  ) {
    try {
      context.commit("setLoading", true);
      const payload: Order[] = await orderService.getOrdersPaidOfCaregiverForPatients(
        idCargiver
      );
      const pagination: TablePagination | null = await orderService.getPagination(
        true
      );
      context.commit("setOrders", payload);
      context.commit("setPagination", pagination);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setLoading", false);
    }
  },

  async filterOrders(
    context: OrderActionContext,
    filters: { [key: string]: any }
  ) {
    try {
      const queryParams: any = [
        {
          model: "endingTime",
          field: "updated_at",
          keyword: "_lteq"
        },
        {
          model: "terminalSelected",
          field: "terminal_id",
          keyword: "_is_in"
        },
        {
          model: "startingTime",
          field: "updated_at",
          keyword: "_gteq"
        },
        {
          model: "customerSelected",
          field: "customer_id",
          keyword: "_equals"
        },
        {
          model: "searchField",
          field: "order_number",
          keyword: "_contains"
        },
        {
          model: "user",
          field: "user_id",
          keyword: "_is_in"
        }
      ];
      context.commit("setLoading", true);
      const filtersParsed = {
        ...filters,
        customerSelected:
          filters.customerSelected && filters.customerSelected.id,
        user: filters.user && filters.user.map((usr: any) => usr.id)
      };
      const query = orderService.getFilterQuery(filtersParsed, queryParams);
      const payload: Order[] = await orderService.get({
        ...query,
        sort: "-updated_at",
        "q[status_is_in]": [
          statusOrder.paid,
          statusOrder.partiallyRefound,
          statusOrder.paidModified,
          statusOrder.refund
        ],
        page: 1
      });
      const pagination: TablePagination | null = await orderService.getPagination();
      context.commit("setOrders", payload);
      context.commit("setPagination", pagination);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setLoading", false);
    }
  },

  async resetState(context: OrderActionContext) {
    context.commit("setOrder", orderDefault);
    context.commit("setOrders", []);
    context.commit("setCustomerLimits", []);
    context.commit("setDiscounts", []);
  },
  async setSelectedPharmacistOnDuty(
    context: OrderActionContext,
    selectedPharmacist: number | null
  ) {
    context.commit("setPharmacistOnDuty", selectedPharmacist);
  },
  async savePrescriptionDetail(
    context: OrderActionContext,
    data: {
      item?: OrderItem;
      orderItemId?: number | null;
      orderItems?: OrderItem[];
    }
  ): Promise<boolean> {
    try {
      context.commit("setLoading", true);
      const orderStore: Order = cloneDeep(context.state.order);
      if (data.orderItems && data.orderItems.length) {
        orderStore.order_items = data.orderItems;
      } else {
        const orderItemIndex = orderStore.order_items!.findIndex(
          orderItem => orderItem.id === data.orderItemId
        );
        if (orderItemIndex !== -1) {
          orderStore.order_items![
            orderItemIndex
          ].prescription_details = data.item!.prescription_details;
        }
        context.state.order.order_items = orderStore.order_items;
        orderStore.order_items = [data.item!];
      }
      const parsedOrder = orderService.parseOrder(orderStore);
      await orderService.updateOrder(parsedOrder, parsedOrder, true);
      return true;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return false;
    } finally {
      context.commit("setLoading", false);
    }
  },
  async updatePrescriptionDetail(
    context: OrderActionContext,
    data: { prescriptionDetails: PrescriptionDetails; pageType: string }
  ): Promise<boolean> {
    try {
      context.commit("setLoading", true);
      if (data.pageType === "orderPage") {
        const orderStore: Order = cloneDeep(context.state.order);
        const orderItemIndex = orderStore.order_items!.findIndex(
          orderItem => orderItem.id === data.prescriptionDetails.order_item_id
        );
        if (orderItemIndex !== -1) {
          orderStore.order_items![orderItemIndex].prescription_details =
            data.prescriptionDetails;
        }
        context.state.order.order_items = orderStore.order_items;
      }
      await orderService.updatePrescriptionDetails(data.prescriptionDetails);
      return true;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return false;
    } finally {
      context.commit("setLoading", false);
    }
  },

  async createInStorePreorder(
    context: OrderActionContext,
    customer: Customer
  ): Promise<Order> {
    const preOrder = await orderFulfillmentService.createInStorePreorder(
      customer.customer_id!
    );

    // The Order at the response does not include the order_items node
    if (preOrder && preOrder.order && !preOrder.order.order_items) {
      preOrder.order.order_items = [];
    }

    // And the customer comes embed into the pre-order only and with only two fields...
    if (preOrder && preOrder.order && !preOrder.order.customer) {
      preOrder.order.customer = customer;
    }

    return preOrder.order;
  }
};

const updateCaregiverInOrder = (lastOrderSaved: Order, orderStore: Order) => {
  if (orderStore.caregiver) {
    lastOrderSaved.caregiver = orderStore.caregiver;
    const profile =
      find(orderStore.caregiver.profiles, ["profile_type", "CAREGIVER"]) ||
      null;
    lastOrderSaved.caregiver_id = (profile && profile.caregiver) || null;
  } else {
    lastOrderSaved.caregiver = null;
    lastOrderSaved.caregiver_id = null;
  }
  lastOrderSaved.customer = orderStore.customer;
  lastOrderSaved.customer!.purchasingBy = orderStore.customer
    ? orderStore.customer.purchasingBy
    : null;
  if (!lastOrderSaved.customer!.profiles) {
    lastOrderSaved.customer!.profiles = orderStore.customer!.profiles;
  }
  return lastOrderSaved;
};

export const preOrderItemPrescriptionDetail = async (data: {
  orderItems?: OrderItem[];
  preOrders: Ordered[];
}) => {
  data.preOrders.map((items: Ordered) => {
    const orderItemIndex = data.orderItems!.findIndex(
      (orderItem: OrderItem) => orderItem.batch_barcode_uid === items.batch_uid
    );
    if (
      orderItemIndex !== -1 &&
      data.orderItems &&
      data.orderItems.length &&
      data.orderItems![orderItemIndex] &&
      data.orderItems![orderItemIndex].product_info &&
      data.orderItems![orderItemIndex].product_info!.marijuana
    ) {
      if (
        !data.orderItems![orderItemIndex].prescription_details ||
        data.orderItems![orderItemIndex].prescription_details === null
      ) {
        const orderPrescriptionDetail = items.prescription_details;
        if (orderPrescriptionDetail) {
          orderPrescriptionDetail!.order_item_id = data.orderItems![
            orderItemIndex
          ].id;
          data.orderItems![
            orderItemIndex
          ].prescription_details = orderPrescriptionDetail;
          orderService.updatePrescriptionDetails(orderPrescriptionDetail);
        }
      }
    }
  });
  return data.orderItems;
};

export const orderItemPrescriptionDetail = async (data: {
  orderItems: OrderItem[];
  pharmacistId: number | null;
  doctorId: string | null;
}) => {
  for (const item of data.orderItems) {
    if (item.product_info && item.product_info!.marijuana) {
      if (!item.prescription_details || item.prescription_details == null) {
        const productDetail: Product = await productService.findBySkuId(
          getParentSKU(item.sku!),
          { load_has_batches: true }
        );
        if (productDetail.prescription_detail) {
          if (item.has_preorder_item) {
            const assignedPharmacistUserPayload =
              Cookie.get("oof_pharmacist") || null;
            productDetail.prescription_detail!.pharmacist_id = Number(
              assignedPharmacistUserPayload
            );
          } else {
            productDetail.prescription_detail!.pharmacist_id =
              data.pharmacistId;
          }
          productDetail.prescription_detail!.doctor_id = data.doctorId;
          productDetail.prescription_detail!.order_item_id = item.id;
          item.prescription_details =
            productDetail.prescription_detail || defaultPrescriptionDetails;
        }
      }
      const prescriptionDetail: PrescriptionDetails | null | undefined =
        item.prescription_details || null;

      if (prescriptionDetail) {
        const [pharmacistList, doctorsList] = await Promise.all([
          pharmacistService.getAll(),
          customerService.getDoctors()
        ]);
        if (prescriptionDetail.pharmacist_id) {
          const pharmacistIndex = pharmacistList.findIndex(
            pharmacist =>
              Number(pharmacist.user_id) ===
              Number(prescriptionDetail!.pharmacist_id)
          );
          if (pharmacistIndex === -1) {
            prescriptionDetail.pharmacist_id = data.pharmacistId || null;
          }
        }

        if (prescriptionDetail.doctor_id) {
          const doctorIndex = doctorsList.findIndex(
            doctor =>
              Number(doctor.id) === Number(prescriptionDetail!.doctor_id)
          );
          if (doctorIndex === -1) {
            prescriptionDetail.doctor_id = data.doctorId || null;
          }
        }
        item.prescription_details = prescriptionDetail;
        orderService.updatePrescriptionDetails(item.prescription_details);
      }
    }
  }
  return data.orderItems;
};
