import { Payouts } from "@/enums/moneyManager";
import { store } from "@/internal";
import { i18n } from "@/plugins/i18n";
import { TableFieldType, TableHeader } from "helix-vue-components";

export const userHasOpenTill = (): boolean => {
  return !!store.getters["UserModule/currentTill"];
};

export const userHasNotOpenTill = (): boolean => {
  return !userHasOpenTill();
};

export const userWithoutOpenFillMessage = (): string => {
  if (userHasOpenTill()) {
    return "";
  }

  return i18n.t("user_without_open_till").toString();
};

export const SafeTableMetadata: TableHeader[] = [
  {
    value: "event_date",
    label: i18n.t("date").toString(),
    class: "tdt__headers__fieldLong",
    fieldComponent: TableFieldType.fnsDate,
    sortable: true
  },
  {
    value: "type/j/sub_type",
    label: i18n.t("action").toString(),
    class: "tdt__headers__fieldLong",
    fieldComponent: TableFieldType.enumText
  },
  {
    value: "amount",
    label: i18n.t("amount").toString(),
    class: "tdt__headers__fieldLong",
    fieldComponent: TableFieldType.currency,
    sortable: true,
    conditionalClassFn: (field: any) => {
      const actionClasses = {
        add: "success-text",
        remove: "error-text"
      };
      // @ts-ignore
      return actionClasses[field.action_type] || "";
    }
  },
  {
    value: "manager.first_name/j/manager.last_name",
    label: i18n.t("user").toString(),
    class: "tdt__headers__fieldLong",
    fieldComponent: TableFieldType.string
  }
];

export const OpenTillTableMetadata: TableHeader[] = [
  {
    value: "created_at",
    label: i18n.t("opened_till_at").toString(),
    class: "tdt__headers__fieldLong",
    fieldComponent: TableFieldType.fnsDate,
    sortable: true
  },
  {
    label: "",
    value: "employee.avatar_thumb_url",
    fieldComponent: TableFieldType.avatar,
    class: "tdt__headers__fieldShort"
  },
  {
    value: "employee.first_name/j/employee.last_name",
    label: i18n.t("employee").toString(),
    class: "tdt__headers__fieldLong",
    fieldComponent: TableFieldType.string
  },
  {
    value: "cash_assigned",
    label: i18n.t("cash_assigned").toString(),
    class: "tdt__headers__fieldLong",
    fieldComponent: TableFieldType.currency
  },
  {
    value: "cash_retail",
    label: i18n.t("cash_retail").toString(),
    class: "tdt__headers__fieldLong",
    fieldComponent: TableFieldType.currency,
    sortable: true
  }
];

export const CloseTillTableMetadata: TableHeader[] = [
  {
    label: "",
    value: "employee.avatar_thumb_url",
    class: "tdt__headers__fieldShort",
    fieldComponent: TableFieldType.avatar
  },
  {
    value: "updated_at",
    label: i18n.t("closed_till_at").toString(),
    class: "tdt__headers__fieldLong",
    fieldComponent: TableFieldType.fnsDate,
    sortable: true
  },
  {
    value: "employee.first_name/j/employee.last_name",
    label: i18n.t("employee").toString(),
    class: "tdt__headers__fieldLong",
    fieldComponent: TableFieldType.string
  },
  {
    value: "cash_assigned",
    label: i18n.t("cash_assigned").toString(),
    class: "tdt__headers__fieldLong",
    fieldComponent: TableFieldType.currency
  },
  {
    value: "cash_retail",
    label: i18n.t("cash_retail").toString(),
    class: "tdt__headers__fieldLong",
    fieldComponent: TableFieldType.custom,
    sortable: true
  },
  {
    value: "employee_count",
    label: i18n.t("employee_count").toString(),
    class: "tdt__headers__fieldLong",
    fieldComponent: TableFieldType.currency
  },
  {
    value: "difference",
    label: i18n.t("difference").toString(),
    class: "tdt__headers__fieldLong",
    fieldComponent: TableFieldType.string,
    conditionalClassFn: (field: any) => {
      if (+field.differenceSign === 0) {
        field.difference = "$0.00";
        return "";
      }
      if (field.differenceSign > 0) {
        if (typeof field.difference === "number") {
          field.difference = `+ $${field.difference.toFixed(2)}`;
        }
        return "success-text";
      }
      if (typeof field.difference === "number") {
        field.difference = `- $${field.difference.toFixed(2)}`;
      }
      return "error-text";
    }
  }
];

export const PayoutsMetadata: TableHeader[] = [
  {
    value: "event_date",
    label: i18n.t("date").toString(),
    class: "tdt__headers__fieldLong",
    fieldComponent: TableFieldType.fnsDate,
    sortable: true
  },
  {
    value: "amount",
    label: i18n.t("amount").toString(),
    class: "tdt__headers__fieldLong",
    fieldComponent: TableFieldType.currency,
    conditionalClassFn: (field: any) => {
      const actionClasses = {
        add: "success-text",
        remove: "error-text"
      };
      // @ts-ignore
      return actionClasses[field.action_type] || "";
    },
    sortable: true
  },
  {
    value: "manager.first_name",
    label: i18n.t("user").toString(),
    class: "tdt__headers__fieldLong",
    fieldComponent: TableFieldType.string
  },
  {
    value: "sub_type",
    label: i18n.t("from").toString(),
    class: "tdt__headers__fieldLong",
    fieldComponent: TableFieldType.enumText,
    enum: Payouts
  },
  {
    value: "user.first_name",
    label: i18n.t("money_manager.till_assignment").toString(),
    class: "tdt__headers__fieldLong",
    fieldComponent: TableFieldType.string,
    conditionalClassFn: (field: any) => {
      return field.sub_type === "PAYOUT_FROM_TILL" ? "" : "hidden";
    }
  }
];

export interface CloseTillFormModel {
  bills: {
    ones: string;
    fives: string;
    tens: string;
    twenties: string;
    fifties: string;
    hundreds: string;
  };
  total_change: string;
  other_amount: string;
  review_count?: string;
  review_difference?: string;
}
