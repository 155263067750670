// @ts-ignore
import { MenuSection } from "@/interfaces/menuSection";
import { Callback } from "@/types/types";
import { RootState } from "@/vuex/types";
import { ActionContext, ActionTree } from "vuex";
import { MegaMenuState } from "./megaMenu.types";

type MegaMenuActionContext = ActionContext<MegaMenuState, RootState>;
type MegaMenuActionTree = ActionTree<MegaMenuState, RootState>;

export const actions: MegaMenuActionTree = {
  async changeTitle(
    context: MegaMenuActionContext,
    title: string
  ): Promise<any> {
    context.commit("setTitle", title);
  },
  async changeSubTitle(
    context: MegaMenuActionContext,
    subTitle: string
  ): Promise<any> {
    context.commit("setSubTitle", subTitle);
  },
  async changeIcon(context: MegaMenuActionContext, icon: string): Promise<any> {
    context.commit("setIcon", icon);
  },
  async changeIconAction(
    context: MegaMenuActionContext,
    iconAction: Callback
  ): Promise<any> {
    context.commit("setIconAction", iconAction);
  },
  async setMenuSections(
    context: MegaMenuActionContext,
    menuSections: MenuSection[]
  ) {
    context.commit("setMenuSections", menuSections);
  }
};
