import { store } from "@/internal";
import { messagesService } from "@/services/messages.service";
import axios, { AxiosError, AxiosResponse } from "axios";
import Cookie from "js-cookie";
import qs from "qs";
import HttpService from "./http.service";

class AuthService extends HttpService {
  private static setCookies(
    accessToken: string,
    refreshToken: string = ""
  ): void {
    Cookie.set("auth_data", accessToken, {
      domain: `${process.env.VUE_APP_COOKIE_DOMAIN}`
    });
    if (refreshToken) {
      Cookie.set("refresh_token", refreshToken, {
        domain: `${process.env.VUE_APP_COOKIE_DOMAIN}`
      });
    }
  }

  public async refreshToken(): Promise<string> {
    const isRefreshing = Cookie.get("refreshing");
    if (isRefreshing) {
      return "";
    }
    const baseURL = process.env.VUE_APP_OAUTH_URL;
    const payload = {
      grant_type: "refresh_token",
      client_id: process.env.VUE_APP_OAUTH_CLIENT_ID,
      refresh_token: Cookie.get("refresh_token")
    };
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          "Basic " +
          btoa(
            `${process.env.VUE_APP_OAUTH_CLIENT_ID}:${
              process.env.VUE_APP_OAUTH_CLIENT_SECRET
            }`
          )
      }
    };
    Cookie.set("refreshing", "1", {
      domain: `${process.env.VUE_APP_COOKIE_DOMAIN}`
    });
    const instance = axios.create({ baseURL });
    return await instance
      .post("/oauth2/token", qs.stringify(payload), config)
      .then((response: AxiosResponse) => {
        const newaccessToken = response.data.access_token;
        AuthService.setCookies(newaccessToken);
        return newaccessToken;
      })
      .catch((error: AxiosError) => {
        if (error.response && error.response.status === 400) {
          messagesService.renderWarningMessage("login_accept_error");
        } else {
          messagesService.renderErrorMessage(error);
        }
        store.dispatch("AuthModule/logout");
        return "";
      })
      .finally(() => {
        Cookie.set("refreshing", "", {
          domain: `${process.env.VUE_APP_COOKIE_DOMAIN}`
        });
      });
  }

  public async revokeToken() {
    const baseURL = process.env.VUE_APP_OAUTH_URL;
    const instance = axios.create({ baseURL });
    const token = Cookie.get("refresh_token");
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          "Basic " +
          btoa(
            `${process.env.VUE_APP_OAUTH_CLIENT_ID}:${
              process.env.VUE_APP_OAUTH_CLIENT_SECRET
            }`
          )
      }
    };
    const response = await instance.post(
      `/oauth2/revoke`,
      qs.stringify({
        token,
        token_type_hint: "refresh_token"
      }),
      config
    );

    return response;
  }
}

export const authService = new AuthService();
