import { MutationTree } from "vuex";
import { Notifications, NotificationState } from "./notifications.types";

type NotificationMutationTree = MutationTree<NotificationState>;

export const mutations: NotificationMutationTree = {
  setNotifications(state, notifications: Notifications[]) {
    state.notifications = notifications.filter(n => n.timeclock);
  },

  setLoading(state, loading: boolean) {
    state.loading = loading;
  }
};
