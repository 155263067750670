import get from "lodash/get";
import { Component } from "vue-property-decorator";
import TemplateEditor from "../../editors.declaration";
import UIBlockDefaultComponent from "../UIBlockDefault.component";
import Template from "./UIBlockBarcodeField.template.vue";

import VueBarcode from "vue-barcode";
// @ts-ignore
import VueQRCodeComponent from "vue-qrcode-component";
import { Getter } from "vuex-class";
import { BarcodeFieldDefaultConfig } from "../UIBlock.config";
import { BarcodeFieldDefaultValues } from "../UIBlock.declarations";

@Component({
  mixins: [Template],
  components: {
    "qr-code": VueQRCodeComponent,
    VueBarcode
  }
})
export default class UIBlockBarcodeFieldComponent extends UIBlockDefaultComponent {
  @Getter("hasBioTrackTraceIntegrations", { namespace: "AuthModule" })
  public hasBioTrackTraceIntegrations!: boolean;
  @Getter("bioTrackTraceEnabled", { namespace: "AuthModule" })
  public bioTrackTraceEnabled!: boolean;
  protected block: TemplateEditor.UIBlocks.Block = {
    model: {
      type: "barcode-item",
      model: { ...BarcodeFieldDefaultValues }
    },
    configuration: BarcodeFieldDefaultConfig
  };

  protected get fromModel() {
    if (this.model.new_batch_print) {
      if (this.model.biotrack_traceability_id) {
        return get(this.model, "biotrack_traceability_id");
      }
      return get(this.model, "batch_uid");
    } else {
      const marijuana =
        (this.model.product && this.model.product.marijuana) ||
        (this.model.product_info && this.model.product_info.marijuana);
      if (
        this.bioTrackTraceEnabled &&
        marijuana === 1 &&
        this.model.biotrack_traceability_id
      ) {
        return get(this.model, "biotrack_traceability_id");
      }
      return get(this.model, "batch_uid");
    }
  }

  get barcodeStyles() {
    return {
      textAlign: this.values.textAlign || "center",
      fontSize:
        this.values.style === "QR"
          ? this.values.fontSize * 2
          : this.values.fontSize || 16,
      height: this.values.lineHeight || 32,
      width: 1,
      marginTop: this.values.marginTop || 0,
      marginBottom: this.values.marginBottom || 0,
      marginLeft: 0,
      marginRight: 0,
      format: `CODE${this.values.style}`,
      tag: "svg",
      font: "Arial",
      displayValue: false
    };
  }

  get displayModel() {
    return !this.printMode || (this.printMode && !this.model)
      ? this.values.code
      : this.printMode && this.model
      ? this.fromModel
      : "";
  }
}
