import { RootState } from "@/vuex/types";
import { Module } from "vuex";
import { actions } from "./permission.actions";
import { getters } from "./permission.getters";
import { mutations } from "./permission.mutations";
import { state } from "./permission.state";
import { PermissionsState } from "./permission.types";

const namespaced = true;

export const PermissionsModule: Module<PermissionsState, RootState> = {
  namespaced,
  state,
  actions,
  mutations,
  getters
};
