import { ProductCategory } from "@/interfaces/productCategoy";
import { ProductCategoryState } from "@/vuex/modules/productCategory/productCategory.types";
import { TablePagination } from "helix-vue-components";
import { MutationTree } from "vuex";

type CustomerMutationTree = MutationTree<ProductCategoryState>;

export const mutations: CustomerMutationTree = {
  setLoading(state: ProductCategoryState, loading: boolean) {
    state.loading = loading;
  },
  setPagination(
    state: ProductCategoryState,
    pagination: TablePagination | null
  ) {
    state.pagination = pagination;
  },
  setProductCategories(
    state: ProductCategoryState,
    productCategories: ProductCategory[]
  ) {
    state.productCategories = productCategories;
  },
  setAddNew(state: ProductCategoryState, addNew: boolean) {
    state.addNew = addNew;
  },
  setLocations(state: ProductCategoryState, locations: Location[]) {
    state.locations = locations;
  },
  setProductCategory(
    state: ProductCategoryState,
    productCategory: ProductCategory
  ) {
    state.productCategory = productCategory;
  },
  setCurrentLocation(state: ProductCategoryState, location: Location) {
    state.currentLocation = location;
  }
};
