import { Location } from "@/interfaces/location";
import { Policy } from "@/interfaces/permission";
import { store } from "@/internal";
import { AxiosResponse } from "axios";
import camelCase from "lodash/camelCase";
import set from "lodash/set";
import Vue from "vue";

const formatPolicies = (policies: Policy[]) => {
  let tag: string;
  let group: string;
  return policies.reduce((acc: { [key: string]: any }, p: Policy) => {
    if (p.tag && tag !== p.tag) {
      tag = camelCase(p.tag);
    }

    if (p.policy_group && group !== p.policy_group) {
      group = camelCase(p.policy_group);
    }
    set(acc, [tag, group, camelCase(p.name)], p);
    return acc;
  }, {});
};

class PermissionService {
  protected query = "?no_pagination=true";
  get currentUser() {
    return store.getters["AuthModule/user"];
  }
  public async getUserPermissions(userId: number) {
    const response: AxiosResponse = await Vue.axios({
      method: "GET",
      url: `${
        process.env.VUE_APP_CORE_URL
      }/permission/users/${userId}/policies${this.query}`
    });

    return formatPolicies(response.data.data);
  }

  public async getUserLocations(userId: number): Promise<Location[]> {
    try {
      const response: AxiosResponse = await Vue.axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_CORE_URL
        }/permission/users/${userId}/locations`,
        params: {
          embed: "state",
          no_pagination: true
        }
      });
      return response.data.data;
    } catch (error) {
      return [];
    }
  }
}

export const permissionService: PermissionService = new PermissionService();
