import AlertComponent from "@/components/sharedComponents/alert/alert.component";
import { MenuSection } from "@/interfaces/menuSection";
import { RetailSettings } from "@/interfaces/retailSettings";
import { EventBus, store } from "@/internal";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Template from "./Item.template.vue";

@Component({
  mixins: [Template]
})
export default class ItemComponent extends Vue {
  @Prop({ required: true })
  public item!: MenuSection;
  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  public currentRetailSettings!: RetailSettings;

  public async goTo(route: string) {
    if (
      route &&
      route === "/retail/pos" &&
      this.currentRetailSettings.pharmacy_mode_enabled &&
      !this.currentRetailSettings.rx_number
    ) {
      await this.$modals
        .load(
          AlertComponent,
          {
            size: "fit"
          },
          {
            msgModal: {
              title: this.$t("pmp.rxNumber_warn_title"),
              body: this.$t("pmp.rxNumber_warn_message"),
              captionButton: "OK"
            }
          }
        )
        .then(
          () => {
            EventBus.$emit("userWasStopedFromNavigating");
          },
          () => {
            EventBus.$emit("userWasStopedFromNavigating");
          }
        );
    } else if (route) {
      store.dispatch("RouterModule/go", route);
      EventBus.$emit("userHasNavigated");
    }
  }
}
