import { SearchQuery } from "@/interfaces/httpQuery";
import { Strain } from "@/interfaces/strain";
import { StrainMetrcDetails } from "@/interfaces/strainMetrc";
import HttpService from "./http.service";
import { messagesService } from "./messages.service";

class StrainService extends HttpService {
  protected uri: string = "/catalog/strains";
  protected defaultSort: string = "";
  protected loadDispatcher: string = "StrainModule/loadStrains";
  protected searchableField: SearchQuery[] = [
    {
      field: "name",
      type: "contains"
    }
  ];

  constructor() {
    super();
    this.query.embed = "strainType,notes";
  }

  public setQuery(query: object | null = {}) {
    this.query.page = 1;
    delete this.query["q[is_active]"];
    delete this.query["q[is_active_is_false]"];
    this.query = { ...this.query, ...query };
  }

  public async get(query: object | null = null): Promise<Strain[]> {
    this.query.embed = "strainType,notes";
    const response = await super.get(query || this.query);
    return response.data.data || response.data;
  }

  public async getStrain(strainId: string): Promise<Strain> {
    this.uri = "/catalog/strains";
    this.query.embed = "strainType,notes";
    const response = await super.findById(strainId, { ...this.query });

    return response;
  }

  public async setCurrentTraceability(
    config: {
      location_id: number | null;
      value: string;
    },
    hasTraceability: boolean
  ) {
    try {
      const response = await this.axios.request({
        url: "/services/bdi/configs_values/traceability",
        method: hasTraceability ? "PUT" : "POST",
        data: config
      });
      return response.data.data;
    } catch (error) {
      return false;
    }
  }

  public async setStrainMetrcDetails(
    id: number | undefined,
    name: string,
    model: StrainMetrcDetails,
    hasMetrcDetails: boolean
  ) {
    try {
      delete model.genetics;
      model.name = name;
      const response = await this.axios.request({
        url: hasMetrcDetails
          ? `/traceability/metrc/strains/${id}`
          : `/traceability/metrc/strains`,
        method: hasMetrcDetails ? "PUT" : "POST",
        data: id ? { ...model, biotrack_id: id } : model
      });
      return response.data;
    } catch (error) {
      return { errors: messagesService.parseMetrcError(error) };
    }
  }

  public async getStrainMetrcDetails(strainId: number) {
    const oldUri = this.uri;
    if (strainId) {
      try {
        this.uri = "/traceability/metrc/strains";
        const response = await super.findById(strainId).finally(() => {
          this.uri = oldUri;
        });
        return response;
      } catch (error) {
        return false;
      }
    }
    return false;
  }

  public async getStrainNameMetrcAvailability(strainName: string) {
    try {
      const response = await this.axios.request({
        url: "/traceability/metrc/strains/lookup",
        method: "POST",
        data: { name: strainName }
      });
      return response.data;
    } catch (error) {
      return false;
    }
  }

  public toggleStrain(strain: Strain, isActive: boolean): Promise<void> {
    if (isActive) {
      return super.enable(strain);
    }
    return super.disable(strain);
  }
}

export const strainService: StrainService = new StrainService();
