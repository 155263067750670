import { SearchQuery } from "@/interfaces/httpQuery";
import { User } from "@/interfaces/user";
import { store } from "@/internal";
import { messagesService } from "@/services/messages.service";
import { AxiosResponse } from "axios";
import Vue from "vue";
import HttpService from "./http.service";

/**
 * customer service
 * @override
 */
class UsersService extends HttpService {
  protected uri: string = `${process.env.VUE_APP_CORE_URL}/users`;
  protected loadDispatcher: string = "UserModule/loadUsers";
  protected defaultSort: string = "name";

  protected searchableField: SearchQuery[] = [
    {
      field: "email",
      type: "contains"
    }
  ];

  public async get(): Promise<User[]> {
    const response = await super.get(this.query);
    for (const customer of response.data.data) {
      customer.name = `${customer.first_name}  ${customer.last_name}`;
    }
    return response.data.data;
  }

  public async getProfile(id: number): Promise<User> {
    this.query.id = id;
    const query = { ...this.query, embed: "profile" };
    const response = await super.get(query);
    return response.data.data[0];
  }

  /**
   * create a new customer
   * @return Promise<Customer>
   * @param user
   */
  public async post(user: FormData): Promise<object> {
    const response = await super.post(user);
    return response.data;
  }

  public async getUsers(ids: number[]) {
    const query: { [key: string]: any } = {};
    query["q[id_is_in]"] = ids;
    const response = await super.get(query);
    return response.data.data;
  }

  public async put(user: User, formData: FormData): Promise<any> {
    const response: AxiosResponse = await Vue.axios({
      method: "POST",
      url: `${this.uri}/${user.id}`,
      data: formData
    });
    return response.data;
  }

  public async searchUsers(value: string, bool?: boolean): Promise<User[]> {
    if (!bool) {
      this.query[
        "q[first_name_or_last_name_or_nickname_or_middle_name_contains]"
      ] = value;
    } else {
      this.query["q[id_is_in]"] = value;
    }
    const response: User[] = await this.get();
    const formatedUsers = response.map(user => {
      return {
        ...user,
        name: `${user.first_name ? user.first_name : ""} ${
          user.middle_name ? user.middle_name : ""
        } ${user.last_name ? user.last_name : ""}`
      };
    });
    return formatedUsers;
  }

  public async changeUserLocation(locationId: number) {
    const currentUser: User = store.getters["AuthModule/user"];
    currentUser.settings.current_location_id = locationId;
    try {
      const response = await Vue.axios({
        method: "PUT",
        url: `${process.env.VUE_APP_CORE_URL}/users/${currentUser.id}/settings`,
        data: currentUser.settings
      });
      return response;
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }
}

export const usersService = new UsersService();
