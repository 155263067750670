import { Callback } from "helix-vue-components";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";
import Template from "./SessionAboutEnd.template.vue";

const namespace = "AuthModule";

@Component({
  mixins: [Template]
})
export default class SessionAboutEndComponent extends Vue {
  @Prop({ required: true })
  protected aboutEndTimeout!: number;

  protected timer: ReturnType<typeof setTimeout> | null = null;

  @Action("logout", { namespace })
  private logoutAction!: Callback;

  @Action("refreshToken", { namespace })
  private refreshTokenAction!: Callback;

  @Action("setAppIdle", { namespace })
  private setAppIdleAction!: Callback;

  protected staySignedIn(): void {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.refreshTokenAction();
    this.setAppIdleAction(false);
    this.$emit("resolve");
  }

  protected logout(): void {
    this.logoutAction(true);
    this.$emit("resolve");
  }

  protected mounted(): void {
    this.timer = setTimeout(this.logout, this.aboutEndTimeout);
    this.setAppIdleAction(true);
  }
}
