import { EventBus } from "@/internal";
import { Component, Vue } from "vue-property-decorator";
import Template from "./print.template.vue";

@Component({
  mixins: [Template]
})
export default class PrintComponent extends Vue {
  public component: any = null;
  public props: any = null;

  public print(type?: string) {
    setTimeout(() => window.print());
    this.$emit("onPrint", true);
    if (type && type === "barcode") {
      EventBus.$off("print");
      setTimeout(() => {
        this.$emit("onPrintEvent", true);
      }, 300);
    }
  }

  public cancel() {
    this.component = null;
    this.$emit("onPrint", false);
  }

  protected mounted() {
    EventBus.$on("print", (data: { component: any; props: any }) => {
      this.component = data.component;
      this.props = data.props;
    });

    window.onafterprint = () => {
      this.cancel();
    };
  }
}
