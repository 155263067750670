import { orderDefault } from "@/interfaces/order";
import { OrderState } from "@/vuex/modules/order/order.types";

export const state: OrderState = {
  orders: [],
  order: orderDefault,
  discounts: [],
  loyaltyConfig: null,
  loading: false,
  pagination: null,
  pharmacistSelected: null,
  customerLimits: []
};
