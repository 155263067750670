import { MutationTree } from "vuex";
import { PermissionsState, UserPermissions } from "./permission.types";

type PermissionsMutationTree = MutationTree<PermissionsState>;

export const mutations: PermissionsMutationTree = {
  setUserPermissions(
    state: PermissionsState,
    permissions: UserPermissions | null
  ) {
    state.userPermissions = permissions;
  }
};
