import {
  DuplicateOrder,
  ReconciliationDuplicateOrder
} from "@/interfaces/retailSalesReconciliation";
import { RootState } from "@/vuex/types";
import { GetterTree } from "vuex";
import { SaleReconciliationState } from "./saleReconciliation.types";

type SaleReconciliationGetter = GetterTree<SaleReconciliationState, RootState>;

export const getters: SaleReconciliationGetter = {
  duplicates(state: SaleReconciliationState): DuplicateOrder[] {
    return state.duplicates;
  },
  order(state: SaleReconciliationState): {} {
    return state.order;
  },
  transaction_date_time(state: SaleReconciliationState): string {
    return state.transaction_date_time;
  },
  license_number(state: SaleReconciliationState): string {
    return state.license_number;
  },
  data(state: SaleReconciliationState) {
    return {
      license_number: state.license_number,
      transaction_date_time: state.transaction_date_time,
      duplicates: state.duplicates,
      order: state.order
    };
  }
};
