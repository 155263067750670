import vuexLocal from "@/plugins/vuex-persist";
import { AuthModule } from "@/vuex/modules/auth/auth.index";
import { CustomerModule } from "@/vuex/modules/customer/customer.index";
import { DiscountManagerModule } from "@/vuex/modules/discountManager/discountManager.index";
import { PricePointModule } from "@/vuex/modules/inventory/pricePoint/pricePoint.index";
import { ProductModule } from "@/vuex/modules/inventory/product/product.index";
import { LocationModule } from "@/vuex/modules/location/location.index";
import { MegaMenuModule } from "@/vuex/modules/megaMenu/megaMenu.index";
import { OrderModule } from "@/vuex/modules/order/order.index";
import { ProductCategoryModule } from "@/vuex/modules/productCategory/productCategory.index";
import { RoomModule } from "@/vuex/modules/room/room.index";
import { RouterModule } from "@/vuex/modules/router/router.index";
import { SystemModule } from "@/vuex/modules/system/system.index";
import { TimeclockModule } from "@/vuex/modules/timeclock/timeclock.index";
import { VendorModule } from "@/vuex/modules/vendor/vendor.index";
import { RootState } from "@/vuex/types";
import Vue from "vue";
import Vuex, { Plugin, StoreOptions } from "vuex";
import { BatchModule } from "./vuex/modules/inventory/batch/batch.index";
import { BatchLevelModule } from "./vuex/modules/inventory/batchLevel/batchLevel.index";
import { MemberLevelModule } from "./vuex/modules/inventory/memberLevel/memberLevel.index";
import { SaleReconciliationModule } from "./vuex/modules/inventory/saleReconciliation/saleReconciliation.index";
import { NotificationModule } from "./vuex/modules/notifications/notifications.index";
import { PageNavModule } from "./vuex/modules/pageNav/pageNav.index";
import { PermissionsModule } from "./vuex/modules/permission/permission.index";
import { TraceabilityModule } from "./vuex/modules/traceability/traceability.index";
import { UserModule } from "./vuex/modules/user/user.index";

Vue.use(Vuex);
const appVer = `${process.env.VUE_APP_VERSION}`;

const storeDef: StoreOptions<RootState> = {
  state: {
    appName: "Helix BioTrack",
    appVersion: appVer
  },
  modules: {
    UserModule,
    AuthModule,
    CustomerModule,
    TimeclockModule,
    LocationModule,
    RouterModule,
    ProductModule,
    VendorModule,
    MegaMenuModule,
    OrderModule,
    ProductCategoryModule,
    SystemModule,
    RoomModule,
    PricePointModule,
    BatchModule,
    BatchLevelModule,
    SaleReconciliationModule,
    MemberLevelModule,
    DiscountManagerModule,
    NotificationModule,
    PermissionsModule,
    PageNavModule,
    TraceabilityModule
  },
  plugins: [vuexLocal.plugin as Plugin<RootState>]
};

export const store = new Vuex.Store<RootState>(storeDef);
