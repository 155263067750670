import { TimeclockState } from "@/vuex/modules/timeclock/timeclock.types";

export const state: TimeclockState = {
  timeclocks: [],
  userTimeclocks: [],
  userFilteredTimeclocks: [],
  currentTimeclock: null,
  loading: false,
  timeWeek: null,
  loadingFiltered: false,
  pagination: null
};
