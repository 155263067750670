import { RootState } from "@/vuex/types";
import { PageNav } from "helix-vue-components";
import { GetterTree } from "vuex";
import { PageNavState } from "./pageNav.types";

type PageNavGetter = GetterTree<PageNavState, RootState>;

export const getters: PageNavGetter = {
  pageNav(state: PageNavState): PageNav | null {
    return state.pageNav;
  }
};
