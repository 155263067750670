// @ts-ignore
import ElementLocale from "element-ui/lib/locale";
// @ts-ignore
import en from "element-ui/lib/locale/lang/en";
// @ts-ignore
import es from "element-ui/lib/locale/lang/es";
import Vue from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const messages = {
  en: { ...require("@/languages/en.json"), ...en },
  es: { ...require("@/languages/es.json"), ...es }
};
export const i18n = new VueI18n({
  locale: "en",
  messages
});

ElementLocale.i18n((key: string, value: string) => i18n.t(key, value));
