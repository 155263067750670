import { router } from "@/internal";
import { messagesService } from "@/services/messages.service";
import { AxiosError } from "axios";
import { RawLocation } from "vue-router";

export const actions: any = {
  go(context: any, payload: object) {
    router.push(payload);
  },
  back() {
    router.back();
  },
  current() {
    return router.currentRoute;
  },
  errorRedirect(
    context: any,
    payload: {
      location?: RawLocation;
      error: AxiosError;
    }
  ) {
    messagesService.renderErrorMessage(payload.error);
    setTimeout(() => {
      if (payload.location) {
        router.push(payload.location);
      } else {
        window.history.length > 1 ? router.back() : router.push("/");
      }
    }, 2500);
  }
};
