import { Terminal } from "@/interfaces/system";
import { Type } from "@/interfaces/type";
import { SystemState } from "@/vuex/modules/system/system.types";
import { TablePagination } from "helix-vue-components";
import { MutationTree } from "vuex";

type SystemMutationTree = MutationTree<SystemState>;

export const mutations: SystemMutationTree = {
  setProductTypes(state: SystemState, productTypes: Type[]) {
    state.productTypes = productTypes;
  },
  setTerminals(state: SystemState, terminals: Terminal[]) {
    state.terminals = terminals;
  },
  setLoading(state: SystemState, loading: boolean) {
    state.loading = loading;
  },
  setPagination(state: SystemState, pagination: TablePagination | null) {
    state.pagination = pagination;
  },
  setCurrentTerminal(state: SystemState, terminals: Terminal | null) {
    state.currentTerminal = terminals;
  }
};
