import { MenuSection } from "@/interfaces/menuSection";
// @ts-ignore
import { Callback } from "@/types/types";
import { RootState } from "@/vuex/types";
import { GetterTree } from "vuex";
import { MegaMenuState } from "./megaMenu.types";

type megaMenuGetter = GetterTree<MegaMenuState, RootState>;

export const getters: megaMenuGetter = {
  menuSections(state: MegaMenuState): MenuSection[] {
    return state.menuSections;
  },
  title(state: MegaMenuState): string {
    return state.title;
  },
  subTitle(state: MegaMenuState): string {
    return state.subTitle;
  },
  icon(state: MegaMenuState): string {
    return state.icon;
  },
  iconAction(state: MegaMenuState): Callback | null {
    return state.iconAction;
  }
};
