import { FilterParams, HttpQuery, SearchQuery } from "@/interfaces/httpQuery";
import { store } from "@/internal";
import { SecurityPinService } from "@/plugins/security-pin/security-pin.service";
import { Callback } from "@/types/types";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import {
  ActionSubheader,
  TableAction,
  TablePagination
} from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import toFormData from "object-to-formdata";
import Vue from "vue";
import { messagesService } from "./messages.service";

type PaginationCallback = (...arg: any[]) => void;

export const INITIAL_QUERY_STATE = {
  sort: "",
  page: 1,
  per_page: 10
};

/**
 * abstract service to manage http request and general services configurations
 */
export default abstract class HttpService extends Vue {
  public currentSort: string = "name";
  /**
   * Cancellation function array. This array store pendings request until
   * are finish or cancelled
   */
  protected cancelTokens: Array<() => void> = [];

  /**
   * path to call in the backend
   * @var string uri
   */
  protected uri!: string;

  /**
   * dispatcher to pagination actions
   * @var string loadDispatcher
   */
  protected loadDispatcher!: string;

  /**
   * default sort for make a request
   * @var string defaultSort
   */
  protected defaultSort!: string;

  /**
   * available fields for make a search action
   * @var SearchQuery[] searchableField
   */
  protected searchableField!: SearchQuery[];

  protected defaultPagination: TablePagination = {
    totalItems: 0,
    itemsPerPage: 10,
    itemsPerPageOptions: [5, 10, 20, 50],
    currentPage: 1,
    lastPage: 0,
    from: 1,
    to: 10
  };

  /**
   * default pagination definition
   * @var Pagination pagination
   */
  protected pagination: TablePagination = { ...this.defaultPagination };

  /**
   * default query for make a request
   * @var query
   */
  protected defaultQuery: HttpQuery = {
    sort: this.defaultSort,
    page: this.pagination.currentPage,
    per_page: this.pagination.itemsPerPage
  };

  /**
   * default query for make a request
   * @var query
   */
  protected query: HttpQuery = { ...this.defaultQuery };

  /**
   * Cancellation token;
   */
  protected CancelToken = axios.CancelToken;

  /**
   * Cancellation function. (Call this function on
   * "beforeDestroy" cycle of any component if you want
   * to cancel a request).
   */
  public cancelRequest(): void {
    this.cancelTokens.forEach(cancelToken => {
      cancelToken();
    });

    this.cancelTokens = [];
  }
  /**
   * @return Promise
   * @param query
   * @param pagination
   */
  public async get(
    query: object | null = null,
    pagination: boolean = true
  ): Promise<any> {
    let token: (() => void) | null = null;
    const response: AxiosResponse = await Vue.axios({
      cancelToken: new this.CancelToken((c: () => void) => {
        token = c;
        this.cancelTokens.push(token);
      }),
      method: "GET",
      url: this.uri,
      params: query || this.query
    });
    if (pagination) {
      this.setPagination(response.data);
    }
    // Remove stored cancel functions token
    const indexToken = this.cancelTokens.findIndex(c => c === token);
    this.cancelTokens.splice(indexToken, 1);

    return response.data;
  }

  public async getScrollApi(
    query: object | null = null,
    url: string = ""
  ): Promise<any> {
    let token: (() => void) | null = null;
    const response: AxiosResponse = await Vue.axios({
      cancelToken: new this.CancelToken((c: () => void) => {
        token = c;
        this.cancelTokens.push(token);
      }),
      method: "GET",
      url,
      params: query || this.query
    });

    // Remove stored cancel functions token
    const indexToken = this.cancelTokens.findIndex(c => c === token);
    this.cancelTokens.splice(indexToken, 1);

    return response.data;
  }

  /**
   * GET with headers.
   * Same as regular get but can add custom headers to the request.
   * @param headers
   * @param query
   * @returns with headers
   */
  public async getWithHeaders(
    headers: object,
    query: object | null
  ): Promise<any> {
    const response: AxiosResponse = await Vue.axios({
      method: "GET",
      url: this.uri,
      headers,
      params: query
    });
    return response.data;
  }

  /**
   * POST with headers
   * Custom headers can be added to the request
   * @param headers
   * @param [data]
   * @returns with headers
   */
  public async postWithHeaders(headers: object, data?: any): Promise<any> {
    const request: AxiosRequestConfig = {
      method: "POST",
      url: this.uri,
      headers,
      data
    };
    const response: AxiosResponse = await Vue.axios(request);

    return response;
  }
  /**
   * @param model
   * @param hasQuery
   * @param pin
   * @return Promise<Array>
   */
  public async post(
    model: any,
    hasQuery: boolean = false,
    pin?: string | number
  ): Promise<any> {
    const request: AxiosRequestConfig = {
      method: "POST",
      url: this.uri,
      data: model,
      params: hasQuery ? this.query : null
    };
    if (pin) {
      request.headers = { Pincode: pin };
    }
    const response: AxiosResponse = await Vue.axios(request);

    return response.data.data || response.data;
  }
  /**
   * @param model
   * @param hasQuery
   * @param pin
   * @return Promise<Array>
   */
  public async patch(
    model: any,
    hasQuery: boolean = false,
    pin?: string | number
  ): Promise<any> {
    const request: AxiosRequestConfig = {
      method: "PATCH",
      url: this.uri,
      data: model,
      params: hasQuery ? this.query : null
    };
    if (pin) {
      request.headers = { Pincode: pin };
    }
    const response: AxiosResponse = await Vue.axios(request);

    return response.data.data || response.data;
  }

  /**
   * @param model
   * @param data
   * @param hasQuery
   * @return Promise<Array>
   */
  public async put(
    model: any,
    data: any,
    hasQuery: boolean = false,
    pin?: number | string
  ): Promise<any> {
    // SKU entities are mapped with this key instead of a regular Id.
    const id = model && (model.sku || model.id);
    const request: AxiosRequestConfig = {
      method: "PUT",
      url: `${this.uri}${id ? "/" + id : ""}`,
      data,
      params: hasQuery ? this.query : null
    };
    if (pin) {
      request.headers = { Pincode: pin };
    }
    const response: AxiosResponse = await Vue.axios(request);
    return response.data.data || response.data;
  }

  public async batchLevelput(
    model: any,
    data: any,
    hasQuery: boolean = false,
    pin?: number | string
  ): Promise<any> {
    // SKU entities are mapped with this key instead of a regular Id.
    const id = model && (model.sku || model.uid);
    const request: AxiosRequestConfig = {
      method: "PUT",
      url: `${this.uri}${id ? "/" + id : ""}`,
      data,
      params: hasQuery ? this.query : null
    };
    if (pin) {
      request.headers = { Pincode: pin };
    }
    const response: AxiosResponse = await Vue.axios(request);
    return response.data.data || response.data;
  }

  public async find(id: number): Promise<any> {
    this.query.id = id;
    this.query.page = 1;
    const customer: any[] = await this.get(this.query);
    const returned = customer[0];
    delete this.query.id;
    return returned;
  }

  /**
   * @return Promise
   * @param id
   * @param query
   */
  public async findById(id: number | string, query?: object): Promise<any> {
    const response: AxiosResponse = await Vue.axios({
      method: "GET",
      url: `${this.uri}/${id}`,
      params: query
    });
    return response.data.data;
  }

  /**
   * @param model
   * @param force
   * @return Promise<Array>
   */
  public async delete(
    model: any,
    force?: boolean,
    pin?: number | string
  ): Promise<any> {
    const id = model && (model.id || model._id);
    const url: string = force
      ? `${this.uri}/${id}?force_delete=1`
      : `${this.uri}/${id}`;
    const request: AxiosRequestConfig = {
      method: "DELETE",
      url
    };
    if (pin) {
      request.headers = { Pincode: pin };
    }
    const response: AxiosResponse = await Vue.axios(request);
    this.resetPagination();

    return response.data;
  }

  /**
   * This method is the new DELETE, but while
   * all the endpoints are migrated we have to
   * keep both.
   * @param model
   * @return Promise<Array>
   */
  public async disable(model: any, pin?: number | string): Promise<any> {
    const id = model && (model.id || model._id || model.sku);
    const url = `${this.uri}/${id}`;
    const request: AxiosRequestConfig = {
      method: "PATCH",
      url: `${url}/disable`
    };
    if (pin) {
      request.headers = { Pincode: pin };
    }
    const response: AxiosResponse = await Vue.axios(request);
    if (this.pagination.from === this.pagination.to) {
      this.resetPagination(this.pagination.currentPage - 1);
    }
    return response.data;
  }
  /**
   * Reverse action to Disable method
   * @param model
   * @return Promise<Array>
   */
  public async enable(model: any, pin?: number | string): Promise<any> {
    const id = model && (model.id || model._id || model.sku);
    const url = `${this.uri}/${id}`;
    const request: AxiosRequestConfig = {
      method: "PATCH",
      url: `${url}/enable`
    };
    if (pin) {
      request.headers = { Pincode: pin };
    }
    const response: AxiosResponse = await Vue.axios(request);
    if (this.pagination.from === this.pagination.to) {
      this.resetPagination(this.pagination.currentPage - 1);
    }
    return response.data;
  }

  public async deleteMultiple(
    models: any[],
    pin?: number | string
  ): Promise<any> {
    const request: AxiosRequestConfig = {
      method: "POST",
      url: this.uri + "/delete",
      data: [...models]
    };
    if (pin) {
      request.headers = { Pincode: pin };
    }
    const response: AxiosResponse = await Vue.axios(request);
    this.resetPagination();
    return response.data;
  }

  /**
   * Works as deleteMultiple but for new endpoints.
   * We have to keep both until every endpoint
   * is migrated.
   */
  public async disableMultiple(
    models: any[],
    pin?: number | string
  ): Promise<any> {
    const request: AxiosRequestConfig = {
      method: "PATCH",
      url: this.uri + "/disable",
      data: [...models]
    };
    if (pin) {
      request.headers = { Pincode: pin };
    }
    const response: AxiosResponse = await Vue.axios(request);
    this.resetPagination();
    return response.data;
  }

  public async enableMultiple(
    models: any[],
    pin?: number | string
  ): Promise<any> {
    const request: AxiosRequestConfig = {
      method: "PATCH",
      url: this.uri + "/enable",
      data: [...models]
    };
    if (pin) {
      request.headers = { Pincode: pin };
    }
    const response: AxiosResponse = await Vue.axios(request);
    this.resetPagination();
    return response.data;
  }

  public async deleteForce(model: any): Promise<any> {
    const response: AxiosResponse = await Vue.axios({
      method: "DELETE",
      url: `${this.uri}/${model.id}`,
      data: {
        force_delete: true
      }
    });
    return response.data;
  }

  public async callCSV(
    service: "csv_import" | "csv_export" | "csv_example" | "csv_fields",
    file?: File
  ): Promise<any> {
    const method = service === "csv_import" ? "POST" : "GET";
    const data = file ? toFormData({ csv_file: file }, { indices: true }) : {};
    const response: AxiosResponse = await Vue.axios({
      method,
      url: `${this.uri}/${service}`,
      data
    });
    return ["csv_export", "csv_example"].includes(service)
      ? response.data
      : response.data.data;
  }

  /**
   * return the actions available for a page
   * @return TableAction[] | null
   */
  public getGeneralActions(): ActionSubheader[] {
    return [{}];
  }

  /**
   * return the actions available for a row in a table
   * @return TableAction[] | null
   */
  public getRowActions(): TableAction[] {
    return [
      {
        icon: ""
      }
    ];
  }

  /**
   * @return Promise<Pagination>
   */
  public async getPagination(clone = false): Promise<TablePagination> {
    if (clone) {
      return cloneDeep(this.pagination);
    }
    return this.pagination;
  }

  /**
   * @param response
   */
  public setPagination(response: any) {
    this.pagination.totalItems = response.data.total;
    this.pagination.itemsPerPage = Number(response.data.per_page);
    this.pagination.lastPage = response.data.last_page;
    this.pagination.currentPage = response.data.current_page;
    this.pagination.changeItemsPerPage = this.paginationAction();
    this.pagination.from = response.data.from;
    this.pagination.to = response.data.to;
  }

  public getPaginationLite(response: any) {
    return {
      totalItems: response.total,
      itemsPerPage: Number(response.per_page),
      lastPage: response.last_page,
      currentPage: response.current_page,
      itemsPerPageOptions: [5, 10, 20, 50],
      from: response.from,
      to: response.to
    };
  }

  public resetPagination(arg: number = 1) {
    this.query.page = arg;
    this.pagination = { ...this.defaultPagination };
  }

  public getResetedPagination(): TablePagination {
    return { ...this.defaultPagination };
  }

  /**
   *
   * @return PaginationCallback
   */
  public paginationAction(): PaginationCallback {
    return (arg: any, payload?: any) => {
      this.query.sort = this.currentSort;
      this.query.page = arg.currentPage;
      this.query.per_page = arg.itemsPerPage;
      const query = cloneDeep(this.query);
      if (arg.filteresSelected) {
        this.query = {
          ...this.query,
          ...arg.filteresSelected
        };
      }
      store.dispatch(arg.dispatchAction || this.loadDispatcher, payload);
      this.query = query;
    };
  }

  /**
   * sort action to make a request
   * @param params
   */
  public sortQuery(params: any, dispatcher?: string) {
    const desc: string = params.descending ? "" : "-";
    let sort = this.defaultSort;

    if (params.value) {
      sort = params.value;
    } else if (params.text) {
      sort = params.text;
    }

    this.query.sort = desc + sort;
    this.currentSort = this.query.sort;
    store.dispatch(dispatcher || this.loadDispatcher);
  }

  /**
   * make a search in a query
   * @return PaginationCallback
   */
  public searchEvent(): Callback {
    const self: HttpService = this;
    return (...arg: any[]) => {
      this.resetPagination();
      const formatted: any = {};
      self.searchableField.map(field => {
        formatted[field.type] =
          typeof formatted[field.type] === "undefined"
            ? []
            : formatted[field.type];
        formatted[field.type].push(field.field);
      });
      let query: string = "";
      Object.keys(formatted).forEach(field => {
        query = formatted[field].join("_or_") + "_" + field;
        if (!!arg[0]) {
          self.query[`q[${query}]`] = arg[0];
        } else if (self.query[`q[${query}]`]) {
          delete self.query[`q[${query}]`];
        }
      });
      store.dispatch(this.loadDispatcher);
    };
  }

  public getFilterQuery(
    model: { [key: string]: any },
    queryParams: FilterParams[]
  ) {
    const query: any = {};
    queryParams.forEach(param => {
      if (model.hasOwnProperty(param.model) && model[param.model]) {
        const searchParam = `q[${param.field}${param.keyword}]`;
        query[searchParam] = model[param.model];
      }
    });

    return query;
  }
  public async authorize() {
    const pin$ = new SecurityPinService();
    return pin$
      .ensure("security_pin.title", {
        text: "security_pin.description"
      })
      .then(
        pin => {
          return pin;
        },
        () => {
          messagesService.showMessage(
            "fas fa-exclamation-circle",
            "security_pin.required_message",
            "error"
          );
          return null;
        }
      );
  }

  public resetQuery() {
    this.query = { ...this.defaultQuery };
  }
}
