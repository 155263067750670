import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./SecurityPin.template.vue";

@Component({
  mixins: [Template]
})
export default class SecurityPinModalComponent extends Vue {
  @Prop() public description!: string;

  protected maskedPin: string = "";
  protected pin: string = "";
  protected fieldType = "password";
  protected symbol = "•";
  protected fullmask = false;

  public resolve() {
    this.$emit("resolve", this.pin);
  }

  public get pinModel() {
    return this.fieldType === "text" ? this.pin : this.maskedPin;
  }

  public set pinModel(pin: string) {
    this.fieldType === "text" ? (this.pin = pin) : (this.maskedPin = pin);
  }

  public doPinMasking() {
    let plainpin: string = "";

    if (this.pin) {
      for (let i = 0; i < this.maskedPin!.length; i++) {
        if (this.maskedPin!.charAt(i) === this.symbol) {
          plainpin += this.pin!.charAt(i);
        } else {
          plainpin += this.maskedPin!.charAt(i);
        }
      }
    } else {
      plainpin = this.maskedPin;
    }

    const maskedstring = this.maskPin(plainpin!, this.fullmask);

    if (this.pin !== plainpin || this.maskedPin !== maskedstring) {
      this.pin = plainpin!;
      this.maskedPin = maskedstring;
    }
  }

  public maskPin(pinstring: string, fullmask: boolean) {
    const characterlimit = fullmask === true ? 0 : 1;
    let maskedstring = "";
    for (let i = 0; i < pinstring.length; i++) {
      if (i < pinstring.length - characterlimit) {
        maskedstring += this.symbol;
      } else {
        maskedstring += pinstring.charAt(i);
      }
    }
    return maskedstring;
  }

  public doOnInput(input: string) {
    this.fullmask = this.pin.length < input.length ? false : true;
    this.doPinMasking();
  }

  public doOnBlur() {
    this.fullmask = true;
    this.doPinMasking();
  }

  public toggleInput() {
    this.fieldType = this.fieldType === "password" ? "text" : "password";
    this.maskedPin = this.pin;
    this.doOnBlur();
  }
}
