import { Component, Vue } from "vue-property-decorator";
import Template from "./NotPatientSelectedMessage.template.vue";

@Component({
  mixins: [Template]
})
export default class NotPatientSelectedMessageComponent extends Vue {
  protected confirm(resp: boolean = true) {
    this.$emit("resolve", resp);
  }
}
