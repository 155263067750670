import { Customer } from "@/interfaces/customer";
import { FormField, TablePagination } from "helix-vue-components";
import { MutationTree } from "vuex";
import { CustomerState } from "./customer.types";

type CustomerMutationTree = MutationTree<CustomerState>;

export const mutations: CustomerMutationTree = {
  setCustomers(state: CustomerState, customers: Customer[]) {
    state.customers = customers;
  },
  setLoading(state: CustomerState, loading: boolean) {
    state.loading = loading;
  },
  setPagination(state: CustomerState, pagination: TablePagination | null) {
    state.pagination = pagination;
  },
  setScrollPagination(
    state: CustomerState,
    scrollPagination: TablePagination | null
  ) {
    state.scrollPagination = scrollPagination;
  },
  setCurrentCustomer(state: CustomerState, customer: Customer | null) {
    state.currentCustomer = customer;
  },
  setForm(state: CustomerState, form: FormField[]) {
    state.form = form;
  },
  setCustomerRouteforSave(state: CustomerState, route: string) {
    state.customerRouteforSave = route;
  },
  setSavedForCheckin(state: CustomerState, save: boolean) {
    state.savedForCheckin = save;
  },
  setNavigateToAddDoctor(state: CustomerState, value: boolean) {
    state.navigateToAddDoctor = value;
  },
  setNavigateToCheckIn(state: CustomerState, value: boolean) {
    state.navigateToCheckIn = value;
  }
};
