import { MemberLevel } from "@/interfaces/memberLevel";
import { MemberLevelState } from "@/vuex/modules/inventory/memberLevel/memberLevel.types";
import { TablePagination } from "helix-vue-components";
import { MutationTree } from "vuex";

type TypeMutationTree = MutationTree<MemberLevelState>;

export const mutations: TypeMutationTree = {
  setMemberLevels(state: MemberLevelState, memberLevels: MemberLevel[]) {
    state.memberLevels = memberLevels;
  },
  setMemberLevel(state: MemberLevelState, memberLevel: MemberLevel | null) {
    state.memberLevel = memberLevel;
  },
  setLoading(state: MemberLevelState, loading: boolean) {
    state.loading = loading;
  },
  setPagination(state: MemberLevelState, pagination: TablePagination | null) {
    state.pagination = pagination;
  }
};
