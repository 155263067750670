import SelectCaregiverOrder from "@/components/retail/pos/pointOfSale/selectCaregiverOrder/SelectCaregiverOrder.component";
import SelectOrdersComponent from "@/components/retail/pos/pointOfSale/selectOrders/SelectOrders.component";
import CustomerLabelComponent from "@/components/sharedComponents/print/customerLabel/CustomerLabel.component";
import PrintReceiptComponent from "@/components/sharedComponents/print/PrintReceipt/PrintReceipt.component";
import { policyList } from "@/enums/permissions";
import { Customer } from "@/interfaces/customer";
import {
  Order,
  OrderActionCallback,
  OrderInvoice,
  OrderItem
} from "@/interfaces/order";
import { EventBus, store } from "@/internal";
import {
  userHasNotOpenTill,
  userWithoutOpenFillMessage
} from "@/metadata/money";
import { i18n } from "@/plugins/i18n";
import {
  getLabsDataForPrintLabels,
  timeUntFormat
} from "@/utils/batch-actions.utils";
import { Callback, TableAction } from "helix-vue-components";
import assign from "lodash/assign";
import cloneDeep from "lodash/cloneDeep";
import { Vue } from "vue-property-decorator";
import { orderService } from "./order.service";

interface ActivationTemplate extends OrderItem {
  activation_time: string;
  activation_time_unit: string;
}

class OrderActionService extends Vue {
  public getRowActions(
    actionCallbacks: OrderActionCallback,
    showPmp: boolean
  ): TableAction[] {
    return [
      {
        icon: "fal fa-ellipsis-v",
        id: "btn_retail_options",
        modalActions: {
          modalNumber: 2,
          modalButtons: [
            {
              icon: "fal fa-file-invoice-dollar",
              label: i18n.t("refund.refund").toString(),
              class: "primary-base",
              tooltip: userWithoutOpenFillMessage,
              disableCondition: userHasNotOpenTill,
              visibleCondition: arg =>
                store.getters["PermissionsModule/hasPermission"](
                  policyList.refund
                ) && arg.status !== "REFUND",
              action: actionCallbacks.refund
            },
            {
              icon: "fas fa-usd-circle",
              label: i18n.t("void_sale").toString(),
              class: "primary-base",
              tooltip: userWithoutOpenFillMessage,
              disableCondition: userHasNotOpenTill,
              action: async arg => {
                if (userHasNotOpenTill()) {
                  return;
                }
                actionCallbacks.voidSale(arg);
              },
              visibleCondition: arg => {
                const status =
                  arg.metrc_reciept_number === "PARTIALLY_REFUND" ||
                  arg.metrc_reciept_number === "REFUND";
                const hasPerm = store.getters[
                  "PermissionsModule/hasPermission"
                ](policyList.voidSale);
                return hasPerm && !status;
              }
            },
            {
              icon: "fal fa-usd-circle",
              label: i18n.t("modify_payment_lbl").toString(),
              class: "primary-base",
              tooltip: userWithoutOpenFillMessage,
              disableCondition: userHasNotOpenTill,
              action: (arg: any) => {
                if (userHasNotOpenTill()) {
                  return;
                }
                actionCallbacks.payment(arg);
              },
              visibleCondition: arg => {
                const status =
                  arg.status === "PARTIALLY_REFUND" || arg.status === "REFUND";
                const hasPerm = store.getters[
                  "PermissionsModule/hasPermission"
                ](policyList.modifyPayment);
                return hasPerm && !status;
              }
            },
            {
              icon: "fal fa-print",
              label: i18n.t("reprint_receipts").toString(),
              class: "primary-base",
              visibleCondition: () =>
                store.getters["PermissionsModule/hasPermission"](
                  policyList.printReceipts
                ),
              action: async arg => {
                const orderInvoice: OrderInvoice = await orderService.orderInvoice(
                  arg.id
                );
                const order: Order = assign(cloneDeep(arg), { orderInvoice });
                EventBus.$emit("print", {
                  component: PrintReceiptComponent,
                  props: { item: order }
                });
              }
            },
            {
              icon: "fal fa-print",
              label: i18n.t("reprint_label").toString(),
              class: "primary-base",
              visibleCondition: () =>
                store.getters["PermissionsModule/hasPermission"](
                  policyList.printLabels
                ),
              action: async arg => {
                const detailedOrder = await orderService.getDetailedOrder(
                  arg.id
                );
                detailedOrder.order_items.map((item: ActivationTemplate) => {
                  if (item.activation_time == null) {
                    item.activation_time =
                      item.activation_time_unit === "I"
                        ? i18n.t("immediate").toString()
                        : item.activation_time;
                  }
                  item.activation_time_unit =
                    item.activation_time_unit === "I"
                      ? " "
                      : item.activation_time_unit === "M" ||
                        item.activation_time_unit === "S" ||
                        item.activation_time_unit === "H"
                      ? timeUntFormat[item.activation_time_unit]
                      : item.activation_time_unit;
                });
                if (
                  detailedOrder.order_items &&
                  detailedOrder.order_items.length > 0
                ) {
                  detailedOrder.order_items = await getLabsDataForPrintLabels(
                    detailedOrder.order_items
                  );
                }
                arg.order_items.map((oi: any) => {
                  const index = detailedOrder.order_items.findIndex(
                    (item: OrderItem) => item.id === oi.id
                  );
                  if (index > -1) {
                    detailedOrder.order_items[index].product_info =
                      oi.product_info;
                  }
                });
                detailedOrder.sold_at = arg.sold_at;
                detailedOrder.sold_at_formatted = arg.sold_at_formatted;
                EventBus.$emit("print", {
                  component: CustomerLabelComponent,
                  props: { order: detailedOrder }
                });
              }
            },
            {
              icon: "fal fa-file-search",
              label: i18n.t("metrc_details.action_button").toString(),
              class: "primary-base",
              visibleCondition: (order: Order) => {
                const hasPerm = store.getters[
                  "PermissionsModule/hasPermission"
                ](policyList.uploadSalesToMetrcViewMetrcSalesDetails);
                return !!(
                  hasPerm &&
                  order.trc_receipt_id &&
                  actionCallbacks.hasMetrc() &&
                  order.trc_status === "SUCCESSFUL" &&
                  order.order_items &&
                  order.order_items.length &&
                  order.order_items.find(item => !!item.product_info!.marijuana)
                );
              },
              action: actionCallbacks.metrcDetail
            },
            {
              icon: "fal fa-upload",
              label: i18n.t("upload_to_metrc").toString(),
              class: "primary-base",
              visibleCondition: (order: Order) => {
                const hasPerm = store.getters[
                  "PermissionsModule/hasPermission"
                ](policyList.uploadSalesToMetrcViewMetrcSalesDetails);
                return !!(
                  hasPerm &&
                  actionCallbacks.hasMetrc() &&
                  order.trc_status === "NOT_UPLOADED" &&
                  order.order_items &&
                  order.order_items.length &&
                  order.order_items.find(item => !!item.product_info!.marijuana)
                );
              },
              action: actionCallbacks.syncMetrcStatus
            },
            {
              icon: "fal fa-upload",
              label: i18n.t("pmp.upload_action").toString(),
              class: "primary-base",
              visibleCondition: (order: Order) => {
                return (
                  showPmp &&
                  order.pmp_status !== "UPLOADED" &&
                  order.pmp_status !== "Non-Cannabis"
                );
              },
              action: actionCallbacks.uploadPmp
            }
          ]
        }
      }
    ];
  }

  public getOrdersActions(selectedCallback: Callback): TableAction[] {
    return [
      {
        icon: "fal fa-pen",
        action: order => {
          selectedCallback(order);
        }
      }
    ];
  }

  public async modalGetOrders(
    orders: Order[],
    customer: Customer
  ): Promise<Order | null> {
    try {
      const order: Order = await this.$modals.load(
        SelectOrdersComponent,
        {
          size: "fit"
        },
        {
          orders,
          customer
        }
      );
      return order;
    } catch (e) {
      return null;
    }
  }
  // Modal for caregiver
  public async modalGetOrdersCaregiver(
    customer: Customer,
    patients: Customer[]
  ): Promise<Order | null> {
    try {
      const order: Order = await this.$modals.load(
        SelectCaregiverOrder,
        {
          size: "fit"
        },
        {
          customer,
          patients
        }
      );
      return order;
    } catch (e) {
      return null;
    }
  }
}

export const orderActionService = new OrderActionService();
