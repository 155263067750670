import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./alert.template.vue";

@Component({
  mixins: [Template]
})
export default class AlertComponent extends Vue {
  @Prop({ required: true })
  public msgModal!: {
    title: string;
    body: string;
    captionButton: string;
  };

  public closeModal() {
    this.$emit("resolve");
  }
}
