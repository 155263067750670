import { SearchQuery } from "@/interfaces/httpQuery";
import { Terminal } from "@/interfaces/system";
import HttpService from "./http.service";

class SystemService extends HttpService {
  protected loadDispatcher: string = "SystemModule/loadTerminals";
  protected defaultSort: string = "";
  protected uri: string = "/terminals";
  protected searchableField: SearchQuery[] = [
    {
      field: "name",
      type: "contains"
    }
  ];

  public async pushTerminal(
    model: Terminal,
    roomNew: object[],
    roomsDeleted: object[]
  ): Promise<Terminal> {
    this.query.embed = "rooms,defaultRoom";
    if (model.id) {
      return await this.put(model, {
        ...model,
        terminal_rooms: roomsDeleted.concat(roomNew)
      });
    } else {
      return await this.post({ ...model, terminalRooms: roomNew });
    }
  }

  public async get(query?: object): Promise<Terminal[]> {
    try {
      const response = await super.get(query || this.query);
      const payload: Terminal[] = response.data.data || response.data;
      return payload;
    } catch (e) {
      return [];
    }
  }

  public async find(id: number): Promise<Terminal | null> {
    const embed = "rooms,defaultRoom,terminalRooms";
    this.uri = "/terminals";
    return await super.findById(id, { embed });
  }

  public async removeTerminal(terminal: Terminal) {
    const response = await super.deleteForce(terminal);
    return response.data;
  }

  public changeQuery(route?: string) {
    route === "sales-limit"
      ? (this.query.embed = "rules")
      : delete this.query.embed;
  }
}

export const systemService: SystemService = new SystemService();
