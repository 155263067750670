import TableProductsComponent from "@/components/sharedComponents/tableProducts/TableProductsComponent";
import { Customer } from "@/interfaces/customer";
import { Order, orderDefault } from "@/interfaces/order";
import { RetailSettings } from "@/interfaces/retailSettings";
import { SelectOrdersTableMetadata } from "@/metadata/order";
import { orderActionService } from "@/services/order.action.service";
import { TableAction, TableComponent, TableHeader } from "helix-vue-components";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Template from "./SelectOrders.template.vue";
@Component({
  mixins: [Template],
  components: {
    TableProductsComponent,
    TableComponent
  }
})
export default class SelectOrdersComponent extends Vue {
  public loading!: boolean;
  public isCaregiver!: boolean;
  public isPatient!: boolean;
  public headers: TableHeader[] = SelectOrdersTableMetadata;
  public rowActions: TableAction[] = orderActionService.getOrdersActions(
    this.selectOrder
  );
  public fieldsToShow: Array<{ [key: string]: string | undefined }> = [];
  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  public retailSettings!: RetailSettings;

  @Prop({ required: true })
  public orders!: Order[];

  @Prop({ required: true })
  public customer!: Customer;

  public newOrder() {
    const order: Order = {
      ...orderDefault,
      customer_id: this.customer.customer_id,
      customer: this.customer
    };
    this.selectOrder(order);
  }

  protected selectOrder(order: Order) {
    if (
      order.online_pending ||
      order.call_in_pending ||
      order.in_store_pending
    ) {
      this.$modals
        .loadConfirmation(
          {
            title: "WARNING",
            text: this.$t("points_of_sales.alert_online_pending").toString(),
            acceptButton: "yes",
            cancelButton: "no"
          },
          {
            positionX: "center",
            size: "fit"
          }
        )
        .then((res: any) => {
          if (res) {
            this.$emit("resolve", order);
          } else {
            return false;
          }
        })
        .catch(error => this.$alert(error));
    } else {
      this.$emit("resolve", order);
    }
  }

  protected setFieldsToShow() {
    const fields = [
      {
        label: this.$t("batch_id").toString(),
        value: "batch_barcode_uid",
        secondaryValue: ""
      },
      {
        label: this.$t("product").toString(),
        value: "product_info.name"
      },
      {
        label: this.$t("quantity").toString(),
        value: "quantityUnit"
      }
    ];
    if (this.retailSettings.stock_identification_type_in_pos === "NDC_NUMBER") {
      fields[0].secondaryValue = "ndc_number";
    } else if (
      this.retailSettings.stock_identification_type_in_pos === "SECONDARY_ID"
    ) {
      fields[0].secondaryValue = "tracking_id";
    }

    return fields;
  }

  protected async mounted() {
    this.fieldsToShow = this.setFieldsToShow();
    this.orders.forEach(order => {
      if (order.order_items) {
        order.order_items = order.order_items.map(item => ({
          ...item,
          ndc_number: item.product_info && item.product_info.ndc_number
        }));
      }
    });
  }
}
