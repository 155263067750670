import CanvasTreeComponent from "@/components/templateEditor/canvas/canvas-tree/CanvasTree.component";
import TemplateEditor from "@/components/templateEditor/editors.declaration";
import { Location } from "@/interfaces/location";
import { Order, OrderItem, ProductInfo } from "@/interfaces/order";
import { messagesService } from "@/services/messages.service";
import { templatesService } from "@/services/templates.service";
import cloneDeep from "lodash/cloneDeep";
import size from "lodash/size";
import uniq from "lodash/uniq";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Template from "./CustomerLabel.template.vue";
@Component({
  mixins: [Template],
  components: { CanvasTreeComponent }
})
export default class CustomerLabelComponent extends Vue {
  @Getter("currentLocation", { namespace: "AuthModule" })
  public currentLocation!: Location;

  @Prop({ required: true })
  public order!: Order;
  public orderTemplates: TemplateEditor.TemplateModel[] = [];
  public templatesObject: { [key: string]: any } = {};
  public defaultTemplate: TemplateEditor.TemplateModel | null = null;
  public labelIdName: string = "";
  public orderForPrintLabel: Order | null = null;
  @Getter("hasBioTrackTraceIntegrations", { namespace: "AuthModule" })
  public hasBioTrackTraceIntegrations!: boolean;
  @Getter("bioTrackTraceEnabled", { namespace: "AuthModule" })
  public bioTrackTraceEnabled!: boolean;

  protected async created() {
    // this.order.order_items!.map(item => {
    //   if (this.bioTrackTraceEnabled && item.product_info!.marijuana === 1) {
    //     item.batch_uid = item.biotrack_traceability_id;
    //   } else {
    //     item.batch_uid = item.batch_uid;
    //   }
    // });
    this.orderForPrintLabel = cloneDeep(this.order);
    this.orderForPrintLabel.location = this.currentLocation;
    this.orderForPrintLabel.location.full_address = `${
      this.orderForPrintLabel.location.city
    },${this.orderForPrintLabel.location.state!.name},${
      this.orderForPrintLabel.location.zip
    }`;
    this.orderForPrintLabel.order_items!.map((item: OrderItem) => {
      item.quantity_unit = item.product_info!.product_unit;
    });

    if (this.currentLocation.location_type === "RETAIL_RECREATIONAL") {
      this.labelIdName = "recreational_customer_label_template_id";
    } else {
      this.labelIdName = "medical_customer_label_template_id";
    }
    let templateIds =
      this.orderForPrintLabel &&
      uniq(
        this.orderForPrintLabel.order_items!.reduce(
          (acc: Array<number | null>, item, index) => {
            if (item.product_info && !item.product_info.packaging_detail) {
              acc.push(null);
            } else {
              const id =
                item.product_info &&
                // @ts-ignore
                item.product_info.packaging_detail[this.labelIdName];
              acc.push(!!parseInt(id, 10) ? id : null);
            }
            return acc;
          },
          []
        )
      );

    if (templateIds.includes(null)) {
      this.defaultTemplate = await templatesService.getDefault("CUSTOMER");
    }
    templateIds = templateIds.filter(id => {
      return id !== null;
    });
    if (templateIds.length) {
      this.orderTemplates = await templatesService.get({
        "q[id_is_in]": templateIds
      });
      this.templatesObject = this.orderTemplates.reduce(
        (acc, current) => ({ ...acc, [current.id]: current }),
        {}
      );
    }
    const printed: OrderItem[] = [];
    this.orderForPrintLabel.order_items!.forEach((order: OrderItem) => {
      if (order.product_info && order.product_info.product_unit === "u") {
        const quantity = order.quantity as number;
        const product = order.product_info as ProductInfo;
        for (let i = 0; i < quantity; i++) {
          printed.push({
            ...order,
            product_info: {
              ...product
            },
            quantity: 1,
            product_unit: "u"
          });
        }
      } else {
        printed.push(order);
      }
    });
    this.orderForPrintLabel.order_items = printed;

    if (this.defaultTemplate || size(this.templatesObject)) {
      this.$nextTick(() => this.$emit("readyToPrint"));
    } else {
      messagesService.renderWarningMessage("no_template_labels");
    }
  }
}
