import { listVariables } from "@/components/templateEditor/editorItems/UIBlock.config";
import TemplateEditor from "@/components/templateEditor/editors.declaration";
import { Component } from "vue-property-decorator";
import UIBlockDefaultComponent from "../UIBlockDefault.component";
import Template from "./UIBlockListField.template.vue";

import get from "lodash/get";
import { ListFieldDefaultConfig } from "../UIBlock.config";
import { ListFieldDefaultValues } from "../UIBlock.declarations";

@Component({
  mixins: [Template]
})
export default class UIBlockListFieldComponent extends UIBlockDefaultComponent {
  protected block: TemplateEditor.UIBlocks.Block = {
    model: {
      type: "text-item",
      model: { ...ListFieldDefaultValues }
    },
    configuration: ListFieldDefaultConfig
  };

  protected get fromModel() {
    let modelValue;
    if (this.values.variable) {
      const optionsValues = listVariables.find(
        item => item.label === this.values.variable.label
      );
      if (optionsValues) {
        for (const query of optionsValues.value) {
          modelValue = get(this.model, query);
          if (modelValue) {
            break;
          }
        }
      }
    }
    return modelValue;
  }

  protected get textStyles() {
    return {
      ...this.values,
      fontSize: this.values.fontSize + "px",
      fontWeight: this.values.bold ? 600 : 400,
      fontStyle: this.values.italic ? "italic" : "normal"
    };
  }
}
