import { Location } from "@/interfaces/location";
import { RetailSettings } from "@/interfaces/retailSettings";
import { User } from "@/interfaces/user";
import { permissionService } from "@/services/permission.service";
import { traceabilityService } from "@/services/traceability.service";
import { Callback, CallbackPromise } from "@/types/types";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import Template from "./TitleBar.template.vue";

const namespace = "AuthModule";

@Component({
  mixins: [Template],
  inject: ["$changes"]
})
export default class TitleBarComponent extends Vue {
  public selectedTraceabilitySystem!: string;
  public get hasMetrcIntegrations() {
    return (
      this.retailSettings &&
      this.retailSettings.integrations &&
      this.retailSettings.integrations!.metrc
    );
  }

  public get hasMetrcEnabled() {
    return (
      this.hasMetrcIntegrations &&
      this.retailSettings.integrations!.metrc.enable
    );
  }
  public locations: Location[] = [];
  public showCurrentLocation = false;

  @Prop({ default: "" }) public title!: string;
  @Prop({ default: "" }) public subTitle!: string;
  @Prop({ default: "" }) public icon!: string;
  @Prop({ default: null }) public iconAction!: Callback | null;

  @Getter("currentLocation", { namespace })
  public currentLocation!: Location;
  @Getter("user", { namespace })
  public user!: User;
  @Getter("currentRetailSettings", { namespace })
  public retailSettings!: RetailSettings;
  @Mutation("setCurrentRetailSettings", { namespace })
  public setRetailSettings!: Callback;
  @Getter("hasBioTrackTraceIntegrations", { namespace: "AuthModule" })
  public hasBioTrackTraceIntegrations!: boolean;
  @Getter("bioTrackTraceEnabled", { namespace: "AuthModule" })
  public bioTrackTraceEnabled!: boolean;

  @Action("updateCurrentLocation", { namespace })
  private updateCurrentLocation!: (l: Location) => Promise<boolean>;
  @Action("setCurrentLocation", { namespace })
  private setCurrentLocation!: CallbackPromise<any>;

  public async setLocation(index: number) {
    const newLocation = this.locations[index];
    if (newLocation.id !== this.currentLocation.id) {
      const response = await this.updateCurrentLocation(newLocation);
      if (response) {
        this.$router.go(0);
      }
    }
  }
  protected async created() {
    const locationId = +this.user.settings.current_location_id!;
    const response = await traceabilityService.getLocationTraceability(
      locationId
    );
    this.selectedTraceabilitySystem = response[0].value;
    permissionService.getUserLocations(this.user.id).then(locations => {
      this.locations = locations;
      if (this.currentLocation && +this.currentLocation.id! !== locationId) {
        this.setCurrentLocation(
          this.locations.find(
            (location: Location) => +location.id! === +locationId!
          )
        ).finally(() => (this.showCurrentLocation = true));
      } else {
        this.showCurrentLocation = true;
      }
    });
  }
}
