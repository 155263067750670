import { SharingData } from "@/interfaces/sharing";
import { AxiosError } from "axios";
import HttpService from "./http.service";
import { messagesService } from "./messages.service";

class SharingService extends HttpService {
  protected uri = "/catalog/sharing";
  public async getSharing(): Promise<SharingData | null> {
    try {
      const response = await super.get(null, false);
      return response.data as SharingData;
    } catch (error) {
      messagesService.renderErrorMessage(error as AxiosError<any>);
      return null;
    }
  }

  public async updateSharingConfig(
    model: SharingData
  ): Promise<SharingData | null> {
    try {
      const response = await super.put(false, model);
      messagesService.renderSuccessMessage("sharing.success_msg");
      return response as SharingData;
    } catch (error) {
      messagesService.renderErrorMessage(error as AxiosError<any>);
      return null;
    }
  }
}

export const sharingService = new SharingService();
