import { User } from "@/interfaces/user";
import { moneyService } from "@/services/money.service";
import { usersService } from "@/services/user.service";
import { UserState } from "@/vuex/modules/user/user.types";
import { RootState } from "@/vuex/types";
import { ActionContext, ActionTree } from "vuex";

type UserActionContext = ActionContext<UserState, RootState>;
type UserActionTree = ActionTree<UserState, RootState>;

export const actions: UserActionTree = {
  async loadUsers(context: UserActionContext): Promise<any> {
    try {
      context.commit("setLoading", true);
      const payload: User[] = await usersService.get();
      context.commit("setUsers", payload);
    } catch (e) {
      // error
    } finally {
      context.commit("setLoading", false);
    }
  },

  async searchUsers(context: UserActionContext, value: string): Promise<any> {
    try {
      context.commit("setLoading", true);
      const payload: User[] = await usersService.searchUsers(value);
      context.commit("setUsers", payload);
    } catch (e) {
      // error
    } finally {
      context.commit("setLoading", false);
    }
  },
  async sort(context: UserActionContext, filters: object): Promise<any> {
    try {
      usersService.sortQuery(filters);
    } catch (e) {
      // error
    }
  },

  async setCurrentTill(context: UserActionContext): Promise<boolean> {
    const response = await moneyService.getCurrentUserTill();
    context.commit("setCurrentTill", response || null);
    return !!response;
  }
};
