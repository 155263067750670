import { SearchQuery } from "@/interfaces/httpQuery";
import { MemberLevel } from "@/interfaces/memberLevel";
import HttpService from "./http.service";
import { messagesService } from "./messages.service";

class MemberLevelService extends HttpService {
  protected uri: string = "/store/member_levels";
  protected loadDispatcher: string = "MemberLevelModule/loadMemberLevels";
  protected searchableField: SearchQuery[] = [
    {
      field: "name",
      type: "contains"
    }
  ];

  public setQuery(query?: object | null) {
    if (query) {
      delete this.query["q[is_active_eq]"];
      delete this.query["q[is_active_is_false]"];
    }
    this.query = { ...this.query, ...query };
  }

  public async get(query?: object | null): Promise<MemberLevel[]> {
    this.setQuery(query);
    const response = await super.get(query);
    if (response.data.data) {
      return response.data.data;
    }
    return response.data;
  }

  public async getMemberLevels(): Promise<MemberLevel[]> {
    const response = await super.get({});
    return response.data.data.map((memberLevel: MemberLevel) => {
      return {
        id: memberLevel.id,
        name: memberLevel.name
      };
    });
  }

  public async toggleMemberLevels(
    memberLevel: MemberLevel,
    isActive: boolean
  ): Promise<void | null> {
    try {
      let response;
      if (isActive) {
        response = await super.enable(memberLevel);
        messagesService.renderSuccessMessage(
          "member_level_pricing.activated_ok"
        );
      } else {
        response = await super.disable(memberLevel);
        messagesService.renderSuccessMessage(
          "member_level_pricing.deactivated_ok"
        );
      }
      return response;
    } catch (error) {
      messagesService.renderErrorMessage(error);
    }
  }
}

export const memberLevelService: MemberLevelService = new MemberLevelService();
