import { ProductCategory } from "@/interfaces/productCategoy";
import { messagesService } from "@/services/messages.service";
import { productCategoryService } from "@/services/productCategory.service";
import { ProductCategoryState } from "@/vuex/modules/productCategory/productCategory.types";
import { RootState } from "@/vuex/types";
import { TablePagination } from "helix-vue-components";
import { ActionContext, ActionTree } from "vuex";

type ProductCategoryActionContext = ActionContext<
  ProductCategoryState,
  RootState
>;
type CustomerActionTree = ActionTree<ProductCategoryState, RootState>;

export const actions: CustomerActionTree = {
  async loadProductCategories(
    context: ProductCategoryActionContext
  ): Promise<any> {
    try {
      context.commit("setLoading", true);
      const productCategories: ProductCategory[] = await productCategoryService.get();
      const pagination: TablePagination = await productCategoryService.getPagination();
      context.commit("setPagination", pagination);
      context.commit("setProductCategories", productCategories);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setLoading", false);
    }
  },

  async enableMultiple(
    context: ProductCategoryActionContext,
    productCategories: ProductCategory[]
  ): Promise<any> {
    const productCategoriesIds = productCategories.map(
      (productCategoriesid: ProductCategory) => productCategoriesid.id!
    );
    context.commit("setLoading", true);
    try {
      await productCategoryService.enableMultiple(productCategoriesIds);
      messagesService.renderSuccessMessage("updated_successfully");
      context.dispatch("loadProductCategories");
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setLoading", false);
    }
  },

  async disableMultiple(
    context: ProductCategoryActionContext,
    productCategories: ProductCategory[]
  ): Promise<any> {
    const productCategoriesIds = productCategories.map(
      (productCategoriesid: ProductCategory) => productCategoriesid.id!
    );
    context.commit("setLoading", true);
    try {
      await productCategoryService.disableMultiple(productCategoriesIds);
      messagesService.renderSuccessMessage("updated_successfully");
      context.dispatch("loadProductCategories");
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setLoading", false);
    }
  }
};
