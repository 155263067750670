import { RootState } from "@/vuex/types";
import { Module } from "vuex";
import { actions } from "./location.actions";
import { getters } from "./location.getters";
import { mutations } from "./location.mutations";
import { state } from "./location.state";
import { LocationState } from "./location.types";

const namespaced: boolean = true;
export const LocationModule: Module<LocationState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
