import utcToZonedTime from "date-fns-tz/utcToZonedTime";
import addHours from "date-fns/addHours";
import addMinutes from "date-fns/addMinutes";
import addSeconds from "date-fns/addSeconds";
import format from "date-fns/format";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import differenceInMilliseconds from "date-fns/fp/differenceInMilliseconds";
import { enUS } from "date-fns/locale";
import parse from "date-fns/parse";

/**
 * @description
 * We should wrap every date-fns function that needs locales as param
 * ir order to avoid locale configuration in each function call.
 * Please refer to https://date-fns.org/v2.9.0/docs/I18n for more information
 * about this pattern.
 */

/**
 * Helper that provides usual app date formats.
 */
export const FNS_DATE_FORMATS = {
  /**
   * @default
   * Format: yyyy-MM-dd - E.g.: 2020-02-12
   */
  DEFAULT: "yyyy-MM-dd",
  /**
   * Format: MM/dd/yyyy - E.g.: 02/02/2020
   */
  BARS_DEFAULT: "MM/dd/yyyy",
  /**
   * Format: MM-dd-yyyy - E.g.: 02-02-2020
   */
  DASH_DEFAULT: "MM-dd-yyyy",
  /**
   * Format: dd-MM-yyyy - E.g.: 13-12-2021
   */
  DASH_ALTERNATE: "dd-MM-yyyy",
  /**
   * Format: yyyy-MM-dd HH:mm:ss - E.g.: 2020-02-12 23:15:20
   */
  DEFAULT_WITH_TIME: "yyyy-MM-dd HH:mm:ss",
  /**
   * Format: "MM-dd-yyyy hh:mm a - E.g.: 02-03-2020 01:01 PM
   */
  EN_DASH_WITH_MERIDIEM_TIME: "MM-dd-yyyy hh:mm a",
  /**
   * Format: MM/dd/yyyy hh:mm a - E.g.: 02/03/2020 01:01 PM
   */
  EN_BARS_WITH_MERIDIEM_TIME: "MM/dd/yyyy hh:mm a",
  /**
   * Format: MM/dd/yyyy hh:mm a - E.g.: 02/03/2020 @ 01:01 PM
   * Note: this format does not actually output what the string shows,
   * it's being modified within this method.
   */
  EN_BARS_WITH_MERIDIEM_TIME_AND_SEPARATOR: "MM/dd/yyyy h:m:s a",
  /**
   * Format: HH:mm:ss - E.g.: 23:15:20
   */
  LOCAL_TIME: "HH:mm:ss",
  /**
   * Format: yyyy-MM-dd HH:mm:ssxx - E.g.: 2019-07-25T13:23:00-0300
   * Note: this format does not actually output what the string shows,
   * it's being modified within this method.
   */
  TIMEZONE: "yyyy-MM-dd HH:mm:ssxx",
  /**
   * Format: yyyy-MM-dd HH:mm:ssxx - E.g.: 2019-07-25T13:23:00-0300
   * Note: this format does not actually output what the string shows,
   * it's being modified within this method.
   */
  TIMEZONE_HUMAN_READABLE: "MM-dd-yyyy HH:mm:ssxx",
  /**
   * Format: yyyy-MM-dd HH:mm:ssX - E.g.: 2019-07-25T13:23:00Z
   * Note: same as API format but adding Z at the end and T
   * in the middle like ISO-8601
   */
  ISO: "yyyy-MM-dd HH:mm:ssX",
  /**
   * Format: hh:mm a - E.g.: 02:43 PM
   */
  LT: "hh:mm a",
  /**
   * Format: HH:mm - E.g.: 22:43
   */
  BASIC_TIME: "HH:mm",
  /**
   * Format: yyyyMMddHHmmss - E.g.: 20200211112931
   */
  FILE: "yyyyMMddHHmmss",
  /**
   * Format: yyyyMMddHHmmssS - E.g.: 202002111129315
   */
  DAT_FILE: "yyyyMMddHHmmssS",
  /**
   * Format: MM/dd/yyyy hh:mm:ss a - E.g.: 02/02/2020 10:30:15 PM
   */
  BARS_FULL_DATE: "MM/dd/yyyy hh:mm:ss a"
};

/**
 * @param Date | string
 * @param string | FNS_DATE_FORMATS;
 * @returns string;
 * Formats the provided date to the provided format.
 * If no format is provided the default format is
 * FNS_DATE_FORMATS.DEFAULT ("yyyy-MM-dd").
 *
 * You can provide a Date object to be formatted, but you
 * can also provide a string with the format yyyy-MM-dd.
 * Any other format will throw error. In that case
 * you can parse the date before with our custom wrapper
 * for date-fns parse function.
 *
 */
export function fnsFormatDate(
  date: Date | string,
  formatStr = FNS_DATE_FORMATS.DEFAULT
): string {
  let dateToFormat;

  if (typeof date === "string") {
    const split = date.split("-");
    if (split.length <= 1) {
      throw new Error(
        "First parameter has to be a Date or a string with format 'yyyy-MM-dd'"
      );
    }
    dateToFormat = new Date(+split[0], +split[1] - 1, +split[2]);
  } else {
    dateToFormat = date;
  }

  switch (formatStr) {
    case FNS_DATE_FORMATS.TIMEZONE:
      return format(dateToFormat, formatStr).replace(" ", "T");

    case FNS_DATE_FORMATS.ISO:
      return (
        format(dateToFormat, FNS_DATE_FORMATS.DEFAULT_WITH_TIME).replace(
          " ",
          "T"
        ) + "Z"
      );

    case FNS_DATE_FORMATS.EN_BARS_WITH_MERIDIEM_TIME_AND_SEPARATOR:
      return format(dateToFormat, formatStr).replace(" ", "@");

    default:
      break;
  }

  return format(dateToFormat, formatStr, { locale: enUS });
}

/**
 * @param date: string
 * @param formatString: string
 * @returns: Date
 * Parses the provided string to a Date object by using the
 * provided string format as model for the date string.
 */
export function fnsParse(date: string, formatString: string): Date {
  return parse(date, formatString, new Date(), { locale: enUS });
}

/**
 * @param dateLeft: number | Date
 * @param dateRight: number | Date
 * @returns string
 * Takes two dates, determines the duration
 * between them and returns a formatted string
 * equal to HH:mm:ss. This methods takes in account
 * UTC in order to avoid differences within locales.
 */
export function fnsDurationAsLocalTime(
  dateLeft: number | Date,
  dateRight: number | Date
): string {
  const diff = new Date(
    0,
    0,
    0,
    0,
    0,
    0,
    Math.abs(differenceInMilliseconds(dateLeft, dateRight))
  );

  return addTimeZeros(
    `${diff.getHours()}:${diff.getMinutes()}:${diff.getSeconds()}`
  );
}

/**
 * @param initialTime: string
 * @param timeToAdd: string
 * @returns string
 * Sums a period of a time to another, expecting
 * both to be formatted as: HH:mm:ss E.g.: 02:32:15
 */
export function addTime(initialTime: string, timeToAdd: string): string {
  const split = timeToAdd.split(":");

  let date = fnsParse(initialTime, FNS_DATE_FORMATS.LOCAL_TIME);
  date = addHours(date, +split[0]);
  date = addMinutes(date, +split[1]);
  date = addSeconds(date, +split[2]);

  return addTimeZeros(
    `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
  );
}

/**
 * @param date: string
 * @returns string
 * Return the distance between the given date and now in words.
 * Note: Keep in mind that the expected date will be parsed as
 * UTC.
 */
export function fnsHumanize(date: string | Date): string {
  return formatDistanceToNow(fnsUtcToZonedTime(date));
}

/**
 * @param date: string | Date | number
 * @returns Date
 * Parses a date from local to utc.
 */
export function fnsUtcToZonedTime(date: string | Date | number) {
  return utcToZonedTime(new Date(date), "", { locale: enUS });
}

/**
 * @param value: string
 * @returns string
 * Adds zeros for hours, minutes or seconds showing
 * only with one digit.
 */
export function addTimeZeros(value: string): string {
  const split = value.split(":");
  return split.map(v => (v.length === 1 ? `${0}${v}` : v)).join(":");
}

/**
 * Converts a UTC time string to local time.
 * @param time: string
 * @returns Date
 */
export function UTCTimeToLocalDate(time: string): Date {
  const split = time.split(":");
  const today = new Date();
  return new Date(
    Date.UTC(
      +today.getFullYear(),
      +today.getMonth(),
      +today.getDate(),
      +split[0],
      +split[1],
      +split[2]
    )
  );
}

/**
 * @param date: string
 * @returns Date
 * Converts the UTC date format provided by the API
 * to a JS Date in local time. Expects input as
 * 2020-02-20T15:11:01+00:00
 */
export function convertUTCDateStringToLocalDate(date: string): Date {
  const dateString = date.split("T")[0].split("-");
  const timeString = date
    .split("T")[1]
    .split("+")[0]
    .split(":");

  return new Date(
    Date.UTC(
      +dateString[0],
      +dateString[1] - 1,
      +dateString[2],
      +timeString[0],
      +timeString[1],
      +timeString[2]
    )
  );
}

/**
 * Converts a javascript date (local) to UTC time.
 * @param date: Date
 * @returns string (HH:mm:ss)
 */
export function localDateToUTCTime(date: Date): string {
  return date
    ? date
        .toISOString()
        .split("T")[1]
        .split(".")[0]
    : "";
}
