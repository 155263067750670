import TablePatientSelectComponent from "@/components/retail/pos/pointOfSale/posCustomerList/tablePatientSelect/TablePatientSelect.component";
import TableProductsComponent from "@/components/sharedComponents/tableProducts/TableProductsComponent";
import { Customer } from "@/interfaces/customer";
import { Order, orderDefault, statusOrder } from "@/interfaces/order";
import { RetailSettings } from "@/interfaces/retailSettings";
import { SelectOrdersTableMetadata } from "@/metadata/order";
import { customerService } from "@/services/customer.service";
import { orderActionService } from "@/services/order.action.service";
import { orderService } from "@/services/order.service";
import { TableAction, TableComponent, TableHeader } from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import union from "lodash/union";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Template from "./SelectCaregiverOrder.template.vue";
export interface SelectedPatient {
  text?: string;
  value?: Customer;
}
@Component({
  mixins: [Template],
  components: {
    TableProductsComponent,
    TableComponent,
    "patient-select": TablePatientSelectComponent
  }
})
export default class SelectCaregiverOrderComponent extends Vue {
  public loading = false;
  public headers: TableHeader[] = SelectOrdersTableMetadata;
  public rowActions: TableAction[] = orderActionService.getOrdersActions(
    this.selectOrder
  );
  public fieldsToShow: Array<{ [key: string]: string | undefined }> = [];
  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  public retailSettings!: RetailSettings;

  public isCaregiver!: boolean;
  public isPatient!: boolean;

  @Prop({ required: true })
  public patients!: Customer[];

  public patientSelected: Customer | null = null;

  public orders: Order[] = [];

  @Prop({ required: true })
  public customer!: Customer;

  get displayCustomer() {
    if (this.customer) {
      return this.customer.purchasingBy || this.customer;
    }
    return null;
  }

  public get getPatients() {
    this.getProfiles();
    const patients = cloneDeep(this.patients);
    if (this.isCaregiver && this.isPatient) {
      patients.push(this.customer);
    }
    if (patients.length) {
      return patients
        ? patients.map((item: Customer) => ({
            text: `${item.first_name} ${item.last_name}`,
            value: item
          }))
        : [];
    }
    return [];
  }

  public getProfiles() {
    const res = this.customer.profiles!.map(obj => {
      if (obj.profile_type === "CAREGIVER") {
        return (this.isCaregiver = true);
      }
      if (obj.profile_type === "PATIENT") {
        return (this.isPatient = true);
      }
      return obj.profile_type;
    });
  }

  public newOrder() {
    const selectedPatient = this.patientSelected! as SelectedPatient;
    const order: Order = {
      ...orderDefault,
      customer_id: selectedPatient.value!.customer_id
    };
    this.selectOrder(order);
  }

  protected async selectOrder(order: Order) {
    if (!order.customer) {
      if (order.customer_id === this.customer.customer_id) {
        order.customer = this.customer;
      } else {
        order.customer = await customerService.findById(order.customer_id!);
        if (order.customer) {
          order.customer.purchasingBy = this.customer;
        }
      }
    }
    order.customer!.purchasingBy = this.customer;
    if (
      order.online_pending ||
      order.call_in_pending ||
      order.in_store_pending
    ) {
      this.$modals
        .loadConfirmation(
          {
            title: "WARNING",
            text: this.$t("points_of_sales.alert_online_pending").toString(),
            acceptButton: "yes",
            cancelButton: "no"
          },
          {
            positionX: "center",
            size: "fit"
          }
        )
        .then((res: boolean) => {
          if (res) {
            this.$emit("resolve", order);
          } else {
            return false;
          }
        })
        .catch(error => this.$alert(error));
    } else {
      this.$emit("resolve", order);
    }
  }

  protected setFieldsToShow() {
    const fields = [
      {
        label: this.$t("batch_id").toString(),
        value: "batch_barcode_uid",
        secondaryValue: ""
      },
      {
        label: this.$t("product").toString(),
        value: "product_info.name"
      },
      {
        label: this.$t("quantity").toString(),
        value: "quantityUnit"
      }
    ];
    if (this.retailSettings.stock_identification_type_in_pos === "NDC_NUMBER") {
      fields[0].secondaryValue = "ndc_number";
    } else if (
      this.retailSettings.stock_identification_type_in_pos === "SECONDARY_ID"
    ) {
      fields[0].secondaryValue = "tracking_id";
    }

    return fields;
  }

  protected parseOrdersToDisplay(ordersArray: Order[]) {
    const parsedOrders = ordersArray.map(order => {
      if (order.order_items) {
        order.order_items = order.order_items.map(item => ({
          ...item,
          ndc_number: item.product_info && item.product_info.ndc_number,
          quantityUnit: `${item.quantity}${
            item.product_info!.requires_weighing
              ? item.product_info!.product_unit
              : "u"
          }`
        }));
      }
      if (order.pre_order) {
        const pending = order.pre_order.status === statusOrder.pending;
        const callIn = order.pre_order.source === "CALL_IN";
        const isoof = order.pre_order.source === "IN_STORE";
        order.online_filled = !pending && !callIn && !isoof;
        order.online_pending = pending && !callIn && !isoof;
        order.call_in_pending = pending && callIn;
        order.call_in_filled = !pending && callIn;
        order.in_store_pending = pending && isoof;
        order.in_store_filled = !pending && isoof;
      }
      return order;
    });
    return parsedOrders;
  }

  protected async mounted() {
    this.loading = true;
    this.getProfiles();
    this.fieldsToShow = this.setFieldsToShow();
    const caregiverPending = await orderService.getCustomerOrders(
      this.customer.customer_id!
    );
    const patientsPending = await orderService.getCaregiverPatientsOrders(
      this.customer.customer_id!
    );

    this.orders = union(
      this.parseOrdersToDisplay(caregiverPending),
      this.parseOrdersToDisplay(patientsPending)
    );
    this.loading = false;
  }
}
