import { actions } from "@/vuex/modules/order/order.actions";
import { getters } from "@/vuex/modules/order/order.getters";
import { mutations } from "@/vuex/modules/order/order.mutations";
import { state } from "@/vuex/modules/order/order.state";
import { OrderState } from "@/vuex/modules/order/order.types";
import { RootState } from "@/vuex/types";
import { Module } from "vuex";

const namespaced: boolean = true;

export const OrderModule: Module<OrderState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
