import { Component } from "vue-property-decorator";
import Template from "./UIBlockColumnField.template.vue";

import { EventBus } from "@/internal";
import TemplateEditor from "../../editors.declaration";
import UIBlockDefaultComponent from "../UIBlockDefault.component";

@Component({
  mixins: [Template]
})
export default class UIBlockColumnFieldComponent extends UIBlockDefaultComponent {
  public hovered: boolean = false;

  protected block: TemplateEditor.UIBlocks.Block = {
    model: {
      type: "column-item",
      model: {
        width: 100,
        movable: true,
        children: [],
        justifyContent: "flex-start",
        alignItems: "flex-start"
      }
    },
    configuration: {
      title: "Column Editor",
      sections: [
        {
          title: "Width",
          fields: {
            flex: {
              type: "range",
              configuration: {
                min: 0,
                max: 1,
                step: 0.01
              }
            }
          }
        },
        {
          title: "Layout",
          fields: {
            splitColumn: {
              type: "action-button",
              configuration: {
                label: "Split Column",
                icon: "fal fa-columns",
                action: this.splitColumns("horizontal")
              }
            },
            addRow: {
              type: "action-button",
              configuration: {
                label: "Add row",
                icon: "fal fa-plus-circle",
                action: this.splitColumns("vertical")
              }
            }
          }
        },
        {
          title: "Alignment",
          fields: {
            justifyContent: {
              type: "boolean-buttons-group",
              configuration: {
                title: "Horizontal Alignment",
                buttons: [
                  {
                    icon: "fal fa-align-left",
                    value: "flex-start"
                  },
                  {
                    icon: "fal fa-align-center",
                    value: "center"
                  },
                  {
                    icon: "fal fa-align-right",
                    value: "flex-end"
                  }
                ]
              }
            },
            alignItems: {
              type: "boolean-buttons-group",
              configuration: {
                title: "Vertical Alignment",
                buttons: [
                  {
                    icon: "fal fa-arrow-to-top",
                    value: "flex-start"
                  },
                  {
                    icon: "fal fa-align-center",
                    value: "center"
                  },
                  {
                    icon: "fal fa-arrow-to-bottom",
                    value: "flex-end"
                  }
                ]
              }
            }
          }
        }
      ]
    }
  };

  protected droppable: string[] = ["row", "components"];

  public get styles() {
    return {
      justifyContent: this.values.alignItems,
      alignItems: this.values.justifyContent,
      flex: this.values.flex
    };
  }

  protected splitColumns(direction: "horizontal" | "vertical") {
    return () => {
      EventBus.$emit("TemplateEditor.SplitColumn", {
        breadcrumb: this.breadcrumb,
        direction
      });
      EventBus.$emit("TemplateEditor.ExitEditBar");
    };
  }
}
