import { ProductCategory } from "@/interfaces/productCategoy";
import { ProductCategoryState } from "@/vuex/modules/productCategory/productCategory.types";
import { RootState } from "@/vuex/types";
import { TablePagination } from "helix-vue-components";
import { GetterTree } from "vuex";

type ProductCategoryGetters = GetterTree<ProductCategoryState, RootState>;

export const getters: ProductCategoryGetters = {
  productCategories(state: ProductCategoryState): ProductCategory[] {
    return state.productCategories;
  },
  productCategory(state: ProductCategoryState): ProductCategory | null {
    return state.productCategory;
  },
  pagination(state: ProductCategoryState): TablePagination | null | undefined {
    return state.pagination;
  },
  loading(state: ProductCategoryState): boolean {
    return state.loading;
  },
  addNew(state: ProductCategoryState): boolean {
    return state.addNew;
  },
  locations(state: ProductCategoryState): Location[] {
    return state.locations;
  },
  currentLocation(state: ProductCategoryState): Location | null {
    return state.currentLocation;
  }
};
