import { RootState } from "@/vuex/types";
import { GetterTree } from "vuex";
import { Notifications, NotificationState } from "./notifications.types";

type EmployeeGetter = GetterTree<NotificationState, RootState>;

export const getters: EmployeeGetter = {
  notifications(state): Notifications[] {
    return state.notifications;
  },

  isLoading(state): boolean {
    return state.loading;
  },

  newNotifications(state): Notifications[] {
    return state.notifications.filter(notification => !notification.viewed);
  }
};
