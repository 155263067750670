import HttpService from "@/services/http.service";
import { messagesService } from "./messages.service";

class InventorySettings extends HttpService {
  public uri = `${process.env.VUE_APP_BASE_URL}/catalog/settings`;
  public async getSettings(query = {}) {
    try {
      return await this.get(query);
    } catch (e) {
      messagesService.renderErrorMessage(e as any);
      return null;
    }
  }

  public async updateSettings(
    value: string
  ): Promise<InventorySettings | null> {
    try {
      const response = await this.put(false, {
        stock_identification_type: value
      });
      messagesService.renderSuccessMessage(
        "inventory_settings_view.save_success"
      );
      return response;
    } catch (e) {
      messagesService.renderErrorMessage(e as any);
      return null;
    }
  }
}

export const inventorySettings: InventorySettings = new InventorySettings();
