import { HttpQuery } from "@/interfaces/httpQuery";
import {
  BatchReconciliation,
  MetrcItem,
  ProductMetrcItem,
  ReconciliationPayload,
  ReconciliationStatus,
  Traceability
} from "@/interfaces/traceability";
import { EventBus } from "@/internal";
import { SecurityPinService } from "@/plugins/security-pin/security-pin.service";
import HttpService from "@/services/http.service";
import Vue from "vue";
import { messagesService } from "./messages.service";

class TraceabilityService extends HttpService {
  public uri: string = `${
    process.env.VUE_APP_CORE_URL
  }/services/bdi/configs_values/traceability`;

  public async getLocationTraceability(locationId: number): Promise<any> {
    this.uri = `${
      process.env.VUE_APP_CORE_URL
    }/services/bdi/configs_values/traceability`;
    const response = await super.get({ location_id: locationId });
    return response.data.data;
  }

  public async traceabilityCredsValidation() {
    try {
      const response = await Vue.axios.get(
        "/traceability/biotrack/notifications"
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }

  public async getReconciliationStatus(): Promise<ReconciliationStatus[]> {
    this.uri = "/traceability/metrc/reconciliation/strains";
    try {
      const response = await super.get(null, false);
      return response.data;
    } catch (error) {
      messagesService.renderErrorMessage(error);
      return [];
    }
  }

  public async reconciliateMetrc() {
    try {
      const response = await this.axios({
        method: "PATCH",
        url: "/traceability/metrc/reconciliation/strains/metrc"
      });
      return response;
    } catch (error) {
      messagesService.renderErrorMessage(error);
    }
  }

  public async reconciliateBiotrack() {
    try {
      const response = await this.axios({
        method: "PATCH",
        url: "/traceability/metrc/reconciliation/strains/biotrack"
      });
      return response;
    } catch (error) {
      messagesService.renderErrorMessage(error);
    }
  }

  public async getProductMetrcMappedItemId(productSKU: string) {
    this.uri = `${
      process.env.VUE_APP_BASE_URL
    }/traceability/metrc/mapped_item/${productSKU}`;

    try {
      const response = await super.get({}, false);
      return response.data;
    } catch (error) {
      return null;
    }
  }

  public async getProductMetrcItem(metrcId: string, query: HttpQuery = {}) {
    this.uri = `${
      process.env.VUE_APP_BASE_URL
    }/traceability/metrc/items/${metrcId}`;
    try {
      const response = await super.get(query, false);
      return response.data;
    } catch (error) {
      return null;
    }
  }

  public async findByMetrcName(
    text: string,
    query: { [key: string]: string | number } = {}
  ): Promise<MetrcItem[]> {
    this.uri = `${process.env.VUE_APP_BASE_URL}/traceability/metrc/items`;
    query = { ...query, name: text };
    const result = await this.get(query);
    return result.data.data;
  }

  public async setProductMetrcMappedItem(payload: ProductMetrcItem) {
    const action = payload.isNewItem ? "POST" : "PUT";
    if (action === "POST") {
      this.uri = `${
        process.env.VUE_APP_BASE_URL
      }/traceability/metrc/mapped_item`;
    } else {
      this.uri = `${
        process.env.VUE_APP_BASE_URL
      }/traceability/metrc/mapped_item/${payload.biotrack_sku}`;
    }

    try {
      const response = await this.axios.request({
        method: action,
        url: this.uri,
        data: {
          biotrack_sku: payload.biotrack_sku,
          metrc_id: payload.metrc_id
        }
      });
      return response;
    } catch (error) {
      messagesService.renderErrorMessage(error);
    }
  }

  public async getBatchDetails(
    batchUID: string
  ): Promise<BatchReconciliation | null> {
    this.uri = `/inventory/batches/${batchUID}/traceability/details`;
    try {
      const response = await super.get({}, false);
      return response.data;
    } catch (error) {
      messagesService.renderErrorMessage(error);
      return null;
    }
  }

  public async getSyncTestResults(batchUID: string) {
    this.uri = `/traceability/biotrack/sync/inventory_sample/${batchUID}`;
    try {
      const response = await super.get({}, false);
      return response;
    } catch (error) {
      return error.response.data;
    }
  }

  public async unlinkProduct(sku: string) {
    this.uri = `/traceability/metrc/mapped_item/${sku}`;
    try {
      const response = await this.axios.delete(this.uri);
      return response.data;
    } catch (error) {
      messagesService.renderErrorMessage(error);
    }
  }

  public async syncCheckAPI() {
    this.uri = "/traceability/biotrack/sync";
    try {
      const response = await super.axios.request({
        method: "POST",
        url: this.uri,
        data: {
          inventory_room: "1"
        }
      });
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }

  public async reconcileBatch(
    payload: Partial<ReconciliationPayload>,
    loadingWindow: boolean
  ) {
    this.uri = "/inventory/operations/reconciliation";
    try {
      let pin: string | null;
      if (
        (payload.local && payload.local.adjust) ||
        (payload.remote && payload.remote.adjust)
      ) {
        pin = await this.authorize();
        if (!pin) {
          return;
        }
      }
      const response = await super.post(payload, false, pin! as string);
      return response;
    } catch (error) {
      if (!loadingWindow) {
        messagesService.renderErrorMessage(error);
      }
      return { errors: messagesService.parseMetrcError(error) };
    }
  }

  public showReconcileSuccess() {
    messagesService.renderSuccessMessage("metrc_reconciliation.batch_reco_ok");
  }
  public async getMetrcOrderDetails(
    orderId: number,
    isRefund: boolean = false
  ) {
    this.uri = `sale/orders/${
      isRefund ? "refunds/" : ""
    }${orderId}/trc_information`;
    try {
      const response = await this.axios.get(this.uri);
      return response.data.data;
    } catch (error) {
      messagesService.renderErrorMessage(error);
      return null;
    }
  }
  public async syncMetrcStatus(orderID: number, type: "sales" | "refunds") {
    this.uri = `/sale/orders/${
      type === "refunds" ? type + "/" : ""
    }${orderID}/trc_sync`;
    try {
      const res = await this.patch(null);

      messagesService.renderSuccessMessage("sale_successfully_uploaded");
      return res;
    } catch (error) {
      return { errors: messagesService.parseMetrcError(error) };
    }
    /** */
  }

  public async syncBatches(sku: string, reconciling: string) {
    this.uri = `/sale/orders/sync`;
    try {
      // const res = await this.patch(null);

      return { code: 200, status: "success" };
    } catch (error) {
      return { errors: messagesService.parseMetrcError(error) };
    }
  }
}

export const traceabilityService: TraceabilityService = new TraceabilityService();
