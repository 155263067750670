import { DataSendTetminal, Terminal } from "@/interfaces/system";
import { store } from "@/internal";
import { messagesService } from "@/services/messages.service";
import { systemService } from "@/services/system.service";
import { SystemState } from "@/vuex/modules/system/system.types";
import { RootState } from "@/vuex/types";
import { TablePagination } from "helix-vue-components";
import { ActionContext, ActionTree } from "vuex";

type SystemActionContext = ActionContext<SystemState, RootState>;
type SystemActionTree = ActionTree<SystemState, RootState>;

export const actions: SystemActionTree = {
  async loadTerminals(context: SystemActionContext): Promise<any> {
    try {
      context.commit("setLoading", true);
      const payload: Terminal[] = await systemService.get();
      const pagination: TablePagination = await systemService.getPagination();
      context.commit("setPagination", pagination);
      context.commit("setTerminals", payload);
    } catch (e) {
      // error
    } finally {
      context.commit("setLoading", false);
    }
  },
  async saveTerminal(
    context: SystemActionContext,
    dataTerminal: DataSendTetminal
  ): Promise<any> {
    context.commit("setLoading", true);
    if (
      dataTerminal.terminal &&
      dataTerminal.newRooms &&
      dataTerminal.delRooms
    ) {
      await systemService.pushTerminal(
        dataTerminal.terminal,
        dataTerminal.newRooms,
        dataTerminal.delRooms
      );
    }
    context.commit("setLoading", false);
    messagesService.renderSuccessMessage("terminal_saved");
    context.dispatch("RouterModule/go", { name: "terminal" }, { root: true });
  },
  async findTerminal(
    context: SystemActionContext,
    dataRequest: {
      id: number;
      route?: string;
    }
  ): Promise<any> {
    try {
      context.commit("setLoading", true);
      systemService.changeQuery(dataRequest.route);
      const terminal: Terminal | null = await systemService.find(
        Number(dataRequest.id)
      );
      context.commit("setCurrentTerminal", terminal);
      context.commit("setLoading", false);
    } catch (error) {
      store.dispatch("RouterModule/errorRedirect", {
        location: { name: "terminal" },
        error
      });
    }
  },

  async deleteTerminal(
    context: SystemActionContext,
    terminal: Terminal
  ): Promise<any> {
    try {
      context.commit("setLoading", true);
      await systemService.removeTerminal(terminal);
      context.dispatch("loadTerminals");
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setLoading", false);
    }
  }
};
