import { i18n } from "@/plugins/i18n";
import {
  FormField,
  FormFieldTypes,
  TableFieldType,
  TableHeader
} from "helix-vue-components";

export const InventoryViewTableMetadata: TableHeader[] = [
  {
    value: "nameQty",
    label: i18n.t("name").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: true
  },
  {
    value: "brand.name",
    label: i18n.t("product_details.brand").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong"
  },
  {
    value: "category.name",
    label: i18n.t("category").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: true
  },
  {
    value: "batch_type.name",
    label: i18n.t("current_inventory.batch_type").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong"
  },
  {
    value: "room_quantity",
    label: i18n.t("current_inventory.room_quantity").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong"
  },
  {
    value: "available_reserved",
    label: i18n.t("current_inventory.available_reserved").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong"
  },
  {
    value: "total_quantity",
    label: i18n.t("current_inventory.location_quantity").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong"
  }
];

export const ProductTableMetadata: TableHeader[] = [
  {
    value: "name",
    label: i18n.t("name").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: true
  },
  {
    value: "category.name",
    label: i18n.t("category").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: true
  },
  {
    value: "batch_type.name",
    label: i18n.t("batch_type").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: true
  }
];

export const SharedProductTableMetadata: TableHeader[] = [
  {
    value: "name",
    label: i18n.t("name").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: true
  }
];

export const ProductAuditMetadata: TableHeader[] = [
  {
    value: "item",
    label: i18n.t("item").toString(),
    fieldComponent: TableFieldType.string,
    class: "auditTable__item__product",
    sortable: false,
    options: {
      shrink: true
    }
  },
  {
    value: "total_quantity",
    label: i18n.t("original").toString(),
    fieldComponent: TableFieldType.string,
    class: "auditTable__item",
    sortable: false,
    options: {
      grow: true
    }
  },
  {
    value: "new",
    label: i18n.t("new").toString(),
    fieldComponent: TableFieldType.string,
    class: "auditTable__item",
    sortable: false,
    options: {
      grow: true
    }
  },
  {
    value: "difference",
    label: i18n.t("difference").toString(),
    fieldComponent: TableFieldType.string,
    class: "auditTable__item",
    sortable: false,
    options: {
      grow: true
    }
  },
  {
    value: "status",
    label: i18n.t("status").toString(),
    fieldComponent: TableFieldType.string,
    class: "auditTable__item",
    sortable: false,
    options: {
      grow: true
    }
  }
];

export const PricePointTableMetadata: TableHeader[] = [
  {
    value: "quantity",
    label: i18n.t("amount").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: false
  },
  {
    value: "price",
    label: i18n.t("price").toString(),
    fieldComponent: TableFieldType.currency,
    class: "tdt__headers__fieldLong",
    sortable: false
  },
  {
    value: "post_tax",
    label: i18n.t("post_tax").toString(),
    fieldComponent: TableFieldType.currency,
    class: "tdt__headers__fieldLong",
    sortable: false
  }
];

export const BatchesTableMetadata: TableHeader[] = [
  {
    value: "batch_uid",
    label: i18n.t("batch_number").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: true
  },
  {
    value: "tracking_id",
    label: i18n.t("second_id").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: true
  },
  // {
  //   value: "location",
  //   label: i18n.t("location").toString(),
  //   fieldComponent: TableFieldType.string,
  //   class: "tdt__headers__fieldLong"
  // },
  {
    value: "total_quantity",
    label: i18n.t("quantity").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: true
  }
];

export const PosSelectProductDiscount: TableHeader[] = [
  {
    value: "batch_barcode_uid",
    label: i18n.t("batch").toString(),
    fieldComponent: TableFieldType.string
  },
  {
    value: "product_info.name",
    label: i18n.t("product").toString(),
    fieldComponent: TableFieldType.string
  },
  {
    value: "quantity",
    label: i18n.t("quantity").toString(),
    fieldComponent: TableFieldType.numericField,
    validateFns: []
  }
];

export const WholeLoyaltyDiscount: TableHeader[] = [
  {
    value: "batch",
    label: i18n.t("batch").toString(),
    fieldComponent: TableFieldType.string
  },
  {
    value: "product",
    label: i18n.t("product").toString(),
    fieldComponent: TableFieldType.custom
  },
  {
    value: "quantity/j/product_unit",
    label: i18n.t("quantity").toString(),
    fieldComponent: TableFieldType.string
  }
];

export const PosManualDiscountFields: TableHeader[] = [
  {
    value: "batch",
    label: i18n.t("batch").toString(),
    fieldComponent: TableFieldType.string
  },
  {
    value: "product",
    label: i18n.t("product").toString(),
    fieldComponent: TableFieldType.custom
  },
  {
    value: "quantity",
    label: i18n.t("quantity").toString(),
    fieldComponent: TableFieldType.custom
  },
  {
    value: "discount",
    label: i18n.t("discount").toString(),
    fieldComponent: TableFieldType.custom
  },
  {
    value: "total_discount",
    label: i18n.t("cart_discounts.total_discount").toString(),
    fieldComponent: TableFieldType.custom
  }
];

export const WholeProductSelectDiscount: TableHeader[] = [
  {
    value: "batch",
    label: i18n.t("batch").toString(),
    fieldComponent: TableFieldType.string
  },
  {
    value: "product",
    label: i18n.t("product").toString(),
    fieldComponent: TableFieldType.custom
  },
  {
    value: "quantity",
    label: i18n.t("quantity").toString(),
    fieldComponent: TableFieldType.custom
  }
];

export const MultipleLoyaltyDiscount: TableHeader[] = [
  {
    value: "batch",
    label: i18n.t("batch").toString(),
    fieldComponent: TableFieldType.string
  },
  {
    value: "product",
    label: i18n.t("product").toString(),
    fieldComponent: TableFieldType.custom
  },
  {
    value: "loyalty_points",
    label: i18n.t("customer.point").toString(),
    fieldComponent: TableFieldType.custom
  },
  {
    value: "quantity/j/product_unit",
    label: i18n.t("quantity").toString(),
    fieldComponent: TableFieldType.string
  },
  {
    value: "loyalty_amount",
    label: i18n.t("discount").toString(),
    fieldComponent: TableFieldType.custom
  }
];

export const productMetrcInfo: (
  ...args: any
) => FormField[] = searchProducts => [
  {
    label: i18n.t("product_details.metrc_item").toString(),
    name: "id",
    component: FormFieldTypes.asyncAutocomplete,
    options: {
      row: 1,
      flex: 6,
      associationText: "name",
      associationValue: "id",
      selectOptions: searchProducts
    }
  },
  {
    label: i18n.t("product_details.category").toString(),
    name: "item_category",
    component: FormFieldTypes.textField,
    options: {
      row: 2,
      flex: 6,
      flexAlign: "align-end fill-height",
      disableCondition: () => true
    }
  },
  {
    label: i18n.t("product_details.strain").toString(),
    name: "strain",
    component: FormFieldTypes.textField,
    options: {
      row: 3,
      flex: 6,
      disableCondition: () => true
    }
  },
  {
    label: i18n.t("product_details.unit_of_measure").toString(),
    name: "unit_of_measure",
    component: FormFieldTypes.textField,
    options: {
      row: 4,
      flex: 6,
      disableCondition: () => true
    }
  },
  {
    label: i18n.t("product_details.unit_weight").toString(),
    name: "unit_weight",
    component: FormFieldTypes.textField,
    options: {
      row: 5,
      flex: 4,
      disableCondition: () => true
    }
  },
  {
    label: "",
    name: "unit_weight_unit_of_measure",
    component: FormFieldTypes.textField,
    options: {
      row: 5,
      flex: 2,
      flexAlign: "align-self-end",
      disableCondition: () => true
    }
  },
  {
    label: i18n.t("product_details.unit_thc_content").toString(),
    name: "unit_thc_content",
    component: FormFieldTypes.textField,
    options: {
      row: 6,
      flex: 4,
      disableCondition: () => true
    }
  },
  {
    label: "",
    name: "unit_thc_content_unit_of_measure",
    component: FormFieldTypes.textField,
    options: {
      row: 6,
      flex: 2,
      flexAlign: "align-self-end",
      disableCondition: () => true
    }
  }
];
