import { ProductCategoryState } from "@/vuex/modules/productCategory/productCategory.types";
import { RootState } from "@/vuex/types";
import { Module } from "vuex";
import { actions } from "./productCategory.actions";
import { getters } from "./productCategory.getters";
import { mutations } from "./productCategory.mutations";
import { state } from "./productCategory.state";

const namespaced: boolean = true;
export const ProductCategoryModule: Module<ProductCategoryState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
