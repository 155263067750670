import { ProductState } from "@/vuex/modules/inventory/product/product.types";
import { RootState } from "@/vuex/types";
import { Module } from "vuex";
import { actions } from "./product.actions";
import { getters } from "./product.getters";
import { mutations } from "./product.mutations";
import { state } from "./product.state";

const namespaced: boolean = true;
export const ProductModule: Module<ProductState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
