import { policyList } from "@/enums/permissions";
import { MenuSection } from "@/interfaces/menuSection";
import {
  doctorsTrackingEnabled,
  hasMetrcIntegrations,
  metrcEnabled
} from "@/router.utils";
import { store } from "@/store";

export const MenuSectionMetadata = (
  selectedTraceabilitySystem?: string
): MenuSection[] => [
  {
    title: "dashboard",
    subSections: [
      {
        name: "time_manager",
        route: "/time-clock",
        requiredSectionPolicy: {
          policy: policyList.viewTimeClockManager
        }
      }
    ],
    icon: require("@/assets/images/icon_primary_menu_dashboard@2x.png"),
    route: "/dashboard"
  },
  {
    title: "inventory.title",
    subSections: [
      {
        name: "inventory.current_inventory",
        route: "/inventory/products-view",
        requiredSectionPolicy: { policy: policyList.viewCurrentInventory }
      },
      {
        name: "inventory_tools",
        route: "/inventory/",
        categories: [
          {
            name: "products",
            route: "/inventory/products",
            requiredCategoryPolicy: { policy: policyList.viewProducts }
          },
          {
            name: "product_categories",
            route: "/inventory/product-category",
            requiredCategoryPolicy: {
              policy: policyList.viewProductsCategories
            }
          },
          {
            name: "batch_types",
            route: "/inventory/batch-types",
            requiredCategoryPolicy: { policy: policyList.viewBatchTypes }
          },
          {
            name: "inventory.strains",
            route: "/inventory/strains",
            requiredCategoryPolicy: { policy: policyList.viewStrains }
          },
          {
            name: "brands",
            route: "/inventory/brands",
            requiredCategoryPolicy: { policy: policyList.viewBrands }
          },
          {
            name: "vendors_labs",
            route: "/inventory/vendor",
            requiredCategoryPolicy: { policy: policyList.viewVendorsLabs }
          },
          {
            name: "inventory_rooms",
            route: "/inventory/rooms",
            requiredCategoryPolicy: {
              policy: policyList.viewInventoryRooms
            }
          },
          {
            name: "audit.audits",
            route: "/inventory/audit",
            requiredCategoryPolicy: {
              policy: policyList.viewInventoryAudit
            }
          }
        ]
      },
      {
        name: "inventory_settings",
        route: "/inventory/inventory-settings",
        requiredSectionPolicy: { policy: policyList.viewInventorySettings }
      },
      {
        name: "pricing_tools",
        route: "/inventory/pricing",
        categories: [
          {
            name: "price_points",
            route: "/inventory/pricing/points",
            requiredCategoryPolicy: {
              policy: policyList.pricePointsTool
            }
          },
          {
            name: "price_groups.title",
            route: "/inventory/pricing/price-groups",
            requiredCategoryPolicy: {
              policy: policyList.viewPriceGroup
            }
          },
          {
            name: "batch_level_pricing.title",
            route: "/inventory/pricing/batch-level",
            requiredCategoryPolicy: {
              policy: policyList.viewBatchLevelPricing
            }
          },
          {
            name: "member_level_pricing.title",
            route: "/inventory/pricing/member-level",
            requiredCategoryPolicy: {
              policy: policyList.viewMemberLevelPricing
            }
          },
          {
            name: "tax_categories",
            route: "/inventory/tax-categories",
            requiredCategoryPolicy: { policy: policyList.viewTaxCategories }
          },
          {
            name: "pricing_options",
            route: "/inventory/pricing/options",
            requiredCategoryPolicy: {
              policy: policyList.viewPricingOptions
            }
          }
        ]
      },
      {
        name: "batch_tools",
        route: "/inventory/batch/transfer",
        requiredSectionPolicy: [
          { policy: policyList.viewPurchaseOrdersTab },
          { policy: policyList.viewQuotesTab },
          { policy: policyList.viewInboundTransferTab },
          { policy: policyList.viewOutboundTransferTab },
          { policy: policyList.viewInvoicesTab },
          { policy: policyList.viewManifestsTab }
        ],
        showIfChildless: true,
        categories: [
          {
            name: "batch_transfer_manager_module.titles.new_batch",
            route: "/inventory/batch/transfer/new-batch/new",
            requiredCategoryPolicy: { policy: policyList.newBatchesTool }
          },
          {
            name: "batch_transfer_manager_module.titles.purchase_order",
            route: "/inventory/batch/transfer/purchase-order/new",
            requiredCategoryPolicy: {
              policy: policyList.viewPurchaseOrdersTab
            }
          },
          {
            name: "batch_transfer_manager_module.titles.inbound_transfer",
            route: "/inventory/batch/transfer/inbound-transfer/new",
            requiredCategoryPolicy: { policy: policyList.inboundTransfersTool }
          },
          {
            name: "batch_transfer_manager_module.titles.quote",
            route: "/inventory/batch/transfer/quote/new",
            requiredCategoryPolicy: { policy: policyList.createQuote }
          },
          {
            name: "batch_transfer_manager_module.titles.outbound_transfer",
            route: "/inventory/batch/transfer/outbound-transfer/new",
            requiredCategoryPolicy: {
              policy: policyList.createOutboundTransfer
            }
          },
          {
            name: "drivers.menu",
            route: "/inventory/drivers",
            requiredCategoryPolicy: { policy: policyList.viewDrivers }
          },
          {
            name: "vehicles.menu",
            route: "/inventory/vehicles",
            requiredCategoryPolicy: { policy: policyList.viewVehicles }
          }
        ]
      }
    ],
    icon: require("@/assets/images/icon_primary_menu_inventory@2x.png"),
    route: "/inventory/products-view",
    columns: 2
  },
  {
    title: "money",
    subSections: [
      {
        name: "money_manager.title",
        route: "/money/manager",
        requiredSectionPolicy: { policy: policyList.viewMoneyManager }
      },
      {
        name: "money_manager.employee_close_till",
        route: "/money/close-till",
        requiredSectionPolicy: {
          policy: policyList.employeeCloseTill
        }
      },
      {
        name: "payout_from_till",
        route: "/money/payout-till",
        requiredSectionPolicy: {
          policy: policyList.employeePayoutFromTill
        }
      }
    ],
    icon: require("@/assets/images/icon_primary_menu_money@2x.png"),
    route: "/money"
  },
  {
    title: "retail",
    subSections: [
      {
        name: "check_in",
        route: "/retail/check-in",
        requiredSectionPolicy: { policy: policyList.accessCheckInList }
      },
      {
        name: "pos",
        route: "/retail/pos",
        requiredSectionPolicy: { policy: policyList.accessPointOfSale }
      },
      {
        name: "order_fulfillment.title",
        route: "/retail/order-fulfillment",
        requiredSectionPolicy: {
          policy: policyList.accessToOrderFulfillment
        }
      },
      {
        name: "customer.customer_profile_management",
        route: "/retail/customers-management",
        requiredSectionPolicy: {
          policy: policyList.accessCustomersList
        }
      },
      {
        name: "retail_sales_history",
        route: "/retail/sales-history",
        requiredSectionPolicy: {
          policy: policyList.viewRetailSalesHistory
        }
      },
      {
        name: "retail_options",
        route: "/retail/options",
        categories: [
          {
            name: "discount_manager.title",
            route: "/retail/discount-manager",
            requiredCategoryPolicy: {
              policy: policyList.viewDiscountPrograms
            }
          },
          {
            name: "loyalty_programs.title",
            route: "/retail/loyalty-programs",
            requiredCategoryPolicy: {
              policy: policyList.viewLoyaltyPrograms
            }
          },
          {
            name: "retail_settings.title",
            route: "/retail/options/settings",
            requiredCategoryPolicy: {
              policy: policyList.viewRetailSettings
            }
          },
          {
            name: "doctors.title",
            route: "/retail/options/doctors",
            requiredCategoryPolicy: {
              policy: policyList.viewDoctors
            },
            visibleCondition: () => {
              return doctorsTrackingEnabled();
            }
          }
        ]
      }
    ],
    icon: require("@/assets/images/icon_primary_menu_retail@2x.png"),
    route: "/retail"
  },
  {
    title: "reports",
    requiredPolicyGroup: { policy: policyList.viewReportsTool },
    subSections: [
      {
        name: "reports_tool",
        route: "/reports",
        requiredSectionPolicy: {
          policy: policyList.viewReportsTool
        }
      }
    ],
    icon: require("@/assets/images/icon_primary_menu_reports@2x.png"),
    route: "/reports"
  },
  {
    title: "locations",
    subSections: [
      {
        name: "sales_limit",
        route: "/locations/sales-limit",
        requiredSectionPolicy: { policy: policyList.viewSalesLimits }
      },
      {
        name: "templates.manager",
        route: "/locations/template-manager",
        requiredSectionPolicy: { policy: policyList.viewTemplates }
      },
      {
        name: "reservations.reservations",
        route: "/locations/reservations",
        requiredSectionPolicy: { policy: policyList.viewReservations }
      },
      {
        name: "importModule.wizard_title",
        route: "/locations/import-export-wizard",
        requiredSectionPolicy: [
          { policy: policyList.importExportBatchTypes },
          { policy: policyList.importExportBrands },
          { policy: policyList.importExportLabResults },
          { policy: policyList.importExportCustomers },
          { policy: policyList.importExportProducts },
          { policy: policyList.importExportProductsCategories },
          { policy: policyList.importExportStrains },
          { policy: policyList.importExportVendorsLaboratories }
        ]
      }
      // { name: "API Uptime", route: "/locations/api-uptime" }
    ],
    icon: require("@/assets/images/icon_primary_menu_locations@2x.png"),
    route: "/locations"
  },
  {
    title: "metrc.menu_item",
    visibleCondition: () => {
      return (
        selectedTraceabilitySystem === "Metrc" &&
        store.getters["PermissionsModule/hasPermission"](
          policyList.viewModifyTagManager
        )
      );
    },
    subSections: [
      {
        name: "tag_manager.title",
        route: "/metrc-tools/tag-manager",
        requiredSectionPolicy: { policy: policyList.viewModifyTagManager }
      },
      ...(metrcEnabled()
        ? [
            {
              name: "metrc.items_manager",
              route: "/metrc-tools/item-manager",
              requiredSectionPolicy: { policy: policyList.viewMetrcItems }
            },
            {
              name: "metrc.strains_reconciliation",
              route: "/metrc-tools/strains-reconciliation",
              requiredSectionPolicy: {
                policy: policyList.viewModifyStrainReconciliation
              }
            },
            {
              name: "metrc.inventory_reconciliation",
              route: "/metrc-tools/inventory-reconciliation",
              requiredSectionPolicy: {
                policy: policyList.viewModifyInventoryReconciliation
              }
            },
            {
              name: "metrc.retail_sales_reconciliation_title",
              route: "/metrc-tools/retail-sales-reconciliation",
              requiredSectionPolicy: {
                policy: policyList.viewModifySalesReconciliation
              }
            }
          ]
        : [])
    ],
    icon: require("@/assets/images/icon_primary_menu_metrc@2x.png"),
    route: "/metrc-tools" // TBD
  },
  {
    title: "system_settings",
    subSections: [
      {
        name: "sharing.menu_item",
        route: "/system-settings/data-sharing-config",
        requiredSectionPolicy: { policy: policyList.viewDataSharingOptions }
      },
      {
        name: "sharing.data_sharing_tools",
        route: "/system-settings/data-sharing-config",
        categories: [
          {
            name: "sharing.shared_products",
            route: "/system-settings/shared-products",
            requiredCategoryPolicy: {
              policy: policyList.viewSharedProducts
            },
            visibleCondition: () => {
              return store.getters["AuthModule/isSeparatedOptionEnabled"];
            }
          }
        ]
      }
    ],
    icon: require("@/assets/images/icon_primary_menu_systemSettings@2x.png"),
    route: "/system-settings"
  },
  {
    title: "support.title",
    subSections: [
      {
        name: "support.create_a_ticket",
        route: "/create_a_ticket",
        requiredSectionPolicy: {
          policy: policyList.createATicket,
          group: "core"
        }
      },
      { name: "support.view_training_documentation", route: "/users" }
    ],
    icon: require("@/assets/images/icon_primary_menu_support@2x.png"),
    route: "about"
  }
];
