// Components
import AppsComponent from "@/components/megaMenu/Apps/Apps.component";
import BreadcrumbsComponent from "@/components/megaMenu/breadcrumbs/Breadcrumbs.component";
import ItemComponent from "@/components/megaMenu/item/Item.component";
import TitleBarComponent from "@/components/megaMenu/titleBar/TitleBar.component";
import { outsideClick } from "@/directives/outsideClick.directive";
// Interfaces
import { Account, App, User } from "@/interfaces/user";
import { EventBus } from "@/internal";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";

import Template from "./MegaMenu.template.vue";
import ProfileComponent from "./Profile/Profile.component";

@Component({
  mixins: [Template],
  components: {
    AppsComponent,
    BreadcrumbsComponent,
    ItemComponent,
    ProfileComponent,
    TitleBarComponent
  },
  directives: {
    outsideClick
  }
})
export default class MegaMenuComponent extends Vue {
  public menuExpanded: boolean = false;
  public fullScreenAction: boolean = false;
  public height = 0;
  public environment = "";

  @Getter("user", { namespace: "AuthModule" }) public user!: User;

  public apps: App[] = [
    {
      title: "cannaSuite",
      image: require("@/assets/images/app_brand_helix_technologies.svg"),
      path: process.env.VUE_APP_CORE_FRONTEND,
      app: "core"
    },
    {
      title: "cannalytics",
      image: require("@/assets/images/app_brand_cannalytics.svg"),
      path:
        process.env.VUE_APP_CANNALYTICS_FRONT ||
        "https://apps.helixcannalytics.com/accounts/user_login/",
      app: "cnl"
    }
  ];

  @Prop() public isExpandedView!: boolean;
  @Prop() public showMenu!: boolean;
  @Prop() public scrollPosition!: number;
  @Prop() public isScrollingUp!: boolean;

  public changeFullScreen() {
    this.fullScreenAction = !this.fullScreenAction;
    this.$emit("fullscreenToggled");
  }

  public mounted() {
    const host = process.env.VUE_APP_DISPENSARY_ENVIROMENT;
    if (!(host === "product")) {
      this.environment = host;
    }
    EventBus.$on("userHasNavigated", () => {
      this.menuExpanded = false;
    });
    EventBus.$on("userWasStopedFromNavigating", () => {
      this.menuExpanded = true;
    });

    this.height = this.$el.clientHeight;
  }

  public get megaMenuPositionStyles() {
    return {
      top: this.isScrollingUp ? 0 : this.scrollPosition * -1 + "px",
      transition: this.isScrollingUp
        ? "top 0.6s ease 0s"
        : this.scrollPosition > 128
        ? "top 0.8s ease 0s"
        : "none"
    };
  }

  get items() {
    const myApps: string[] = ["cnb", "cnl"];
    const items: object[] = [];
    const account: Account | undefined = this.user.accounts!.find(
      accountItem =>
        accountItem.id === Number(this.user.settings.current_account_id)
    );
    account!.services.forEach(service => myApps.push(String(service.id)));
    for (const app of this.apps) {
      if (myApps.includes(app.app)) {
        if (app.app !== "bdi") {
          items.push({ ...app, isLive: true });
        }
      } else {
        items.push({ ...app, isLive: false });
      }
    }
    return items;
  }
}

export {
  AppsComponent,
  MegaMenuComponent,
  BreadcrumbsComponent,
  ItemComponent,
  TitleBarComponent
};
