/* eslint-disable no-console */
// @ts-ignore
import { register } from "register-service-worker";

if (process.env.NODE_ENV === "production") {
  register(`/sw.js`, {
    ready() {
      // tslint:disable-next-line
      console.info("Service worker loaded.");
    },
    offline() {
      // tslint:disable-next-line
      console.warn("No internet connection found.");
    },
    error() {
      // tslint:disable-next-line
      console.error("Error loading service worker.");
    }
  });
}
