import { RootState } from "@/vuex/types";
import { Module } from "vuex";
import { actions } from "./megaMenu.actions";
import { getters } from "./megaMenu.getters";
import { mutations } from "./megaMenu.mutations";
import { state } from "./megaMenu.state";
import { MegaMenuState } from "./megaMenu.types";

const namespaced: boolean = true;
export const MegaMenuModule: Module<MegaMenuState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
