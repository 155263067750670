import {
  customerVariables,
  inventoryVariables,
  listVariables,
  pharmacistVariables,
  VarConfig
} from "@/components/templateEditor/editorItems/UIBlock.config";
import TemplateEditor from "@/components/templateEditor/editors.declaration";
import { currencyFilter } from "@/filters/currency.filter";
import { Location } from "@/interfaces/location";
import { fnsFormatDate } from "@/utils/date-fns.utils";
import { FNS_DATE_FORMATS } from "@/utils/date-fns.utils";
import isValid from "date-fns/isValid";
import get from "lodash/get";
import isNumber from "lodash/isNumber";
import { Component } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { VarFieldDefaultConfig } from "../UIBlock.config";
import { VarFieldDefaultValues } from "../UIBlock.declarations";
import UIBlockDefaultComponent from "../UIBlockDefault.component";
import Template from "./UIBlockVarField.template.vue";

const templateVariables: VarConfig[] = [
  ...inventoryVariables,
  ...customerVariables,
  ...listVariables,
  ...pharmacistVariables
];
@Component({
  mixins: [Template]
})
export default class UIBlockVarFieldComponent extends UIBlockDefaultComponent {
  @Getter("currentLocation", { namespace: "AuthModule" })
  public currentLocation!: Location;
  @Getter("hasBioTrackTraceIntegrations", { namespace: "AuthModule" })
  public hasBioTrackTraceIntegrations!: boolean;
  @Getter("bioTrackTraceEnabled", { namespace: "AuthModule" })
  public bioTrackTraceEnabled!: boolean;
  protected currentVar: VarConfig | null = null;
  protected block: TemplateEditor.UIBlocks.Block = {
    model: {
      type: "text-item",
      model: { ...VarFieldDefaultValues }
    },
    configuration: VarFieldDefaultConfig
  };

  protected get fromModel() {
    let modelValue: string = "";
    if (this.values.variable) {
      this.currentVar =
        templateVariables.find(
          item => item.label === this.values.variable.label
        ) || null;
      if (this.currentVar) {
        for (let query of this.currentVar.value) {
          if (query === "") {
            continue;
          }
          query = this.getLaboratoryQuery(query);
          modelValue = get(this.model, query);
          modelValue = this.formmatCurrency(
            modelValue,
            this.values.variable.label
          );
          modelValue = this.formatebatchId(
            modelValue,
            this.values.variable.label
          );
          if (modelValue) {
            break;
          }
        }
      }
    }
    return this.formatValue(modelValue);
  }

  private getLaboratoryQuery(query: string): string {
    const modelUid: string = get(this.model, "batch_barcode_uid");
    const laboratories = get(this.model, "fatherItem");
    if (laboratories && laboratories.length > 0) {
      for (let i = 0; i < laboratories.length; i++) {
        const laboratory = laboratories[i];
        if (laboratory.laboratory_batch_barcode_uid === modelUid) {
          if (query === "fatherItem.laboratory_name") {
            return "fatherItem[" + i + "].laboratory_name";
          } else if (query === "fatherItem.laboratory_license") {
            return "fatherItem[" + i + "].laboratory_license";
          } else if (query === "fatherItem.laboratory_testing_date") {
            return "fatherItem[" + i + "].laboratory_testing_date";
          }
        }
      }
    }

    return query;
  }

  protected get textStyles() {
    return {
      ...this.values,
      fontSize: this.values.fontSize + "px",
      fontWeight: this.values.bold ? 600 : 400,
      fontStyle: this.values.italic ? "italic" : "normal"
    };
  }

  private formmatCurrency(data: string, label: string): string {
    const toNumber: number = +data;
    if (
      (label === "label.pre_tax" || label === "label.post_tax") &&
      isNumber(toNumber) &&
      !isNaN(toNumber)
    ) {
      let currencyFormated;
      if (Number.isInteger(toNumber)) {
        currencyFormated = `$ ${toNumber}`;
      } else {
        currencyFormated = currencyFilter(toNumber);
      }
      return currencyFormated;
    }
    return data;
  }

  private formatebatchId(data: string, label: string): string {
    if (label === "label.batch_id") {
      if (this.model.new_batch_print) {
        if (this.model.biotrack_traceability_id) {
          return (data = this.model.biotrack_traceability_id);
        }
        return (data = this.model.batch_uid);
      } else {
        const marijuana =
          (this.model.product && this.model.product.marijuana) ||
          (this.model.product_info && this.model.product_info.marijuana);
        if (
          this.bioTrackTraceEnabled &&
          marijuana === 1 &&
          this.model.biotrack_traceability_id
        ) {
          return (data = this.model.biotrack_traceability_id);
        } else {
          return (data = this.model.batch_uid);
        }
      }
    }
    return data;
  }

  /*
   * Adds unit if varaible has unit array configured or formats date if dateFormat field is configured
   */

  private formatValue(raw: string): string {
    if (raw && this.currentVar && this.currentVar.unit) {
      let resolved;
      for (const unitPath of this.currentVar.unit) {
        resolved = get(this.model, unitPath);
        if (resolved) {
          break;
        }
      }
      return `${raw} ${resolved}`;
    }

    if (raw && this.currentVar && this.currentVar.dateFormat) {
      let dateFormat = "";
      if (this.currentLocation && this.currentLocation.date_format) {
        if (this.currentLocation.date_format === "MM-DD-YYYY") {
          dateFormat = FNS_DATE_FORMATS.DASH_DEFAULT;
        } else if (this.currentLocation.date_format === "DD-MM-YYYY") {
          dateFormat = FNS_DATE_FORMATS.DASH_ALTERNATE;
        } else {
          dateFormat = this.currentVar.dateFormat;
        }
      } else {
        dateFormat = this.currentVar.dateFormat;
      }

      const date = new Date(raw);
      if (isValid(date)) {
        return fnsFormatDate(date, dateFormat);
      }
    }

    return raw || "--";
  }
}
