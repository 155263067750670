import { PriceGroup } from "@/interfaces/product";
import { store } from "@/internal";
import { i18n } from "@/plugins/i18n";
import { batchLevelService } from "@/services/batchLevel.service";
import { messagesService } from "@/services/messages.service";
import { BatchLevelState } from "@/vuex/modules/inventory/batchLevel/batchLevel.types";
import { RootState } from "@/vuex/types";
import { TablePagination } from "helix-vue-components";
import { ActionContext, ActionTree } from "vuex";

type BatchLevelActionContext = ActionContext<BatchLevelState, RootState>;
type BatchLevelActionTree = ActionTree<BatchLevelState, RootState>;

export const actions: BatchLevelActionTree = {
  async loadBatchLevels(context: BatchLevelActionContext): Promise<any> {
    try {
      context.commit("setLoading", true);
      context.commit("setBatchLevel", null);
      const batchLevels = await batchLevelService.get();
      context.commit("setBatchLevels", batchLevels);
      const pagination: TablePagination = await batchLevelService.getPagination();
      context.commit("setPagination", pagination);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setLoading", false);
    }
  },
  async findBatchLevel(
    context: BatchLevelActionContext,
    id: number
  ): Promise<any> {
    try {
      context.commit("setLoading", true);
      const batchLevel: PriceGroup | null = await batchLevelService.findById(
        id
      );
      context.commit("setBatchLevel", batchLevel);
    } catch (error) {
      store.dispatch("RouterModule/errorRedirect", {
        location: { name: "batch-level-list" },
        error
      });
    } finally {
      context.commit("setLoading", false);
    }
  },
  async saveBatchLevel(
    context: BatchLevelActionContext,
    batchLevel: PriceGroup
  ): Promise<any> {
    try {
      if (batchLevel.uid) {
        // @ts-ignore
        batchLevel._method = "PUT";
        await batchLevelService.batchLevelput(batchLevel, batchLevel);
      } else {
        batchLevel.group_type = "BATCH_LEVEL";
        await batchLevelService.post(batchLevel);
      }
      messagesService.renderSuccessMessage("batch_level_saved");
      context.dispatch(
        "RouterModule/go",
        { name: "batch-level-list" },
        { root: true }
      );
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  },
  async deleteBatchLevel(
    context: BatchLevelActionContext,
    batchLevel: PriceGroup
  ): Promise<any> {
    try {
      await batchLevelService.disable(batchLevel);
      messagesService.renderSuccessMessage(
        "batch_level_pricing.delete_success"
      );
      context.dispatch("loadBatchLevels");
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  },
  async deleteMultiple(context, batchLevels: PriceGroup[]) {
    try {
      context.commit("setLoading", true);
      await batchLevelService.disableMultiple(
        batchLevels.map(batchLevel => batchLevel.id)
      );
      messagesService.renderSuccessMessage(
        i18n.t("deleted_successfully").toString()
      );
      context.dispatch("loadBatchLevels");
    } catch (e) {
      context.commit("setLoading", false);
      messagesService.renderErrorMessage(e);
    }
  },
  unsetBatchLevel(context: BatchLevelActionContext): void {
    context.commit("setBatchLevel", null);
  },
  async enableMultiple(context, batchLevels: PriceGroup[]) {
    try {
      context.commit("setLoading", true);
      await batchLevelService.enableMultiple(
        batchLevels.map(batchLevel => batchLevel.id)
      );
      messagesService.renderSuccessMessage(
        i18n.t("enabled_successfully").toString()
      );
      context.dispatch("loadBatchLevels");
    } catch (e) {
      context.commit("setLoading", false);
      messagesService.renderErrorMessage(e);
    }
  },
  async disableMultiple(context, batchLevels: PriceGroup[]) {
    try {
      context.commit("setLoading", true);
      await batchLevelService.disableMultiple(
        batchLevels.map(batchLevel => batchLevel.id)
      );
      messagesService.renderSuccessMessage(
        i18n.t("disabled_successfully").toString()
      );
      context.dispatch("loadBatchLevels");
    } catch (e) {
      context.commit("setLoading", false);
      messagesService.renderErrorMessage(e);
    }
  }
};
