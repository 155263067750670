import { MenuSectionMetadata } from "@/metadata/menuSection";
import { MegaMenuState } from "@/vuex/modules/megaMenu/megaMenu.types";

export const state: MegaMenuState = {
  menuSections: [],
  title: "",
  subTitle: "",
  icon: "",
  iconAction: null
};
