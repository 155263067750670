import { Vendor, VendorDocuments } from "@/interfaces/vendor";
import { store } from "@/internal";
import { messagesService } from "@/services/messages.service";
import { vendorService } from "@/services/vendor.service";
import { RootState } from "@/vuex/types";
import { TablePagination } from "helix-vue-components";
import { ActionContext, ActionTree } from "vuex";
import { VendorState } from "./vendor.types";

type VendorActionContext = ActionContext<VendorState, RootState>;
type VendorActionTree = ActionTree<VendorState, RootState>;

export const actions: VendorActionTree = {
  async loadVendors(
    context: VendorActionContext,
    getAll: boolean = false
  ): Promise<any> {
    context.commit("setLoading", true);
    const payload: Vendor[] = await vendorService.getVendors(getAll);
    const pagination: TablePagination = await vendorService.getPagination();
    context.commit("setPagination", pagination);
    context.commit("setVendors", payload);
    context.commit("setLoading", false);
  },

  async loadVendorFiles(
    context: VendorActionContext,
    vendorID: string
  ): Promise<any> {
    context.commit("setLoadingFiles", true);
    let payload: VendorDocuments[] | null = await vendorService.getFiles(
      vendorID
    );

    if (payload) {
      payload = payload.map(p => {
        return {
          ...p,
          user: `${p.created_by.first_name} ${p.created_by.last_name}`
        };
      });
    }
    const pagination: TablePagination = await vendorService.getPagination();
    context.commit("setPagination", pagination);
    context.commit("setVendorFiles", payload);
    context.commit("setLoadingFiles", false);
  },

  async deleteVendor(
    context: VendorActionContext,
    vendor: Vendor
  ): Promise<any> {
    try {
      context.commit("setLoading", true);
      await vendorService.removeVendor(vendor);
      messagesService.renderSuccessMessage("vendors.vendor_deleted");
      context.dispatch("loadVendors");
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setLoading", false);
    }
  },

  async deleteMultipleVendors(context, vendorsList: Vendor[]) {
    try {
      context.commit("setLoading", true);
      const vendorIds = vendorsList.map(vendor => vendor.id);
      await vendorService.disableMultiple(vendorIds);
      messagesService.renderSuccessMessage("updated_successfully");
      context.dispatch("loadVendors");
    } catch (e) {
      messagesService.renderErrorMessage(e);
      context.commit("setLoading", false);
    }
  },

  async enableMultiple(context, vendorsList: Vendor[]) {
    try {
      context.commit("setLoading", true);
      const vendorIds = vendorsList.map(vendor => vendor.id);
      await vendorService.enableMultiple(vendorIds);
      messagesService.renderSuccessMessage("updated_successfully");
      context.dispatch("loadVendors");
    } catch (e) {
      messagesService.renderErrorMessage(e);
      context.commit("setLoading", false);
    }
  },

  async disableMultiple(context, vendorsList: Vendor[]) {
    try {
      context.commit("setLoading", true);
      const vendorIds = vendorsList.map(vendor => vendor.id);
      await vendorService.disableMultiple(vendorIds);
      messagesService.renderSuccessMessage("updated_successfully");
      context.dispatch("loadVendors");
    } catch (e) {
      messagesService.renderErrorMessage(e);
      context.commit("setLoading", false);
    }
  },

  async deleteVendorFiles(
    context,
    data: { vendorID: string; filesIDs: number[] }
  ) {
    context.commit("setLoading", true);
    await vendorService.deleteFiles(data.vendorID, data.filesIDs);
    context.dispatch("loadVendorFiles");
  },

  async addVendor(
    context: VendorActionContext,
    vendor: any
  ): Promise<Vendor | void> {
    context.commit("setLoading", true);
    const response = await vendorService.addVendor(vendor);
    messagesService.renderSuccessMessage("vendors.vendor_added");
    store.dispatch("RouterModule/go", {
      name: "vendor-list"
    });
    context.commit("setLoading", false);
    return response;
  },

  async getVendor(
    context: VendorActionContext,
    vendorId: string
  ): Promise<any> {
    try {
      context.commit("setLoading", true);
      const vendor = await vendorService.getVendor(vendorId);
      context.commit("setEditVendor", vendor);
    } catch (e) {
      store.dispatch("RouterModule/errorRedirect", {
        location: { name: "vendor-list" },
        error: e
      });
    } finally {
      context.commit("setLoading", false);
    }
  },

  async searchVendors(
    context: VendorActionContext,
    value: string
  ): Promise<any> {
    if (value || !context.state.defaultVendors.length) {
      try {
        const payload = await vendorService.search(value);
        context.commit("setVendors", payload);
      } catch (e) {
        messagesService.renderErrorMessage(e);
      }
    } else {
      context.commit("setVendors", context.state.defaultVendors);
    }
  },

  async updateVendor(
    context: VendorActionContext,
    data: { vendor: Vendor; deletedFiles: any[] }
  ): Promise<Vendor | void> {
    const response = await vendorService.updateVendor(
      data.vendor,
      data.deletedFiles
    );
    messagesService.renderSuccessMessage("vendors.vendor_updated");

    store.dispatch("RouterModule/go", {
      name: "vendor-list"
    });
    return response;
  },

  async clearVendorToEdit(context: VendorActionContext): Promise<any> {
    context.commit("setEditVendor", null);
    context.commit("setVendorFiles", []);
  }
};
