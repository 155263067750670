import { Product } from "@/interfaces/product";
import { messagesService } from "@/services/messages.service";
import { productService } from "@/services/product.service";
import { PricePointState } from "@/vuex/modules/inventory/pricePoint/pricePoint.types";
import { RootState } from "@/vuex/types";
import { ActionContext, ActionTree } from "vuex";

type PricePointActionContext = ActionContext<PricePointState, RootState>;
type PricePointActionTree = ActionTree<PricePointState, RootState>;

export const actions: PricePointActionTree = {
  async findProducts(
    context: PricePointActionContext,
    productName: string
  ): Promise<any> {
    try {
      const query = {
        "q[name_contains]": productName,
        "q[status.published_eq]": 1
      };
      const products: Product[] = await productService.get(query);
      context.commit("setProducts", products);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }
};
