import { PageNav } from "helix-vue-components";
import { MutationTree } from "vuex";
import { PageNavState } from "./pageNav.types";

type MegaMenuMutationTree = MutationTree<PageNavState>;

export const mutations: MegaMenuMutationTree = {
  setPageNavConfig(state: PageNavState, config: PageNav) {
    state.pageNav = config;
  }
};
