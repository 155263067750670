export enum DiscountTypes {
  PERCENTAGE_DISCOUNT_TYPE = "discount_manager.percentage_discount",
  FIXED_DISCOUNT_TYPE = "discount_manager.fixed_discount",
  BIRTH_DATE_DISCOUNT_TYPE = "discount_manager.birthdate_discount",
  BUY_ONE_GET_ONE_DISCOUNT_TYPE = "discount_manager.bogo_discount",
  EXPIRING_PRODUCT_DISCOUNT_TYPE = "discount_manager.expiring_discount"
}

export enum DiscountValues {
  PERCENTAGE = "PERCENTAGE",
  FIXED = "FIXED"
}
export enum DiscountScope {
  LINE_ITEM = "discount_manager.LINE_ITEM",
  WHOLE_ORDER = "discount_manager.WHOLE_ORDER"
}

export const overwriteDiscountCodes = {
  POS_FIXED_OVERWRITE: "POS_FIXED_OVERWRITE",
  POS_FIXED_OVERWRITE_LINE_ITEM: "POS_FIXED_OVERWRITE_LINE_ITEM",
  POS_PERCENTAGE_OVERWRITE: "POS_PERCENTAGE_OVERWRITE",
  POS_PERCENTAGE_OVERWRITE_LINE_ITEM: "POS_PERCENTAGE_OVERWRITE_LINE_ITEM"
};

export const loyaltyDiscountsCodes = {
  LOYALTY_PER_ITEM: "LOYALTY_PER_ITEM_DISCOUNT",
  LOYALTY_PER_ORDER: "LOYALTY_PER_ORDER_DISCOUNT"
};
