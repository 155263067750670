import { Location } from "@/interfaces/location";
import { LocationState } from "@/vuex/modules/location/location.types";
import { RootState } from "@/vuex/types";
import { GetterTree } from "vuex";

type LocationGetter = GetterTree<LocationState, RootState>;

export const getters: LocationGetter = {
  locations(state: LocationState): Location[] {
    return state.locations;
  },
  defaultLocations(state: LocationState): Location[] {
    return state.defaultLocations;
  },
  loading(state: LocationState): boolean {
    return state.loading;
  }
};
