import { Order, Refund } from "@/interfaces/order";
import { i18n } from "@/plugins/i18n";
import { TableFieldType, TableHeader } from "helix-vue-components";

const amountCssClass = (amount: number): string => {
  if (amount > 0) {
    return "show-negative-number";
  }

  return "";
};

export const SalesHistoryTableMetadata = (
  hasMetrc: boolean,
  hasPmpIntegration: boolean
): TableHeader[] => {
  const headers: TableHeader[] = [
    {
      value: "order_number",
      label: i18n.t("transaction_id").toString(),
      fieldComponent: TableFieldType.string,
      class: "tdt__headers__fieldLong",
      sortable: true
    },
    {
      value: "sold_at",
      label: i18n.t("date_&_time").toString(),
      fieldComponent: TableFieldType.fnsDate,
      class: "tdt__headers__fieldLong",
      dateFormat: "MM/DD/YYYY hh:mm a",
      sortable: true
    },
    {
      value: "customer.first_name/j/customer.last_name",
      label: i18n.t("customer.customer").toString(),
      fieldComponent: TableFieldType.string,
      class: "tdt__headers__fieldLong"
    },
    {
      value: "source",
      label: i18n.t("reports_group.filters.transaction_type").toString(),
      fieldComponent: TableFieldType.string,
      class: "tdt__headers__fieldLong"
    },
    {
      value: "source_name",
      label: i18n.t("reports_group.filters.source").toString(),
      fieldComponent: TableFieldType.custom,
      class: "tdt__headers__fieldLong"
    },
    {
      value: "total",
      label: i18n.t("total").toString(),
      fieldComponent: TableFieldType.currency,
      class: "tdt__headers__fieldLong",
      sortable: true
    },
    {
      value: "user.first_name/j/user.last_name",
      label: i18n.t("user").toString(),
      fieldComponent: TableFieldType.string,
      class: "tdt__headers__fieldLong"
    }
  ];

  if (hasMetrc) {
    headers.splice(headers.length - 1, 0, {
      value: "trc_receipt_id",
      label: i18n.t("metrc_reciept_number").toString(),
      fieldComponent: TableFieldType.string,
      class: "tdt__headers__fieldLong",
      sortable: true,
      conditionalClassFn: (row: Order) => {
        const classFlag =
          row.trc_receipt_id && !isNaN(parseInt(row.trc_receipt_id!, 10))
            ? "up-arrow"
            : "no-red-dot";
        const message = i18n.t("non_cannabis").toString();
        if (
          row &&
          row.order_items &&
          !row.order_items[0].product_info!.marijuana
        ) {
          if (row.trc_receipt_id === null) {
            row.trc_receipt_id = message;
          } else if (
            row.trc_receipt_id &&
            !row.trc_receipt_id!.includes(message)
          ) {
            row.trc_receipt_id = message;
          }
          return "";
        }
        return classFlag;
      }
    });
  }
  if (hasPmpIntegration) {
    headers.splice(headers.length - 1, 0, {
      value: "pmp_status",
      label: i18n.t("pmp.pmp_upload").toString(),
      fieldComponent: TableFieldType.custom,
      class: "tdt__headers__fieldLong",
      sortable: true
    });
  }

  return headers;
};

export const RefundHistoryFields = (
  changingField: string
): Array<{ label: string; value: string }> => {
  let field: any[] = [];
  switch (changingField) {
    case "SECONDARY_ID":
      field = [
        "inventory_settings_view.secondary_id",
        "order_items.batch_detail.tracking_id"
      ];
      break;
    case "NDC_NUMBER":
      field = ["inventory_settings_view.ndc_number", "product_info.ndc_number"];
      break;
    default:
      // NONE
      field = ["", ""];
      break;
  }
  const fieldsToReturn = [
    {
      label: i18n.t("batch_id").toString(),
      value: "batch_uid"
    },
    {
      label: i18n.t(field[0]).toString(),
      value: field[1]
    },

    {
      label: i18n.t("product").toString(),
      value: "order_items.product_info.name"
    },
    {
      label: i18n.t("price").toString(),
      value: "price_raw"
    },
    {
      label: i18n.t("quantity").toString(),
      value: "quantity/j/order_items.product_info.product_unit"
    },
    {
      label: i18n.t("line_item_discounts").toString(),
      value: "discount_total"
    },
    {
      label: i18n.t("price_after_discounts").toString(),
      value: "sub_total"
    },
    {
      label: i18n.t("line_item_tax").toString(),
      value: "tax_total"
    },
    {
      label: i18n.t("refund_retail_sales.line_item_total").toString(),
      value: "total"
    }
  ];

  if (changingField === "NONE") {
    fieldsToReturn.splice(1, 1);
  }

  return fieldsToReturn;
};

export const ReconciliationOrderFields = [
  {
    label: i18n.t("batch_id").toString(),
    value: "batch_uid"
  },
  {
    label: i18n.t("tracking_id").toString(),
    value: "tracking_id"
  },
  {
    label: i18n.t("product").toString(),
    value: "product"
  },
  {
    label: i18n.t("price").toString(),
    value: "price/b/$"
  },
  {
    label: i18n.t("line_item_discounts").toString(),
    value: "line_item_formatted/b/$"
  },
  {
    label: i18n.t("price_after_discounts").toString(),
    value: "price_formatted/b/$"
  },
  {
    label: i18n.t("quantity").toString(),
    value: "quantity_formatted"
  }
];

export const RefundsHistoryTableMetadata = (
  hasMetrc: boolean,
  hasPmpIntegration: boolean
): TableHeader[] => {
  const headers: TableHeader[] = [
    {
      value: "operation_uid",
      label: i18n.t("transaction_id").toString(),
      fieldComponent: TableFieldType.string,
      class: "tdt__headers__fieldLong",
      sortable: true
    },
    {
      value: "created_at",
      label: i18n.t("date_&_time").toString(),
      fieldComponent: TableFieldType.fnsDate,
      class: "tdt__headers__fieldLong",
      dateFormat: "MM/DD/YYYY hh:mm a",
      sortable: true
    },
    {
      value: "customer.first_name/j/customer.last_name",
      label: i18n.t("customer.customer").toString(),
      fieldComponent: TableFieldType.string,
      class: "tdt__headers__fieldLong"
    },
    {
      value: "source",
      label: i18n.t("reports_group.filters.transaction_type").toString(),
      fieldComponent: TableFieldType.string,
      class: "tdt__headers__fieldLong"
    },
    {
      value: "source_name",
      label: i18n.t("reports_group.filters.source").toString(),
      fieldComponent: TableFieldType.custom,
      class: "tdt__headers__fieldLong"
    },
    {
      value: "total",
      label: i18n.t("total").toString(),
      fieldComponent: TableFieldType.currency,
      class: "tdt__headers__fieldLong",
      sortable: true,
      conditionalClassFn: (refund: Refund) => {
        return amountCssClass(refund.total);
      }
    },
    {
      value: "user.first_name/j/user.last_name",
      label: i18n.t("user").toString(),
      fieldComponent: TableFieldType.string,
      class: "tdt__headers__fieldLong"
    }
  ];

  if (hasMetrc) {
    headers.splice(headers.length - 1, 0, {
      value: "trc_receipt_id",
      label: i18n.t("metrc_reciept_number").toString(),
      fieldComponent: TableFieldType.string,
      class: "tdt__headers__fieldLong",
      sortable: true,
      conditionalClassFn: (row: any) => {
        const classFlag =
          row.trc_receipt_id && !isNaN(parseInt(row.trc_receipt_id, 10))
            ? "up-arrow"
            : "no-red-dot";
        const message = i18n.t("non_cannabis").toString();
        if (
          row &&
          row.order_items &&
          row.order_items[0] &&
          !row.order_items[0].product_info.marijuana
        ) {
          if (row.trc_receipt_id === null) {
            row.trc_receipt_id = message;
          } else if (
            row.trc_receipt_id &&
            !row.trc_receipt_id.includes(message)
          ) {
            row.trc_receipt_id = message;
          }
          return "";
        }
        return classFlag;
      }
    });
  }

  if (hasPmpIntegration) {
    headers.splice(headers.length - 1, 0, {
      value: "pmp_status",
      label: i18n.t("pmp.pmp_upload").toString(),
      fieldComponent: TableFieldType.custom,
      class: "tdt__headers__fieldLong",
      sortable: true
    });
  }

  return headers;
};

export const itemsOrder = [
  {
    label: i18n.t("batch_id").toString(),
    value: "batch_barcode_uid"
  },
  {
    label: i18n.t("tracking_id").toString(),
    value: "batch.tracking_id"
  },
  {
    label: i18n.t("product").toString(),
    value: "product_info.name"
  },
  {
    label: i18n.t("price").toString(),
    value: "price_raw/b/$"
  },
  {
    label: i18n.t("line_item_discounts").toString(),
    value: "line_item_discounts"
  },
  {
    label: i18n.t("price_after_discounts").toString(),
    value: "price_after_discounts/b/$"
  }
];

export const SalesHistoryCustomerTableMetadata: TableHeader[] = [
  {
    value: "id",
    label: i18n.t("transaction_id").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: false
  },
  {
    value: "updated_at",
    label: i18n.t("date").toString(),
    fieldComponent: TableFieldType.fnsDate,
    class: "tdt__headers__fieldLong",
    sortable: false
  },
  {
    value: "location.name",
    label: i18n.t("location").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: false
  },
  {
    value: "customer.first_name/j/customer.last_name",
    label: i18n.t("customer.customer").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: false
  },
  {
    value: "sub_total",
    label: i18n.t("subtotal").toString(),
    fieldComponent: TableFieldType.currency,
    class: "tdt__headers__fieldLong",
    sortable: false
  },
  {
    value: "tax_total",
    label: i18n.t("tax").toString(),
    fieldComponent: TableFieldType.currency,
    class: "tdt__headers__fieldLong",
    sortable: false
  },
  {
    value: "line_order_discounts",
    label: i18n.t("discounts").toString(),
    fieldComponent: TableFieldType.currency,
    class: "tdt__headers__fieldLong",
    sortable: false
  },
  {
    value: "total",
    label: i18n.t("total").toString(),
    fieldComponent: TableFieldType.currency,
    class: "tdt__headers__fieldLong",
    sortable: false
  },
  {
    value: "change_due",
    label: i18n.t("change_due").toString(),
    fieldComponent: TableFieldType.currency,
    class: "tdt__headers__fieldLong",
    sortable: false
  }
];

export const SalesPreviousCustomerTableMetadata = (
  hasMetrc: boolean
): TableHeader[] => {
  const headers: TableHeader[] = [
    {
      value: "order_number",
      label: i18n.t("transaction_id").toString(),
      fieldComponent: TableFieldType.string,
      class: "tdt__headers__fieldLong",
      sortable: false
    },
    {
      value: "updated_at",
      label: i18n.t("date").toString(),
      fieldComponent: TableFieldType.fnsDate,
      class: "tdt__headers__fieldLong",
      sortable: false
    },
    {
      value: "location.name",
      label: i18n.t("location").toString(),
      fieldComponent: TableFieldType.string,
      class: "tdt__headers__fieldLong",
      sortable: false
    },
    {
      value: "customer.first_name/j/customer.last_name",
      label: i18n.t("customer.customer").toString(),
      fieldComponent: TableFieldType.string,
      class: "tdt__headers__fieldLong",
      sortable: false
    },
    {
      value: "sub_total",
      label: i18n.t("subtotal").toString(),
      fieldComponent: TableFieldType.currency,
      class: "tdt__headers__fieldLong",
      sortable: false
    },
    {
      value: "tax_total",
      label: i18n.t("tax").toString(),
      fieldComponent: TableFieldType.currency,
      class: "tdt__headers__fieldLong",
      sortable: false
    },
    {
      value: "line_order_discounts",
      label: i18n.t("discounts").toString(),
      fieldComponent: TableFieldType.currency,
      class: "tdt__headers__fieldLong",
      sortable: false
    },
    {
      value: "total",
      label: i18n.t("total").toString(),
      fieldComponent: TableFieldType.currency,
      class: "tdt__headers__fieldLong",
      sortable: false
    },
    {
      value: "change_due",
      label: i18n.t("change_due").toString(),
      fieldComponent: TableFieldType.currency,
      class: "tdt__headers__fieldLong",
      sortable: false
    },
    {
      value: "user.first_name/j/user.last_name",
      label: i18n.t("user").toString(),
      fieldComponent: TableFieldType.string,
      class: "tdt__headers__fieldLong",
      sortable: false
    }
  ];

  if (hasMetrc) {
    headers.splice(headers.length - 1, 0, {
      value: "trc_receipt_id",
      label: i18n.t("metrc_reciept_number").toString(),
      fieldComponent: TableFieldType.string,
      class: "tdt__headers__fieldLong",
      sortable: true,
      conditionalClassFn: (row: Order) => {
        const classFlag =
          row.trc_receipt_id && !isNaN(parseInt(row.trc_receipt_id!, 10))
            ? "up-arrow"
            : "no-red-dot";
        const message = i18n.t("non_cannabis").toString();
        if (
          row &&
          row.order_items &&
          !row.order_items[0].product_info!.marijuana
        ) {
          if (row.trc_receipt_id === null) {
            row.trc_receipt_id = message;
          } else if (
            row.trc_receipt_id &&
            !row.trc_receipt_id!.includes(message)
          ) {
            row.trc_receipt_id = message;
          }
          return "";
        }
        return classFlag;
      }
    });
  }

  return headers;
};

export const SalesPreviusCustomerTableMetadata: TableHeader[] = [
  {
    value: "order_number",
    label: i18n.t("transaction_id").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: false
  },
  {
    value: "updated_at",
    label: i18n.t("date").toString(),
    fieldComponent: TableFieldType.fnsDate,
    class: "tdt__headers__fieldLong",
    sortable: false
  },
  {
    value: "location.name",
    label: i18n.t("location").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: false
  },
  {
    value: "customer.first_name/j/customer.last_name",
    label: i18n.t("customer.customer").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: false
  },
  {
    value: "sub_total",
    label: i18n.t("subtotal").toString(),
    fieldComponent: TableFieldType.currency,
    class: "tdt__headers__fieldLong",
    sortable: false
  },
  {
    value: "tax_total",
    label: i18n.t("tax").toString(),
    fieldComponent: TableFieldType.currency,
    class: "tdt__headers__fieldLong",
    sortable: false
  },
  {
    value: "line_order_discounts",
    label: i18n.t("discounts").toString(),
    fieldComponent: TableFieldType.currency,
    class: "tdt__headers__fieldLong",
    sortable: false
  },
  {
    value: "total",
    label: i18n.t("total").toString(),
    fieldComponent: TableFieldType.currency,
    class: "tdt__headers__fieldLong",
    sortable: false
  },
  {
    value: "change_due",
    label: i18n.t("change_due").toString(),
    fieldComponent: TableFieldType.currency,
    class: "tdt__headers__fieldLong",
    sortable: false
  },
  {
    value: "user.first_name/j/user.last_name",
    label: i18n.t("user").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: false
  }
];

export const SelectOrdersTableMetadata: TableHeader[] = [
  {
    value: "order_number",
    label: i18n.t("order_number").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: false
  },
  {
    label: i18n.t("status").toString(),
    value: "status",
    fieldComponent: TableFieldType.statusCheckin
  },
  {
    value: "customer.first_name/j/customer.last_name",
    label: i18n.t("name").toString(),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong"
  },
  {
    value: "created_at",
    label: i18n.t("order_time").toString(),
    fieldComponent: TableFieldType.fnsDate,
    class: "tdt__headers__fieldLong",
    sortable: false
  },
  {
    value: "total",
    label: i18n.t("total").toString(),
    fieldComponent: TableFieldType.currency,
    class: "tdt__headers__fieldLong",
    sortable: false
  }
];
