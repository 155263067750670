import { PharmacistInterface } from "@/interfaces/pharmacist";
import HttpService from "./http.service";
import { messagesService } from "./messages.service";

export class PharmacistService extends HttpService {
  protected uri: string = "pharmacists";

  public async getAll(): Promise<PharmacistInterface[]> {
    try {
      const response = await super.get({ no_pagination: true }, false);
      return response.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return [];
    }
  }
}

export const pharmacistService = new PharmacistService();
