import { PusherNotification } from "@/interfaces/notification";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Template from "./ChangesNotification.template.vue";

@Component({
  mixins: [Template]
})
export default class ChangesNotificationModal extends Vue {
  @Prop() public actionResolve!: () => void;
  @Prop() public data!: PusherNotification;
  public resolve() {
    this.actionResolve();
    this.$emit("resolve");
  }

  public reject() {
    this.$emit("reject");
  }

  @Watch("$route")
  protected onRouteChange() {
    this.reject();
  }
}
