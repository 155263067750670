import { render, staticRenderFns } from "./UIBlockRowField.template.vue?vue&type=template&id=02ec4da2&scoped=true&"
var script = {}
import style0 from "./UIBlockRowField.template.vue?vue&type=style&index=0&id=02ec4da2&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02ec4da2",
  null
  
)

export default component.exports