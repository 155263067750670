import { store } from "@/internal";
import { i18n } from "@/plugins/i18n";
import { SecurityPinService } from "@/plugins/security-pin/security-pin.service";
import { messagesService } from "@/services/messages.service";
import { Callback } from "@/types/types";
import Axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse
} from "axios";
import Cookie from "js-cookie";
import Vue from "vue";
declare module "vue/types/vue" {
  interface VueConstructor {
    axios: AxiosInstance;
  }
}

export namespace Http {
  export class ApiJwtService extends Vue {
    protected pinCodeModules = ["change_room", "modify_payment"];
    protected noResponseFeedback = { message: i18n.t("no_response") };
    protected errorHandler: { [key: number]: Callback } = {
      401: (error: AxiosError) => {
        store.dispatch("AuthModule/logout");
      },
      403: (error: AxiosError) => {
        messagesService.renderErrorMessage(error);
      },
      500: (error: AxiosError) => {
        messagesService.renderErrorMessage(error);
      },
      0: () => {
        messagesService.renderErrorMessage(this.noResponseFeedback);
      }
    };
    constructor() {
      super();
      this.request();
      this.response();
    }

    protected request() {
      Vue.axios.interceptors.request.use(
        async (config: AxiosRequestConfig) => {
          const token = Cookie.get("auth_data");
          if (token) {
            config.headers.Authorization = `Bearer ${token}`;
          } else {
            store.dispatch("AuthModule/logout");
          }
          config.headers.Accept = "application/json";
          config.headers["Content-Type"] =
            config.headers["Content-Type"] || "application/json";
          // config.headers["Access-Control-Allow-Origin"] = "*";
          config.params = config.params || {};
          if (process.env.VUE_APP_XDEBUG_PARAMETER === "1") {
            config.params.XDEBUG_SESSION_START =
              process.env.VUE_APP_XDEBUG_PARAMETER_VALUE;
          }

          const isRequiredPinCode = this.pinCodeModules.find(
            (module: string | RegExp) => {
              return !!config.url!.match(module);
            }
          );
          if (isRequiredPinCode) {
            const pinService = new SecurityPinService();
            const pin = await pinService
              .ensure(
                "Security Pin",
                "You must enter a valid PIN code to do this action"
              )
              .catch((e: Error) => {
                // Nothing to do
              });
            if (pin) {
              config.headers.pincode = pin;
            } else {
              return {
                ...config,
                cancelToken: new Axios.CancelToken(cancel =>
                  cancel("security_pin.required_message")
                )
              };
            }
          }
          return config;
        },
        (err: any) => {
          return Promise.reject(err);
        }
      );
    }

    protected response() {
      Vue.axios.interceptors.response.use(
        (response: AxiosResponse) => {
          return response;
        },
        (error: AxiosError) => {
          if (error.response && this.errorHandler[error.response.status]) {
            this.errorHandler[error.response.status](error);
          }
          if (!error.response) {
            this.errorHandler[0]();
          }
          return Promise.reject(error);
        }
      );
    }
  }
}
